import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SearchInput from "../searchInput/SearchInput";
import styles from "./Swap.module.css";
import Select from "../../../../UI-components/select/index";
import {
  filterByCurrency,
  filterByStatus,
  filterByDate,
} from "../../../../../lib/constant";
import Button from "../../../../UI-components/button";
import Loader from "../../../../UI-components/loader/Loader";
import Pagination from "../../../../UI-components/pagination/Pagination";
import TableOne from "../../../../UI-components/new -tables/Table";
import EmptyState from "../../../../UI-components/null state/EmptyState";
import { Typography } from "@mui/material";

const swapTransactionTableHead = [
  "CURRENCY",
  "DATE/TIME",
  "AMOUNT FROM",
  "AMOUNT TO",
  "TNX REFERENCE",
  "CHANNEL",
  "STATUS",
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: 12,
    border: "none",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 12,
  }),
};

function Swap({
  swapIsFetched,
  currencies,
  swapFilterData,
  loadingSwap,
  handlePageChange,
  swaps,
  handleGetSwaps,
  handleFilter,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const {
    register: registerSearch,
    setValue: setValueSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    formState: { errors: errorsSearch },
  } = useForm();
  const [searched, setSearched] = useState(false);
  const searchedValue = watchSearch("search");

  useEffect(() => {
    Object.keys(swapFilterData).map((item) => {
      setValue(item, swapFilterData[item]);
      return null;
    });
  }, [swapFilterData]);

  const onSubmit = async (data) => {
    handleFilter("filter", data);
  };

  const onSubmitSearch = (data) => {
    setSearched(!searched);
    handleFilter("search", data);
  };

  return (
    <div className={styles.trx_page}>
      <div className={styles.filters}>
        <div className={styles.search_main}>
          <div
            style={{ width: searched && swapIsFetched ? "auto" : 300 }}
            className={styles.search}
          >
            <SearchInput
              handleClose={() => {
                handleGetSwaps();
              }}
              searchedValue={searchedValue}
              setSearched={setSearched}
              searched={searched && swapIsFetched}
              handleSubmit={handleSubmitSearch}
              onSubmit={onSubmitSearch}
              register={registerSearch}
              setValue={setValueSearch}
              name={"search"}
            />
          </div>
          {searched && swapIsFetched && (
            <div className={styles.search_result_info}>
              <Typography>"{swaps?.data?.length} result found"</Typography>
            </div>
          )}
        </div>

        {searched && swapIsFetched ? null : (
          <div className={styles.filter_selects}>
            <div className={styles.select}>
              <span>Date</span>
              <Select
                border={"none"}
                styles={customStyles}
                listOptions={filterByDate}
                register={register}
                name={"date"}
                required={false}
              />
            </div>
            <div className={styles.select}>
              <span>Currency</span>
              <Select
                border={"none"}
                styles={customStyles}
                listOptions={filterByCurrency}
                register={register}
                name={"currency"}
                required={false}
              />
            </div>
            <div className={styles.select}>
              <span>Status</span>
              <Select
                border={"none"}
                styles={customStyles}
                listOptions={filterByStatus}
                register={register}
                name={"status"}
                required={false}
              />
            </div>
            <div className={styles.filter_btn}>
              <Button
                Styles
                width="200px"
                text={"Apply filters"}
                bgColor={"#E7F1FF"}
                color={"#1A2CCE"}
                size="sm"
                //fullwidth
                type="submit"
                border={"0.4px solid #1A2CCE"}
                boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
                borderRadius={"5px"}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        )}
      </div>
      {loadingSwap ? (
        <Loader />
      ) : (
        <>
          <div>
            {swaps?.data?.length === 0 ? (
              <EmptyState
                primaryText={
                  swaps?.from === "filter"
                    ? "Swap transactions not found"
                    : "No Swap transactions here yet"
                }
                secondaryText={
                  swaps?.from === "filter"
                    ? "No Swap transactions with filtered parameters"
                    : "it looks like you dont have any Swap transactions yet"
                }
                handleRefresh={handleGetSwaps}
              />
            ) : (
              <>
                <TableOne
                  currencies={currencies}
                  handleRefresh={handleGetSwaps}
                  type={"transaction"}
                  transactionType={"swap"}
                  tableBody={swaps?.data}
                  tableHeadData={swapTransactionTableHead}
                />
                {swaps?.data?.length === 0 ? null : (
                  <Pagination
                    type={"swap"}
                    handlePageChange={handlePageChange}
                    meta={swaps?.meta}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Swap;
