// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewWalletStepTwo_btns__6WoOB {\n  margin-top: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.NewWalletStepTwo_btns__6WoOB {\n  margin-left: 12px;\n}\n\n.NewWalletStepTwo_field__\\+1s3P {\n  margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/wallets/components/newWallet/step2/NewWalletStepTwo.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".btns {\n  margin-top: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.btns {\n  margin-left: 12px;\n}\n\n.field {\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btns": "NewWalletStepTwo_btns__6WoOB",
	"field": "NewWalletStepTwo_field__+1s3P"
};
export default ___CSS_LOADER_EXPORT___;
