import axios from "axios";

export const CREATE_WALLET = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}wallets`, data)
    .then((res) => {
      return res.data;
    });
};


export const CREATE_WALLET_ACCOUNTS = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}wallets/${data.wallet_reference}`, data.data)
    .then((res) => {
      return res.data;
    });
};