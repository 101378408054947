import { Typography } from "@mui/material";
import React from "react";
import Button from "../../../../../../UI-components/button";
import Search from "../../../../../../UI-components/search";
import styles from "./WalletAccountFilter.module.css";
import add_icon from "../../../../../../../assets/images/add-icon.svg";
import useModal from "../../../../../../../customHooks/useModal";

function WalletAccountsFilter({toggle, setSearchValue }) {
  

  return (
    <div className={styles.filter}>
      <div className={styles.filter_form}>
        <div className={styles.search_field}>
          <Typography>Search for an account</Typography>

          <Search
            type={"filter"}
            handleFilter={(name, value) => {
              setSearchValue(value.search);
            }}
            name={"search"}
            placeholder={"Search reference no."}
          />
        </div>
        <div></div>
      </div>

      <div className={styles.filter_btn}>
        <Button
          Styles
          text={"New account"}
          bgColor={"#1A2CCE"}
          color={"#FFFFFF"}
          size="sm"
          type="submit"
          border={"0.4px solid #1A2CCE"}
          boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
          borderRadius={"5px"}
          icon={add_icon}
          onClick={() => {
            toggle("new-wallet-account");
          }}
        />
      </div>
    </div>
  );
}

export default WalletAccountsFilter;
