import styled from 'styled-components';

export const Styles = styled.div`
	width: 100%;
	padding: 30px 30px 20px;
	background-color: #f3f6fb;
	@media (max-width: 700px) {
		padding: 20px 4%;
	}
	

		.welcome_back {
			display: flex;
			align-items: center;
			>h1 {
				color: #1a1f36;
				font-size: 18px;
				margin-right: 5px;
			}
		}
	

	
`;
