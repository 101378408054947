import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import styles from "./BinancePaySuccessful.module.css";
import Button from "../../../../UI-components/button/index";
import binance_logo from "../../../../../assets/images/binance-logo.svg";
import green_mark from "../../../../../assets/images/successful.svg";
import { useNavigate } from "react-router";

function BinancePaySuccessful() {
    const navigate = useNavigate()
  return (
    <div className={styles.binance_pay_successful}>
      <Container maxWidth="sm">
        <div className={styles.binance_pay_successful_content}>
          <div className={styles.heading}>
            <img src={binance_logo} alt="binance logo" />
          </div>
          <div className={styles.body}>
            <div className={styles.successful_icon_text}>
              <div className={styles.successful_icon}>
                <img src={green_mark} alt="successful" />
              </div>
              <Typography>Merchant request submitted successfully</Typography>
            </div>

            <Button
              Styles
              text={"Return to dashboard"}
              bgColor={"#1A2CCE"}
              color={"#FFFFFF"}
              size="md"
              fullwidth
              type="submit"
              border={"0.4px solid #1A2CCE"}
              boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
              borderRadius={"5px"}
              onClick={() => {
                navigate('/dashboard')
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BinancePaySuccessful;
