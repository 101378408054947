import { Typography } from "@mui/material";
import React, {  useState } from "react";
import styles from "./Withdraw.module.css";
import arrow_right_icon from "../../../../../../assets/images/arrow-right.svg";
import HeadingOne from "../../../../../UI-components/headings/headingsOne/HeadingsOne";
import BlueBackground from "../../../../../UI-components/blueBg/BlueBg";
import LabeledInputsWithText from "../../../../../UI-components/new-inputs/labeledInputsWithTexts/LabeledInputsWithTexts";
import BankTransfer from "../bankTransfer/BankTransfer";
import ConfirmWithdrawal from "../confirmWithdrawal/ConfirmWIthdrawal";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../../UI-components/validation-message/ValidationMessage";
import { useNavigate } from "react-router-dom";
import useGetCountries from "../../../../../../customHooks/useGetCountries";
import { useMutation } from "@tanstack/react-query";
import { WITHDRAWAL } from "../../../../../../api/mutations/dashboard/balance/index";
import useGetBanks from "../../../../../../customHooks/useGetBanks";

const usdcNetworkData = [
  // {
  //   value: "HBAR",
  //   label: " Hedera (HBAR)",
  // },
  {
    value: "TRX",
    label: "Tron (TRX)",
  },
  {
    value: "ETH",
    label: " Ethereum (ETH)",
  },
  {
    value: "BSC",
    label: "BNB Smart Chain (BEP20)",
  },
  {
    value: "SOL",
    label: "Solana (SOL)",
  },
  // {
  //   value: "XLM",
  //   label: "Stellar (XLM)",
  // },
  // {
  //   value: "ALGO",
  //   label: "Algorand (ALGO)",
  // },
];

const WithdrawalMethod = ({
  balance,
  openSnackbar,
  amount,
  currency,
  title,
  availableWithdrawalMethods,
  setAmount,
}) => {
  const [screen, setScreen] = useState("");
  const navigate = useNavigate();
  const [method, setMethod] = useState("");
  const { isLoadingCountries, countries } = useGetCountries();
  const { banks, isLoadingBanks } = useGetBanks(method, currency);
  const [bank_code, setBankCode] = useState("");
  const [branches, setBranches] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [fees, setFees] = useState("");

  const { mutate, isLoading } = useMutation(WITHDRAWAL, {
    onSuccess: (response) => {
      navigate(`/transaction/${response?.data?.id}`);
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });

  const handleScreen = (e) => {
    setScreen(e);
  };

  const handleMethod = (method) => {
    setRequirements(method.requirements);
    setMethod(method);

    const selectedFee = method?.fees.find((fee) => {
      if (fee.currency === currency.code) {
        return fee;
      } else {
        return null;
      }
    });

    setFees(selectedFee);
  };

  const handleWithdrawal = async () => {
    const tempData = requirements.map((method) => {
      if(method.name === 'narration'){

      }else {
        return {
          [method.name]: method.value,
        };
      }
 
    });
    const _beneficiary = Object.assign({}, ...tempData);

    mutate({
      beneficiary: _beneficiary,
      narration: requirements?.find((item)=>item.name === 'narration')?.value,
      amount: parseFloat(amount),
      currency: currency.code,
      withdrawal_method: method.slug,
    });
  };

  switch (screen) {
    case "next":
      return (
        <BankTransfer
          openSnackbar={openSnackbar}
          usdcNetworkData={usdcNetworkData}
          handleScreen={handleScreen}
          banks={banks}
          requirements={requirements}
          setRequirements={setRequirements}
          countries={countries}
          amount={amount}
          currency={currency}
          bank_code={bank_code}
          setBankCode={setBankCode}
          method={method}
          branches={branches}
          setBranches={setBranches}
          loadingBanks={isLoadingBanks}
        />
      );
    case "confirm":
      return (
        <ConfirmWithdrawal
          usdcNetworkData={usdcNetworkData}
          paymentMethod={method.slug}
          currency={currency}
          requirements={requirements}
          fees={fees}
          branches={branches}
          amount={amount}
          countries={countries}
          banks={banks}
          withdrawalLoading={isLoading}
          handleConfirm={handleWithdrawal}
        />
      );
    default:
      return (
        <WithdrawMethodList
          balance={balance}
          currency={currency}
          availableWithdrawalMethods={availableWithdrawalMethods}
          handleMethod={handleMethod}
          title={title}
          handleScreen={handleScreen}
          setAmount={setAmount}
          openSnackbar={openSnackbar}
          fees={fees}
          amount={amount}
          method={method}
        />
      );
  }
};

export default WithdrawalMethod;

const WithdrawMethodList = ({
  balance,
  setAmount,
  title,
  handleMethod,

  availableWithdrawalMethods,
  currency,
  handleScreen,
  openSnackbar,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setAmount(data.amount);
    if (
      data.amount > Number(balance)
      // Number(fees.fee_type === "FLAT" ? fees.fee : (fees.fee / 100) * amount)
    ) {
      openSnackbar("error", "Withdrawal amount exceeds balance");
    } else {
      if (data.amount < currency.min_withdrawal) {
        openSnackbar(
          "error",
          `Minimum withdrawal is ${currency.min_withdrawal.toLocaleString()} ${
            currency.code
          }`
        );
      } else {
        if (data.amount > currency.max_withdrawal) {
          openSnackbar(
            "error",
            `Maximum withdrawal is ${currency.max_withdrawal.toLocaleString()} ${
              currency.code
            }`
          );
        } else {
          handleScreen("next");
        }
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.withdraw}>
      <HeadingOne
        title={title}
        description={
          "Kindly fill in the details to help us process your withdrawal"
        }
      />
      <div className={styles.withdraw_amount}>
        <BlueBackground
          content={
            <div className={styles.withdraw_amount_main}>
              <LabeledInputsWithText
                watch={watch}
                setValue={setValue}
                name="amount"
                label={"amount"}
                text={currency?.code}
                type={"number"}
                redOutline={errors.amount ? true : false}
                register={register}
              />
              <ValidationMessage
                message={errors.amount ? "amount is required" : null}
              />
            </div>
          }
        />
      </div>
      <div className={styles.withdraw_send_via}>
        <div className={styles.withdraw_send_via_heading}>
          <Typography>send via</Typography>
        </div>
        <div className={styles.send_via_options}>
          {availableWithdrawalMethods?.map((method) => {
            return (
              <button key={method.id} style={{ width: "100%" }}>
                <BlueBackground
                  content={
                    <div
                      onClick={() => {
                        handleMethod(method);
                      }}
                      className={styles.send_via_option}
                    >
                      <div className={styles.send_via_option_left}>
                        <div className={styles.send_via_option_icon}>
                          <img src={method.icon} alt="bank icon" />
                        </div>
                        <div className={styles.send_via_option_text}>
                          <Typography variant="h6">{method.name}</Typography>
                          <Typography>{method.description}</Typography>
                        </div>
                      </div>
                      <div className={styles.send_via_option_arrow}>
                        <img src={arrow_right_icon} alt="arrow-right-icon" />
                      </div>
                    </div>
                  }
                />
              </button>
            );
          })}
        </div>
      </div>
    </form>
  );
};
