// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BinancePayEnable_binance_pay_enable__JV18B {\n  display: flex;\n  flex-direction: column;\n}\n.BinancePayEnable_binance_pay_enable__JV18B h6 {\n  font-size: 18px;\n  font-weight: 500;\n  margin-bottom: 13px;\n}\n\n.BinancePayEnable_binance_pay_enable__JV18B p {\n  font-size: 14px;\n  font-weight: 400;\n  color: #666565;\n  margin-bottom: 38px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/balances/modals/depositMethod/prompt/BinancePayEnable/BinancePayEnable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".binance_pay_enable {\n  display: flex;\n  flex-direction: column;\n}\n.binance_pay_enable h6 {\n  font-size: 18px;\n  font-weight: 500;\n  margin-bottom: 13px;\n}\n\n.binance_pay_enable p {\n  font-size: 14px;\n  font-weight: 400;\n  color: #666565;\n  margin-bottom: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"binance_pay_enable": "BinancePayEnable_binance_pay_enable__JV18B"
};
export default ___CSS_LOADER_EXPORT___;
