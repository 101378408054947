import React, { useEffect } from "react";
import LabeledInputs from "../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import styles from "./BusinessInfo.module.css";
import Button from "../../../../../UI-components/button";
import LabeledTextarea from "../../../../../UI-components/textArea/labeledTextarea/LabeledTextarea";
import LabeledSelectTwo from "../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import ValidationMessage from "../../../../../UI-components/validation-message/ValidationMessage";
import BusinessInfoPreview from "./BusinessInfoPreview";
import { useForm } from "react-hook-form";
import { UPDATE_BUSINESS_COMPLIANCE } from "../../../../../../api/mutations/dashboard/settings/compliance";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useGetStates from "../../../../../../customHooks/useGetStates";
import { yupResolver } from "@hookform/resolvers/yup";
import { businessInfoSchema } from "../../../../../../schemas/dashboard/compliance";
import PhoneNumberInput from "../../../../../UI-components/phoneNumberInput/PhoneNumberInput";
import { Typography } from "@mui/material";
import RadioBtn from "../../../../../UI-components/new-inputs/radioBtn/RadioBtn";

const bus_info_form = [
  {
    name: "business_name",
    label: "Business name",
    input_type: "input",
    placeholder: "Treasura",
  },
  {
    name: "business_description",
    label: "Business description",
    input_type: "textarea",
    placeholder: "Tell us about your business",
  },
  {
    name: "website",
    label: "Website",
    input_type: "input",
    placeholder: "www.name.domain",
  },
  {
    name: "address",
    label: "Business address",
    input_type: "textarea",
    placeholder: "Where is your business located?",
  },
  {
    name: "trading_name",
    label: "trading name",
    input_type: "input",
    placeholder: "What name do you trade with?",
  },
  {
    name: "business_email",
    label: "contact email",
    input_type: "input",
    placeholder: "Enter your email",
  },
  {
    name: "phone",
    label: "phone number",
    input_type: "phone",
    placeholder: "",
  },
  {
    name: "country",
    label: "Country",
    input_type: "select",
    placeholder: "Country",
  },
  {
    name: "state",
    label: "State/Region",
    input_type: "select",
    placeholder: "State",
  },

  {
    name: "authorized_signatory",
    label: "Authorized Representative/Signatory",
    input_type: "input",
    placeholder: "Enter name of representative",
    tooltip: true,
  },
  {
    name: "ultimate_beneficial_owner",
    label: "Name of Ultimate Beneficial Owner (UBO)",
    input_type: "input",
    placeholder: "Enter UBO name",
    tooltip: true,
  },
  {
    name: "expected_monthly_inflow",
    label: "Expected Monthly Inflow ($)",
    input_type: "input",
    placeholder: "$ Enter amount",
    type: 'number'
  },
  {
    name: "licensable_in_country",
    label: "Is your business licensable in your country of operation?",
    input_type: "radio",
    placeholder: "",
  },
  {
    name: "has_license",
    label: "Do you have a license?",
    input_type: "radio",
    placeholder: "",
  },
  {
    name: "has_political",
    label:
      "Are any of your shareholders, directors or officers a Politically Exposed Person?",
    input_type: "radio",
    placeholder: "",
  },
  {
    name: "politically_exposed_person",
    label: "Name of Politically Exposed Person",
    input_type: "input",
    placeholder: "Enter name",
  },
];

const BusinessInfo = ({
  countries,
  setComplianceInfo,
  setcountry,
  complianceInfo,
  openSnackbar,
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(businessInfoSchema) });


  const hasPolitical = watch("has_political", "");
  const country = watch("country", ""); // you can supply default value as second argument
  const queryClient = useQueryClient();
  const { states, setCountryId, isLoadingStates } = useGetStates();
  const phone = watch("phone", "");

  const { mutate, isLoading } = useMutation(UPDATE_BUSINESS_COMPLIANCE, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["compliance"]);
      openSnackbar("success", response.message);
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  useEffect(() => {
    trigger("phone");
  }, [phone]);

  useEffect(() => {
    setValue("country", complianceInfo?.country);
  }, [countries]);

  useEffect(() => {
    if (complianceInfo.country !== country) {
      if (states) {
        setValue("state", states[0]?.name);
      }
    } else {
      setValue("state", complianceInfo.state);
    }
  }, [states]);

  useEffect(() => {
    if (country !== undefined) {
      handleGetCountryID(country);
      setcountry(country);
    }
  }, [country, countries]);

  const handleGetCountryID = async (name) => {
    const country = await countries?.find((country) => country.name === name);
    if (country !== undefined) {
      setCountryId(country.id);
    }
  };

  useEffect(() => {
    bus_info_form.map((item) => {
      if (item.name === "has_political") {
        if (complianceInfo?.politically_exposed_person) {
          setValue(item.name, "yes");
        }
      } else if (item?.name === "country") {
        //setValue("country", complianceInfo?.country);
      } else if (item.name === "state") {
      } else {
        setValue(item.name, complianceInfo[item.name] || "");
      }
    });
  }, [complianceInfo]);

  const onSubmit = async (data) => {
    mutate({
      ...data,
      phone,
    });
  };
  // ;

  return (
    <>
      {complianceInfo?.verified_business ? (
        <BusinessInfoPreview
          busInfoForm={bus_info_form}
          complianceInfo={complianceInfo}
          business_name={complianceInfo.business_name}
          business_email={complianceInfo.business_email}
          website={complianceInfo.website}
          phone={complianceInfo.phone}
          country={complianceInfo.country}
          state={complianceInfo.state}
          address={complianceInfo.address}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.bank_info}>
          {bus_info_form.map((item) => {
            if (item.input_type === "input") {
              if (item.name === "politically_exposed_person") {
                return (
                  hasPolitical === "yes" && (
                    <div key={item.name} className={styles.bank_info_field}>
                      <LabeledInputs
                        label={item.label}
                        placeholder={item.placeholder}
                        redOutline={errors[item.name] ? true : false}
                        register={register}
                        tooltip={item.tooltip}
                        name={item.name}
                        handleChangeAuto={true}
                      />
                      <ValidationMessage
                        message={errors[`${item.name}`]?.message}
                      />
                    </div>
                  )
                );
              } else {
                return (
                  <div key={item.name} className={styles.bank_info_field}>
                    <LabeledInputs
                      label={item.label}
                      placeholder={item.placeholder}
                      redOutline={errors[item.name] ? true : false}
                      register={register}
                      type={item.type}
                      tooltip={item.tooltip}
                      name={item.name}
                      handleChangeAuto={true}
                    />
                    <ValidationMessage
                      message={errors[`${item.name}`]?.message}
                    />
                  </div>
                );
              }
            } else if (item.input_type === "select") {
              if (item.name === "country") {
                return (
                  <div key={item.name} className={styles.bank_info_field}>
                    <LabeledSelectTwo
                      register={register}
                      data={countries?.map((country) => {
                        return {
                          label: country.name,
                          value: country.name,
                        };
                      })}
                      placeholder={"Country"}
                      label={"Country"}
                      name={"country"}
                      redOutline={errors.country ? true : false}
                    />
                    <ValidationMessage message={errors?.country?.message} />
                  </div>
                );
              } else {
                return (
                  <div key={item.name} className={styles.bank_info_field}>
                    <LabeledSelectTwo
                      register={register}
                      name={item.name}
                      data={states?.map((state) => {
                        return {
                          label: state.name,
                          value: state.name,
                        };
                      })}
                      
                      placeholder={item.placeholder}
                      label={item.label}
                      redOutline={errors.state ? true : false}
                    />
                    <ValidationMessage message={errors?.state?.message} />
                  </div>
                );
              }
            } else if (item.input_type === "textarea") {
              return (
                <div key={item.name} className={styles.bank_info_field}>
                  <LabeledTextarea
                    register={register}
                    name={item.name}
                    label={item.label}
                    placeholder={item.placeholder}
                    redOutline={errors[item.name] ? true : false}
                  />
                  <ValidationMessage
                    message={errors[`${item.name}`]?.message}
                  />
                </div>
              );
            } else if (item.input_type === "phone") {
              return (
                <div key={item.name} className={styles.bank_info_field}>
                  <PhoneNumberInput
                    value={phone}
                    register={register}
                    name="phone"
                    setValue={setValue}
                    redOutline={errors[item.name]?.message}
                  />
                  <ValidationMessage
                    message={errors[`${item.name}`]?.message}
                  />
                </div>
              );
            } else if (item.input_type === "radio") {
              return (
                <div key={item.name} className={` ${styles.radio_div}`}>
                  <RadioBtn register={register} name={item.name} item={item} />
                  <ValidationMessage
                    message={errors[`${item.name}`]?.message}
                  />
                </div>
              );
            }
          })}

          {/* 
          <div className={styles.bank_info_field}>
            <LabeledInputs
              label={"business name"}
              placeholder={"Treasura"}
              redOutline={errors.business_name ? true : false}
              register={register}
              name={"business_name"}
              handleChangeAuto={true}
            />
            <ValidationMessage message={errors?.business_name?.message} />
          </div>
          <div className={styles.bank_info_field}>
            <LabeledTextarea
              register={register}
              name={"business_description"}
              label={"business description"}
              placeholder={"Tell us about your business"}
              redOutline={errors.business_description ? true : false}
            />
            <ValidationMessage
              message={errors?.business_description?.message}
            />
          </div>
          <div className={styles.bank_info_field}>
            <LabeledInputs
              label={"business email address"}
              placeholder={"admin@treasura.io"}
              redOutline={errors.business_email ? true : false}
              register={register}
              name={"business_email"}
              handleChangeAuto={true}
            />
            <ValidationMessage message={errors?.business_email?.message} />
          </div>

          <div className={styles.bank_info_field}>
            <LabeledInputs
              label={"website"}
              placeholder={"www.name.domain"}
              redOutline={errors.website ? true : false}
              register={register}
              name={"website"}
              handleChangeAuto={true}
            />
            <ValidationMessage message={errors?.website?.message} />
          </div>
          <div className={styles.bank_info_field}>
            <LabeledSelectTwo
              register={register}
              data={countries?.map((country) => {
                return {
                  label: country.name,
                  value: country.name,
                };
              })}
              placeholder={"Country"}
              label={"Country"}
              name={"country"}
              redOutline={errors.country ? true : false}
            />
            <ValidationMessage message={errors?.country?.message} />
          </div>
          <div className={styles.bank_info_field}>
            <LabeledSelectTwo
              register={register}
              name={"state"}
              data={states?.map((state) => {
                return {
                  label: state.name,
                  value: state.name,
                };
              })}
              placeholder={"States"}
              label={"State/Region"}
              redOutline={errors.state ? true : false}
            />
            <ValidationMessage message={errors?.state?.message} />
          </div>
          <div className={styles.bank_info_field}>
            <PhoneNumberInput
              value={phone}
              register={register}
              name="phone"
              setValue={setValue}
            />
            <ValidationMessage message={errors?.phone?.message} />
          </div>

          <div className={styles.bank_info_field}>
            <LabeledTextarea
              required={true}
              register={register}
              name={"address"}
              label={"Address"}
              placeholder={"Address"}
              redOutline={errors.address ? true : false}
            />
            <ValidationMessage message={errors?.address?.message} />
          </div> */}
          <div className={styles.bank_info_btn}>
            <Button
              text={"Save changes"}
              bgColor={"#1A2CCE"}
              color={"#fff"}
              size="sm"
              defaultChecked
              type="submit"
              borderRadius={"5px"}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              loading={isLoading}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default BusinessInfo;
