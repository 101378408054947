import styled from 'styled-components';
import { Flex } from '../box';

export const Container1 = styled(Flex)`
	width: 100%;
	flex: 1;
	position: relative;
`;

export const Container2 = styled(Flex)`
	flex: 1;
	max-width: 100%;
	overflow: hidden;
	margin: 0 0 0 auto;
	height: 48px;
	border: 0.7px solid #dfdfdf;
	border-radius: 3px;
	background: #ffffff;
`;
export const Container3 = styled(Flex)`
	background: #ffffff;
	// border: 0.7px solid #dfdfdf;
	box-sizing: border-box;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
	// border-radius: 3px;
`;
export const CloseSaerch = styled(Flex)`
	position: absolute;
	cursor: pointer;
	top: -6px;
	right: -9px;
	border-radius: 100%;
	background: #00000030;
`;

export const SearchContainer = styled(Flex)`
	flex: 1;
	/* border: 1px solid red; */
`;

export const Input = styled.input`
	max-width: 100%;
	width: 250px;
	height: 48px;
	// padding: 10px 5px 10px 0;
`;
