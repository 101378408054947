import React from "react";
import styles from "./ModalFrame.module.css";
import close_icon from "../../../assets/images/close.svg";


const ModalFrame = ({ child, style, handleAction }) => {
  return (
    <div  className={styles.modal_frame}>
      <div onClick={handleAction} className={styles.modal_frame_close_div}>
        <img  src={close_icon} alt={close_icon} />
      </div>
      <div onClick={(e)=>{e.stopPropagation()}} style={style}  className={styles.child_div}>
      {child}
      </div>
    
    </div>
  );
};

export default ModalFrame;
