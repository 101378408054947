import { Typography } from "@mui/material";
import React from "react";
import styles from "./InputWithAction.module.css";

const LabeledInputsWithAction = ({
  guide,
  type,
  register,
  redOutline,
  name,
  placeholder,
  label,
  pattern,
  required,
  readOnly,
  btnText,
  handleClickBtn
}) => {
  return (
    <label className={styles.labeled_input} htmlFor="">
      <div className={styles.heading}>
        <Typography variant="h6">{label}</Typography>
        {guide && (
          <Typography
            style={{
              color: guide === "optional" ? "rgb(126, 121, 121)" : "red",
            }}
          >
            {guide}
          </Typography>
        )}
      </div>

      <div className={`${styles.input_box} ${redOutline ? styles.red_outline : null}`}>
        <input
          readOnly={readOnly}
          step="any"
          className={` ${
            readOnly && styles.read_only_bg
          } `}
          {...register(name, {
            required: required,
            maxLength: 80,
            pattern: pattern,
          })}
          type={type ? type : "text"}
          name={name}
          placeholder={placeholder}
        />
        <button onClick={handleClickBtn}>
            {btnText}
        </button>
      </div>
    </label>
  );
};

export default LabeledInputsWithAction;
