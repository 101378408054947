import { Typography } from "@mui/material";
import React from "react";
import styles from "./BusinessInfo.module.css";
const BusinessInfoPreview = ({
  busInfoForm,
  business_name,
  business_email,
  business_description,
  website,
  phone,
  country,
  state,
  address,
  complianceInfo,
}) => {
  return (
    <div className={styles.business_info_previews}>
      {busInfoForm.map((item) => {
        if (item.name === "has_political") {
          return (
            <div className={styles.business_info_preview}>
              <Typography variant="h6">{item.label}</Typography>
              <Typography> {complianceInfo['politically_exposed_person']? 'Yes' : 'No'} </Typography>
            </div>
          );
        } else {
          return (
            <div className={styles.business_info_preview}>
              <Typography variant="h6">{item.label}</Typography>
              <Typography> {complianceInfo[item.name]} </Typography>
            </div>
          );
        }
      })}

      {/* <div className={styles.business_info_preview}>
        <Typography variant="h6">Business description</Typography>
        <Typography>{business_description}</Typography>
      </div>
      <div className={styles.business_info_preview}>
        <Typography variant="h6">Business email address</Typography>
        <Typography>{business_email}</Typography>
      </div>
      <div className={styles.business_info_preview}>
        <Typography variant="h6">Website</Typography>
        <Typography>{website}</Typography>
      </div>
      <div className={styles.business_info_preview}>
        <Typography variant="h6">Phone Number</Typography>
        <Typography>{phone}</Typography>
      </div>
      <div className={styles.business_info_preview}>
        <Typography variant="h6">Country</Typography>
        <Typography>{country}</Typography>
      </div>
      <div className={styles.business_info_preview}>
        <Typography variant="h6">State/Region</Typography>
        <Typography>{state}</Typography>
      </div>
      <div className={`${styles.last_item} ${styles.business_info_preview}`}>
        <Typography variant="h6">Address</Typography>
        <Typography>{address}</Typography>
      </div> */}
    </div>
  );
};

export default BusinessInfoPreview;
