import React from "react";
import { StylesContainer } from "./styles";
import { Flex, Frame } from "../../../../UI-components/box";
import email from "../../../../../assets/images/email2.svg";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <StylesContainer margin={"auto"}>
      <Flex
        className="flex_container"
        width={"550px"}
        pad={"4em"}
        flexDir={"column"}
        bgColor={"#ffff"}
      >
        <Frame height={"90px"} width={"90px"} margin={"0px 0px 40px"}>
          <img alt="email-svg" src={email} />
        </Frame>
        <h3>Email reset activated</h3>
        <p>
          We’ve sent a set of instructions to your mail box. Please check to
          proceed for next steps
        </p>
        <div className="link_box">
          <Link to={"/"}>Check my inbox</Link>
        </div>
      </Flex>
    </StylesContainer>
  );
};

export default Index;
