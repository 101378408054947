import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./ActivitiesRow.module.css";

function ActivitiesRow({ data }) {
  return (
    <tr className={styles.row}>
      <td>
        <div className={styles.created_at}>
          <Typography>{new Date(data?.created_at)?.toLocaleString("en-US", {
             year: 'numeric',
             month: 'short',
              day: 'numeric',
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.event}>
          <Typography variant="h6">{data?.event}:</Typography>
          <Typography>{data?.description}</Typography>
          {data?.object === "transaction" && (
            <Link to={`/transaction/${data?.objectId}`}> View transaction</Link>
          )}
        </div>
      </td>
      <td>
        <div className={styles.channel}>
          <Typography>{data?.channel}</Typography>
        </div>
      </td>
    </tr>
  );
}

export default ActivitiesRow;
