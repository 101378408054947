import React, {useEffect, useState } from "react";
import styles from "./OverdraftSingle.module.css";
import Layout from "../../../../UI-components/layouts";
import { Styles } from "../styles";
import back_arrow from "../../../../../assets/images/arrow-left.svg";
import { Grid, Typography } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import { handleGetRequestCalls } from "../../../../../axiosConfig/apiCalls";
import Loader from "../../../../UI-components/loader/Loader";
import useModal from "../../../../../customHooks/useModal";
import AppBackdrop from "../../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrame from "../../../../UI-components/modalFrame/ModalFrame";
import DownloadReport from "../../../../UI-components/downloadReport/DownloadReport";

import TableOne from "../../../../UI-components/new -tables/Table";
import Pagination from "../../../../UI-components/pagination/Pagination";
import useGetDefaultCurrencies from "../../../../../customHooks/useGetDefaultCurrencies";
import { useQuery } from "@tanstack/react-query";
import { GET_SINGLE_OVERDRAFT } from "../../../../../api/queries/dashboard/overdraft";

const OverdraftSingle = () => {
  const { toggle, opened, handleCloseModal, handleOpenModal } = useModal();
  const navigate = useNavigate();
  const params = useParams();
  const {
    isLoading: isLoadingCurrencies,
    currencies,
  } = useGetDefaultCurrencies();
  const [overdrafts, setOverdrafts] = useState({});
  //const [loadingOverdraft, setLoadingOverdraft] = useState(true);
  const tableHeadData = ["date/time", "currency", "amount", "status", ""];
  const [overdraftsTrx, setOverdraftTrx] = useState([]);

  const {
    data,
    isLoading,
    refetch,
  } = useQuery(["overdraft", params.id], GET_SINGLE_OVERDRAFT, {
    refetchOnWindowFocus: false,
    onSuccess:(data)=>{
   
      setOverdrafts(data?.liquidity)
      setOverdraftTrx(data?.transactions)
    }
  });


 


  const handleGoBack = () => {
    navigate(-1);
  };

  const handleExportReport = (e) => {
    e.preventDefault();
    handleOpenModal("download-report");
  };

  const handlePageChange = async (page, type) => {
    // setLoadingOverdraft(true);
    // const response = await handleGetRequestCalls(
    //   `liquidity/${params.id}?order=DESC&page=${page}&take=10`
    // );

    // if (response.status < 300 && response.status >= 200) {
    //   const { data } = response.data;

    //   setOverdraftTrx(data.transactions);
    //   setLoadingOverdraft(false);
    // } else if (response.response) {
    //   setLoadingOverdraft(false);
    // } else if (response.request) {
    //   setLoadingOverdraft(false);
    // } else {
    //   setLoadingOverdraft(false);
    // }
  };
  return (
    <Layout title={"Liquidity"} bgColor={"#ffffff"}>
      {isLoading ? (
        <Loader />
      ) : (
        <Styles>
          <div className={styles.overdraft}>
            <div onClick={handleGoBack} className={styles.go_back}>
              <img src={back_arrow} alt="" />
              <Typography>Back to liquidity</Typography>
            </div>

            <div className={styles.overdraft_summary}>
              <div className={styles.overdraft_summary_upper}>
                <div className={styles.heading_currency}>
                  <div className={styles.z_index}>
                    {currencies?.result?.map((currency) => {
                      if (currency.code === overdrafts.to_currency) {
                        return (
                          <img
                            key={currency.id}
                            src={currency.icon}
                            alt={`flag`}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                  <div className={styles.translate}>
                    {currencies?.result?.map((currency) => {
                      if (currency.code === overdrafts.from_currency) {
                        return (
                          <img
                            key={currency.id}
                            src={currency.icon}
                            alt={`flag`}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                  <Typography>{overdrafts.currency_pair}</Typography>
                </div>
                <div className={styles.heading_export}>
                  <Link onClick={handleExportReport} to="/">
                    Export Report
                  </Link>
                </div>
              </div>
              <div className={styles.overdraft_summary_lower}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <div className={`${styles.card} ${styles.blue_card}`}>
                      <Typography variant="h6">credit cap</Typography>
                      {overdrafts?.credit_cap ? (
                        <Typography>
                          {overdrafts?.to_currency}{" "}
                          {overdrafts?.credit_cap?.toLocaleString(
                            "en-US",
                            currencies?.result?.find((currency) => {
                              if (currency.code === overdrafts.to_currency) {
                                return currency.name;
                              } else {
                                return null;
                              }
                            })?.type === "CRYPTO"
                              ? { minimumFractionDigits: 8 }
                              : { minimumFractionDigits: 2 }
                          )}
                        </Typography>
                      ) : (
                        <Typography>NA</Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={9}>
                    <div className={styles.card}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.white_card}>
                            <Typography variant="h6">available</Typography>
                            {overdrafts?.balance ? (
                              <Typography>
                                {overdrafts?.to_currency}{" "}
                                {overdrafts?.balance?.toLocaleString(
                                  "en-US",
                                  currencies?.result?.find((currency) => {
                                    if (
                                      currency.code === overdrafts.to_currency
                                    ) {
                                      return currency.name;
                                    } else {
                                      return null;
                                    }
                                  })?.type === "CRYPTO"
                                    ? { minimumFractionDigits: 8 }
                                    : { minimumFractionDigits: 2 }
                                )}
                              </Typography>
                            ) : (
                              <Typography>NA</Typography>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.white_card}>
                            <Typography variant="h6">payouts</Typography>
                            {overdrafts?.amount_used ? (
                              <Typography>
                                {overdrafts?.to_currency}{" "}
                                {overdrafts?.amount_used?.toLocaleString(
                                  "en-US",
                                  currencies?.result?.find((currency) => {
                                    if (
                                      currency.code === overdrafts.to_currency
                                    ) {
                                      return currency.name;
                                    } else {
                                      return null;
                                    }
                                  })?.type === "CRYPTO"
                                    ? { minimumFractionDigits: 8 }
                                    : { minimumFractionDigits: 2 }
                                )}
                              </Typography>
                            ) : (
                              <Typography>NA</Typography>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.white_card}>
                            <Typography variant="h6">Status</Typography>
                            {overdrafts?.status ? (
                              <Typography>{overdrafts?.status}</Typography>
                            ) : (
                              <Typography>NA</Typography>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <TableOne
            currencies={currencies}
              tableBody={overdraftsTrx?.data}
              type={"settlement"}
              tableHeadData={tableHeadData}
              handleRefresh={()=>{
                refetch()
              }}
            />
            {overdraftsTrx?.data?.length === 0 ? null : (
              <Pagination
                type={"overdraft-single"}
                handlePageChange={handlePageChange}
                meta={overdraftsTrx?.meta}
              />
            )}
          </div>
          {opened.some((item) => item === "download-report") && (
            <AppBackdrop
              openModal={opened.some((item) => item === "download-report")}
              handleCloseModal={() => {
                handleCloseModal("download-report");
              }}
              child={
                <ModalFrame
                  handleAction={() => {
                    toggle("download-report");
                  }}
                  child={<DownloadReport />}
                />
              }
            />
          )}
        </Styles>
      )}
    </Layout>
  );
};

export default OverdraftSingle;
