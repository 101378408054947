import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TERMINATE_SESSION } from "../../../../../api/mutations/dashboard/settings/security";
import { GET_SESSIONS } from "../../../../../api/queries/dashboard/settings/security";

import useModal from "../../../../../customHooks/useModal";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import AppBackdrop from "../../../../UI-components/appBackdrop/AppBackdrop";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import GoBack from "../../../../UI-components/goBack/GoBack";
import Loader from "../../../../UI-components/loader/Loader";
import ModalFrame2 from "../../../../UI-components/modalFrame2/ModalFrame2";
import TableOne from "../../../../UI-components/new -tables/Table";
import EmptyState from "../../../../UI-components/null state/EmptyState";
import Pagination from "../../../../UI-components/pagination/Pagination";
import ConfirmationPrompt from "../../../../UI-components/prompt/confirmationPrompt/ConfirmationPrompt";
import LabeledSelectTwo from "../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import styles from "./Sessions.module.css";

function Sessions() {
  const { register } = useForm();
  const [page, setPage] = useState(1)
  const [selectedSession, setSelectedSession] = useState({});
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const { handleOpenModal, isShowingPrompt, toggle, handleCloseModal, opened } =
    useModal();
  const queryClient = useQueryClient()    

    const {
      data: sessions,
      isLoading,
      refetch
    } = useQuery(["sessions", page], GET_SESSIONS, {
      refetchOnWindowFocus: false,
    });
  
    const { mutate: terminateSession, isLoading: isLoadingTerminateSession } =
    useMutation(TERMINATE_SESSION, {
      onSuccess: (response) => {
        queryClient.invalidateQueries(['sessions'])
        toggle("confirmation-prompt");
        openSnackbar("success", response.message);
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    });
  
  
    const handlePageChange = async (page) => {
      setPage(page)
    };

  



  const handleOpenTerminatePrompt = (session) => {
    setSelectedSession(session);
    toggle("confirmation-prompt");
  };

  const handleTerminateSession = async () => {
    terminateSession(selectedSession)
    // setLoadingTermination(true);
    // const response = await handleDeleteRequestCalls(
    //   `sessions/${selectedSession.id}`
    // );

    // if (response.status < 300 && response.status >= 200) {
    //   //handleGetSessions();
    //   toggle("confirmation-prompt");
    //   setLoadingTermination(false);
    // } else if (response.response) {
    //   openSnackbar("error", response?.response?.data?.message);
    // } else if (response.request) {
    //   openSnackbar("error", "something went wrong, please try again");
    // } else {
    //   openSnackbar("error", "something went wrong, please try again");
    // }
  };

 

  return (
    <SettingsLayout
      activeSecurityTab="sessions"
      tab="security"
      child={
        <div className={styles.sessions}>
          <GoBack />
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {sessions?.data?.data?.length > 0 ? (
                <>
                  <div className={styles.filter_field}>
                    <LabeledSelectTwo
                      name="event"
                      register={register}
                      data={[]}
                    />
                  </div>
                  <TableOne
                    handleOpenTerminatePrompt={handleOpenTerminatePrompt}
                    type="sessions"
                    tableHeadData={[
                      "OPERATING SYSTEM",
                      "DATE STARTED",
                      "LAST SEEN",
                      "",
                    ]}
                    tableBody={sessions?.data?.data}
                  />
                </>
              ) : (
                <div style={{ marginTop: 40 }}>
                  <EmptyState
                    handleRefresh={() => {
                     refetch()
                    }}
                    primaryText={"Sessions not found"}
                    secondaryText={
                      "it looks like you dont have any session yet"
                    }
                  />
                </div>
              )}
              {sessions?.data?.data?.length > 0 && (
                <Pagination
                  meta={sessions?.data?.meta}
                  handlePageChange={(page) => {
                    handlePageChange(page);
                  }}
                />
              )}
            </>
          )}
          {isShowingPrompt && (
            <AppBackdrop
              openModal={isShowingPrompt}
              handleCloseModal={() => {
                toggle("confirmation-prompt");
              }}
              child={
                <ModalFrame2
                  btnColor={"#D80027"}
                  btnLoading={isLoadingTerminateSession}
                  handleActionTwo={() => {
                    toggle("confirmation-prompt");
                  }}
                  handleAction={() => {
                    handleTerminateSession();
                  }}
                  btnTxt={"Yes, Terminate"}
                  child={
                    <ConfirmationPrompt
                      header="Terminate this session?"
                      text={
                        "Log in access to selected device will be revoked once session is terminated."
                      }
                    />
                  }
                />
              }
            />
          )}

          <SwapstaSnackbar
            open={openToast}
            message={message}
            severity={severity}
            setOpen={setOpenToast}
            duration={duration}
          />
        </div>
      }
    />
  );
}

export default Sessions;
