// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CopyButton_copy_btn__ACihs {\n\tbackground: #3ab75d;\n\tborder: 1px solid #3ab75d;\n\tbox-sizing: border-box;\n\tborder-radius: 15px;\n\tcolor: white;\n\theight: 20.30000114440918px;\n\twidth: 58px;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 14px;\n\tletter-spacing: 0em;\n\t\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/UI-components/copy-button/CopyButton.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,yBAAyB;CACzB,sBAAsB;CACtB,mBAAmB;CACnB,YAAY;CACZ,2BAA2B;CAC3B,WAAW;CACX,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB;CACjB,mBAAmB;;EAElB","sourcesContent":[".copy_btn {\n\tbackground: #3ab75d;\n\tborder: 1px solid #3ab75d;\n\tbox-sizing: border-box;\n\tborder-radius: 15px;\n\tcolor: white;\n\theight: 20.30000114440918px;\n\twidth: 58px;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 14px;\n\tletter-spacing: 0em;\n\t\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy_btn": "CopyButton_copy_btn__ACihs"
};
export default ___CSS_LOADER_EXPORT___;
