import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import publicIp from "public-ip";
import { Container, Flex } from "../../../../UI-components/box";
import Button from "../../../../UI-components/button";
import { BrowseIcon } from "../../../../../assets";
import { Styles, Section1, Section2, Section3, Section4 } from "./styles";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../../UI-components/input/styles";
import Loader from "../../../../UI-components/loader/Loader";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../UI-components/validation-message/ValidationMessage";
import Webhook from "./components/webhook/Webhook";
import { DataCentral } from "../../../../../context/AppContext";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_API_DATA } from "../../../../../api/queries/dashboard/settings/api";
import {
  GENERATE_KEYS,
  SUBMIT_IP,
  SUBMIT_WEBHOOK_URL,
} from "../../../../../api/mutations/dashboard/settings/api";
import { CircularProgress } from "@mui/material";

const Index = () => {
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { user } = useContext(DataCentral);
  const [copySuccessfulPub, setCopySuccessfulPub] = useState("copy");
  const [copySuccessfulSec, setCopySuccessfulSec] = useState("copy");
  const [addingCurrentIp, setAddingCurrentIp] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: api,
    isLoading,
    refetch,
  } = useQuery(["api"], GET_API_DATA, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
    
      handlePopulation(data?.data);
    },
  });

  
  const { mutate: generateKeys, isLoading: isLoadingGenerateKeys } =
    useMutation(GENERATE_KEYS, {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["api"]);
        openSnackbar("success", response.message);
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    });

  const { mutate: submitWebhook, isLoading: isLoadingSubmitWebhook } =
    useMutation(SUBMIT_WEBHOOK_URL, {
      onSuccess: (response) => {
        openSnackbar("success", response.message);
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    });

  const { mutate: submitIP, isLoading: isLoadingSubmitIP } = useMutation(
    SUBMIT_IP,
    {
      onSuccess: (response) => {
        openSnackbar("success", response.message);
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    }
  );

 

  const handlePopulation = (data) => {
    const ips = data[`${data?.mode}_ips`];
    var formattedIp = JSON.parse(ips)?.join(", ");
    setValue("ip", formattedIp);
  
  };

  const handleSubmitWebhookURL = async (webhook) => {
    submitWebhook({
      webhook,
    });
  };

  const handleAddCurrentIPAddr = async (e) => {
    setAddingCurrentIp(true);
    e.preventDefault();
    publicIp
      .v4()
      .then((ip) => {
        setValue("ip", ip);
        openSnackbar(
          "success",
          "current IP address added to field successfully"
        );
        setAddingCurrentIp(false);
      })
      .catch(() => {
        openSnackbar(
          "error",
          "Unable to populate field with current IP address"
        );
        setAddingCurrentIp(false);
      });
  };

  const handlePostIp = async (ips) => {
    submitIP({
      ips,
    });
  };

  const copyToClipboard = async (tag, copy) => {
    try {
      await navigator.clipboard.writeText(copy);
      if (tag === "secretKey") {
        setCopySuccessfulSec("copied");
      } else {
        setCopySuccessfulPub("copied");
      }
    } catch (error) {
      if (tag === "secretKey") {
        setCopySuccessfulSec("failed to copy");
      } else {
        setCopySuccessfulPub("failed to copy");
      }
    }
  };

  const onSubmit = async (data) => {
    handlePostIp(data.ip);
  };

  return (
    <SettingsLayout
      tab="API"
      child={
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <Styles>
              <Section1>
                <Flex
                  className="api_config"
                  flexDir="column"
                  alignItem="flex-start"
                >
                  <h4>API Configuration</h4>
                  <p>
                    Treasura authenticates your API requests using your
                    account’s API keys.
                  </p>

                  <span onClick={generateKeys}>
                    Generate API Keys{" "}
                    {isLoadingGenerateKeys && <CircularProgress size={15} />}
                  </span>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  className={user?.mode === "test" ? "mode" : "mode_green"}
                >
                  <span>You are viewing {user?.mode} API keys</span>
                </Flex>
              </Section1>
              <Section2>
                <Flex flexDir="column" gap="30px" alignItem="flex-start">
                  <InputStyle>
                    <Label>Public Key</Label>
                    <Flex className="readonlyInput_box">
                      <FieldWrapper width="80%">
                        <input
                          readOnly
                          value={
                     
                            api?.data[`${api.data?.mode}_public_key`]
                          }
                          name="publicKey"
                        />
                      </FieldWrapper>
                      <button
                        onClick={() => copyToClipboard("publickey",  api?.data[`${api.data?.mode}_public_key`])}
                        className="copy_clipboard_btn"
                      >
                        {copySuccessfulPub}
                      </button>
                    </Flex>
                  </InputStyle>
                  <InputStyle>
                    <Label>Secret Key</Label>
                    <Flex className="readonlyInput_box">
                      <FieldWrapper width="80%">
                        <input
                          readOnly
                          value={
                      
                            api?.data[`${api?.data?.mode}_secret_key`]
                          }
                        />
                      </FieldWrapper>
                      <button
                        onClick={() => copyToClipboard("secretKey", api?.data[`${api?.data?.mode}_secret_key`])}
                        className="copy_clipboard_btn"
                      >
                        {copySuccessfulSec}
                      </button>
                    </Flex>
                  </InputStyle>
                  <span>
                    <Link to={"/settings/API"}>
                      Browse docs <BrowseIcon />
                    </Link>
                  </span>
                </Flex>
              </Section2>
              <Section3>
                <Webhook
                  user={user}
                  api={api?.data}
                  handleSubmitWebhookURL={handleSubmitWebhookURL}
                  submittingWebhook={isLoadingSubmitWebhook}
                />
              </Section3>
              <Section4>
                <Flex flexDir="column" alignItem="flex-start">
                  <h4>IP Address limit</h4>
                  <p>
                    Enter a single IP address or seperate them using the comma
                    sign
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputStyle>
                      <Label>Unrestricted IP addresses</Label>
                      <Container className="ip_address">
                        <FieldWrapper>
                          <input
                            name="ip"
                            className={errors.ip ? "red_outline" : null}
                            {...register("ip", {
                              required: true,
                            })}
                            placeholder="121.12.30.0.1, 121.94.9.0, 129.48.0.9"
                          />
                        </FieldWrapper>
                        <ValidationMessage
                          message={
                            errors.ip ? "Input a valid IP address" : null
                          }
                        />
                      </Container>
                    </InputStyle>
                    <div className="ip_actions">
                      <Link
                        to="/"
                        onClick={handleAddCurrentIPAddr}
                        type="submit"
                      >
                        Add my current IP address{" "}
                        {addingCurrentIp && (
                          <CircularProgress
                            size={15}
                            sx={{ color: "#1a2cce", marginLeft: "10px" }}
                          />
                        )}
                      </Link>
                      <Button
                        text={"Save IP address"}
                        bgColor={"#1A2CCE"}
                        color={"#fff"}
                        size="sm"
                        type="submit"
                        borderRadius={"5px"}
                        loading={isLoadingSubmitIP}
                      />
                    </div>
                  </form>
                </Flex>
              </Section4>
            </Styles>
          )}
          <SwapstaSnackbar
            open={openToast}
            message={message}
            severity={severity}
            setOpen={setOpenToast}
            duration={duration}
          />
        </>
      }
    />
  );
};

export default Index;
