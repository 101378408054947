import { Typography } from '@mui/material'
import React from 'react'
import styles from './styles.module.css'


function DynamicDepositPrompt({header, text}) {
  return (
    <div className={styles.dynamic_deposit_prompt}>
        <Typography variant='h6'>
            {header}
        </Typography>
        <Typography>
            {text}
        </Typography>
    </div>
  )
}

export default DynamicDepositPrompt