import { Typography } from "@mui/material";
import React from "react";
import styles from "./ConfirmationPrompt.module.css";

function ConfirmationPrompt({ header, text, src }) {
  return (
    <div className={styles.confirmation_prompt}>
      {header && (
        <div className={styles.header}>
          {src && <img src={src} alt="" />}
          <Typography>{header}</Typography>
        </div>
      )}
      <Typography>{text}</Typography>
    </div>
  );
}

export default ConfirmationPrompt;
