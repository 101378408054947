import { Typography } from "@mui/material";
import React from "react";
import styles from "./WalletsRows.module.css";
import green_dot from "../../../../../assets/images/dot-green.svg";
import red_dot from "../../../../../assets/images/dot-red.svg";
import { useNavigate } from "react-router-dom";
import { cutText, getDate } from "../../../../../utils/helpers";
import copy_icon from "../../../../../assets/images/copy_icon.svg";
import bin_icon from "../../../../../assets/images/bin-icon.svg";
import eye_icon from "../../../../../assets/images/eye.svg";
import horizontal_dot from "../../../../../assets/images/threeDots.svg";

function WalletsAccountsRowCrypto({
  walletActiveTab,
  type,
  openSnackbar,
  handleSelectSingleCheckbox,
  data,
  openToolTip,
  handleOpenTooltip,
  handleDeleteWallet,
  walletCheckbox,
  handleDeleteAccount,
  handleDisableAccount,
}) {
  const navigate = useNavigate();

  const handleNavigateToTransaction = (data) => {
    navigate(`/wallets/${data.wallet_reference}/accounts/${data.account_reference}/transactions`, {
      state: {
        data,
      },
    });
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      openSnackbar("success", "Copied successfully");
    } catch (error) {}
  };

  return (
    <tr
      onClick={() => {
        handleNavigateToTransaction(data);
      }}
      className={styles.row}
    >
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <input
          onChange={(e) => {
            handleSelectSingleCheckbox(e, data.account_reference);
          }}
          type="checkbox"
          checked={walletCheckbox?.some((item) => {
            return item === data.account_reference;
          })}
          name=""
          id=""
        />
      </td>
      <td>
        <div className={styles.email}>
          {data.status ? (
            <img src={green_dot} alt="" />
          ) : (
            <img src={red_dot} alt="" />
          )}

          <Typography>{getDate(data.created_at)}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.reference}>
          <Typography>
            {" "}
            {cutText(data?.address, 15, "start")}...
            {cutText(data?.address, -2, "end")}
          </Typography>
          {data.address !== null && (
            <img
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(data?.address);
              }}
              src={copy_icon}
              alt=""
            />
          )}
        </div>
      </td>

      <td>
        <div className={styles.reference}>
          <Typography>{data?.chain || "NA"}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.network}>
          <Typography>{data?.currency}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.reference}>
          <Typography
            onClick={(e) => {
              e.stopPropagation();

              handleNavigateToTransaction(data);
            }}
          >
            {data.account_type}
          </Typography>
        </div>
      </td>
      <td>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleOpenTooltip(data.account_reference);
          }}
          className={styles.action}
        >
          <img src={horizontal_dot} alt="" />
          {openToolTip?.id === data.account_reference ? (
          <div className={styles.tooltip}>
            <div className={styles.tooltip_container}>
              {/* <img className={styles.pointer_img} src={pointer_icon} alt="" /> */}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleNavigateToTransaction(data);
                }}
                className={styles.tooltip_item}
              >
                <div className={styles.tooltip_item_img}>
                  <img src={eye_icon} alt="eye" />
                </div>
                <Typography>View Transactions</Typography>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDisableAccount();
                }}
                className={styles.tooltip_item}
              >
                <div className={styles.tooltip_item_img}>
                  <img src={eye_icon} alt="eye" />
                </div>
                <Typography>Disable Account</Typography>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteAccount(data);
                }}
                className={` ${styles.tooltip_item_last} ${styles.tooltip_item}`}
              >
                <div className={styles.tooltip_item_img}>
                  <img src={bin_icon} alt="export" />
                </div>
                <Typography>Delete Account</Typography>
              </div>
            </div>
          </div>
        ) : null}
        </div>
       
      </td>
    </tr>
  );
}

export default WalletsAccountsRowCrypto;
