import React from "react";
import { Flex } from "../../../UI-components/box";
import { ForgotPasswordContainer, ForgotPasswordText, SubText } from "./styles";
import Fields from "./fields";

const Signup = () => {
  return (
    <ForgotPasswordContainer>
      <Flex
        className="form-container"
        width={"530px"}
        flexDir={"column"}
        margin={"auto"}
      >
        <ForgotPasswordText>Forgot Password</ForgotPasswordText>
        <SubText>Enter your email address, we’ll send a reset link. </SubText>
        <Fields />
      </Flex>
    </ForgotPasswordContainer>
  );
};

export default Signup;
