import React from "react";
import { SelectStyles } from "./styles";

const Index = ({ width, border,register, name,required, boxShadow, borderRad, listOptions }) => {
  return (
    <SelectStyles
      width={width}
      border={border}
      boxShadow={boxShadow}
      borderRad={borderRad}
    >
      <select  {...register(name, { required: required})}
      name={name}>
        <option value='' >All</option>
        {listOptions.length > 0
          ? listOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          : null}
      </select>
    </SelectStyles>
  );
};

export default Index;
