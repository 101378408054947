import { Typography } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-number-input";
import styles from "./PhoneNumberInput.module.css";
import "react-phone-number-input/style.css";

function PhoneNumberInput({redOutline, setValue, value, name, errors }) {

  return (
    <div>
      <div className={styles.phone_textfield}>
        <div className={styles.heading}>
          <Typography>
            Phone Number {false && <span style={{ color: "red" }}>*</span>}
          </Typography>
        </div>

        <PhoneInput
          countryCallingCodeEditable={false}
          className={`${styles.phone_number_input} ${redOutline && styles.red_outline}`}
          international
          defaultCountry="NG"
          value={value}
          onChange={(value) => {
           
            setValue(name, value);
          }}
        />
      </div>

      {/* {errors[name] && <ValidationMessage message={errors[name]?.message} />}
    
     */}
    </div>
  );
}

export default PhoneNumberInput;
