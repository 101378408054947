// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".teams_teams__A\\+1ei {\n    margin-top: 25px;\n    background-color: #F8F9FD;\n    padding: 40px 52px;\n    border-radius: 4px;\n    display: flex;\n    flex-direction: column;\n    overflow: scroll;\n}\n\n.teams_heading__9CgWY {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 35px;\n}\n\n\n\n.teams_heading_text__vV\\+Ju h4{\n    font-size: 18px;\n    font-weight: 700;\n    color: #000000;\n    margin-bottom: 12px;\n}\n\n.teams_heading_text__vV\\+Ju p{\ncolor: #3E3E3E;\nfont-size: 14px;\nfont-weight: 400;\n}\n\n.teams_table__mdulJ {\n   \n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/teams/teams.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;;;AAIA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;AACA,cAAc;AACd,eAAe;AACf,gBAAgB;AAChB;;AAEA;;AAEA","sourcesContent":[".teams {\n    margin-top: 25px;\n    background-color: #F8F9FD;\n    padding: 40px 52px;\n    border-radius: 4px;\n    display: flex;\n    flex-direction: column;\n    overflow: scroll;\n}\n\n.heading {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 35px;\n}\n\n\n\n.heading_text h4{\n    font-size: 18px;\n    font-weight: 700;\n    color: #000000;\n    margin-bottom: 12px;\n}\n\n.heading_text p{\ncolor: #3E3E3E;\nfont-size: 14px;\nfont-weight: 400;\n}\n\n.table {\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teams": "teams_teams__A+1ei",
	"heading": "teams_heading__9CgWY",
	"heading_text": "teams_heading_text__vV+Ju",
	"table": "teams_table__mdulJ"
};
export default ___CSS_LOADER_EXPORT___;
