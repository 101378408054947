import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./SettlementRow.module.css";
import Moment from "react-moment";

const SettlementRow = ({ data, currencies }) => {
  const { date } = data;

  return (
    <tr className={styles.row}>
      <td>
        <div className={styles.td_div}>
          <Typography variant="h6">
            <Moment format="DD MMM YYYY" date={date} />
          </Typography>
          <Typography>
            {new Date(data.created_at).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </Typography>
        </div>
      </td>
      <td>
        {data?.type === "WITHDRAWAL" && (
          <div className={styles.td_div2}>
            <Typography>{data?.currency}</Typography>
          </div>
        )}

        {data?.type === "DEPOSIT" && (
          <div className={styles.td_div2}>
            <Typography>{data?.currency}</Typography>
          </div>
        )}
      </td>
      <td>
        <div className={styles.td_div3}>
          {data?.type === "DEPOSIT" && (
            <Typography>
              <span>{data?.currency}</span>{" "}
              {data?.to_amount?.toLocaleString(
                "en-US",
                currencies?.result?.find((currency) => {
                  if (currency.code === data.currency) {
                    return currency.name;
                  } else {
                    return null;
                  }
                }).type === "CRYPTO"
                  ? { minimumFractionDigits: 8 }
                  : { minimumFractionDigits: 2 }
              )}
            </Typography>
          )}

          {data?.type === "WITHDRAWAL" && (
            <Typography>
              <span>{data?.from_currency}</span>{" "}
              {data?.from_amount?.toLocaleString(
                "en-US",
                currencies?.result?.find((currency) => {
                  if (currency.code === data.from_currency) {
                    return currency.name;
                  } else {
                    return null;
                  }
                }).type === "CRYPTO"
                  ? { minimumFractionDigits: 8 }
                  : { minimumFractionDigits: 2 }
              )}
            </Typography>
          )}
        </div>
      </td>
      <td>
        {data.status === "pending" ? (
          <div className={`${styles.pending} ${styles.td_div4}`}>
            <Typography>{data.status}</Typography>
          </div>
        ) : null}
        {data.status === "success" ? (
          <div className={`${styles.success} ${styles.td_div4}`}>
            <Typography>{data.status}</Typography>
          </div>
        ) : null}
        {data.status === "failed" ? (
          <div className={`${styles.failed} ${styles.td_div4}`}>
            <Typography>{data.status}</Typography>
          </div>
        ) : null}
      </td>
      <td>
        <div className={styles.td_div5}>
          <Link to={`/transaction/${data?.id}`}>View details</Link>
        </div>
      </td>
    </tr>
  );
};

export default SettlementRow;
