// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BusinessInfo_bank_info__AqluW {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.BusinessInfo_bank_info_field__WaSSK {\n\n}\n\n.BusinessInfo_business_info_previews__kMIuP {\n  background: #f5f8f9;\n  padding: 32px;\n  border-radius: 6px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.BusinessInfo_business_info_preview__aqFH- {\n  display: flex;\n  flex-direction: column;\n\n}\n\n.BusinessInfo_business_info_preview__aqFH- h6 {\ntext-transform: capitalize;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.BusinessInfo_business_info_preview__aqFH- p {\n  color: #9a9a9a;\n  font-size: 14px;\n  font-weight: 400;\ntext-transform: capitalize;\n}\n\n.BusinessInfo_last_item__GUdih {\n\n}\n\n.BusinessInfo_radio_div__OPyRv {\n  border-top: 1px solid #ebebeb;\n  \n}\n", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/compliance/business-info/BusinessInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;;AAEA;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;;AAExB;;AAEA;AACA,0BAA0B;EACxB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB,0BAA0B;AAC1B;;AAEA;;AAEA;;AAEA;EACE,6BAA6B;;AAE/B","sourcesContent":[".bank_info {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.bank_info_field {\n\n}\n\n.business_info_previews {\n  background: #f5f8f9;\n  padding: 32px;\n  border-radius: 6px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.business_info_preview {\n  display: flex;\n  flex-direction: column;\n\n}\n\n.business_info_preview h6 {\ntext-transform: capitalize;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.business_info_preview p {\n  color: #9a9a9a;\n  font-size: 14px;\n  font-weight: 400;\ntext-transform: capitalize;\n}\n\n.last_item {\n\n}\n\n.radio_div {\n  border-top: 1px solid #ebebeb;\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bank_info": "BusinessInfo_bank_info__AqluW",
	"bank_info_field": "BusinessInfo_bank_info_field__WaSSK",
	"business_info_previews": "BusinessInfo_business_info_previews__kMIuP",
	"business_info_preview": "BusinessInfo_business_info_preview__aqFH-",
	"last_item": "BusinessInfo_last_item__GUdih",
	"radio_div": "BusinessInfo_radio_div__OPyRv"
};
export default ___CSS_LOADER_EXPORT___;
