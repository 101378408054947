import React, { useState } from "react";
import { useLocation } from "react-router";
import {
  GET_WALLETS_TRANSACTIONS,
  GET_WALLETS_TRX_SUMMARY,
} from "../../../../../api/queries/dashboard/wallets";
import GoBack from "../../../../UI-components/goBack/GoBack";
import Layout from "../../../../UI-components/layouts";
import TableOne from "../../../../UI-components/new -tables/Table";
import { Styles } from "../styles";
import styles from "./WalletTrx.module.css";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../UI-components/loader/Loader";
import Pagination from "../../../../UI-components/pagination/Pagination";
import { useParams } from "react-router-dom";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import SettlementTab from "../../../../UI-components/settlementTabs/SettlementTabs";

import OverviewCards from "../walletAccounts/components/overviewCards/OverviewCards";
import Overview from "../walletAccounts/components/overview/Overview";
import { Typography } from "@mui/material";
import OverviewWalletTrx from "./components/WalletTrxOverview";

const tableHeadData = [
  "date",
  "amount",
  // "recipient",
  "account no",
  "transaction type",
  "status",
];

const tableHeadDataCrypto = [
  "date",
  "amount",
  // "recipient",
  "address",
  "transaction type",
  "status",
];

function WalletAccountsTransactions() {
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const params = useParams();
  const { state } = useLocation();
  const [page, setPage] = useState(1);


  const {
    data: walletsTrx,
    isLoading: loadingWalletsTrx,
    refetch,
  } = useQuery(
    ["wallets-transactions", params.id, page, params.a_id],
    GET_WALLETS_TRANSACTIONS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <Layout title={state?.data?.account_name} bgColor={"#ffffff"}>
      <Styles>
        <div className={styles.heading}>
          <GoBack />
        </div>
        <OverviewCards params={params} />

        <OverviewWalletTrx
          type={state.data.type}
          openSnackbar={openSnackbar}
          data={state?.data}
        />

        {loadingWalletsTrx ? (
          <Loader />
        ) : (
          <div className={styles.table}>
            <div className={styles.table_heading}>
              <Typography>Account Transactions</Typography>
            </div>
            <div className={styles.body}>
              <>
                <TableOne
                  handleRefresh={refetch}
                  tableBody={walletsTrx?.data?.data}
                  type={`wallet_account_transactions_${state?.data?.type}`}
                  tableHeadData={state?.data?.type === 'FIAT' ? tableHeadData : tableHeadDataCrypto}
                />
                {walletsTrx?.data?.data?.length > 0 ? (
                  <Pagination
                    handlePageChange={handlePageChange}
                    meta={walletsTrx?.data?.meta}
                  />
                ) : null}
              </>
            </div>
          </div>
        )}
      </Styles>

      {/* {isShowingPrompt && (
        <AppBackdrop
          openModal={isShowingPrompt}
          handleCloseModal={() => {
            toggle("confirmation-prompt");
          }}
          child={
            <ModalFrame2
              btnColor={"#D80027"}
              btnLoading={""}
              handleActionTwo={() => {
                toggle("confirmation-prompt");
              }}
              handleAction={() => {
                handleConfirmWalletDelete();
              }}
              btnTxt={"Yes, delete"}
              child={
                <ConfirmationPrompt
                  header="Delete this wallet??"
                  text={"Funds stored in deleted wallets cannot be recovered."}
                />
              }
            />
          }
        />
      )} */}
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </Layout>
  );
}

export default WalletAccountsTransactions;
