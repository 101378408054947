import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { depositAmountSchema } from "../../../../../../../schemas/dashboard/deposit";
import { calculateFee } from "../../../../../../../utils/helpers";
import AppBackdrop from "../../../../../../UI-components/appBackdrop/AppBackdrop";
import HeadingOne from "../../../../../../UI-components/headings/headingsOne/HeadingsOne";
import ModalFrame2 from "../../../../../../UI-components/modalFrame2/ModalFrame2";
import styles from "./DynamicDeposit.module.css";

import DynamicDepositPrompt from "./modals/DynamicDepositPrompt";
import DynamicDepositStep1 from "./modules/DynamicDepositStep1";
import DynamicDepositStep2 from "./modules/DynamicDepositStep2";

import ModalModeAlert from "../../../../../../UI-components/modeAlerts/ModalModeAlert";
import { DataCentral } from "../../../../../../../context/AppContext";

function DynamicDeposit({
  openSnackbar,
  fees,
  currency,
  handleScreen,
  method,
}) {
  const [step, setStep] = useState(1);
  const [isShowingPrompt, setIsShowingPrompt] = useState(false);
  const [fee, setFee] = useState(Number);
  const {user} = useContext(DataCentral)
  useEffect(() => {
    setFee(calculateFee(fees, amount));
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(depositAmountSchema),
  });

  const amount = watch("amount", "");

  return (
    <div className={styles.dynamic_deposit}>
      {
        (step === 2 && user?.mode === 'test') &&   <ModalModeAlert/>
      }

      <HeadingOne
        title={method?.modal_title}
        description={method?.modal_description}
      />
      {/* <div className={styles.step}>
        <Typography>Step {step} of 2</Typography>
      </div> */}
      <div className={styles.dynamic_deposit_form}>
        {step === 1 ? (
          <DynamicDepositStep1
            fee={fee}
            openSnackbar={openSnackbar}
            register={register}
            watch={watch}
            setValue={setValue}
            errors={errors}
            handleSubmit={handleSubmit}
            handleScreen={handleScreen}
            currency={currency}
            step={step}
            setStep={setStep}
          />
        ) : (
          <DynamicDepositStep2
            method={method}
            fee={fee}
            handleCancel={() => {
              setIsShowingPrompt(true);
            }}
            handleProceed={() => {
              handleScreen("dynamic-bank-pending-deposit");
              //setIsShowingPending(true);
            }}
            amount={amount}
            handleScreen={handleScreen}
            currency={currency}
            step={step}
            setStep={setStep}
          />
        )}
      </div>

      {isShowingPrompt && (
        <AppBackdrop
          openModal={isShowingPrompt}
          handleCloseModal={() => {
            setIsShowingPrompt(false);
          }}
          child={
            <ModalFrame2
              btnLoading={""}
              handleActionTwo={() => {
                handleScreen("");
              }}
              handleAction={() => {
                setIsShowingPrompt(false);
              }}
              btnText2={"Yes, Cancel"}
              btnTxt={"No, Go Back"}
              child={
                <DynamicDepositPrompt
                  header={"Cancel Deposit?"}
                  text={
                    "Are you sure you want to cancel this deposit? Unconfirmed deposits will not reflect on your balance."
                  }
                />
              }
            />
          }
        />
      )}

      {/* {isShowingPending && (
        <AppBackdrop
          openModal={isShowingPending}
          handleCloseModal={() => {
            setIsShowingPending(false);
          }}
          child={
            <ModalFrame2
              btnLoading={""}
              handleActionTwo={() => {
                handleScreen("");
              }}
              handleAction={() => {
                setIsShowingPending(false);
              }}
              btnText2={"Okay"}
              btnTxt={"Contact Support"}
              child={
                <DynamicDepositPending
                 
                />
              }
            />
          }
        />
      )} */}
    </div>
  );
}

export default DynamicDeposit;
