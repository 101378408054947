import { useQuery } from "@tanstack/react-query";
import { GET_PROFILE } from "../api/queries/dashboard/settings/profile";


const useGetProfile = () => {
  const {
    data: profile,
    isLoading,
  } = useQuery(["profile"], GET_PROFILE, {
    refetchOnWindowFocus: false,
  });

  return {
    profile: profile?.data,
    isLoading
  };
};

export default useGetProfile;
