import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./ConfirmOrder.module.css";
import swipe from "../../../assets/images/swipe.svg";
import Button from "../button";
import { parseAmount } from "../../../utils/helpers";
import { useState } from "react";

const ConfirmOrder = ({
  currencies,
  swapDetails,
  timer,
  handleRefreshSwap,
  refreshingSwap,
  handleConfirmSubmitSwap,
  loadingConfirmSwap,
}) => {
  return (
    <OrderSummary
    currencies={currencies}
      handleConfirmSubmitSwap={handleConfirmSubmitSwap}
      refreshingSwap={refreshingSwap}
      handleRefreshSwap={handleRefreshSwap}
      swapDetails={swapDetails}
      timer={timer}
      loadingConfirmSwap={loadingConfirmSwap}
    />
  );
};

export default ConfirmOrder;

const OrderSummary = ({
  currencies,
  timer,
  loadingConfirmSwap,
  swapDetails,
  handleRefreshSwap,
  refreshingSwap,
  handleConfirmSubmitSwap,
}) => {
  const [fromCurrencyType, setFromCurrencyType] = useState('')
  const [toCurrencyType, setToCurrencyType] = useState('')

  useEffect(()=>{
    setFromCurrencyType( currencies.result.find((currency)=>{
      return currency.code === swapDetails.from_currency
    })?.type)

    setToCurrencyType( currencies.result.find((currency)=>{
      return currency.code === swapDetails.to_currency
    })?.type)
   
  }, [])

 
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.confirm_order}
    >
      <div
        className={`${timer.sec === 0 ? styles.info_red : styles.info_green} ${
          styles.info
        }`}
      >
        <Typography>
          {timer.sec === 0
            ? "Your quote rate has expired. Click on Refresh rate to get new quote"
            : "Please swap within the alloted time. Once the timer expires, you will have to generate a new rate to proceed"}
        </Typography>
      </div>
      <div className={styles.currency_icons}>
        <div className={styles.icons}>
          <div className={styles.icon_div}>
            {currencies.result.map((currency) => {
              if (currency.code === swapDetails.from_currency) {
                return (
                  <img key={currency.id} src={currency.icon} alt={`flag`} />
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className={styles.icon_text}>
            <Typography variant="h6">
              {" "}
              {currencies.result.map((currency) => {
                if (currency.code === swapDetails.from_currency) {
                  return currency.name;
                } else {
                  return null;
                }
              })}
            </Typography>
            <Typography>
              (
              {currencies.result.map((currency) => {
                if (currency.code === swapDetails.from_currency) {
                  return currency.code;
                } else {
                  return null;
                }
              })}
              )
            </Typography>
          </div>
        </div>

        <img src={swipe} alt="" />

        <div className={styles.icons}>
          <div className={styles.icon_div}>
            {currencies?.result?.map((currency) => {
              if (currency.code === swapDetails.to_currency) {
                return (
                  <img key={currency.id} src={currency.icon} alt={`flag`} />
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className={styles.icon_text}>
            <Typography variant="h6">
              {" "}
              {currencies?.result?.map((currency) => {
                if (currency.code === swapDetails.to_currency) {
                  return currency.name;
                } else {
                  return null;
                }
              })}
            </Typography>
            <Typography>
              (
              {currencies?.result?.map((currency) => {
                if (currency.code === swapDetails.to_currency) {
                  return currency.code;
                } else {
                  return null;
                }
              })}
              )
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.will_recieve}>
        <Typography variant="h5">You will recieve</Typography>
        <Typography variant="h6">
          {parseAmount(swapDetails?.to_amount, toCurrencyType)} {' '}
          {swapDetails.to_currency}
        </Typography>
        <Typography>
          Converted Amount: {parseAmount(swapDetails?.from_amount, fromCurrencyType)}  {" "}
          {swapDetails?.from_currency}
        </Typography>
      </div>
      <div className={styles.details}>
        <div className={styles.detail}>
          <Typography variant="h6">Rate</Typography>
          <Typography>
            {swapDetails?.from_amount < swapDetails?.to_amount
              ? ` 1 ${swapDetails?.from_currency} = ${swapDetails?.to_currency} ${parseAmount(swapDetails?.rate)}`
              : ` 1 ${swapDetails?.to_currency} = ${swapDetails?.from_currency} ${parseAmount(swapDetails?.rate)}`}
          
          </Typography>
        </div>
        <div className={styles.detail}>
          <Typography variant="h6">Settlement time</Typography>
          <Typography>
            {Number(swapDetails?.settlement_time / 60)?.toFixed(2)} hrs
          </Typography>
        </div>{" "}
        <div className={styles.detail}>
          <Typography variant="h6">Fee</Typography>
          <Typography>
            {swapDetails?.fee ? swapDetails?.fee?.toFixed(2) : 0}{" "}
            {swapDetails?.fee_currency}
          </Typography>
        </div>{" "}
        <div className={styles.detail}>
          <Typography variant="h6">Expires in</Typography>
          <Typography>{timer.sec}s</Typography>
        </div>
      </div>
      <div className={styles.btn}>
        <Button
          onClick={() => {
            timer.sec === 0 ? handleRefreshSwap() : handleConfirmSubmitSwap();
          }}
          text={`${
            timer.sec === 0
              ? "Click to Request new rate"
              : `Convert Now (${timer.sec}s)`
          }`}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          loading={refreshingSwap || loadingConfirmSwap}
          type="button"
          borderRadius={"5px"}
        />
      </div>
    </div>
  );
};
