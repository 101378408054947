import { Typography } from "@mui/material";
import React, { useContext } from "react";
import styles from "./TeamRow.module.css";

import { DataCentral } from "../../../../../context/AppContext";
import { getDate } from "../../../../../utils/helpers";

const TeamRow = ({
  data,
  handleEditMember,
  handleRemoveMember,
  handleResetLogin,
}) => {
  const { user } = useContext(DataCentral);
  return (
    <tr className={styles.row}>
      <td>
        <div className={styles.member}>
          <Typography>{data.full_name}</Typography>
          {/* {data.own_merchant ? (
            <div className={styles.chip}>
              <Typography>Owner</Typography>
            </div>
          ) :  */}
          {user.username === data.username ? (
            <div className={styles.chip}>
              <Typography>You</Typography>
            </div>
          ) : null}
        </div>
      </td>
      <td>
        <div className={styles.email}>
          <Typography>{data.email}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.email}>
          <Typography>{data?.role?.name}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.status}>
          <Typography style={{ color: data.status ? "green" : "red" }}>
            {data.status ? "active" : "inactive"}
          </Typography>
        </div>
      </td>
      <td>
        <div className={styles.last_login}>
          <Typography>{getDate(data.updated_at)}</Typography>
        </div>
      </td>
      <td>
        {user.own_merchant ? (
          <div className={styles.action}>
            {data.own_merchant ? null : (
              <>
                {" "}
                <Typography
                  style={{ color: "#D80027" }}
                  onClick={() => {
                    handleRemoveMember(data);
                  }}
                >
                  Remove
                </Typography>{" "}
                <Typography
                  style={{ color: "#1A2CCE" }}
                  onClick={() => {
                    handleResetLogin(data);
                  }}
                >
                  Reset Login
                </Typography>
                <Typography
                  style={{ color: "#1A2CCE" }}
                  onClick={() => {
                    handleEditMember(data);
                  }}
                >
                  Edit
                </Typography>
              </>
            )}
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export default TeamRow;
