import React, { useContext, useEffect, useState } from "react";
import { Styles } from "./styles";
import OverviewCards from "./overview-cards";
import VerifyBuisness from "./verify-business";
import Swap from "./swap";
import Layout from "../../../UI-components/layouts";
import { Spacer } from "../../../UI-components/spacer/styles";
import { DataCentral } from "../../../../context/AppContext";

import Loader from "../../../UI-components/loader/Loader";

import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import { Skeleton } from "@mui/material";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useGetDefaultCurrencies from "../../../../customHooks/useGetDefaultCurrencies";
import {
  GET_MARKET_RATES,
  GET_TOTAL_PENDING_TRANSACTIONS_SUMMARY,
  GET_TOTAL_TRANSACTIONS_SUMMARY,
  GET_WALLET_SUMMARY,
} from "../../../../api/queries/dashboard/home";
import { useQuery } from "@tanstack/react-query";

const Index = () => {
  const { loadingUser, user } = useContext(DataCentral);
  const { message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  const {
    isLoading: isLoadingCurrencies,
    currencies,
    fiatCurrencies,
    cryptoCurrencies,
  } = useGetDefaultCurrencies();

  const {
    data: totalTransactions,
    isLoading: isLoadingTransactions,
    refetch: refetchTotalTransactions,
  } = useQuery(["total-transactions"], GET_TOTAL_TRANSACTIONS_SUMMARY, {
    refetchOnWindowFocus: false,
  });

  const {
    data: totalPendingTransactions,
    isLoading: isLoadingPendingTransactions,
    refetch: refetchPendingTransactions,
  } = useQuery(
    ["total-pending-transactions"],
    GET_TOTAL_PENDING_TRANSACTIONS_SUMMARY,
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: balanceData,
    isLoading: isLoadingWalletSummary,
    refetch: refetchWalletSummary,
  } = useQuery(["wallet-summary"], GET_WALLET_SUMMARY, {
    refetchOnWindowFocus: false,
  });

  const { isLoading, data: rates } = useQuery(
    ["market-rates"],
    GET_MARKET_RATES,
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchIntervalInBackground: true,
      refetchInterval: 60000,
    }
  );



  return (
    <Layout title={"Home"} bgColor={"#f3f6fb"}>
      {isLoadingCurrencies ? (
        <Loader />
      ) : (
        <Styles>
          <div className="welcome_back">
            <h1>Welcome back, </h1>
            {loadingUser ? (
              <Skeleton variant="rectangular" width={"150px"} height={"20px"} />
            ) : (
              <h1> {user?.full_name}</h1>
            )}
          </div>
          <Spacer height={"20px"} />
          {user?.merchant?.verified_business ? null : <VerifyBuisness />}
          <Spacer height={"20px"} />
          <OverviewCards
            totalPendingTransactions={totalPendingTransactions?.data}
            loadingTotalPendingTransactions={isLoadingPendingTransactions}
            totalTransactions={totalTransactions?.data}
            loadingTotalTransactions={isLoadingTransactions}
            loadingBalances={isLoadingWalletSummary}
            balanceData={balanceData?.data}
          />
          <Swap
            rates={rates}
            loadingMarketRates={isLoading}
            cryptoCurrencies={cryptoCurrencies}
            currencies={currencies}
            fiatCurrencies={fiatCurrencies}
          />
        </Styles>
      )}
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </Layout>
  );
};

export default Index;
