import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { GET_COMPLIANCE } from "../../../../../api/queries/dashboard/settings/compliance";

import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import Accordion from "../../../../UI-components/accordion/Accordion";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import BlueBackground from "../../../../UI-components/blueBg/BlueBg";
import HeadingOne from "../../../../UI-components/headings/headingsOne/HeadingsOne";
import Loader from "../../../../UI-components/loader/Loader";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import BankInfo from "./bank-info/BankInfo";
import BusinessInfo from "./business-info/BusinessInfo";
import styles from "./Compliance.module.css";
import DocumentUpload from "./doc-upload/DocUpload";
import Progress from "./progress/Progress";
import useGetCountries from "./../../../../../customHooks/useGetCountries";
import useGetBanks from "../../../../../customHooks/useGetBanks";

const Compliance = () => {
  const { isLoadingCountries, countries } = useGetCountries();
  const { banks, isLoadingBanks } = useGetBanks();
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  const [country, setcountry] = useState("");

  const { data: complianceInfo, isLoading } = useQuery(
    ["compliance"],
    GET_COMPLIANCE,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <SettingsLayout
      tab="compliance"
      child={
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <BlueBackground
              content={
                <div className={styles.compliance}>
                  <div className={styles.compliance_heading}>
                    <HeadingOne
                      title={"compliance and KYC verification"}
                      description={
                        "Please fill in accurately required information and upload the required documents to verify your account"
                      }
                    />
                  </div>
                  <div className={styles.compliance_body}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className={styles.accordions}>
                          <div className={styles.accordion_item}>
                            <Accordion
                              accordionTitle={"Business Information"}
                              accordionContent={
                                <BusinessInfo
                                  countries={countries}
                                  complianceInfo={complianceInfo?.data}
                                  //setComplianceInfo={setComplianceInfo}
                                  openSnackbar={openSnackbar}
                                  setcountry={setcountry}
                                />
                              }
                            />
                          </div>
                          <div className={styles.accordion_item}>
                            <Accordion
                              accordionTitle={"Document Upload"}
                              accordionContent={
                                <DocumentUpload
                                  countries={countries}
                                  //setComplianceInfo={setComplianceInfo}
                                  complianceInfo={complianceInfo?.data}
                                  openSnackbar={openSnackbar}
                                />
                              }
                            />
                          </div>

                          <div className={styles.accordion_item}>
                            <Accordion
                              accordionTitle={"Bank information"}
                              accordionContent={
                                <BankInfo
                                  openSnackbar={openSnackbar}
                                  //setComplianceInfo={setComplianceInfo}
                                  complianceInfo={complianceInfo?.data}
                                  country={country}
                                  banks={banks}
                                />
                              }
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Progress complianceInfo={complianceInfo?.data} />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              }
            />
          )}
          <SwapstaSnackbar
            open={openToast}
            message={message}
            severity={severity}
            setOpen={setOpenToast}
            duration={duration}
          />
        </>
      }
    />
  );
};

export default Compliance;
