import Moment from "react-moment";
import Papa from 'papaparse';


export const cutText = (name, chars, from) => {
  if (!name) {
    return;
  } else {
    if (name.length > chars) {
      if (from === "start") {
        return name.length > chars ? name.substr(0, chars) : name;
      } else if (from === "end") {
        return name.length > chars ? name.substr(chars) : name;
      } else {
        return name.length > chars ? name.substr(0, chars) : name;
      }
    } else {
      return name;
    }
  }
};

export const endpointFilterParser = (value, key) => {
  if (value) {
    return `&${key}=${value}`;
  } else {
    return "";
  }
};

export const endpointFilterParserStart = (value, key) => {
  if (value) {
    return `?${key}=${value}`;
  } else {
    return "";
  }
};

export const removeUrl = (name) => {
  if (!name) {
    return;
  } else {
    if (name.includes(process.env.REACT_APP_BASE_URL)) {
      return name?.slice(process.env.REACT_APP_BASE_URL.length + 28);
    } else {
      return name;
    }
  }
};

export const extractFileType = (name) => {
  if (name) {
    const fileNameArray = name.split(".");
    const fileType = fileNameArray[fileNameArray.length - 1];

    return fileType;
  }
};

export async function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64 = reader.result;
      resolve(base64);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export const convertToLocaleString = (string) => {
  return string.toLocaleString();
};

export const parseAmount = (num, type) => {
  
  if (num) {
    let formatedWithDecimal = Number(num).toFixed(type === "CRYPTO" ? 8 : 2);
    let [integer, decimal] = String(formatedWithDecimal).split(".");

    let formatedWithComma = integer
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    let formattedAll = [formatedWithComma, decimal].join(".");

    return formattedAll;
  } else {
    return 0;
  }
};

export const getDate = (date) => {
  if (date) {
    return <Moment format="DD MMM YYYY" date={date} />;
  } else {
    return <Moment format="DD MMM YYYY" date={new Date()} />;
  }
};
export const getTime = (date) => {
  if (date) {
    return new Date(date).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
};


export const removeDash= (string)=> {
  if(string){
    return string?.replace(/-/g, '');
  }
}

export function convertToCSV(textData) {
  const rows = textData.split('\n');
  const data = rows.map(row => row.split(','));
  const csv = Papa.unparse(data);
  return csv;
}


export const calculateFee =(fees, amount)=>{
 
    if (fees.fee_type === "FLAT") {
     return (fees.fee.toLocaleString());
    } else {
      return ((fees.fee / 100) * amount);
    }
  
}


export function getOrdinalNumber(n) {
  const suffixes = ["st", "nd", "rd"];
  const remainderTen = n % 10;
  const remainderHundred = n % 100;

  if (remainderTen >= 1 && remainderTen <= 3 && remainderHundred !== 11 && remainderHundred !== 12 && remainderHundred !== 13) {
    return n + suffixes[remainderTen - 1];
  } else {
    return n + "th";
  }
}