import { Typography } from '@mui/material';
import React from 'react'
import LabeledSelectTwo from '../../../../../UI-components/select/labeledSelectTwo/LabeledSelect';
import styles from './BottomFilter.module.css'
import Button from '../../../../../UI-components/button/index'

function BottomFilter({handleSubmit, onSubmit, walletCheckbox, handleSelectAll}) {
  return (
    <div className={styles.bottom_filter}>
    <div className={styles.selections}>
      <Typography variant="h6">
        {walletCheckbox.length} item selected
      </Typography>
      <Typography
        onClick={() => {
          handleSelectAll(false);
        }}
      >
        Clear selection
      </Typography>
    </div>
    <div className={styles.field}>
      <LabeledSelectTwo
        name="action"
        label=""
        register={() => {}}
        data={[]}
      />
    </div>
    <div className={styles.filter_btn}>
      <Button
        Styles
        text={"Apply"}
        bgColor={"#E7F1FF"}
        color={"#1A2CCE"}
        size="sm"
        type="submit"
        border={"0.4px solid #1A2CCE"}
        boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
        borderRadius={"5px"}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  </div>
  )
}

export default BottomFilter