import { Typography } from "@mui/material";
import React from "react";
import styles from "./Password.module.css";
import mark_green from "../../../assets/images/mark_green.svg";
import mark from "../../../assets/images/mark.svg";

const PasswordContext = ({
  passwordErrorEightChar,
  passwordErrorNumber,
  passwordErrorSpecialChar,
}) => {
  return (
    <div className={styles.password_context}>
      <div className={styles.password_context_item}>
        {passwordErrorEightChar.eightChar ? (
          <img src={mark} alt="" />
        ) : (
          <img src={mark_green} alt="" />
        )}

        <Typography>Must contain more than 8 characters</Typography>
      </div>
      <div className={styles.password_context_item}>
        {passwordErrorNumber.number ? (
          <img src={mark} alt="" />
        ) : (
          <img src={mark_green} alt="" />
        )}

        <Typography>Must contain numbers</Typography>
      </div>
      <div className={styles.password_context_item}>
        {passwordErrorSpecialChar.specialChar ? (
          <img src={mark} alt="" />
        ) : (
          <img src={mark_green} alt="" />
        )}

        <Typography>Must contain a special character</Typography>
      </div>
    </div>
  );
};

export default PasswordContext;
