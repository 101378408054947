import { useQuery } from "@tanstack/react-query";
import { GET_CURRENCY_PAIRS } from "../api/queries/dashboard/misc";

const useGetCurrencyPairs = () => {
  const { data: currencyPairs, isLoading } = useQuery(
    ["currency-pairs"],
    GET_CURRENCY_PAIRS,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    currencyPairs: currencyPairs?.data,
    isLoadingPairs: isLoading,
  };
};

export default useGetCurrencyPairs;
