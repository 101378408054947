import React, { useEffect } from "react";
import styles from "./InputsWIthTexts.module.css";
import { Typography } from "@mui/material";

const InputWithTexts = ({
  text,
  redOutline,
  type,
  maxLength,
  minLength,
  register,
  pattern,
  name,
  placeholder,
  watch,
  setValue,
}) => {
  const value = watch && watch(name, "");

  useEffect(() => {
    if (type === "text") {
      setValue(name, value?.replace(/[^a-z ]/gi, ""));
    } else if (type === "number") {
      setValue(name, value?.replace(/[^0-9.-]/g, ""));
    }
  }, [value]);

  return (
    <div
      className={`${redOutline ? styles.red_outline : null} ${
        styles.input_with_text
      }`}
    >
      <div className={styles.heading}>
        <Typography>{text}</Typography>
      </div>
      <input
        step="any"
        name={name}
        type={type}
        placeholder={placeholder}
        {...register(name, {
          required: true,
          maxLength: maxLength ? maxLength : 80,
          minLength: minLength ? minLength : 1,
          pattern: pattern,
        })}
      />
    </div>
  );
};

export default InputWithTexts;
