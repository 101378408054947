import { Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./NewWallet.module.css";
import NewWalletMain from "./NewWalletMain";

function NewWallet({ handleCloseModal, openSnackbar }) {
  return (
    <div className={styles.new_wallet}>
      <div className={styles.heading}>
        <Typography variant="h6">Add a new wallet</Typography>
        <Typography>Step 1 of 2</Typography>
      </div>
      {/* <div className={styles.new_wallet_tab}>
        <div
          onClick={() => {
            setActiveTab("crypto");
          }}
          className={activeTab === "crypto" && styles.active_tab}
        >
          <Typography>Crypto</Typography>
        </div>
        <div
          onClick={() => {
            setActiveTab("fiat");
          }}
          className={activeTab === "fiat" && styles.active_tab}
        >
          <Typography>Fiat</Typography>
        </div>
      </div> */}

      <NewWalletMain
        openSnackbar={openSnackbar}
        handleCloseModal={handleCloseModal}
      />

      {/* {activeTab === "fiat" && (
        <NewWalletFiat
          openSnackbar={openSnackbar}
          activeTab={activeTab}
          handleCloseModal={handleCloseModal}
        />
      )} */}
    </div>
  );
}

export default NewWallet;
