import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./InviteMember.module.css";
import Button from "../../../../../../UI-components/button";
import LabeledInputs from "../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";
import { useForm } from "react-hook-form";
import PasswordContext from "../../../../../../UI-components/password-context/PasswordContext";
import eye_icon from "../../../../../../../assets/images/eye.svg";
import eye_off_icon from "../../../../../../../assets/images/EyeOff.svg";

import LabeledSelectTwo from "../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_ROLES } from "../../../../../../../api/queries/dashboard/teams";
import { INVITE_MEMBER } from "../../../../../../../api/mutations/dashboard/settings/teams";
import useGetRoles from "../../../../../../../customHooks/useGetRoles";

const InviteMember = ({ openSnackbar, handleGetTeams, handleCloseModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [passwordErrorSpecialChar, setPasswordErrorSpecialChar] = useState({});
  const [passwordErrorEightChar, setPasswordErrorEightChar] = useState({});
  const [passwordErrorNumber, setPasswordErrorNumber] = useState({});

  const queryClient = useQueryClient();
  const {roles, isLoadingRoles} = useGetRoles()

  const { mutate, isLoading: isLoadingInvite } = useMutation(INVITE_MEMBER, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["teams"]);
      openSnackbar("success", response.message);
      handleCloseModal("invite");
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });
  useEffect(() => {
    handleSpecialChar("");
    handleNumberValidation("");
    handleEightChar("");
  }, []);

  const handleEightChar = (value) => {
    if (value.length < 8) {
      setPasswordErrorEightChar({ ...passwordErrorEightChar, eightChar: true });
    } else {
      const tempPass = { ...passwordErrorEightChar };
      tempPass.eightChar = false;
      setPasswordErrorEightChar(tempPass);
    }
  };

  const handleNumberValidation = (value) => {
    const numberRegex = /\d/;
    if (numberRegex.test(value)) {
      const tempPass = { ...passwordErrorNumber };
      tempPass.number = false;
      setPasswordErrorNumber(tempPass);
    } else {
      setPasswordErrorNumber({ ...passwordErrorNumber, number: true });
    }
  };
  const handleSpecialChar = (value) => {
    const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialCharRegex.test(value)) {
      const tempPass = { ...passwordErrorSpecialChar };
      tempPass.specialChar = false;
      setPasswordErrorSpecialChar(tempPass);
    } else {
      setPasswordErrorSpecialChar({
        ...passwordErrorSpecialChar,
        specialChar: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    if (name === "password") {
      setPassword(value);
    }
  };

  const handleValidation = (name, value) => {
    if (name === "password") {
      handleEightChar(value);
      handleSpecialChar(value);
      handleNumberValidation(value);
    }
  };

  const handleTogglePassword = async (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    if (
      !passwordErrorSpecialChar.specialChar &&
      !passwordErrorNumber.number &&
      !passwordErrorEightChar.eightChar
    ) {
      mutate({
        full_name: data.full_name,
        email: data.email,
        password: password,
        role_id: data.role,
      });

     
    } else {
    }
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.change_password}
    >
      <div className={styles.heading}>
        <h2>Invite team member</h2>
        <Typography>
          Add a new team memeber to access this business account
        </Typography>
      </div>
      <div className={styles.body}>
        <div className={styles.field}>
          <LabeledInputs
            label={"full name"}
            type={"text"}
            redOutline={errors.full_name ? true : false}
            register={register}
            name={"full_name"}
            handleChangeAuto={true}
            required={true}
          />
          <ValidationMessage
            message={errors.full_name ? "Full name is required" : null}
          />
        </div>
        <div className={styles.field}>
          <LabeledInputs
            label={"email"}
            type={"text"}
            redOutline={errors.email ? true : false}
            register={register}
            name={"email"}
            handleChangeAuto={true}
            required={true}
          />
          <ValidationMessage
            message={errors.email ? "Email is required" : null}
          />
        </div>
        <div className={styles.field}>
          <LabeledSelectTwo
            data={roles?.data?.map((role) => {
              return {
                label: role.name,
                value: role.id,
              };
            })}
            redOutline={errors.role ? true : false}
            register={register}
            name="role"
            label="Role"
            placeholder="Select role"
            required={true}
          />
           <ValidationMessage
            message={errors.role ? "Role is required" : null}
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="">
            <Typography>New Password</Typography>
            <div className={styles.input_box}>
              <input
                value={password}
                onChange={handleChange}
                name="password"
                type={showPassword ? "text" : "password"}
              />
              <div onClick={handleTogglePassword}>
                {showPassword ? (
                  <img src={eye_icon} alt="" />
                ) : (
                  <img src={eye_off_icon} alt="" />
                )}
              </div>
            </div>
          </label>
        </div>
        <PasswordContext
          passwordErrorEightChar={passwordErrorEightChar}
          passwordErrorNumber={passwordErrorNumber}
          passwordErrorSpecialChar={passwordErrorSpecialChar}
        />
      </div>
      <div className={styles.btn}>
        <Button
          text={"Send invite"}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type="button"
          borderRadius={"5px"}
          onClick={handleSubmit(onSubmit)}
          loading={isLoadingInvite}
        />
      </div>
    </div>
  );
};

export default InviteMember;
