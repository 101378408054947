import axios from "axios";

export const INITIATE_SWAP = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}market/rates/create`, data)
    .then((res) => {
      return res.data;
    });
};

export const REFRESH_SWAP = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}market/rates/refresh`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const CONFIRM_SWAP = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}transaction/swap`, data)
      .then((res) => {
        return res.data;
      });
  };