import React from "react";
import { LoginInContainer, LoginTitle } from "./styles";
import { Flex, Frame } from "../../../UI-components/box";
import logo from "../../../../assets/images/logo-dark.svg";
import Fields from "./fields";

const TwoFa = () => {
  return (
    <LoginInContainer>
      <Flex
        width={"530px"}
        flexDir={"column"}
        margin={"auto"}
        className="form-container"
      >
        <Flex>
          <Frame height={"150px"} width={"150px"}>
            <img alt="logo" src={logo} />
          </Frame>
        </Flex>
        <LoginTitle>Security verification</LoginTitle>
        <Fields />
      </Flex>
    </LoginInContainer>
  );
};

export default TwoFa;
