import { Typography } from "@mui/material";
import React from "react";
import { PendingClockIcon } from "../../../../../../../../assets";
import Button from "../../../../../../../UI-components/button";
import Disclaimer from "../../../../../../../UI-components/disclaimer/Disclaimer";
import styles from "./styles.module.css";

function DynamicDepositPending({ handleClose }) {
  return (
    <div className={styles.dynamic_deposit_pending}>
      <div className={styles.header}>
        <PendingClockIcon />

        <Typography>Your Deposit is Pending</Typography>
      </div>

      <Disclaimer
        message={
          "Pending deposits made outside the stipulated time frame will be reversed. Please contact support if you do not receive a reversal within 24 hours."
        }
      />
      <div className={styles.btns}>
      <div className={styles.btn}>
          <Button
            Styles
            text={"Okay"}
            color={"#FFFFFF"}
          
            size="md"
            bgColor={"#1A2CCE"}
       
            fullwidth
            type="submit"
            onClick={() => {
              handleClose();
            }}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"5px"}
          />
        </div>
        <div className={styles.btn}>
          <a href="mailto:start@trytreasura.com">
            <Button
              Styles
              text={"Contact Support"}
              bgColor={"white"}
              color={"#676767"}
              size="md"
              fullwidth
              type="submit"
              border={"none"}
              boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
              borderRadius={"5px"}
            />
          </a>
        </div>
      
      </div>
    </div>
  );
}

export default DynamicDepositPending;
