import { Typography } from "@mui/material";
import React from "react";
import styles from "./DocUpload.module.css";
import { getOrdinalNumber } from "../../../../../../utils/helpers";

const DocumentUploadPreview = ({
  documentUploadData,
  complianceInfo,
  identity_document_type,
  country,
  document_business,
  document_memorandum,
  document_identity,
  document_incorporation,
  cutText,
  removeUrl,
}) => {
  return (
    <div className={styles.document_previews}>
      <div className={styles.document_preview}>
        <Typography variant="h6">Country of issuance</Typography>
        <Typography>{country}</Typography>
      </div>

      {documentUploadData?.map((item) => {
        if (item.name === "id_directors_") {
          return (
            <div key={item.name} className={styles.document_preview}>
              <Typography variant="h6">{item.label}</Typography>

              {complianceInfo["id_directors"]?.map((director, id) => {
                return (
                  <div key={id}>
                    <Typography>{cutText(removeUrl(director), 20)}</Typography>
                    <a target="_blank" rel="noreferrer" href={director}>
                    View {getOrdinalNumber(id+1)} document 
                    </a>
                  </div>
                );
              })}
            </div>
          );
        } else if (item.name === "id_shareholders_") {
          return (
            <div key={item.name} className={styles.document_preview}>
              <Typography variant="h6">{item.label}</Typography>

              {complianceInfo["id_shareholders"]?.map((director, id) => {
                return (
                  <div key={id}>
                    <Typography>{cutText(removeUrl(director), 20)}</Typography>
                    <a target="_blank" rel="noreferrer" href={director}>
                      View {getOrdinalNumber(id+1)} document 
                    </a>
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div key={item.name} className={styles.document_preview}>
              <Typography variant="h6">{item.label}</Typography>
              <Typography>
                {" "}
                {cutText(removeUrl(complianceInfo[item.name]), 20)}
              </Typography>
              <a
                target="_blank"
                rel="noreferrer"
                href={complianceInfo[item.name]}
              >
                View document
              </a>
            </div>
          );
        }
      })}
      {/* <div className={styles.document_preview}>
        <Typography variant="h6">Certificate of incorporation</Typography>
        <Typography>
          {" "}
          {cutText(removeUrl(document_incorporation), 20)}
        </Typography>
        <a target="_blank" rel="noreferrer" href={document_incorporation}>
          View document
        </a>
      </div>{" "} */}
      {/* <div className={styles.document_preview}>
        <Typography variant="h6">
          Memorandum and articles of association
        </Typography>
        <Typography>{cutText(removeUrl(document_memorandum), 20)}</Typography>
        <a target="_blank" rel="noreferrer" href={document_memorandum}>
          View document
        </a>
      </div>{" "} */}
      {/* <div className={styles.document_preview}>
        <Typography variant="h6">Business registration</Typography>
        <Typography>{cutText(removeUrl(document_business), 20)}</Typography>
        <a target="_blank" rel="noreferrer" href={document_business}>
          View document
        </a>
      </div>{" "} */}
      {/* <div className={styles.document_preview}>
        <Typography variant="h6">Identity type</Typography>
        <Typography>{identity_document_type}</Typography>
      </div>{" "} */}
      {/* <div className={`${styles.last_item} ${styles.document_preview}`}>
        <Typography variant="h6">Identity upload</Typography>
        <Typography>{cutText(removeUrl(document_identity), 20)}</Typography>
        <a target="_blank" rel="noreferrer" href={document_identity}>
          View document
        </a>
      </div> */}
    </div>
  );
};

export default DocumentUploadPreview;
