import { useQuery } from "@tanstack/react-query";
import { GET_REQUIREMENTS } from "../api/queries/dashboard/balances";
import { GET_PROFILE } from "../api/queries/dashboard/settings/profile";


const useGetRequirements = (method, currency, amount) => {


  const {
    data: requirements,
    isLoading,
    refetch,
  } = useQuery(["requirements", currency.code, method.slug, amount], GET_REQUIREMENTS, {
    refetchOnWindowFocus: false,
  });

  return {
    requirements: requirements?.data,
    isLoading
  };
};

export default useGetRequirements;
