import { Typography } from "@mui/material";
import React from "react";
import styles from "./BalanceRow.module.css";
import threeDot from "../../../../../assets/images/threeDots.svg";
import swap from "../../../../../assets/images/swap2.svg";
import deposit from "../../../../../assets/images/deposit.svg";
import withdrawal from "../../../../../assets/images/withdrawal.svg";
import eye_icon from "../../../../../assets/images/eye.svg";
import export_icon from "../../../../../assets/images/export.svg";
import { Link } from "react-router-dom";
import usePermission from "../../../../../customHooks/usePermissions";
import { parseAmount } from "../../../../../utils/helpers";
import Tooltip from "../../../tooltip/Tooltip";

const BalanceRow = ({
  currencies,
  handleSetBalance,
  handleOpenExport,
  data,
  handleOpenSwap,
  handleOpenTooltip,
  openToolTip,
  handleSetCurrency,
}) => {
  const { checkPermission } = usePermission();
  return (
    <tr className={styles.row}>
      <td style={{ width: 200 }}>
        <div className={styles.td_div}>
          <div className={styles.td_div_img}>
            {currencies?.result?.map((currency) => {
              if (currency?.code === data?.currency_code) {
                return (
                  <img key={currency.id} src={currency.icon} alt={`flag`} />
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className={styles.td_div_text}>
            <Typography variant="h6">
              {currencies?.result?.map((currency) => {
                if (currency?.code === data?.currency_code) {
                  return currency.name;
                } else {
                  return null;
                }
              })}
            </Typography>
            <Typography> {data?.currency?.code}</Typography>
          </div>
        </div>
      </td>
      <td>
        <div className={styles.td_div3}>
          <Typography>
            <span>{data?.currency?.symbol}</span>{" "}
            {parseAmount(data?.balance, data?.type)}
            {/* {data?.balance?.toLocaleString(
              "en-US",
              data?.type === "CRYPTO"
                ? { minimumFractionDigits: 8 }
                : { minimumFractionDigits: 2 }
            )} */}
          </Typography>
        </div>
      </td>
      <td>
        <div className={styles.td_div3}>
          <Typography>
            <span>{data?.currency?.symbol}</span>{" "}
            {parseAmount(data?.liquidity_balance, data?.type)}
          </Typography>
        </div>
      </td>

      <td>
        <div className={styles.td_div4}>
          {checkPermission("balances.swap") && (
            <div
              className={styles.action}
              onClick={() => {
                handleSetCurrency(data?.currency_code);
                handleOpenSwap();
              }}
            >
              <div className={styles.action_icon}>
                <img src={swap} alt="swap" />
              </div>

              <Typography>swap</Typography>
            </div>
          )}
          {checkPermission("balances.deposit") && (
            <div
              className={styles.action}
              onClick={() => {
                handleSetCurrency(data?.currency, "deposit");
              }}
            >
              <div className={styles.action_icon}>
                <img src={deposit} alt="deposit" />
              </div>
              <Typography>deposit</Typography>
            </div>
          )}
          {checkPermission("balances.deposit") && (
            <div
              onClick={() => {
                handleSetCurrency(data?.currency, "withdrawal");
                handleSetBalance(data?.balance, data?.liquidity_balance);
              }}
              className={styles.action}
            >
              <div className={styles.action_icon}>
                <img src={withdrawal} alt="withdrawal" />
              </div>
              <Typography>withdrawal</Typography>
            </div>
          )}
          <Tooltip type='bal' data={data} handleOpenTooltip={handleOpenTooltip} openToolTip={openToolTip} />
        
        </div>
      </td>
    </tr>
  );
};

export default BalanceRow;
