import axios from "axios";

export const GET_ROLES = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}admin/role/merchant-roles`)
    .then((res) => {
      return res.data;
    });
};


export const GET_TEAMS = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}settings/team`)
    .then((res) => {
      return res.data;
    });
};