import { Skeleton, Typography } from "@mui/material";
import React from "react";
import styles from "./OverviewCards.module.css";
import group163 from "../../../../../../../assets/images/Group 163.svg";
import group164 from "../../../../../../../assets/images/Group 164.svg";
import { parseAmount } from "../../../../../../../utils/helpers";
import { GET_WALLETS_TRX_SUMMARY } from "../../../../../../../api/queries/dashboard/wallets";
import { useQuery } from "@tanstack/react-query";

function OverviewCards({params}) {

  // const { data: walletsTrxVol, isLoading: loadingVolume } = useQuery(
  //   ["wallets-trx-summary", params.id, "", "sum", 'deposit'],
  //   GET_WALLETS_TRX_SUMMARY,
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const { data: walletsDptValue, isLoading: loadingAmountDeposited } = useQuery(
    ["wallets-trx-summary", params.id, "amount", "sum", 'deposit'],
    GET_WALLETS_TRX_SUMMARY,
    {
      refetchOnWindowFocus: false,
    }
  );



  const { data: walletsWthValue, isLoading: loadingAmountWithdrawn } = useQuery(
    ["wallets-trx-summary", params.id, "amount", "sum", 'withdrawal'],
    GET_WALLETS_TRX_SUMMARY,
    {
      refetchOnWindowFocus: false,
    }
  );


  return (
    <div className={styles.overview_cards}>
      {/* <div className={`${styles.card} ${styles.blue_card}`}>
        <img className={styles.group163} src={group163} alt="" />
        <img className={styles.group164} src={group164} alt="" />
        <Typography variant="h6">Transaction Value</Typography>
        {loadingValue ? (
          <Skeleton
            sx={{ bgcolor: "rgb(187, 183, 183)" }}
            width={"150px"}
            height={"40px"}
          />
        ) : (
          <Typography>
            {value?.data?.currency?.symbol}{" "}
            {parseAmount(value?.data?.result, value?.data?.currency?.type)}
          </Typography>
        )}
      </div> */}
      <div className={`${styles.card} ${styles.white_card}`}>
        <Typography variant="h6">Total Volume Transacted</Typography>
        {loadingAmountDeposited || loadingAmountWithdrawn ? (
          <Skeleton width={"150px"} height={"40px"} />
        ) : (
          <Typography>{ walletsDptValue?.data?.currency?.symbol} {parseAmount(walletsDptValue?.data?.result + walletsWthValue?.data?.result, walletsDptValue?.data?.currency?.type)}</Typography>
        )}
      </div>
      <div className={`${styles.card} ${styles.white_card}`}>
        <Typography variant="h6">Total Amount Deposited</Typography>
        {loadingAmountDeposited ? (
          <Skeleton width={"150px"} height={"40px"} />
        ) : (
          <Typography>{ walletsDptValue?.data?.currency?.symbol} {parseAmount(walletsDptValue?.data?.result,  walletsDptValue?.data?.currency?.type)}</Typography>
        )}
      </div>
      <div className={`${styles.card} ${styles.white_card}`}>
        <Typography variant="h6">Total Amount Withdrawn</Typography>
        {loadingAmountWithdrawn ? (
          <Skeleton width={"150px"} height={"40px"} />
        ) : (
          <Typography>{ walletsWthValue?.data?.currency?.symbol} {parseAmount(walletsWthValue?.data?.result,  walletsWthValue?.data?.currency?.type)}</Typography>
        )}
      </div>
    </div>
  );
}

export default OverviewCards;
