import { useState } from "react";

const useSortDocumentUploadDataPayload = (directorsCount, shareHoldersCount) => {
  const [payload, setPayload] = useState({});

  const handleSort = (docUploadsData) => {
   
   

    const directorsArray = Array.from({
      length: directorsCount,
    }).map((e, index) => {
      if (docUploadsData[`id_directors_${index}`]) {
        if (typeof docUploadsData[`id_directors_${index}`] === "object") {
          return docUploadsData[`id_directors_${index}`][0];
        } else {
          return docUploadsData[`id_directors_${index}`];
        }
      }
    });

    const shareholdersArray = Array.from({
      length: shareHoldersCount,
    }).map((e, index) => {
      if (docUploadsData[`id_shareholders_${index}`]) {
        if (typeof docUploadsData[`id_shareholders_${index}`] === "object") {
          return docUploadsData[`id_shareholders_${index}`][0];
        } else {
          return docUploadsData[`id_shareholders_${index}`];
        }
      }
    });

  


    const payload = {
      document_country_issuance: docUploadsData.country,
      // document_identity: docUploadsData.document_identity,
      document_incorporation: docUploadsData.document_incorporation,
      document_memorandum: docUploadsData.document_memorandum,
      letter_of_authorization: docUploadsData.letter_of_authorization,
      proof_of_address: docUploadsData.proof_of_address,
      register_of_directors: docUploadsData.register_of_directors,
      register_of_members: docUploadsData.register_of_members,
      document_aml: docUploadsData.document_aml,
      id_directors: directorsArray,
      id_shareholders: shareholdersArray,
    };
    
  

    setPayload(payload);

  };

  return {
    handleSort,
    payload,
  };
};

export default useSortDocumentUploadDataPayload;
