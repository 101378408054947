import React from "react";
import styles from "./Tooltip.module.css";
import eye_icon from "../../../assets/images/eye.svg";
import refund_icon from "../../../assets/images/refund_arrow.svg";
import threeDot from "../../../assets/images/threeDots.svg";
import { Link } from "react-router-dom";
import chevron_up from "../../../assets/images/chevron-up.png";
import { CircularProgress, Typography } from "@mui/material";

const Tooltip = ({
  loading,
  handleOpenTooltip,
  openToolTip,
  data,
  type,
  handleRequestRefund,
  refundSuccessfull,
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleOpenTooltip(data.id);
      }}
      className={styles.three_dot}
    >
      <img src={threeDot} alt="" />
      {openToolTip?.id === data?.id ? (
        <div className={styles.tooltip}>
          {type === "trx" && (
            <>
              <div className={styles.tooltip_item}>
                <div className={styles.tooltip_item_img}>
                  <img src={eye_icon} alt="eye" />
                </div>
                <Link to={`/transaction/${data.id}`}>View Transaction</Link>
              </div>
              {refundSuccessfull ? (
                <div className={styles.tooltip_item}>
                  <div className={styles.tooltip_item_img}>
                    <img src={refund_icon} alt="eye" />
                  </div>
                  <Typography style={{ marginRight: 10, color: '#979797' }}>
                    Refund Requested
                  </Typography>
                  {loading && <CircularProgress size={15} />}
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRequestRefund();
                  }}
                  className={styles.tooltip_item}
                >
                  <div className={styles.tooltip_item_img}>
                    <img src={refund_icon} alt="eye" />
                  </div>
                  <Typography style={{ marginRight: 10 }}>
                    Request Refund
                  </Typography>
                  {loading && <CircularProgress size={15} />}
                </div>
              )}

              {/* <div
                  className={` ${styles.tooltip_item_last} ${styles.tooltip_item}`}
                >
                  <div className={styles.tooltip_item_img}>
                    <img src={export_icon} alt="export" />
                  </div>

                  <Typography onClick={handleOpenExport}>
                    export data{" "}
                  </Typography>
                </div> */}
            </>
          )}
          {type === "bal" && (
            <div className={styles.tooltip_item}>
              <div className={styles.tooltip_item_img}>
                <img src={eye_icon} alt="eye" />
              </div>
              <Link to="/transactions/swap">Transaction history</Link>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Tooltip;
