// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddMore_add_new_member__yjuDS {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    cursor: pointer;\n  }\n  \n  .AddMore_add_new_member__yjuDS span {\n    flex: 1 1;\n    height: 1px;\n    background-color: #EBEBEB;\n  \n  }\n  \n  .AddMore_add_new_member_main__fzG6Z {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n  }\n  .AddMore_add_new_member_main__fzG6Z p{\n    color: #1A2CCE;\n    font-size: 13px;\n    font-weight: 400;\n  }", "",{"version":3,"sources":["webpack://./src/components/UI-components/addMore/AddMore.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;EACjB;;EAEA;IACE,SAAO;IACP,WAAW;IACX,yBAAyB;;EAE3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;EACA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".add_new_member {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    cursor: pointer;\n  }\n  \n  .add_new_member span {\n    flex: 1;\n    height: 1px;\n    background-color: #EBEBEB;\n  \n  }\n  \n  .add_new_member_main {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n  }\n  .add_new_member_main p{\n    color: #1A2CCE;\n    font-size: 13px;\n    font-weight: 400;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add_new_member": "AddMore_add_new_member__yjuDS",
	"add_new_member_main": "AddMore_add_new_member_main__fzG6Z"
};
export default ___CSS_LOADER_EXPORT___;
