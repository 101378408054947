import { ButtonStyle, Loader } from "./styles";
import { LoaderIcon } from "../../../assets";

const Button = (props) => {
  return (
    <ButtonStyle
      onClick={props.handleAction}
      type={"button"}
      disabled={props.loading || props.disabled}
      {...props}
    >
      {props.icon && <img style={{marginRight: 10}} src={props.icon} alt="" />}
      {props.loading ? (
        <Loader>
          <LoaderIcon color={"#1A2CCE"} width="30px" height="30px" />
        </Loader>
      ) : null}
      {props.text}
    </ButtonStyle>
  );
};
export default Button;
