import * as yup from "yup";
import React, { useContext } from "react";
import { Flex } from "../../../UI-components/box";
import Button from "../../../UI-components/button";
import { FormContainer } from "./styles";
import { Form, Field, ErrorMessage, Formik } from "formik";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../UI-components/input/styles";
import { DataCentral } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { LOGIN_2FA } from "../../../../api/mutations/auth";
import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";

const Fields = () => {
  const navigate = useNavigate();
  const {
    openSnackbar,
    message,
    severity,
    duration,
    setOpenToast,
    openToast,
  } = useOpenSnackbar();
  const { handleSignIn } = useContext(DataCentral);
  const initialValues = {
    twoFa: "",
  };

  const { mutate, isLoading } = useMutation(LOGIN_2FA, {
    onSuccess: (response) => {
      const { access_token } = response.data;
      handleSignIn(access_token);
      localStorage.removeItem('Access-token-2fa')
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });

  const handleLogin = async (twoFa, setSubmitting) => {
    mutate({
      twoFactorAuthenticationCode: twoFa,
    });
  
  };

  const validateSchema = yup.object().shape({
    twoFa: yup.string().required("Provide your 2fa code"),
  });

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={({ twoFa }, { setSubmitting, resetForm }) => {
          handleLogin(twoFa, setSubmitting);
        }}
        validationSchema={validateSchema}
      >
        {({
          isSubmitting,
          handleChange,
          values: { twoFa },
          dirty,
          handleBlur,
        }) => (
          <Form>
            <Flex flexDir={"column"} gap={"30px"}>
              <InputStyle>
                <Label htmlFor="fullName"> 2FA login code </Label>{" "}
                <FieldWrapper>
                  <Field
                    type={"text"}
                    onChange={handleChange}
                    name={"twoFa"}
                    value={twoFa}
                    onBlur={handleBlur}
                  />{" "}
                </FieldWrapper>{" "}
                <ErrorMessage name={"twoFa"} component={"div"} />{" "}
              </InputStyle>{" "}
              <Button
                text={"Login to account"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="md"
                fullwidth
                type="submit"
                borderRadius={"5px"}
                // disable={!dirty || isSubmitting}
                loading={isLoading}
              />{" "}
            </Flex>
          </Form>
        )}
      </Formik>
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </FormContainer>
  );
};

export default Fields;
