import { Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./Accordion.module.css";
import arrow_down from "../../../assets/images/arrow-down.svg";

const Accordion = ({ accordionTitle, accordionContent }) => {
  const [open, setOpen] = useState(false);

  const handleToggleAccordion = () => {
    setOpen(!open);
  };

  return (
    <div
      className={` ${open ? styles.accordion_active : null} ${
        styles.accordion
      }`}
    >
      <div
        onClick={(e) => {
          handleToggleAccordion();
        }}
        className={styles.accordion_header}
      >
        <Typography>{accordionTitle}</Typography>
        <img className={`${open ? styles.img_active: null}`} src={arrow_down} alt="" />
      </div>
      <div
        className={`${open ? styles.accordion_content_active : null}  ${
          styles.accordion_content
        }`}
      >
        {accordionContent}
      </div>
    </div>
  );
};

export default Accordion;
