import { Styles } from "./styles";

import Header from "../header";
import Drawer from "../drawer";
import { Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { handlePatchRequestCalls } from "../../../axiosConfig/apiCalls";
import useOpenSnackbar from "../../../customHooks/useOpenSnackbar";
import { DataCentral } from "../../../context/AppContext";
import SwapstaSnackbar from "../AppSnackbars/SwapstaSnackbar";
import AppBackdrop from "../appBackdrop/AppBackdrop";
import ModalFrame2 from "../modalFrame2/ModalFrame2";
import ConfirmationPrompt from "../prompt/confirmationPrompt/ConfirmationPrompt";
import time_icon from "../../../assets/images/time_icon.svg";
import BoopButton from "../../../customHooks/Sound";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TOGGLE_MODE } from "../../../api/mutations/dashboard/settings/api";
import LayoutModeAlert from "../modeAlerts/LayoutModeAlert";

const Layout = ({ children, title, bgColor }) => {
  const { user, loadingUser, countdownFrom, handleLogout, showAlert, setShowAlert, isRefetchingUser } =
    useContext(DataCentral);
  const soundref = useRef();

  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const [showIdlePrompt, setShowIdlePrompt] = useState(false);
  const [loadingRefreshSessions, setLoadingRefreshSessions] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(TOGGLE_MODE, {
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0];
        },
      });
      openSnackbar('success', response?.message)
   
    },
    onError: (response) => {
      openSnackbar("error", response.response.data.message);
    },
  });

  useEffect(() => {
    if (countdownFrom < 1) {
      handleLogout();
      //openSnackbar('info', 'you were logged out because you were idle')
    } else if (countdownFrom < 60) {
      if (countdownFrom < 10) {
        soundref.current.click();
      }
      setShowIdlePrompt(true);
    }
  }, [countdownFrom]);



  useEffect(() => {
    if (showIdlePrompt) {
      soundref.current.click();
    }
  }, [showIdlePrompt]);

  const handleSwitchChange = async (switched) => {
    let timer;

    if (user.merchant.kyc !== "verified") {
      openSnackbar("error", "Merchant must to be verified first");
    } else {
      clearTimeout(timer);

      mutate({
        mode: !switched ? "live" : "test",
      });

   
    }
  };

  const handleDismiss = () => {
    setShowAlert(false);
  };

  const handleRefreshSession = async () => {
    setLoadingRefreshSessions(true);
    const response = await handlePatchRequestCalls("sessions");
    if (response.status < 300 && response.status >= 200) {
      setLoadingRefreshSessions(false);
      setShowIdlePrompt(false);
    } else if (response.response) {
    } else if (response.request) {
    } else {
    }
  };

  return (
    <div>
      <Header title={title} />
      <Drawer
        switching={isLoading}
        mode={user?.mode}
        handleSwitchChange={handleSwitchChange}
        loading={loadingUser}
        isRefetchingUser={isRefetchingUser}
      />
      <Styles className="App" bgColor={bgColor}>
        <section className="layout">
          {showAlert && (
           <LayoutModeAlert user={user} handleDismiss={handleDismiss} />
          )}
          {children}
        </section>
      </Styles>
      {showIdlePrompt && (
        <AppBackdrop
          openModal={showIdlePrompt}
          handleCloseModal={() => {
            //toggle("confirmation-prompt");
          }}
          child={
            <ModalFrame2
              btnColor={"#1A2CCE"}
              btnLoading={loadingRefreshSessions}
              handleActionTwo={() => {
                handleLogout();
                //toggle("confirmation-prompt");
              }}
              handleAction={() => {
                handleRefreshSession();
              }}
              btnTxt={"Stay connected"}
              btnText2={"Log out"}
              child={
                <ConfirmationPrompt
                  src={time_icon}
                  header="You have been idle!"
                  text={`You will be logged out in ${countdownFrom} seconds.`}
                />
              }
            />
          }
        />
      )}
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
      <BoopButton soundref={soundref} />
    </div>
  );
};

export default Layout;
