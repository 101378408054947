// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VerifyEmail_verify_email_action__gyJMz {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n\n.VerifyEmail_verify_email_action__gyJMz span {\n    height: 100px !important;\n    width: 100px !important;\n}\n.VerifyEmail_verify_email_text__9EkMJ{\n    display: flex;\n    align-items: center;\n}\n.VerifyEmail_verify_email_text__9EkMJ p{\n    text-align: center;\n}\n\n.VerifyEmail_verify_email_text__9EkMJ a{\n    text-decoration: underline;\n    color: blue;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/auth/verify/verify-email-action/VerifyEmail.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,WAAW;AACf","sourcesContent":[".verify_email_action {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n\n.verify_email_action span {\n    height: 100px !important;\n    width: 100px !important;\n}\n.verify_email_text{\n    display: flex;\n    align-items: center;\n}\n.verify_email_text p{\n    text-align: center;\n}\n\n.verify_email_text a{\n    text-decoration: underline;\n    color: blue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verify_email_action": "VerifyEmail_verify_email_action__gyJMz",
	"verify_email_text": "VerifyEmail_verify_email_text__9EkMJ"
};
export default ___CSS_LOADER_EXPORT___;
