// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ValidationMessage_validation_message__I789m {\n    margin-top: 6px;\n}\n\n.ValidationMessage_validation_message__I789m p{\n    font-size: 12px !important;\n    color: #D80027 !important;\n   \n}", "",{"version":3,"sources":["webpack://./src/components/UI-components/validation-message/ValidationMessage.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;;AAE7B","sourcesContent":[".validation_message {\n    margin-top: 6px;\n}\n\n.validation_message p{\n    font-size: 12px !important;\n    color: #D80027 !important;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validation_message": "ValidationMessage_validation_message__I789m"
};
export default ___CSS_LOADER_EXPORT___;
