import { Typography } from "@mui/material";
import React from "react";
import { BankIcon, ProfileIcon, UserIcon, WalletIcon } from "../../../assets";
import styles from "./BankAccountDetails.module.css";
import bank_icon from "../../../assets/images/bank-icon.svg";

function BankAccountDetails({
  requirement,
  copied,
  handleCopy,
  lastItem,
  firstItem,
}) {
  return (
    <div
      className={`${styles.bank_account_detail} ${
        lastItem ? styles.last_item : null
      } ${firstItem ? styles.first_item : null}`}
    >
      <div className={styles.bank_account_detail_left}>
        {requirement.name === "bank" && <img src={bank_icon} alt="" />}
        {requirement.name === "account_name" && <UserIcon />}
        {requirement.name === "account_number" && <WalletIcon />}

        <div className={styles.text}>
          <Typography variant="h6">{requirement.label}</Typography>
          <Typography>{requirement.value || "NA"}</Typography>
        </div>
      </div>
      <div className={styles.bank_account_detail_right}>
        <button
          className={styles.copy_btn}
          onClick={() => {
            handleCopy(requirement.value, requirement.label);
          }}
        >
          {copied.some((item) => item.id === requirement.label)
            ? "copied"
            : "copy"}
        </button>
      </div>
    </div>
  );
}

export default BankAccountDetails;
