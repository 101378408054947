import { Typography } from "@mui/material";
import React, { useState } from "react";
import LabeledInputs from "../../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import LabeledSelectTwo from "../../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import UploadBox from "../../../../../../../UI-components/uploadBox/UploadBox";
import ValidationMessage from "../../../../../../../UI-components/validation-message/ValidationMessage";
import styles from "./StepTwoForm.module.css";
import id_icon from "../../../../../../../../assets/images/id_no.svg";
import link_icon from "../../../../../../../../assets/images/link.svg";
import upload_icon from "../../../../../../../../assets/images/upload2.svg";
import add_icon from "../../../../../../../../assets/images/add_icon.svg";
import remove_icon from "../../../../../../../../assets/images/remove_icon.svg";

const documentType = [
  { value: "INTERNATIONAL_PASSPORT", label: "International Passport", id: 1 },
  { value: "DRIVERS_LICENSE", label: "Driver's License", id: 2 },
  { value: "RESIDENCE_PERMIT_CARD", label: "Residence Permit/Card", id: 3 },
  { value: "NATION_ID_CARD", label: "National ID Card", id: 4 },
];

function StepTwoForm({
  trigger,
  index,
  errors,
  register,
  setValue,
  setFormCount,
  formCount,
  setAddition,
}) {
  const [activeSource, setActiveSource] = useState("upload");

  return (
    <div>
      <div className={styles.field}>
        <LabeledSelectTwo
          placeholder="Choose ID type"
          redOutline={errors[`verification_id_${index}`] ? true : false}
          label="Verification type "
          required={true}
          name={`verification_id_${index}`}
          register={register}
          data={documentType}
        />
        <ValidationMessage
          message={errors[`verification_id_${index}`]?.message}
        />
      </div>
      <div className={styles.field}>
        <LabeledInputs
          redOutline={errors[`id_number_${index}`] ? true : false}
          label="ID number"
          name={`id_number_${index}`}
          register={register}
          placeholder="Enter ID number"
        />
        <ValidationMessage message={errors[`id_number_${index}`]?.message} />
      </div>
      <div className={styles.field}>
        <div className={styles.source_input}>
          <div className={styles.heading}>
            <Typography>Source</Typography>
          </div>

          <div className={styles.source_tabs}>
            {/* <div
              onClick={() => {
                setValue(`source_${index}`, "id_no");
                setActiveSource("id_no");
              }}
              className={`${styles.source_tab} ${
                activeSource === "id_no" && styles.source_tab_active
              } `}
            >
              <img src={id_icon} alt="" />
              <Typography>ID number</Typography>
            </div> */}
            <div
              onClick={() => {
                setValue(`source_${index}`, "upload");
                setActiveSource("upload");
              }}
              className={`${styles.source_tab} ${
                activeSource === "upload" && styles.source_tab_active
              } `}
            >
              <img src={upload_icon} alt="" />
              <Typography>Upload ID</Typography>
            </div>
            <div
              onClick={() => {
                setValue(`source_${index}`, "link");
                setActiveSource("link");
              }}
              style={{ marginRight: 0 }}
              className={`${styles.source_tab} ${
                activeSource === "link" && styles.source_tab_active
              } `}
            >
              <img src={link_icon} alt="" />
              <Typography>Provide link</Typography>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.field}>
        {activeSource === "id_no" && <></>}
        {activeSource === "upload" && (
          <>
            <UploadBox
              trigger={trigger}
              index={index}
              setValue={setValue}
              name={`document_${index}`}
              register={register}
              errors={errors}
              label="Upload ID"
            />
          </>
        )}
        {activeSource === "link" && (
          <>
            <LabeledInputs
              redOutline={errors[`document_link_${index}`] ? true : false}
              label="Document link"
              name={`document_link_${index}`}
              register={register}
              placeholder="https://"
            />
            <ValidationMessage
              message={errors[`document_link_${index}`]?.message}
            />
          </>
        )}
      </div>

      {/* {index + 1 === formCount ? (
        <div className={styles.field}>
          <div
            onClick={() => {
              setAddition(true)
              setFormCount(formCount + 1);
            }}
            className={styles.add_more}
          >
            <span></span>
            <div>
              <img src={add_icon} alt="" />
              <Typography>Add more</Typography>
            </div>

            <span></span>
          </div>
        </div>
      ) : (
        <div className={styles.field}>
          <div
            onClick={() => {
              setAddition(false)
              setFormCount(formCount - 1);
            }}
            className={`${styles.add_more} ${styles.remove}`}
          >
            <span></span>
            <div>
              <img src={remove_icon} alt="" />
              <Typography>Remove extra</Typography>
            </div>

            <span></span>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default StepTwoForm;
