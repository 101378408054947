// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.Disclaimer_disclaimer__lfn0P{\n    background: #FFFAEB;\nborder-radius: 8px;\npadding: 10px 16px;\n}\n.Disclaimer_disclaimer__lfn0P p{\n    color: #93370D;\n    font-size: 14px;\n    font-weight: 400;\n}", "",{"version":3,"sources":["webpack://./src/components/UI-components/disclaimer/Disclaimer.module.css"],"names":[],"mappings":";;AAEA;IACI,mBAAmB;AACvB,kBAAkB;AAClB,kBAAkB;AAClB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n\n.disclaimer{\n    background: #FFFAEB;\nborder-radius: 8px;\npadding: 10px 16px;\n}\n.disclaimer p{\n    color: #93370D;\n    font-size: 14px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": "Disclaimer_disclaimer__lfn0P"
};
export default ___CSS_LOADER_EXPORT___;
