import styled from "styled-components";
import { Container } from "../../../../UI-components/box";

export const VerifyEmailContainer = styled(Container)`
  min-height: 100vh;
  width: 100%;
  padding-top: 15vh;
  background-color: #f3f6fb;
  @media screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .flex_container {
    @media screen and (max-width: 600px) {
      width: 95%;
    }
  }
  .verify_mail_title {
    color: #000000;
    padding-bottom: 40px;
    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  .verify_mail_text {
    color: #414141;
    text-align: center;
    padding: 5px;
    font-size: 16px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;
