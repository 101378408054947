import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import styles from "./BinancePayForm.module.css";
import binance_logo from "../../../../../assets/images/binance-logo.svg";
import BinancePayForm1 from "./BinancePayForm1";
import BinancePayForm2 from "./BinancePayForm2";
import { useForm } from "react-hook-form";
import useModal from "../../../../../customHooks/useModal";
import AppBackdrop from "../../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrame from "../../../../UI-components/modalFrame/ModalFrame";
import WhatIsBinancePay from "./modal/whatIsBinancePay/WhatIsBinancePay";
import useGetCountries from "../../../../../customHooks/useGetCountries";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GET_MCC } from "../../../../../api/queries/dashboard/balances";
import { CREATE_MERCHANT } from "../../../../../api/mutations/dashboard/balance";
import { yupResolver } from "@hookform/resolvers/yup";
import { BinancePaySchemaStep2 } from "../../../../../schemas/dashboard/binancePay";

function BinancePayForm() {
  const navigate = useNavigate();
  const [formLevel, setFormLevel] = useState(1);

  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const { toggle, isShowingPrompt } = useModal();
  const { isLoadingCountries, countries } = useGetCountries();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BinancePaySchemaStep2),
  });

  const [merchantDataFormOne, setMerchantData] = useState({});
  const website_type = watch('website_type', '')
  const { data: mcc, isLoading } = useQuery(["mcc"], GET_MCC, {
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading: isLoadingCreateMerchant } = useMutation(
    CREATE_MERCHANT,
    {
      onSuccess: (response) => {
        openSnackbar("success", response?.message);
        navigate("/binance-pay-successful");
      },
      onError: (error) => {
        openSnackbar("error", error?.response?.data?.message);
      },
    }
  );

  useEffect(()=>{
    trigger('site_name')
    trigger('website_url')
  }, [website_type])

 
  const onSubmit = async (data) => {
 
    mutate({
      merchantName: merchantDataFormOne.business_name,
      merchantType: merchantDataFormOne.business_type,
      merchantMcc: merchantDataFormOne.business_category,
      brandLogo: merchantDataFormOne.business_logo,
      country: merchantDataFormOne.country,
      address: data?.registration_address,
      companyName: merchantDataFormOne.business_name,
      registrationNumber: data?.registration_number,
      registrationCountry: data?.registration_country,
      registrationAddress: data?.registration_address,
      incorporationDate: data?.incorporation_date,
      storeType: data?.store_type,
      siteType: data?.website_type,
      siteUrl: data?.website_url,
      siteName: data?.site_name,
    });
  };

  return (
    <div className={styles.binance_pay_form}>
      <Container maxWidth="sm">
        <div className={styles.binance_pay_form_content}>
          <div className={styles.heading}>
            <div className={styles.logo}>
              <img src={binance_logo} alt="binance logo" />
            </div>
            <div className={styles.heading_text}>
              <Typography variant="h5">
                Create a Binance pay merchant account
              </Typography>
              <Typography variant="h6">
                Fill this form to enable your Binance Pay accout
              </Typography>
              <Typography
                onClick={() => {
                  toggle("confirmation-prompt");
                }}
              >
                What is Binance pay?
              </Typography>
            </div>
          </div>
          <div className={styles.body}>
            {formLevel === 1 && (
              <BinancePayForm1
                merchantData={merchantDataFormOne}
                setMerchantData={setMerchantData}
                mcc={mcc?.data}
                loadingMcc={isLoading}
                isLoadingCountries={isLoadingCountries}
                setFormLevel={setFormLevel}
                countries={countries}
              />
            )}
            {formLevel === 2 && (
              <BinancePayForm2
                watch={watch}
                isCreating={isLoadingCreateMerchant}
                handleSubmit={handleSubmit(onSubmit)}
                mcc={mcc?.data}
                register={register}
                errors={errors}
                loadingMcc={isLoading}
                setFormLevel={setFormLevel}
                countries={countries}
              />
            )}
          </div>
        </div>
      </Container>
      {isShowingPrompt && (
        <AppBackdrop
          openModal={isShowingPrompt}
          child={
            <ModalFrame
              style={{
                height: "auto",
                width: "445px",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
              handleAction={() => {
                toggle("confirmation-prompt");
              }}
              child={
                <WhatIsBinancePay
                  handleClose={() => {
                    toggle("confirmation-prompt");
                  }}
                />
              }
            />
          }
        />
      )}
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </div>
  );
}

export default BinancePayForm;
