import styled from 'styled-components';
import { Flex } from '../../../../UI-components/box';

export const Styles = styled(Flex)`
	justify-content: space-between;
	padding: 2.5rem 3.25rem;
	margin-top: 10px;
	
	background-color: #f8f9fd;
	// padding-left: 3rem;
	// padding-right: 1.5em;
	.container {
		width: 50%;
		border-bottom: none;
	}

	hr {
	
		margin-bottom: 25px;
		margin-top: 35px;
		width: 100%;
	}

	.save {
		margin-top: 40px;
	}

	.invite-team {
		Button {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			padding: 0;
			text-decoration-line: underline;
			color: #1a2cce;
		}
		h2 {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 23px;

			color: #000000;
		}
		padding-bottom: 2.188rem;

		p {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #3e3e3e;
		}
	}

	.transaction {
		h4 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			/* identical to box height */

			color: #000000;
		}
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;

			color: #6f6f6f;
		}

		.checkbox_field {
			display: flex;
			align-items: center;
			margin-right: 90px;
			margin-top: 30px;
			p {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;

				color: #000000;
			}
			input {
				// height: 100%;
				// width: 100%;
				margin-right: 10px;

				background: #ffffff;
				border: 0.617647px solid #d8d8d8;
				box-sizing: border-box;
				box-shadow: 0px 0px 3.08824px rgba(0, 0, 0, 0.07);
				border-radius: 3.08824px;

				:checked {
					background: #1a2cce !important;
					border: 0.617647px solid #1a2cce !important;
					box-sizing: border-box;
					box-shadow: 0px 0px 3.08824px rgba(0, 0, 0, 0.07);
					border-radius: 3.08824px;
				}
			}
		}
	}

	.login {
		border-bottom: none !important;
		h4 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;
			color: #000000;
		}
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;

			color: #6f6f6f;

			:last-child {
				font-size: 12px;
				color: #000000;
			}
		}

		Button {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			padding: 15px 25px;
		}
	}

	@media (max-width: 1289px) {
		.container {
			width: 100%;
		}
		padding: 20px 4%;
	}
`;
