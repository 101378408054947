import "./index.css";
import Router from "./router";
import React from "react";
import ReactDOM from "react-dom";
import AppContext from "./context/AppContext";
import AxiosContext from "./context/AxiosContext";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

if (JSON.parse(process.env.REACT_APP_CLEAN_CONSOLE)) {
  console.log = console.warn = console.error = () => {};
} else {
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppContext>
        <AxiosContext>
          <Router />
        </AxiosContext>
      </AppContext>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
