import React, { useState } from "react";
import Layout from "../../../UI-components/layouts";
import { Styles } from "./styles";
import styles from "./Wallets.module.css";
// import statistics_icon from "../../../../../assets/images/statistics.svg";
// import upload_icon from "../../../../../assets/images/Upload.svg";
// import download_icon from "../../../../../assets/images/Download.svg";
// import LabeledSelectTwo from "../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import Search from "../../../UI-components/search";
import { Typography } from "@mui/material";
import Button from "../../../UI-components/button";
import { useForm } from "react-hook-form";
import add_icon from "../../../../assets/images/add-icon.svg";
import TableOne from "../../../UI-components/new -tables/Table";
import useModal from "../../../../customHooks/useModal";
import AppBackdrop from "../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrame from "../../../UI-components/modalFrame/ModalFrame";
import NewWallet from "./components/newWallet/NewWallet";
import { useQuery } from "@tanstack/react-query";
import { GET_WALLETS } from "../../../../api/queries/dashboard/wallets";
import Pagination from "../../../UI-components/pagination/Pagination";
import Loader from "../../../UI-components/loader/Loader";
import BottomFilter from "./components/bottomFilter/BottomFilter";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import usePermission from "../../../../customHooks/usePermissions";
const tableHead = ["CREATION DATE", "WALLET NAME", "REFERENCE ID", "COMPLIANCE STATUS", ""];

const modalFrameStyle = {
  height: "auto",
  width: 470,
};

function Wallets() {
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const [openToolTip, setOpenToolTip] = useState({});
  const { toggle, isShowingAddWallet, isShowingPrompt } = useModal();
  const [wallet, setWallet] = useState({});
  const [walletCheckbox, setWalletCheckbox] = useState([]);
  const { checkPermission } = usePermission();
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      currency: "",
    },
  });

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);

  const {
    data: wallets,
    isLoading,
    refetch,
  } = useQuery(["wallets", searchValue, page], GET_WALLETS, {
    refetchOnWindowFocus: false,
  });
  // const { data: walletsVolume, isLoading: loadingVol } = useQuery(
  //   ["wallet-summary", "amount", "count", "deposit"],
  //   GET_WALLETS_SUMMARY,
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  // const { data: walletsDptSummary, isLoading: loadingSummaryDpt } = useQuery(
  //   ["wallet-summary", "amount", "sum", "deposit"],
  //   GET_WALLETS_SUMMARY,
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  // const { data: walletsWthSummary, isLoading: loadingSummaryWth } = useQuery(
  //   ["wallet-summary", "amount", "sum", "withdrawal"],
  //   GET_WALLETS_SUMMARY,
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const onSubmit = () => {};

  const handleTooltipClose = () => {
    setOpenToolTip({});
  };

  const handleOpenTooltip = (id) => {
    if (openToolTip.open) {
      setOpenToolTip({});
    } else {
      setOpenToolTip({ open: true, id });
    }
  };

  const handleDeleteWallet = (wallet) => {
    setWallet(wallet);
    toggle("confirmation-prompt");
  };

  const handleConfirmWalletDelete = () => {};

  const handleSelectAll = (value) => {
    if (value) {
      setWalletCheckbox(
        wallets?.data?.data?.map((item) => {
          return item.wallet_reference;
        })
      );
    } else {
      setWalletCheckbox([]);
    }
  };

  const handleSelectSingleCheckbox = (e, id) => {
    const { checked } = e.target;

    if (checked) {
      setWalletCheckbox([...walletCheckbox, id]);
    } else {
      const remWalletCheckbox = walletCheckbox.filter((item) => {
        return item !== id;
      });
      setWalletCheckbox(remWalletCheckbox);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  return (
    <Layout title={"Wallets"} bgColor={"#ffffff"}>
      <Styles>
        {/* <div className={styles.overview_tabs}>
          <WalletsTab
            value={walletsVolume?.data?.result}
            currencyValue={walletsVolume?.data?.currency?.symbol}
            title={"Total Volume Transacted"}
            loading={loadingVol}
          />
          <WalletsTab
            value={walletsDptSummary?.data?.result}
            currencyValue={walletsDptSummary?.data?.currency?.symbol}
            title={"Total Deposit"}
            loading={loadingSummaryDpt}
          />
          <WalletsTab
            value={walletsWthSummary?.data?.result}
            currencyValue={walletsWthSummary?.data?.currency?.symbol}
            title={"Total Withdrawal"}
            loading={loadingSummaryWth}
          />
        </div> */}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.filter}>
              <div className={styles.filter_fields}>
                {/* <div className={styles.field}>
                  <LabeledSelectTwo
                    name="currency"
                    placeholder="Select currency"
                    label="Currency"
                    register={register}
                    data={currencies?.result
                      ?.filter((currency) => {
                        return currency.type === "CRYPTO";
                      })
                      ?.map((currency) => {
                        return {
                          label: currency.code,
                          value: currency.code,
                        };
                      })}
                  />
                </div> */}

                <div className={styles.search_field}>
                  <Typography>Search for a wallet</Typography>

                  <Search
                    type={"filter"}
                    handleFilter={(name, value) => {
                      setSearchValue(value.search);
                    }}
                    name={"search"}
                    placeholder={"Search reference no."}
                  />
                </div>
              </div>

              {checkPermission("wallets.new") && (
                <div className={styles.filter_btn}>
                  <Button
                    Styles
                    text={"New wallets"}
                    bgColor={"#1A2CCE"}
                    color={"#FFFFFF"}
                    size="sm"
                    type="submit"
                    border={"0.4px solid #1A2CCE"}
                    boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
                    borderRadius={"5px"}
                    icon={add_icon}
                    onClick={() => {
                      toggle("new-wallets");
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.table}>
              <TableOne
                openSnackbar={openSnackbar}
                handleRefresh={refetch}
                handleSelectSingleCheckbox={handleSelectSingleCheckbox}
                walletCheckbox={walletCheckbox}
                handleDeleteWallet={(wallet) => {
                  handleDeleteWallet(wallet);
                }}
                type={"wallets"}
                handleSelectAll={handleSelectAll}
                openToolTip={openToolTip}
                handleOpenTooltip={handleOpenTooltip}
                tableBody={wallets?.data?.data}
                tableHeadData={tableHead}
              />
            </div>

            {wallets?.data?.data?.length > 0 ? (
              <>
                {/* <BottomFilter
                  walletCheckbox={walletCheckbox}
                  onSubmit={onSubmit}
                  handleSubmit={handleSubmit}
                  handleSelectAll={handleSelectAll}
                /> */}
                <Pagination
                  handlePageChange={handlePageChange}
                  meta={wallets?.data?.meta}
                />
              </>
            ) : null}
          </>
        )}
      </Styles>
      {isShowingAddWallet && (
        <AppBackdrop
          handleCloseModal={() => {
            //toggle("new-wallets");
          }}
          openModal={isShowingAddWallet}
          child={
            <ModalFrame
              style={modalFrameStyle}
              handleAction={() => {
                toggle("new-wallets");
              }}
              child={
                <NewWallet
                  openSnackbar={openSnackbar}
                  handleCloseModal={() => {
                    toggle("new-wallets");
                  }}
                />
              }
            />
          }
        />
      )}
      {/* {isShowingPrompt && (
        <AppBackdrop
          openModal={isShowingPrompt}
          handleCloseModal={() => {
            toggle("confirmation-prompt");
          }}
          child={
            <ModalFrame2
              btnColor={"#D80027"}
              btnLoading={""}
              handleActionTwo={() => {
                toggle("confirmation-prompt");
              }}
              handleAction={() => {
                handleConfirmWalletDelete();
              }}
              btnTxt={"Yes, delete"}
              child={
                <ConfirmationPrompt
                  header="Delete this wallet??"
                  text={"Funds stored in deleted wallets cannot be recovered."}
                />
              }
            />
          }
        />
      )} */}
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </Layout>
  );
}

export default Wallets;
