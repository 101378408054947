import { useQuery } from "@tanstack/react-query";
import { cutText } from "../utils/helpers";
import { GET_BANKS } from "./../api/queries/dashboard/misc/index";

const useGetBanks = (method, currency) => {

  const { data: banks, isLoading } = useQuery(
    [
      "financial-institution",
      method?.slug?.includes("mobile-money") ? "mobile-money" : "banks",
      currency?.type === 'FIAT' ? currency?.code === "NGN" ? null : cutText(currency?.code, 2, 'start') : null,
    ],
    GET_BANKS,
    {
      refetchOnWindowFocus: false,
      enabled: currency?.type === 'CRYPTO' ? false : true
    }
  );

  return {
    banks: banks?.data,
    isLoadingBanks: isLoading,
  };
};

export default useGetBanks;
