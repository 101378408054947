import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

import { GET_USER } from "../api/queries/dashboard/user";
import { handleDeleteRequestCalls } from "../axiosConfig/apiCalls";
export const DataCentral = React.createContext();

const settingsTabs = [
  {
    label: "profile",
  },
  {
    label: "compliance",
  },
  {
    label: "team",
  },
  {
    label: "security",
  },
  {
    label: "API",
  },

  {
    label: "preferences",
  },
];

const transactionsTabs = [
  {
    label: "swap",
  },
  {
    label: "deposit",
  },
  {
    label: "withdrawal",
  },
 
];

const walletsTabs = [
  {
    label: "crypto",
  },
  {
    label: "fiat",
  },
];
const AppContext = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn"))
  );
  const [openTransactionDropDown, setOpenTransactionDropDown] = useState(false);
  const [openSettingsDropDown, setOpenSettingsDropDown] = useState(false);
  const [openWalletsDropDown, setOpenWalletsDropDown] = useState(false);
  const [countdownFrom, setCountdownFrom] = useState(2400);
  const [showAlert, setShowAlert] = useState(false)
  const queryClient = useQueryClient();

  const {
    data: user,
    isLoading: isLoadingUser,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery(["user"], GET_USER, {
    refetchOnWindowFocus: false,
    enabled: Boolean(isLoggedIn),
  });


  useEffect(()=>{
    if(!isLoadingUser){
      setShowAlert(true);
    }
 
  }, [user])


  useEffect(() => {
   
    
    // update every second
    const interval = setInterval(() => {
      setCountdownFrom(countdownFrom - 1);
    }, 1000);
    // Clear timeout if the component is unmounted
    if (countdownFrom === 0) {
      clearTimeout(interval);
    }
    return () => clearTimeout(interval);
  }, [countdownFrom]);

  useEffect(() => {
    if (isLoggedIn) {
      //getUser();
    } else {
      handleDeleteSession();
    }
  }, [isLoggedIn]);



  const handleSignIn = (access_token) => {
    setIsLoggedIn(true);
    localStorage.setItem("Access-token", access_token);
    localStorage.setItem("isLoggedIn", JSON.parse(true));
  };

  const calculateSessionIdleCountdown = () => {
    setCountdownFrom(2400);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const _logout = () => {
    localStorage.clear("isLoggedIn");
    localStorage.clear("Access-token");
    queryClient.removeQueries([]);
  };
  
  
  const handleDeleteSession = async () => {
    if (localStorage.getItem("Access-token")) {
      const response = await handleDeleteRequestCalls("sessions");
      if (response.status < 300 && response.status >= 200) {
        _logout();
      } else if (response.response) {
        _logout();
      } else if (response.request) {
        _logout();
      } else {
        _logout();
      }
    } else {
      
    }
  };

  return (
    <DataCentral.Provider
      value={{
        handleSignIn,
        user: user?.data,
        loadingUser: isLoadingUser,
        isFetchingUser: isFetching ,
        isRefetchingUser: isRefetching,
        isLoggedIn,
        handleLogout,
        settingsTabs,
        transactionsTabs,
        walletsTabs,
        openTransactionDropDown,
        setOpenTransactionDropDown,
        setOpenWalletsDropDown,
        openSettingsDropDown,
        openWalletsDropDown,
        setOpenSettingsDropDown,
        calculateSessionIdleCountdown,
        countdownFrom,
        showAlert,
        setShowAlert
      }}
    >
      {props.children}
    </DataCentral.Provider>
  );
};

export default AppContext;
