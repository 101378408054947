import axios from "axios";

export const GET_WALLETS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}wallets?order=DESC&page=${data.queryKey[2]}&take=10&ref=${data.queryKey[1]}`
    )
    .then((res) => {
      return res.data;
    });
};

export const GET_WALLETS_SUMMARY = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}wallets/summary?column=${data.queryKey[1]}&type=${data.queryKey[3]}&math=${data.queryKey[2]}`
    )
    .then((res) => {
      return res.data;
    });
};

export const GET_WALLETS_ACCOUNTS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}wallets/${data.queryKey[2]}/accounts?order=DESC&type=${data.queryKey[1]}&page=${data.queryKey[3]}&take=10&ref=${data.queryKey[4]}`
    )
    .then((res) => {
      return res.data;
    });
};

export const GET_WALLETS_TRANSACTIONS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}wallets/accounts/${data.queryKey[3]}/transactions?order=DESC&page=${data.queryKey[2]}`
    )
    .then((res) => {
      return res.data;
    });
};

export const GET_WALLETS_TRX_SUMMARY = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}wallets/summary?column=${data.queryKey[2]}&type=${data.queryKey[4]}&math=${data.queryKey[3]}`
    )
    .then((res) => {
      return res.data;
    });
};
