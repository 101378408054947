export const tableHead = ['asset', 'sell', 'buy'];


export const teamsHeader= [
	'TEAM MEMBER', 'EMAIL ADDRESS', 'ROLE', 'STATUS', 'LAST SEEN', '', '', 
]

export const balanceTableHead = ['Currency', 'Available', 'Overdraft', ''];
export const transcationTableHead = [
	'CURRENCY',
	'DATE/TIME',
	'AMOUNT',
	'TNX REFERENCE',
	'CHANNEL',
	'STATUS',


];

export const DPTtranscationTableHead = [
	'CURRENCY',
	'DATE/TIME',
	'AMOUNT',
	'TNX REFERENCE',
	'CHANNEL',
	'STATUS',
	'ACTIONS'

];

export const filterByDate = [
	{ value: 'week', label: 'Week' },
	{ value: 'month', label: 'Month' },
	{ value: 'day', label: 'Today' }
];

export const filterByCurrency = [
	{ value: 'ngn', label: 'NGN' },
	{ value: 'usd', label: 'USD' },
	{ value: 'kes', label: 'KES' }
];
export const filterByStatus = [
	{ value: 'pending', label: 'Pending' },
	{ value: 'success', label: 'Success' },
	{ value: 'failed', label: 'Failed' }
];

export const DPTfilterByStatus = [
	{ value: 'pending', label: 'Pending' },
	{ value: 'success', label: 'Success' },
	{ value: 'failed', label: 'Failed' },
	{ value: 'refunded', label: 'Refunded' }
];



