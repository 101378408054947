import { Typography } from "@mui/material";
import React from "react";
import styles from "./BankInfo.module.css";
const BankInfoPreview = ({
  bank_name,
  bvn,
  bvn_name,
  account_number,
  country,
  bank_details,
}) => {
  return (
    <>
      {country === "Nigeria" ? (
        <div className={styles.bank_info_previews}>
          <div className={styles.bank_info_preview}>
            <Typography variant="h6">Bank Name</Typography>
            <Typography>{bank_name} </Typography>
          </div>
          <div className={styles.bank_info_preview}>
            <Typography variant="h6">Account Number</Typography>
            <Typography>{account_number}</Typography>
          </div>
          <div className={styles.bank_info_preview}>
            <Typography variant="h6">BVN</Typography>
            <Typography>{bvn}</Typography>
          </div>
          <div className={styles.bank_info_preview}>
            <Typography variant="h6">Name on BVN</Typography>
            <Typography>{bvn_name}</Typography>
          </div>
        </div>
      ) : (
        <div className={styles.bank_info_previews}>
          <div className={styles.bank_info_preview}>
            <Typography variant="h6">Bank Info</Typography>
            <Typography>{bank_details} </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default BankInfoPreview;
