import { useState } from 'react';
import {
	Container2,
	SearchContainer,
	Input,
	Container3,
	Container1,
	CloseSaerch
} from './styles';
import { SearchIcon, CloseIcon } from '../../../assets';
import { Container, Flex } from '../../UI-components/box';
import {

} from '@mui/material';


const SearchBox = ({ placeholder, setShowSearch, showSearch }) => {
	const [input, setInput] = useState('');

	const handleClearInput = () => setInput('');

	return (
		<>
			<Container1 height='max-content'>
				<Container2
					margin='0 30px 0 auto'
					height='max-content'
					justifyContent='flex-end'
				>
					<Container3>
						<Flex
							//  bgColor={'#fff'}
							wrap='nowrap'
						>
							<Container width='max-content' height='max-content' pad='10px'>
								<SearchIcon
									width='14px'
									height='14px'
									// color={""}
								/>
							</Container>

							<SearchContainer height='max-content'>
								<Input
									tye='text'
									value={input}
									onChange={(e) => setInput(e.target.value)}
									placeholder={placeholder}
								/>
							</SearchContainer>

							{input.length ? (
								<Container
									width='max-content'
									height='max-content'
									pad='10px'
									onClick={handleClearInput}
									cursor='pointer'
								>
									<CloseIcon
										width='14px'
										height='14px'
										// color={["grey", "1500", theme]}
									/>
								</Container>
							) : null}
						</Flex>
					</Container3>
				</Container2>

				{showSearch ? (
					<CloseSaerch
						width='17px'
						height='17px'
						onClick={() => setShowSearch(false)}
						cursor='pointer'
						className='close-search'
					>
						<CloseIcon
							width='10px'
							height='10px'
							// color={["grey", "200", theme]}
						/>
					</CloseSaerch>
				) : null}
			</Container1>
		</>
	);
};
export default SearchBox;
