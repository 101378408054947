import styled from 'styled-components';
import { Container, Flex } from '../../../../UI-components/box';

export const Styles = styled(Flex)`
	flex-direction: column;

	// background-color: #f8f9fd;
`;

export const Section1 = styled(Flex)`
	background-color: #f8f9fd;
    margin-top: 10px;
	flex-direction: column;
	.api_config {
		padding-left: 3rem;
		padding-top: 2em;

		@media (max-width: 900px) {
			padding: 20px 4%;
		}
		h4 {
			margin: 5px 0;
			color: #000000;
		}
		p {
			margin: 5px 0;
			font-size: 16px;
			color: #3e3e3e;
		}
		span {
			margin: 5px 0;
			color: #1a2cce;
			text-decoration: underline;
			font-size: 16px;
			cursor: pointer;
			display: flex;
			align-items: flex-end;
			
			span{
				margin-left: 10px;
			}
		}
	}
	.mode_green{
		margin: 20px 0;
	
		background-color: #3AB75D;
		max-width: 100%;
		padding: 5px 3rem;
		@media (max-width: 900px) {
			padding: 5px;
		}
		p {
			color: white;
			font-size: 16px;
		}
		span {
			color: white;
			font-size: 16px;
		}
	}
	.mode {
		
		margin: 20px 0;
		background-color: #fff6e5;
		max-width: 100%;
		padding: 5px 3rem;
		@media (max-width: 900px) {
			padding: 5px;
		}
		p {
			color: #723d00;
			font-size: 16px;
		}
		span {
			color: #686868;
			font-size: 16px;
		}
	}
`;

export const Section2 = styled(Flex)`
	background-color: #f8f9fd;
	padding-left: 3rem;
	padding-top: 2em;
	padding-bottom: 2em;
	.readonlyInput_box {
		max-width: 470px;
		gap: 10px;
	}
	.copy_clipboard_btn {
		height: 44px;
		width: 20%;
		background-color: #edf4ff;
		border: 1px solid #5866e2;
		color: #1a2cce;
		border-radius: 5px;
		font-weight: bold;
	}
	a {
		// margin-bottom: 30px;
		font-size: 16px;
		text-decoration: underline;
		color: #1a2cce;
	}
	@media (max-width: 900px) {
		padding: 20px 4%;
	}
`;
export const Section3 = styled(Container)`
	background-color: #f8f9fd;
	margin-top: 10px;
	padding-left: 3rem;
	padding-bottom: 2em;
	padding-top: 2em;
	h4 {
		margin: 5px 0;
		color: #000000;
	}
	span {
		color: #686868;
		font-size: 16px;
		padding: 5px 0;
		margin-bottom: 10px;
	}
	.url {
		max-width: 470px;
		gap: 10px;
	
		.red_outline {
			outline: 1px solid red !important;
		}
	}
	a {
		font-size: 16px;
		text-decoration: underline;
		color: #1a2cce;
	}
	@media (max-width: 900px) {
		padding: 20px 4%;
	}
`;
export const Section4 = styled(Flex)`
	background-color: #f8f9fd;
	margin-top: 10px;
	padding: 2rem 3rem;

	h4 {
		margin: 5px 0;
		color: #000000;
	}
	p {
		color: #686868;
		font-size: 16px;
		padding: 5px 0;
		margin-bottom: 10px;
	}
	.ip_address {
		max-width: 800px;
	}
	
	.ip_actions{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	
	
		>	a {
			margin-bottom: 20px;
			color: #1a2cce;
			font-size: 16px;
			cursor: pointer;
		}
	}

	
	form {
		width: 100%;
	}

	@media (max-width: 900px) {
		padding: 20px 4%;
	}
`;
