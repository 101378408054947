// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DownloadReport_download_report__pNhTu {\n\tbackground-color: white;\n\n\twidth: 453px;\n\n\tbox-sizing: border-box;\n\n}\n\n.DownloadReport_text__RJEfp {\n\tmargin: 13px 0 18px 0;\n}\n.DownloadReport_form_field__KLQ4O {\n\tmargin-bottom: 15px;\n}\n.DownloadReport_input_boxes__opcYV {\n\tdisplay: flex;\n\t/* justify-content: space-between; */\n\talign-items: center;\n\tmargin-bottom: 28px;\n}\n.DownloadReport_input_boxes__opcYV label {\n\tflex: 1 1;\n}\n\n.DownloadReport_horizontal_line__V5RqD {\n\twidth: 200px;\n\tflex: 1 1;\n\tmargin: 0 16px;\n}\n@media (max-width: 500px) {\n\t.DownloadReport_download_report__pNhTu {\n\t\twidth: 90%;\n\t\tpadding: 30px 20px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI-components/downloadReport/DownloadReport.module.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;;CAEvB,YAAY;;CAEZ,sBAAsB;;AAEvB;;AAEA;CACC,qBAAqB;AACtB;AACA;CACC,mBAAmB;AACpB;AACA;CACC,aAAa;CACb,oCAAoC;CACpC,mBAAmB;CACnB,mBAAmB;AACpB;AACA;CACC,SAAO;AACR;;AAEA;CACC,YAAY;CACZ,SAAO;CACP,cAAc;AACf;AACA;CACC;EACC,UAAU;EACV,kBAAkB;CACnB;AACD","sourcesContent":[".download_report {\n\tbackground-color: white;\n\n\twidth: 453px;\n\n\tbox-sizing: border-box;\n\n}\n\n.text {\n\tmargin: 13px 0 18px 0;\n}\n.form_field {\n\tmargin-bottom: 15px;\n}\n.input_boxes {\n\tdisplay: flex;\n\t/* justify-content: space-between; */\n\talign-items: center;\n\tmargin-bottom: 28px;\n}\n.input_boxes label {\n\tflex: 1;\n}\n\n.horizontal_line {\n\twidth: 200px;\n\tflex: 1;\n\tmargin: 0 16px;\n}\n@media (max-width: 500px) {\n\t.download_report {\n\t\twidth: 90%;\n\t\tpadding: 30px 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download_report": "DownloadReport_download_report__pNhTu",
	"text": "DownloadReport_text__RJEfp",
	"form_field": "DownloadReport_form_field__KLQ4O",
	"input_boxes": "DownloadReport_input_boxes__opcYV",
	"horizontal_line": "DownloadReport_horizontal_line__V5RqD"
};
export default ___CSS_LOADER_EXPORT___;
