// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletAccountFilter_filter__yIfOp {\n    margin-top: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n\n\n  \n  .WalletAccountFilter_filter_fields__1Nw1k {\n    display: flex;\n    align-items: center;\n  }\n  .WalletAccountFilter_field__pMopK {\n    margin-right: 20px;\n    width: 200px;\n  }\n  .WalletAccountFilter_search_field__ywRiF {\n    width: 348px;\n  }\n  .WalletAccountFilter_search_field__ywRiF p {\n    text-transform: capitalize;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    text-align: left;\n    margin-bottom: 9px;\n    line-height: 17px;\n    color: #505050;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/wallets/walletAccounts/components/filter/WalletAccountFilter.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;;;;EAKE;IACE,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":[".filter {\n    margin-top: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n\n\n  \n  .filter_fields {\n    display: flex;\n    align-items: center;\n  }\n  .field {\n    margin-right: 20px;\n    width: 200px;\n  }\n  .search_field {\n    width: 348px;\n  }\n  .search_field p {\n    text-transform: capitalize;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    text-align: left;\n    margin-bottom: 9px;\n    line-height: 17px;\n    color: #505050;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "WalletAccountFilter_filter__yIfOp",
	"filter_fields": "WalletAccountFilter_filter_fields__1Nw1k",
	"field": "WalletAccountFilter_field__pMopK",
	"search_field": "WalletAccountFilter_search_field__ywRiF"
};
export default ___CSS_LOADER_EXPORT___;
