import React, { useState } from "react";
import Button from "../../../../UI-components/button";
import styles from "./teams.module.css";
import { teamsHeader } from "../../../../../lib/constant";
import AppBackdrop from "../../../../UI-components/appBackdrop/AppBackdrop";
import useModal from "../../../../../customHooks/useModal";
import ModalFrame from "../../../../UI-components/modalFrame/ModalFrame";
import InviteMember from "./modals/invitemember/InviteMember";
import TableOne from "../../../../UI-components/new -tables/Table";

import Loader from "../../../../UI-components/loader/Loader";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import ModalFrame2 from "../../../../UI-components/modalFrame2/ModalFrame2";
import ConfirmationPrompt from "../../../../UI-components/prompt/confirmationPrompt/ConfirmationPrompt";

import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import EditMember from "./modals/editMember/EditMember";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_TEAMS } from "../../../../../api/queries/dashboard/teams";
import {
  REMOVE_MEMBER,
  RESET_LOGIN,
} from "../../../../../api/mutations/dashboard/settings/teams";

const Index = () => {
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
    const queryClient = useQueryClient();
  const { handleOpenModal, isShowingPrompt, toggle, handleCloseModal, opened } =
    useModal();

  const [member, setMember] = useState({});

  const [promptType, setPromptType] = useState("");

  const { data: teams, isLoading } = useQuery(["teams"], GET_TEAMS, {
    refetchOnWindowFocus: false,
  });



  const { mutate, isLoading: isLoadingRemove } = useMutation(REMOVE_MEMBER, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["teams"]);
      openSnackbar("success", response.message);
      toggle("confirmation-prompt");
      setMember({});
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  const { mutate: resetLogin, isLoading: isLoadingReset } = useMutation(
    RESET_LOGIN,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["teams"]);
        openSnackbar("success", response.message);
        toggle("confirmation-prompt");
        setMember({});
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    }
  );

  const handleRemoveMember = async () => {
    mutate(member);
  };

  const handleResetLogin = async () => {
    resetLogin(member);
  };

  const handleEditMember = (team) => {
    setMember(team);
    handleOpenModal("edit-member");
  };

  return (
    <SettingsLayout
      tab="team"
      child={
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className={styles.teams}>
              <div className={styles.heading}>
                <div className={styles.heading_text}>
                  <h4 className="">My team</h4>
                  <p className="">
                    Manage your team and invite new team members
                  </p>
                </div>
                <Button
                  text={"Invite member"}
                  bgColor={"#1A2CCE"}
                  color={"#fff"}
                  size="sm"
                  type="button"
                  borderRadius={"5px"}
                  onClick={() => {
                    handleOpenModal("invite");
                  }}
                />
              </div>
              <div className={styles.table}>
                <TableOne
                  handleEditMember={handleEditMember}
                  tableBody={teams?.data}
                  type={"teams"}
                  tableHeadData={teamsHeader}
                  handleRemoveMember={(data) => {
                    setMember(data);
                    toggle("confirmation-prompt");
                    setPromptType("remove-member");
                  }}
                  handleResetLogin={(data) => {
                    setPromptType("reset-login");
                    setMember(data);
                    toggle("confirmation-prompt");
                  }}
                />
              </div>
            </div>
          )}
          {opened.some((item) => item === "invite") && (
            <AppBackdrop
              handleCloseModal={() => {
                handleCloseModal("invite");
              }}
              openModal={opened.some((item) => item === "invite")}
              child={
                <ModalFrame
                  width="476px"
                  child={
                    <InviteMember
                      //handleGetTeams={handleGetTeams}
                      openSnackbar={openSnackbar}
                      handleCloseModal={() => {
                        handleCloseModal("invite");
                      }}
                    />
                  }
                />
              }
            />
          )}

          {opened.some((item) => item === "edit-member") && (
            <AppBackdrop
              handleCloseModal={() => {
                handleCloseModal("edit-member");
              }}
              openModal={opened.some((item) => item === "edit-member")}
              child={
                <ModalFrame
                  child={
                    <EditMember
                      member={member}
                      openSnackbar={openSnackbar}
                      handleCloseModal={() => {
                        setMember({});
                        handleCloseModal("edit-member");
                      }}
                    />
                  }
                />
              }
            />
          )}

          {isShowingPrompt && (
            <AppBackdrop
              openModal={isShowingPrompt}
              handleCloseModal={() => {
                toggle("confirmation-prompt");
              }}
              child={
                <ModalFrame2
                  btnLoading={isLoadingRemove || isLoadingReset}
                  handleActionTwo={() => {
                    setMember({});
                    toggle("confirmation-prompt");
                  }}
                  handleAction={() => {
                    promptType === "remove-member"
                      ? handleRemoveMember()
                      : handleResetLogin();
                  }}
                  btnTxt={
                    promptType === "remove-member" ? "Confirm" : "Reset login"
                  }
                  child={
                    <ConfirmationPrompt
                      text={
                        promptType === "remove-member"
                          ? `Are you sure you want to remove ${member?.full_name} as a member of this team?`
                          : `You are about to reset login for ${member?.full_name}`
                      }
                    />
                  }
                />
              }
            />
          )}

          <SwapstaSnackbar
            open={openToast}
            message={message}
            severity={severity}
            setOpen={setOpenToast}
            duration={duration}
          />
        </>
      }
    />
  );
};

export default Index;
