import { Typography } from "@mui/material";
import React from "react";
import styles from "./WalletTrxOverview.module.css";
import copy_icon from "../../../../../../assets/images/copy_icon.svg";
import { cutText } from "../../../../../../utils/helpers";

function OverviewWalletTrx({ openSnackbar, data, type }) {
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      openSnackbar("success", "Copied successfully");
    } catch (error) {}
  };

  return (
    <div className={styles.overview}>
      {type === "FIAT" ? (
        <div className={styles.overview_item}>
          <div className={styles.title}>
            <Typography variant="h6">Account Name</Typography>
          </div>
          <div className={styles.content_value}>
            <Typography>{data?.account_name}</Typography>
          </div>
        </div>
      ) : (
        <div className={styles.overview_item}>
          <div className={styles.title}>
            <Typography variant="h6">Wallet Address</Typography>
          </div>
          <div className={styles.content_value}>
            <Typography>
              {" "}
              {cutText(data?.address, 15, "start")}...
              {cutText(data?.address, -2, "end")}
            </Typography>
            <img
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(data.address);
              }}
              src={copy_icon}
              alt=""
            />
          </div>
        </div>
      )}
      {type === "FIAT" ? (
        <div className={`${styles.overview_item} ${styles.overview_center}`}>
          <div className={styles.title}>
            <Typography variant="h6">Account Number</Typography>
          </div>
          <div className={styles.content_value}>
            <Typography>{data?.account_number}</Typography>
            <img
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(data.account_number);
              }}
              src={copy_icon}
              alt=""
            />
          </div>
        </div>
      ) : (
        <div className={`${styles.overview_item} ${styles.overview_center}`}>
          <div className={styles.title}>
            <Typography variant="h6">Network</Typography>
          </div>
          <div className={styles.content_value}>
            <Typography style={{ textTransform: "uppercase" }}>
              {data?.chain}
            </Typography>
            <img
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(data.chain);
              }}
              src={copy_icon}
              alt=""
            />
          </div>
        </div>
      )}

      <div className={`${styles.overview_item} ${styles.overview_center}`}>
        <div className={styles.title}>
          <Typography variant="h6">Currency</Typography>
        </div>

        <div className={styles.address}>
          <Typography>{data?.currency}</Typography>
        </div>
      </div>

      <div className={`${styles.overview_item}`}>
        <div className={styles.title}>
          <Typography variant="h6">Category</Typography>
        </div>

        <div className={`${styles.wallet_details}`}>
          <Typography>{data?.account_type}</Typography>
        </div>
      </div>
    </div>
  );
}

export default OverviewWalletTrx;
