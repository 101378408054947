import { Typography } from "@mui/material";
import React from "react";
import styles from "./Fiat.module.css";



const Asset = ({ asset, type, handleClose, handleSetAssets}) => {
  return (
    <div  onClick={() => {
      handleClose();
      handleSetAssets(type, asset);
    }} className={styles.fiat}>
      <div className={styles.fiat_left}>
        <div className={styles.fiat_icon}>
           <img src={asset.icon} alt={`icon`} />
        </div>
        <div className={styles.fiat_value}>
          <Typography>{asset.code}</Typography>
        </div>
      </div>
      <div className={styles.fiat_label}>
        <Typography>{asset.code}</Typography>
      </div>
    </div>
  );
};

export default Asset;
