// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DynamicDeposit_step__HFA7I p {\n  font-weight: 400;\n  font-size: 12px;\n  color: #727272;\n}\n\n.DynamicDeposit_dynamic_deposit_form__AplG3 {\n  margin-top: 16px;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/balances/modals/depositMethod/dynamicDeposit/DynamicDeposit.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".step p {\n  font-weight: 400;\n  font-size: 12px;\n  color: #727272;\n}\n\n.dynamic_deposit_form {\n  margin-top: 16px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": "DynamicDeposit_step__HFA7I",
	"dynamic_deposit_form": "DynamicDeposit_dynamic_deposit_form__AplG3"
};
export default ___CSS_LOADER_EXPORT___;
