import React from "react";
import { VerifyEmailContainer } from "./styles";
import { Flex, Frame } from "../../../../UI-components/box";
import logo from "../../../../../assets/images/logo-dark.svg";
import email from "../../../../../assets/images/email.svg";


const Index = () => {
 

  return (
    <VerifyEmailContainer>
      <Flex flexDir={"column"} margin={"auto"}>
        <Frame height={"150px"} width={"150px"}>
          <img alt="logo" src={logo} />
        </Frame>
        <h1 className="verify_mail_title">Verify Your Email</h1>
        <Flex
          className="flex_container"
          width={"550px"}
          pad={"4em"}
          flexDir={"column"}
          bgColor={"#ffff"}
        
        >
          <Frame height={"90px"} width={"90px"} margin={"0px 0px 40px"}>
            <img alt="email-svg" src={email} />
          </Frame>
          <p className="verify_mail_text">
            We sent a verification link to your email. Click on it to proceed to
            complete your onboarding proceess
          </p>
        </Flex>
      </Flex>
    </VerifyEmailContainer>
  );
};

export default Index;
