import { Typography } from '@mui/material'
import React from 'react'
import styles from './Disclaimer.module.css'



function Disclaimer({message}) {
  return (
    <div className={styles.disclaimer}>
        <Typography>
{message}
        </Typography>
    </div>
  )
}

export default Disclaimer