import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { Styles } from "./styles";
import { tableHead } from "../../../../../lib/constant";
import styles from "./Swap.module.css";
import Swap from "../../../../UI-components/swap/Swap";
import MarketRate from "../market-rate/MarketRate";

import usePermissions from "../../../../../customHooks/usePermissions";

const Index = ({ fiatCurrencies, cryptoCurrencies, currencies, rates, loadingMarketRates }) => {
  const { checkPermission } = usePermissions();

  return (
    <Styles>
      <Grid container spacing={2}>
        {checkPermission("dashboard.swap") && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className={styles.card}>
              <Swap
                fiatCurrencies={fiatCurrencies}
                cryptoCurrencies={cryptoCurrencies}
                currencies={currencies}
                type={"balance"}
              />
            </div>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={checkPermission("dashboard.swap") ? 6 : 12}
        >
          <div className={`${styles.card} ${styles.add_scroll}`}>
            <MarketRate isLoading={loadingMarketRates} rates={rates} tableHeadData={tableHead} fiatCurrencies={fiatCurrencies} currencies={currencies} />
          </div>
        </Grid>
      </Grid>
    </Styles>
  );
};

export default Index;
