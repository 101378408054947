import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { GET_ACTIVITIES } from "../../../../../api/queries/dashboard/settings/security";
import GoBack from "../../../../UI-components/goBack/GoBack";
import Loader from "../../../../UI-components/loader/Loader";
import TableOne from "../../../../UI-components/new -tables/Table";
import EmptyState from "../../../../UI-components/null state/EmptyState";
import Pagination from "../../../../UI-components/pagination/Pagination";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import styles from "./Activities.module.css";
import ActivitiesFilter from "./filter/ActivitiesFilter";

function Activities() {
  const [page, setPage] = useState(1)

  const {
    data: activities,
    isLoading,
    refetch
  } = useQuery(["activities", page], GET_ACTIVITIES, {
    refetchOnWindowFocus: false,
  });



  const handlePageChange = async (page) => {
  
    setPage(page)
  };

  return (
    <SettingsLayout
      activeSecurityTab="activities"
      tab="security"
      child={
        <div className={styles.activities}>
          <GoBack />
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {activities?.data?.data?.length > 0 ? (
                <>
                  <ActivitiesFilter events={activities?.data?.data} />
                 
                  <TableOne
                    type="activities"
                    tableHeadData={["TIMESTAMP", "EVENT", "CHANNEL"]}
                    tableBody={activities?.data?.data}
                  />
                </>
              ) : (
                <div style={{ marginTop: 40 }}>
                  <EmptyState
                    handleRefresh={() => {
                      refetch()
                    }}
                    primaryText={"Activities not found"}
                    secondaryText={
                      "it looks like you dont have any activity yet"
                    }
                  />
                </div>
              )}

              {activities?.data?.data?.length > 0 && (
                <Pagination
                  meta={activities?.data?.meta}
                  handlePageChange={(page) => {
                    handlePageChange(page);
                  }}
                />
              )}
            </>
          )}
        </div>
      }
    />
  );
}

export default Activities;
