// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletTrx_heading__Fwsp8 {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 37px;\n  }\n  \n  .WalletTrx_heading__Fwsp8 h6 {\n    font-size: 14px;\n    font-weight: 400;\n    text-decoration: underline;\n    color: #d80027;\n    cursor: pointer;\n  }\n  .WalletTrx_table__2W2sN {\n    margin-top: 32px;\n   \n  }\n  .WalletTrx_table_heading__v7D4F p {\n    font-size: 16px;\n    font-weight: 600;\n    color: #6f6f6f;\n  }\n  .WalletTrx_body__-eFaK {\n    padding: 30px 0;\n  }", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/wallets/walletsTrxs/WalletTrx.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,0BAA0B;IAC1B,cAAc;IACd,eAAe;EACjB;EACA;IACE,gBAAgB;;EAElB;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,eAAe;EACjB","sourcesContent":[".heading {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 37px;\n  }\n  \n  .heading h6 {\n    font-size: 14px;\n    font-weight: 400;\n    text-decoration: underline;\n    color: #d80027;\n    cursor: pointer;\n  }\n  .table {\n    margin-top: 32px;\n   \n  }\n  .table_heading p {\n    font-size: 16px;\n    font-weight: 600;\n    color: #6f6f6f;\n  }\n  .body {\n    padding: 30px 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "WalletTrx_heading__Fwsp8",
	"table": "WalletTrx_table__2W2sN",
	"table_heading": "WalletTrx_table_heading__v7D4F",
	"body": "WalletTrx_body__-eFaK"
};
export default ___CSS_LOADER_EXPORT___;
