import { Typography } from '@mui/material';
import React from 'react';
import styles from './HeadingsOne.module.css';
const HeadingOne = ({ title, description }) => {
	return (
		<div className={styles.deposit_heading}>
			<Typography variant='h6'>{title}</Typography>
			<Typography>{description}</Typography>
		</div>
	);
};

export default HeadingOne;
