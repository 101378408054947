import { useQuery } from "@tanstack/react-query";
import {GET_ACCOUNT_NAME} from './../api/queries/dashboard/misc/index'

const useGetAccountName = (account_number, bank) => {
  
  const {
    data: account,
    isLoading,
    isFetching
  } = useQuery(["account", bank, account_number], GET_ACCOUNT_NAME, {
    refetchOnWindowFocus: false,
    enabled: account_number?.length === 10 ? true : false
  });

  return {
     accountName: account?.account_name,
    isLoadingAccountName: isFetching
  };
};

export default useGetAccountName;
