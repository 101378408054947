import React, { useEffect, } from "react";
import styles from "./EditMember.module.css";
import Button from "../../../../../../UI-components/button";
import LabeledInputs from "../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";
import { useForm } from "react-hook-form";

import LabeledSelectTwo from "../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import { useMutation,  useQueryClient } from "@tanstack/react-query";

import { EDIT_MEMBER } from "../../../../../../../api/mutations/dashboard/settings/teams";
import useGetRoles from "../../../../../../../customHooks/useGetRoles";

const EditMember = ({
  member,
  openSnackbar,
  handleGetTeams,
  handleCloseModal,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const {roles, isLoadingRoles} = useGetRoles()
  const { mutate, isLoading: isLoadingEditMember } = useMutation(EDIT_MEMBER, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["teams"]);
      openSnackbar("success", response.message);
      handleCloseModal("edit-member");
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  useEffect(() => {
    handlePopulation(member);
  }, [roles]);

  const handlePopulation = (member) => {
    setValue("full_name", member?.full_name);
    setValue("email", member?.email);
    setValue("role", member?.role?.id);
  };

  const onSubmit = async (data) => {
    mutate({
      data: {
        full_name: data.full_name,
        email: data.email,
        role_id: data.role,
      },
      member,
    });
 
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.edit_member}
    >
      <div className={styles.heading}>
        <h2>Edit team member</h2>
        {/* <Typography>
          Add a new team memeber to access this business account
        </Typography> */}
      </div>
      <div className={styles.body}>
        <div className={styles.field}>
          <LabeledInputs
            label={"full name"}
            type={"text"}
            redOutline={errors.full_name ? true : false}
            register={register}
            name={"full_name"}
            handleChangeAuto={true}
            required={true}
          />
          <ValidationMessage
            message={errors.full_name ? "Full name is required" : null}
          />
        </div>
        <div className={styles.field}>
          <LabeledInputs
            label={"email"}
            type={"text"}
            redOutline={errors.email ? true : false}
            register={register}
            name={"email"}
            handleChangeAuto={true}
            required={true}
          />
          <ValidationMessage
            message={errors.email ? "Email is required" : null}
          />
        </div>
        <div className={styles.field}>
          <LabeledSelectTwo
            data={roles?.data?.map((role) => {
              return {
                label: role.name,
                value: role.id,
              };
            })}
            redOutline={errors.role ? true : false}
            register={register}
            name="role"
            label="Role"
            placeholder="Select role"
            required={true}
          />
           <ValidationMessage
            message={errors.role ? "Role is required" : null}
          />
        </div>
       
      </div>
      <div className={styles.btn}>
        <Button
          text={"Edit member"}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type="button"
          borderRadius={"5px"}
          onClick={handleSubmit(onSubmit)}
          loading={isLoadingEditMember}
        />
      </div>
    </div>
  );
};

export default EditMember;
