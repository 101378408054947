import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import Disclaimer from "../../../../../../../UI-components/disclaimer/Disclaimer";
import styles from "./styles.module.css";
import { ClockIcon } from "../../../../../../../../assets";
import BankAccountDetails from "../../../../../../../UI-components/bankAccountDetails/BankAccountDetails";
import { useState } from "react";
import Button from "../../../../../../../UI-components/button";
import { useEffect } from "react";
import {
  calculateFee,
  parseAmount,
} from "../../../../../../../../utils/helpers";
import useGetRequirements from "../../../../../../../../customHooks/useGetRequirements";
import { useQuery } from "@tanstack/react-query";
import { GET_REQUIREMENTS } from "../../../../../../../../api/queries/dashboard/balances";

function DynamicDepositStep2({
  fee,
  amount,
  handleProceed,
  handleCancel,
  handleScreen,
  method,
  currency,
}) {
  const [copied, setCopied] = useState([]);

  const [time, setTime] = useState(80);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const {
    data: requirements,
    isLoading,

  } = useQuery(
    ["requirements", currency.code, method.slug, amount * 1 + fee * 1],
    GET_REQUIREMENTS,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: () => {
        setTime(80);
      },
    }
  );
  

  useEffect(() => {

    const timer = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  useEffect(() => {
    
    if(time < 1){
      handleScreen('dynamic-bank-pending-deposit')
    }
    setMinutes(Math.floor(time / 60));
    setSeconds(time % 60);
  }, [time]);

  const handleCopy = async (text, label) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied([...copied, { id: label, copy: "copied" }]);
    } catch (error) {}
  };

  return (
    <div className={styles.step2}>
      <div className={styles.disclaimer}>
        <Disclaimer
          message={ time < 10 ? `Please do not make any payment if you have not done so already` : `Please ensure that you make a deposit of ${
            currency?.code
          } ${parseAmount(
            amount * 1 + fee * 1,
            currency?.fee_type
          )} only within the time frame shown in the timer.`}
        />
      </div>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div>
            <div className={styles.bank_account_details}>
              {requirements?.data?.map((requirement, index) => {
                return (
                  <BankAccountDetails
                    key={requirement.label}
                    requirement={requirement}
                    handleCopy={handleCopy}
                    copied={copied}
                    lastItem={requirements?.data?.length === index + 1}
                    firstItem={index === 0}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.timer}>
            <ClockIcon color={time < 10 ? "#D92D20" : null} />
            <Typography
              style={{
                color: time < 10 ? "#D92D20" : "#545A63",
              }}
            >
              Payment gateway closes in {minutes < 10 ? `0${minutes}` : minutes}
              :{seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
          </div>

          <div className={`${styles.btns} ${styles.absolute_btns}`}>
        
              <Button
                Styles
                text={"I have paid"}
                bgColor={"#1A2CCE"}
                color={"#FFFFFF"}
                size="md"
                fullwidth
                type="submit"
                border={"0.4px solid #1A2CCE"}
                boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
                borderRadius={"5px"}
                onClick={() => {
                  handleProceed();
                }}
              />
          
              <Button
                Styles
                text={"Cancel transaction"}
                color={"#676767"}
                size="md"
                bgColor={"white"}
                fullwidth
                type="submit"
                onClick={() => {
                  handleCancel();
                }}
                boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
                borderRadius={"5px"}
              />
            </div>
          
        </div>
      )}
    </div>
  );
}

export default DynamicDepositStep2;
