import styled from 'styled-components';

export const Styles = styled.div`
	// background-color: #e5e5e5;
	// display: flex;
	flex-direction: ;

	// width: 100%;
	// height: 100%;
	padding: 30px 30px 30px;
	height: 100%;
	@media (max-width: 700px) {
		padding: 20px 4%;
	}
`;
