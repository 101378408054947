import React, { useEffect, useState } from "react";
import styles from "./BankInfo.module.css";
import Button from "../../../../../UI-components/button";
import LabeledInputs from "../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import { CircularProgress, Skeleton, Typography } from "@mui/material";
import LabeledSelectTwo from "../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import LabeledTextarea from "../../../../../UI-components/textArea/labeledTextarea/LabeledTextarea";
import BankInfoPreview from "./BankInfoPreview";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../../UI-components/validation-message/ValidationMessage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UPDATE_BANK_COMPLIANCE } from "../../../../../../api/mutations/dashboard/settings/compliance";
import useGetAccountName from "../../../../../../customHooks/useGetAccountName";
import { bankInfoSchema } from "../../../../../../schemas/dashboard/compliance";
import { yupResolver } from "@hookform/resolvers/yup";

const BankInfo = ({
  setComplianceInfo,
  openSnackbar,
  country,
  banks,
  complianceInfo,
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(bankInfoSchema) });
  const bank = watch("bank", false); // you can supply default value as second argument
  const account_number = watch("account_number", ""); // you can supply default value as second argument
  const { accountName, isLoadingAccountName } = useGetAccountName(
    account_number,
    bank
  );
  const queryClient = useQueryClient();


  const { mutate, isLoading } = useMutation(UPDATE_BANK_COMPLIANCE, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["compliance"]);
      openSnackbar("success", response.message);
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  useEffect(() => {
    if (country) {
      setValue("country", country);
    }
  }, [country]);

  useEffect(() => {
    setValue("bank_details", complianceInfo.bank_details || "");
    setValue("account_number", complianceInfo.account_number || "");
    setValue("bvn", complianceInfo.bvn || "");
    setValue("bank", complianceInfo.bank_code || "");
    setValue('bvn_name', complianceInfo?.bvn_name)
  }, [complianceInfo, banks]);

  const onSubmit = async (data) => {
    mutate({
      bank_code: data.bank,
      bank_details: data.bank_details,
      account_number: data.account_number,
      bvn: data.bvn,
      bvn_name: data.bvn_name

    });
  };

  return (
    <>
      {complianceInfo?.verified_bank ? (
        <BankInfoPreview
          country={complianceInfo.country}
          bank_name={
            banks?.find((bank) => {
              return bank.code === complianceInfo.bank_code;
            })?.name
          }
          bvn_name={complianceInfo?.bvn_name}
          account_number={complianceInfo.account_number}
          bvn={complianceInfo.bvn}
          bank_details={complianceInfo.bank_details}
        />
      ) : (
        <div className={styles.bank_info}>
          {country === "Nigeria" ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.bank_info_field}>
                <LabeledSelectTwo
                  required={true}
                  data={banks?.map((bank) => {
                    return {
                      label: bank.name,
                      value: bank.code,
                    };
                  })}
                  placeholder={"Bank"}
                  label={"bank name"}
                  register={register}
                  name={"bank"}
                  redOutline={errors.bank ? true : false}
                />
                <ValidationMessage message={errors?.bank?.message} />
              </div>
              <div className={styles.bank_info_field}>
                <LabeledInputs
                  required={true}
                  name={"account_number"}
                  label={"account number"}
                  placeholder={"Account number"}
                  redOutline={errors.account_number ? true : false}
                  register={register}
                  handleChangeAuto={true}
                />
                <ValidationMessage message={errors?.account_number?.message} />
                <div className={styles.bank_info_field_caption}>
                  {isLoadingAccountName ? (
                    <Skeleton width={'50%'} height={30}/>
                  ) : (
                    <Typography>{accountName}</Typography>
                  )}
                </div>
              </div>
              <div className={styles.bank_info_field}>
                <LabeledInputs
                  required={true}
                  name={"bvn"}
                  label={"BVN"}
                  placeholder={"0000-0000-0000-0000"}
                  redOutline={errors.bvn ? true : false}
                  register={register}
                  handleChangeAuto={true}
                />
                <ValidationMessage message={errors?.bvn?.message} />
              </div>
              <div className={styles.bank_info_field}>
                <LabeledInputs
                  required={true}
                  name={"bvn_name"}
                  label={"Name on BVN"}
                  placeholder={""}
                  redOutline={errors.bvn_name ? true : false}
                  register={register}
                  handleChangeAuto={true}
                />
                <ValidationMessage message={errors?.bvn_name?.message} />
              </div>
              <div className={styles.bank_info_btn}>
                <Button
                  text={"Save changes"}
                  bgColor={"#1A2CCE"}
                  color={"#fff"}
                  size="sm"
                  defaultChecked
                  type="submit"
                  borderRadius={"5px"}
                  loading={isLoading}
                />
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <LabeledTextarea
                name={"bank_details"}
                label={"bank details"}
                placeholder={"Bank name, city etc"}
                register={register}
                redOutline={errors.bank_details ? true : false}
              />
              <ValidationMessage
                message={
                  errors.country
                    ? errors?.country?.message
                    : errors.bank_details?.message
                }
              />
              <div className={styles.bank_info_btn}>
                <Button
                  text={"Save changes"}
                  bgColor={"#1A2CCE"}
                  color={"#fff"}
                  size="sm"
                  defaultChecked
                  type="submit"
                  borderRadius={"5px"}
                  loading={isLoading}
                />
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default BankInfo;
