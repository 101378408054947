import * as yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import { Flex } from "../../../UI-components/box";
import Button from "../../../UI-components/button";

import { LoginText, ConditionText, FormContainer } from "./styles";
import { HiddenVisibilityIcon, PasswordEyeIcon } from "../../../../assets/";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../UI-components/input/styles";


import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LabeledInputs from "./signup-inputs/LabeledInputs";
import PasswordContext from "../../../UI-components/password-context/PasswordContext";
import ValidationMessage from "../../../UI-components/validation-message/ValidationMessage";
import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import { CREATE_ACCOUNT } from "../../../../api/mutations/auth";
import { useMutation } from "@tanstack/react-query";
const Fields = () => {
  const navigate = useNavigate();
  const { id: invite_token } = useParams();

  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const [visibility, setVisibility] = useState(false);
  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [business_name, setBusinessName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [passwordErrorSpecialChar, setPasswordErrorSpecialChar] = useState({});
  const [passwordErrorEightChar, setPasswordErrorEightChar] = useState({});
  const [passwordErrorNumber, setPasswordErrorNumber] = useState({});



  const { mutate, isLoading } = useMutation(CREATE_ACCOUNT, {
    onSuccess: (response) => {
      navigate("/verify-email");
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });

  useEffect(() => {
    handleSpecialChar(password);
    handleNumberValidation(password);
    handleEightChar(password);
  }, [password]);

  useEffect(() => {
    handleClearLocal();
  }, []);

  const handleClearLocal = () => {
    localStorage.clear("isLoggedIn");
    localStorage.clear("Access-token");

  };

  const handleSignUp = async () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      full_name.length > 2 &&
      emailRegex.test(email) &&
      business_name.length > 2
    ) {
      if (
        !passwordErrorSpecialChar.specialChar &&
        !passwordErrorNumber.number &&
        !passwordErrorEightChar.eightChar
      ) {
        mutate({
          email,
          password,
          full_name,
          business_name,
         invite_token,
        });

      
      } else {
      }
    } else {
      handleEmailValidation(email);
      handleBusinessNameValidation(business_name);
      handleFullNameValidation(full_name);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    handleValidation(name, value);
    if (name === "full-name") {
      setFullName(value);
    } else if (name === "business-name") {
      setBusinessName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else {
    }
  };

  const handleValidation = (name, value) => {
    if (name === "password") {
      handleEightChar(value);
      handleSpecialChar(value);
      handleNumberValidation(value);
    } else if (name === "full-name") {
      handleFullNameValidation(value);
    } else if (name === "business-name") {
      handleBusinessNameValidation(value);
    } else if (name === "email") {
      handleEmailValidation(value);
    } else {
    }
  };

  const handleBusinessNameValidation = (value) => {
    if (value.length < 3) {
      setError({
        ...error,
        business_name: "Business name has to be atleast 3 characters",
      });
    } else {
      delete error.business_name;
    }
  };

  const handleFullNameValidation = (value) => {
    if (value.length < 3) {
      setError({
        ...error,
        full_name: "Fullname has to be atleast 3 characters",
      });
    } else {
      delete error.full_name;
    }
  };

  const handleEmailValidation = (value) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(value)) {
      delete error.email;
    } else {
      setError({
        ...error,
        email: "email needs to be a valid email",
      });
    }
  };

  const handleEightChar = (value) => {
    if (value.length < 8) {
      setPasswordErrorEightChar({ ...passwordErrorEightChar, eightChar: true });
    } else {
      const tempPass = { ...passwordErrorEightChar };
      tempPass.eightChar = false;
      setPasswordErrorEightChar(tempPass);
    }
  };

  const handleNumberValidation = (value) => {
    const numberRegex = /\d/;
    if (numberRegex.test(value)) {
      const tempPass = { ...passwordErrorNumber };
      tempPass.number = false;
      setPasswordErrorNumber(tempPass);
    } else {
      setPasswordErrorNumber({ ...passwordErrorNumber, number: true });
    }
  };
  const handleSpecialChar = (value) => {
    const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialCharRegex.test(value)) {
      const tempPass = { ...passwordErrorSpecialChar };
      tempPass.specialChar = false;
      setPasswordErrorSpecialChar(tempPass);
    } else {
      setPasswordErrorSpecialChar({
        ...passwordErrorSpecialChar,
        specialChar: true,
      });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    handleValidation(name, value);
  };
  return (
    <FormContainer>
      <Flex flexDir={"column"} gap={"30px"}>
        <InputStyle>
          <LabeledInputs
            value={full_name}
            label={"Full Name"}
            placeholder={""}
            handleChange={handleChange}
            name={"full-name"}
            onBlur={handleBlur}
            redOutline={error.full_name ? true : false}
          />
          <ValidationMessage message={error.full_name} />
        </InputStyle>
        <InputStyle>
          <LabeledInputs
            value={business_name}
            label={"business name"}
            placeholder={""}
            handleChange={handleChange}
            name={"business-name"}
            onBlur={handleBlur}
            redOutline={error.business_name ? true : false}
          />
          <ValidationMessage message={error.business_name} />
        </InputStyle>
        <InputStyle>
          <LabeledInputs
            value={email}
            label={"email"}
            placeholder={""}
            handleChange={handleChange}
            name={"email"}
            onBlur={handleBlur}
            redOutline={error.email ? true : false}
          />
          <ValidationMessage message={error.email} />
        </InputStyle>
        <InputStyle>
          <Label htmlFor="fullName">Create a password</Label>
          <FieldWrapper btn={"btn"}>
            <input
              name="password"
              value={password}
              onChange={handleChange}
              type={visibility ? "text" : "password"}
            />
            <button
              onClick={(e) => {
                setVisibility(!visibility);
                e.preventDefault();
              }}
            >
              {visibility ? (
                <PasswordEyeIcon width={"20px"} height={"20px"} />
              ) : (
                <HiddenVisibilityIcon width={"20px"} height={"20px"} />
              )}
            </button>
          </FieldWrapper>
          <PasswordContext
            passwordErrorEightChar={passwordErrorEightChar}
            passwordErrorNumber={passwordErrorNumber}
            passwordErrorSpecialChar={passwordErrorSpecialChar}
          />
        </InputStyle>
        <Button
          text={"Create Account"}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type={"submit"}
          borderRadius={"5px"}
          loading={isLoading}
          onClick={handleSignUp}
        />
        <ConditionText>
          By clicking the "Create your account" button, you agree <br />
          to our <Link to={"/"}>Terms of use</Link> and
          <Link to={"/"}> Privacy Policy</Link>
        </ConditionText>
        <LoginText>
          Have an account? <Link to="/signin">Sign In</Link>
        </LoginText>
      </Flex>
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </FormContainer>
  );
};

export default Fields;
