import { Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./ActivityManager.module.css";
import activities_icon from "../../../../../../../assets/images/activities_icon.svg";
import sessions_icon from "../../../../../../../assets/images/sessions_icon.svg";
import Button from "../../../../../../UI-components/button";
import { useNavigate } from "react-router";

function ActivityManager() {
  const navigate = useNavigate();

  return (
    <div className={styles.activity_manager}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6}>
          <div className={styles.activity_manager_item}>
            <img src={activities_icon} alt="shield" />
            <div className={styles.activity_manager_item_text}>
              <div className={styles.activity_manager_item_text_up}>
                <Typography>Activities</Typography>
              </div>
              <Typography>
                Monitor and manage all activities that has been performed on
                your account.
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={styles.activity_manager_btn}>
            <Button
              fullwidth={true}
              text={"View activites"}
              bgColor={"#1A2CCE"}
              color={"#fff"}
              size="default"
              type="button"
              borderRadius={"5px"}
              onClick={() => {
                navigate("/settings/activities");
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div
            className={`${styles.activity_manager_item_last} ${styles.activity_manager_item}`}
          >
            <img src={sessions_icon} alt="lock" />
            <div className={styles.activity_manager_item_text}>
              <div className={styles.activity_manager_item_text_up}>
                <Typography>Sessions</Typography>
              </div>
              <Typography>
                Track and manage your sessions and connected devices.
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={styles.activity_manager_btn}>
            <Button
              fullwidth={true}
              text={"Manage sessions"}
              bgColor={"#1A2CCE"}
              color={"#fff"}
              size="default"
              type="button"
              borderRadius={"5px"}
              onClick={() => {
                navigate("/settings/sessions");
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ActivityManager;
