import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Progress.module.css";
import mark_green from "../../../../../../assets/images/mark_green.svg";
import mark from "../../../../../../assets/images/mark.svg";
import mark_orange from "../../../../../../assets/images/orange_mark.svg";

const Progress = ({ complianceInfo }) => {
  const [businessInfoDocumentSubmitted, setBusinessInfoDocumentSubmitted] =
    useState("default");
  const [documentUploadsSubmitted, setDocumentUploadsSubmitted] =
    useState("default");
  const [bankInfoDocumentSubmitted, setBankInfoDocumentSubmitted] =
    useState("default");

  useEffect(() => {
    handleCheckIfBusinessInfoSubmitted(complianceInfo);
    handleCheckIfBankInfoSubmitted(complianceInfo);
    handleCheckIfDocumentUploadsSubmitted(complianceInfo);
  }, [complianceInfo]);

  const handleCheckIfBusinessInfoSubmitted = (info) => {
    if (
      info.business_name &&
      info.business_description &&
      info.business_email &&
      info.website &&
      info.phone &&
      info.country &&
      info.state &&
      info.address
    ) {
      if (info.verified_business === false) {
        setBusinessInfoDocumentSubmitted("orange");
      } else {
        setBusinessInfoDocumentSubmitted("green");
      }
    } else {
      setBusinessInfoDocumentSubmitted("default");
    }
  };

  //CHECK FOR BANK INFO
  const handleCheckIfBankInfoSubmitted = (info) => {
    if (info.bank_code && info.bvn && info.account_number) {
      if (info.verified_bank === false) {
        setBankInfoDocumentSubmitted("orange");
      } else {
        setBankInfoDocumentSubmitted("green");
      }
    } else if (info.bank_details) {
      if (info.verified_bank === false) {
        setBankInfoDocumentSubmitted("orange");
      } else {
        setBankInfoDocumentSubmitted("green");
      }
    } else {
      setBankInfoDocumentSubmitted("default");
    }
  };

  const handleCheckIfDocumentUploadsSubmitted = (info) => {
    if (
      info?.document_country_issuance &&
      info?.document_incorporation &&
      info?.document_memorandum &&
      info?.id_directors?.length > 0 &&
      info?.id_shareholders?.length > 0 &&
      info?.letter_of_authorization &&
      info?.proof_of_address &&
      info?.register_of_members &&
      info?.register_of_directors &&
      info?.document_aml
    ) {
      if (info.verified_document === false) {
        setDocumentUploadsSubmitted("orange");
      } else {
        setDocumentUploadsSubmitted("green");
      }
    } else {
      setDocumentUploadsSubmitted("default");
    }
  };
  return (
    <div className={styles.progress}>
      <div className={styles.progress_heading}>
        <Typography>verification progress</Typography>
      </div>
      <div className={styles.progress_items}>
        <div className={styles.progress_item}>
          <div className={styles.progress_item_text}>
            <Typography variant="h6">business information</Typography>
            <Typography>Add your business information</Typography>
          </div>
          <div className={styles.progress_item_icon}>
            {businessInfoDocumentSubmitted === "orange" ? (
              <img src={mark_orange} alt="mark" />
            ) : null}
            {businessInfoDocumentSubmitted === "green" ? (
              <img src={mark_green} alt="mark" />
            ) : null}
            {businessInfoDocumentSubmitted === "default" ? (
              <img src={mark} alt="mark" />
            ) : null}
          </div>
        </div>
        <div className={styles.progress_item}>
          <div className={styles.progress_item_text}>
            <Typography variant="h6">Document Upload</Typography>
            <Typography>Upload revelant compliance doucments</Typography>
          </div>
          <div className={styles.progress_item_icon}>
            {documentUploadsSubmitted === "orange" ? (
              <img src={mark_orange} alt="mark" />
            ) : null}
            {documentUploadsSubmitted === "green" ? (
              <img src={mark_green} alt="mark" />
            ) : null}
            {documentUploadsSubmitted === "default" ? (
              <img src={mark} alt="mark" />
            ) : null}
          </div>
        </div>
        <div className={`${styles.last_item} ${styles.progress_item}`}>
          <div className={styles.progress_item_text}>
            <Typography variant="h6">Bank Information</Typography>
            <Typography>Verify your banking information</Typography>
          </div>
          <div className={styles.progress_item_icon}>
            {bankInfoDocumentSubmitted === "orange" ? (
              <img src={mark_orange} alt="mark" />
            ) : null}
            {bankInfoDocumentSubmitted === "green" ? (
              <img src={mark_green} alt="mark" />
            ) : null}
            {bankInfoDocumentSubmitted === "default" ? (
              <img src={mark} alt="mark" />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
