import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Swap.module.css";
import swap_icon from "../../../assets/images/swap_icon_new.svg";
import NewSelect from "../newSelect/NewSelect";
import Button from "../button";
import AppBackdrop from "../appBackdrop/AppBackdrop";
import ModalFrame from "../modalFrame/ModalFrame";
import ChooseAssets from "../../UI-components//chooseAsserts/ChooseAssets";
import useModal from "../../../customHooks/useModal";
import ConfirmOrder from "../confirmOrder/ConfirmOrder";

import { DataCentral } from "../../../context/AppContext";
import {
  handleGetRequestCalls,
} from "../../../axiosConfig/apiCalls";
import ChooseAssetsTwo from "../chooseAsserts/ChooseAssetsTwo";
import useOpenSnackbar from "../../../customHooks/useOpenSnackbar";

import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import SwapstaSnackbar from "../AppSnackbars/SwapstaSnackbar";
import {
  CONFIRM_SWAP,
  INITIATE_SWAP,
  REFRESH_SWAP,
} from "../../../api/mutations/dashboard/swap/index";
import { useMutation } from "@tanstack/react-query";
import { parseAmount } from "../../../utils/helpers";
const modalFrameStyle = {
  height: "auto",
  width: 470,
};

const Swapp = ({ fiatCurrencies, cryptoCurrencies, currencies }) => {
  const {
    toggle,
    isShowingChooseAssets,
    isShowingChooseAssetsTwo,
    opened,
    handleCloseModal,
    handleOpenModal,
  } = useModal();
  const navigate = useNavigate();
  

  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  const [asset1, setAsset1] = useState(cryptoCurrencies[0]);
  const [asset2, setAsset2] = useState(fiatCurrencies[0]);
  const [asset1Qty, setAsset1Qty] = useState(Number);
  const [asset2Qty, setAsset2Qty] = useState(Number);
  const [assetType, setAssetType] = useState("");

  const [swapDetails, setSwapDetails] = useState({});
  const [rate, setRate] = useState("");
  const [timer, setTimer] = useState({
    sec: 0,
  });
  const [time, setTime] = useState(new Date(Date.now()));
  const [isFetchingRate, setIsFetchingRate] = useState(false);

  const [errorMessage, setErrorMessage] = useState({});
  const [swapablesTwo, setSwapablesTwo] = useState({});

  const [minSwapLimitErrors, setMinSwapLimitErrors] = useState({});
  const [maxSwapLimitErrors, setMaxSwapLimitErrors] = useState({});

  const { mutate: swap, isLoading: isLoadingSwap } = useMutation(
    INITIATE_SWAP,
    {
      onSuccess: (response) => {
        setSwapDetails(response.data);
        handleOpenModal("confirm-order");
      },
      onError: (error) => {
        openSnackbar("error", error?.response?.data?.message);
      },
    }
  );
  const { mutate: refreshSwap, isLoading: isLoadingRefreshSwap } = useMutation(
    REFRESH_SWAP,
    {
      onSuccess: (response) => {
        setTime(new Date(response.data.expires_at));
        setSwapDetails(response.data);
      },
      onError: (error) => {
        openSnackbar("error", error?.response?.data?.message);
      },
    }
  );
  const { mutate: confirmSwap, isLoading: isLoadingConfirmSwap } = useMutation(
    CONFIRM_SWAP,
    {
      onSuccess: (response) => {
        navigate(`/transaction/${response.data.id}`);
        handleCloseModal("confirm-order");
        openSnackbar("success", response.message);
      },
      onError: (error) => {
        openSnackbar("error", error?.response?.data?.message);
      },
    }
  );

  useEffect(()=>{
    setAsset2(fiatCurrencies[0])
  }, [fiatCurrencies])

  useEffect(()=>{
    setAsset1(cryptoCurrencies[0])
  }, [cryptoCurrencies])

  useEffect(() => {
    handleValidateMaxSwap(asset1Qty);
    handleValidateMinSwap(asset1Qty);
    handleAsset2Calc(asset1Qty, rate);
  }, [asset1]);

  useEffect(() => {
    handleValidateMaxSwap(asset2Qty);
    handleValidateMinSwap(asset2Qty);
    handleAsset1Calc(asset2Qty, rate);
    handleGetRate(asset1, asset2);
  }, [asset2]);

  useEffect(() => {
    const selectedSwapable = currencies?.swapables?.find((swapable) => {
      return swapable.currency === asset1.code;
    });

    setSwapablesTwo(selectedSwapable?.swapable);
  }, []);

  useEffect(() => {
    handleCalculation(asset1, asset2, rate);
  }, [rate]);

  useEffect(() => {
    // update every second
    const interval = setInterval(() => {
      const date = calculateCountdown(time);
      if (date) {
        setTimer(date);
      } else {
        clearTimeout(interval);
        //handleRefreshTimer()
      }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(interval);
  }, [time]);

  const calculateCountdown = (endDate) => {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
    // clear countdown when date is reached
    if (diff < 0) return false;
    const timeLeft = {
      sec: 0,
    };
    // calculate time difference between now and expected date
    timeLeft.sec = diff;
    return timeLeft;
  };

  const handleGetRate = async (asset1, asset2) => {
    setErrorMessage({});
    setIsFetchingRate(true);
    const response = await handleGetRequestCalls(
      `market/rates?from_currency=${asset1.code}&to_currency=${asset2.code}`
    );

    if (response.status < 300 && response.status >= 200) {
      const { data } = response.data;
   
      setRate(data);
      setTime(new Date(data.expireAt));
      setTimeout(() => {
        setIsFetchingRate(false);
      }, 1000);
    } else if (response.response) {
      setIsFetchingRate(false);
      openSnackbar("error", "Currency pair not supported");
    } else if (response.request) {
      setIsFetchingRate(false);
    } else {
      setIsFetchingRate(false);
    }
  };

  const handleSetAssets = (type, asset) => {
    if (assetType === "assetOne") {
      setAsset1({ ...asset });
      handleSetSwapables(asset);
    } else if (assetType === "assetTwo") {
      setAsset2({ ...asset });
    }
  };

  const handlePickAssetNo = (type) => {
    setAssetType(type);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "asset1") {
      handleValidateMaxSwap(value.replace(/,/g, ""));
      handleValidateMinSwap(value.replace(/,/g, ""));
      setAsset1Qty(value.replace(/,/g, ""));
      handleAsset2Calc(value.replace(/,/g, ""), rate);
    } else if (name === "asset2") {
      setAsset2Qty(value.replace(/,/g, ""));
      handleAsset1Calc(value.replace(/,/g, ""), rate);
    }
  };

  const handleAsset2Calc = (value, rate) => {
    if (asset1?.type === "FIAT") {
      setAsset2Qty((value / rate?.rate).toFixed(8));
    } else if (asset1?.type === "CRYPTO") {
      setAsset2Qty((value * rate?.rate).toFixed(2));
    }
  };

  const handleAsset1Calc = (value, rate) => {
    if (asset2?.type === "FIAT") {
      setAsset1Qty((value / rate?.rate).toFixed(8));
    } else if (asset2?.type === "CRYPTO") {
      setAsset1Qty((value * rate?.rate).toFixed(2));
    }
  };

  const handleCalculation = (asset1, asset2, rate) => {
    if (asset1.type === "FIAT") {
      setAsset2Qty((asset1Qty / rate.rate).toFixed(8));
    } else if (asset1.type === "CRYPTO") {
      setAsset2Qty((asset1Qty * rate.rate).toFixed(2));
    }
  };

  const handleValidateMinSwap = (value) => {
    if (value < asset1?.min_swap) {
      setMinSwapLimitErrors({ ...minSwapLimitErrors, min: true });
    } else if (value >= asset1?.min_swap) {
      delete minSwapLimitErrors.min;
    } else {
    }
  };

  const handleValidateMaxSwap = (value) => {
    if (value > asset1?.max_swap) {
      setMaxSwapLimitErrors({ ...maxSwapLimitErrors, max: true });
    } else if (value <= asset1?.max_swap) {
      delete maxSwapLimitErrors.max;
    } else {
    }
  };

  const handleSwap = async () => {
    if (asset1Qty > asset1?.balance + asset1?.liquidity_balance) {
      openSnackbar("error", "you have exceeded your balance");
    } else {
      if (asset1Qty >= asset1?.min_swap) {
        if (asset1Qty > asset1?.max_swap) {
          openSnackbar(
            "error",
            `Swap Maximum is ${asset1?.max_swap?.toLocaleString()}  ${
              asset1.symbol
            }`
          );
        } else {
          delete errorMessage.limit;

          swap({
            from_currency: asset1.code,
            to_currency: asset2.code,
            from_amount: parseFloat(asset1Qty),
            to_amount: 0,
            transaction_type: "swap",
          });

       
        }
      } else {
        openSnackbar(
          "error",
          `Swap Minimum is ${asset1?.min_swap?.toLocaleString()}  ${
            asset1?.symbol
          }`
        );
      }
    }
  };

  const handleRefreshTimer = () => {
    handleGetRate(asset1, asset2);
  };

  const handleRefreshSwap = async () => {
    refreshSwap({
      reference: swapDetails.reference,
    });

  
  };

  const handleSwitch = () => {
    const tempAsset1 = { ...asset1 };
    const tempAsset2 = { ...asset2 };
    const tempQty1 = asset1Qty;
    const tempQty2 = asset2Qty;
    setAsset1(tempAsset2);
    setAsset2(tempAsset1);
    setAsset1Qty(tempQty2);
    setAsset2Qty(tempQty1);
  };

  const handleSetSwapables = (asset) => {
    const selectedSwapable = currencies?.swapables?.find((swapable) => {
      return swapable.currency === asset.code;
    });
    if (asset.type === "CRYPTO") {
      setAsset2(selectedSwapable.swapable.fiat[0]);
    } else if (asset.type === "FIAT") {
      setAsset2(selectedSwapable.swapable.crypto[0]);
    }
    setSwapablesTwo(selectedSwapable?.swapable);
  };

  const handleConfirmSubmitSwap = async () => {
    confirmSwap({
      reference: swapDetails.reference,
    });
 
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.swap}
    >
      <div className={styles.swap_heading}>
        <Typography>Swap</Typography>
      </div>
      <div className={styles.swap_container}>
        <div className={styles.send_tab}>
          <div className={styles.tab_heading}>
            <Typography>You sell </Typography>
          </div>

          <div className={styles.input_box}>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={asset1Qty}
              decimalSeparator="."
              displayType="input"
              type="text"
              name={"asset1"}
              thousandSeparator={true}
              onChange={handleChange}
              placeholder="0.00"
              allowNegative={false}
            />

            <NewSelect
              currencies={currencies}
              handleAction={() => {
                handlePickAssetNo("assetOne");
                toggle("choose-assets");
              }}
              name_src={asset1?.code}
              img_src={asset1?.code}
            />
          </div>

          <Typography
            style={{ color: asset1?.balance < asset1?.min_swap && "red" }}
            variant="h6"
          >
            Balance:{" "}
            {parseAmount(asset1?.balance, asset1?.type)}
           
          </Typography>
        </div>

        <div
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            handleSwitch();
          }}
        >
          <img src={swap_icon} alt="swap_icon" width="100%" />
        </div>
        <div className={styles.recieve_tab}>
          <div className={styles.tab_heading}>
            <Typography>{"You receive"}</Typography>
          </div>
          <div className={styles.input_box}>
            {/* <input type="text"     placeholder="0.00"   value={Number(asset2Qty)?.toLocaleString()} readOnly /> */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={asset2Qty}
              decimalSeparator="."
              displayType="span"
              type="text"
              name={"asset2"}
              thousandSeparator={true}
              onChange={handleChange}
              placeholder="0.00"
              allowNegative={false}
            />

            <NewSelect
              currencies={currencies}
              handleAction={() => {
                handlePickAssetNo("assetTwo");
                toggle("choose-assets-two");
              }}
              name_src={asset2?.code}
              img_src={asset2?.code}
            />
          </div>
        </div>
        <hr style={{ border: ".3px solid #DDDDDD" }} />
        <div className={styles.rate}>
          <Typography style={{ color: minSwapLimitErrors.min ? "red" : null }}>
            Swap Minimum: {asset1?.code}{" "}
            {parseAmount(asset1?.min_swap,asset1?.type)}
           
          </Typography>
          <Typography style={{ color: maxSwapLimitErrors.max ? "red" : null }}>
            Swap Maximum: {asset1?.code}{" "}
            {parseAmount(asset1?.max_swap, asset1?.type)}
          
          </Typography>

          <Typography>
            Est. Price: {parseAmount(rate?.rate, 'FIAT')} {rate?.fiat} / {rate?.asset}
          </Typography>

          {timer.sec === 0 ? (
            <Typography style={{ color: "red" }} className={styles.new_rate}>
              Rate expired, click to get new rate
            </Typography>
          ) : (
            <Typography className={styles.new_rate}>
              Please swap within the time, (<strong>{timer.sec}s</strong>){" "}
            </Typography>
          )}
        </div>
      </div>
      <div className={styles.swap_btn}>
        <Button
          handleAction={() => {
            timer.sec === 0 ? handleRefreshTimer() : handleSwap();
          }}
          text={`${
            timer.sec === 0
              ? "Click To Get New Rate"
              : `Preview Swap (${timer.sec}s)`
          }`}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type={"click"}
          borderRadius={"5px"}
          loading={isFetchingRate || isLoadingSwap}
        />
      </div>
      {isShowingChooseAssets && (
        <AppBackdrop
          handleCloseModal={() => {
            toggle("choose-assets");
          }}
          openModal={isShowingChooseAssets}
          child={
            <ModalFrame
              handleAction={() => {
                toggle("choose-assets");
              }}
              style={modalFrameStyle}
              child={
                <ChooseAssets
                  cryptoCurrencies={cryptoCurrencies}
                  fiatCurrencies={fiatCurrencies}
                  title="Choose an asset"
                  handleSetAssets={handleSetAssets}
                  currencies={currencies}
                  handleClose={() => {
                    toggle("choose-assets");
                  }}
                />
              }
            />
          }
        />
      )}
      {isShowingChooseAssetsTwo && (
        <AppBackdrop
          handleCloseModal={() => {
            toggle("choose-assets-two");
          }}
          openModal={isShowingChooseAssetsTwo}
          child={
            <ModalFrame
              handleAction={() => {
                toggle("choose-assets-two");
              }}
              style={modalFrameStyle}
              child={
                <ChooseAssetsTwo
                  cryptoCurrencies={cryptoCurrencies}
                  fiatCurrencies={fiatCurrencies}
                  title="Choose an asset"
                  handleSetAssets={handleSetAssets}
                  currencies={currencies}
                  swapables={swapablesTwo}
                  handleClose={() => {
                    toggle("choose-assets-two");
                  }}
                />
              }
            />
          }
        />
      )}

      {opened.some((item) => item === "confirm-order") && (
        <AppBackdrop
          handleCloseModal={() => {}}
          openModal={opened.some((item) => item === "confirm-order")}
          child={
            <ModalFrame
              handleAction={() => {
                handleCloseModal("confirm-order");
              }}
              style={{ height: "auto", width: 545 }}
              child={
                <ConfirmOrder
                  currencies={currencies}
                  loadingConfirmSwap={isLoadingConfirmSwap}
                  handleConfirmSubmitSwap={handleConfirmSubmitSwap}
                  handleRefreshSwap={handleRefreshSwap}
                  refreshingSwap={isLoadingRefreshSwap}
                  swapDetails={swapDetails}
                  timer={timer}
                />
              }
            />
          }
        />
      )}
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </div>
  );
};

export default Swapp;
