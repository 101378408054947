import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Styles, ListStyles } from "./styles";
import logo from "../../../assets/images/logo-light.svg";
import { generateID } from "../../../lib/generateID";
import { Flex, Frame } from "../box";
import arrow_down from "../../../assets/images/arrow-down.svg";
import {
  DashboardIcon,
  BalanceIcon,
  TransactionIcon,
  SettingsIcon,
  WithdrawIcon,
  WalletsIcon,
} from "../../../assets";
import React, { useContext, useEffect } from "react";
import { DataCentral } from "../../../context/AppContext";
import { CircularProgress, Tooltip, Typography, Zoom } from "@mui/material";
import Switch from "../switch";
import usePermission from "../../../customHooks/usePermissions";

const Drawer = ({ loading, switching, handleSwitchChange, mode , isRefetchingUser}) => {
  let path = useLocation().pathname;

  const {
    openSettingsDropDown,
    setOpenSettingsDropDown,
    settingsTabs,
    transactionsTabs,
    walletsTabs,
    openWalletsDropDown,
    setOpenWalletsDropDown,
    openTransactionDropDown,
    setOpenTransactionDropDown,
  } = useContext(DataCentral);
  const { checkPermission } = usePermission();
  
  useEffect(() => {
    if (path.includes("transaction")) {
      if(path.includes('wallets')){
        setOpenTransactionDropDown(false)
      }else {
        setOpenTransactionDropDown(true);
        setOpenSettingsDropDown(false);
      }

    } else if (path.includes("settings")) {
      setOpenSettingsDropDown(true);
      setOpenTransactionDropDown(false);
    } else if (path.includes("wallets")) {
      setOpenWalletsDropDown(true);
      setOpenSettingsDropDown(false);
      setOpenTransactionDropDown(false);
    } else {
      setOpenTransactionDropDown(false);
      setOpenSettingsDropDown(false);
      setOpenWalletsDropDown(false);
    }
  }, [path, setOpenSettingsDropDown, setOpenTransactionDropDown]);

  const handleToggleSettingsContent = () => {
    setOpenTransactionDropDown(false);
    setOpenWalletsDropDown(false);
    setOpenSettingsDropDown(!openSettingsDropDown);
  };

  const handleToggleTransactionsContent = () => {
    setOpenSettingsDropDown(false);
    setOpenWalletsDropDown(false);
    setOpenTransactionDropDown(!openTransactionDropDown);
  };

  const handleToggleWalletsContent = () => {
    setOpenTransactionDropDown(false);
    setOpenSettingsDropDown(false);
    setOpenWalletsDropDown(!openWalletsDropDown);
  };
  return (
    <Styles className="App">
      <div className="drawer">
        <div className="logo_links">
          <Frame className="logo_box" height={"100px"} width={"200px"}>
            <img alt={"logo"} src={logo} />
          </Frame>
          <ul>
            <ListStyles active={path === "/dashboard"} key={generateID(11)}>
              <Link to={"/dashboard"}>
                <Flex className="link_container" justifyContent="flex-start">
                  <span className="icon_container">
                    <DashboardIcon width="14px" height="14px" />
                  </span>
                  <span className="drawerText">Dashboard</span>
                </Flex>
              </Link>
            </ListStyles>
            <ListStyles active={path === "/balances"}>
              <Link to={"/balances"}>
                <Flex className="link_container" justifyContent="flex-start">
                  <span className="icon_container">
                    <BalanceIcon width="14px" height="14px" />
                  </span>
                  <span className="drawerText">Balances</span>
                </Flex>
              </Link>
            </ListStyles>

            {JSON.parse(process.env.REACT_APP_WALLET_STATE) 
              ? checkPermission("wallets") && (
                  <ListStyles active={path.includes(`wallets`)}>
                    <Link
                    
                      to={"/wallets"}
                    >
                      <Flex
                        className="link_container"
                        alignItems="center"
                        justifyContent="flex-start"
                        onClick={() => {
                          handleToggleWalletsContent();
                        }}
                      >
                        <span className="icon_container">
                          <WalletsIcon width="14px" height="14px" />
                        </span>
                        <span className="drawerText">Wallets</span>
                        {/* <span
                          className={
                            openWalletsDropDown
                              ? "link_arrow_active"
                              : "link_arrow"
                          }
                        >
                          <img src={arrow_down} alt="" />
                        </span> */}
                      </Flex>
                      {/* <div
                        className={`settings_content  ${
                          openWalletsDropDown
                            ? "settings_content_active"
                            : "settings_content_disabled"
                        }`}
                      >
                        {walletsTabs.map((wallet) => {
                          return (
                            <Link
                              key={wallet.label}
                              to={`/wallets/${wallet.label}`}
                              className={
                                path.includes(wallet.label)
                                  ? "sublink_active"
                                  : null
                              }
                            >
                              {wallet.label}
                            </Link>
                          );
                        })}
                      </div> */}
                    </Link>
                  </ListStyles>
                )
              : null}

            {JSON.parse(process.env.REACT_APP_LIQUIDITY_STATE)
              ? checkPermission("liquidity") && (
                  <ListStyles active={path.includes("/liquidity")}>
                    <Link to={"/liquidity"}>
                      <Flex
                        className="link_container"
                        justifyContent="flex-start"
                      >
                        <span className="icon_container">
                          <WithdrawIcon width="14px" height="14px" />
                        </span>
                        <span className="drawerText">Liquidity</span>
                      </Flex>
                    </Link>
                  </ListStyles>
                )
              : null}

            <ListStyles active={path.includes(`transaction`) && !path.includes('wallets')}>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                }}
                to={""}
              >
                <Flex
                  className="link_container"
                  alignItems="center"
                  justifyContent="flex-start"
                  onClick={() => {
                    handleToggleTransactionsContent();
                  }}
                >
                  <span className="icon_container">
                    <TransactionIcon width="14px" height="14px" />
                  </span>
                  <span className="drawerText">Transactions</span>
                  <span
                    className={
                      openTransactionDropDown
                        ? "link_arrow_active"
                        : "link_arrow"
                    }
                  >
                    <img src={arrow_down} alt="" />
                  </span>
                </Flex>
                <div
                  className={`settings_content  ${
                    openTransactionDropDown
                      ? "settings_content_active"
                      : "settings_content_disabled"
                  }`}
                >
                  {transactionsTabs.map((transaction) => {
               
                    return (
                      <Link
                        key={transaction.label}
                        to={`/transactions/${transaction.label}`}
                        className={
                          path.includes(transaction.label)
                            ? "sublink_active"
                            : null
                        }
                      >
                        {transaction.label}
                      </Link>
                    );
                  })}
                </div>
              </Link>
            </ListStyles>
            <ListStyles active={path.includes("settings")}>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                }}
                to={""}
              >
                <Flex
                  className="link_container"
                  alignItems="center"
                  justifyContent="flex-start"
                  onClick={() => {
                    handleToggleSettingsContent();
                  }}
                >
                  <span className="icon_container">
                    <SettingsIcon Icon width="14px" height="14px" />
                  </span>
                  <span className="drawerText">Settings</span>
                  <span
                    className={
                      openSettingsDropDown ? "link_arrow_active" : "link_arrow"
                    }
                  >
                    <img src={arrow_down} alt="" />
                  </span>
                </Flex>
                <div
                  className={`settings_content  ${
                    openSettingsDropDown
                      ? "settings_content_active"
                      : "settings_content_disabled"
                  }`}
                >
                  {settingsTabs.map((setting) => {
                    return (
                      checkPermission(
                        `settings.${setting.label.toLowerCase()}`
                      ) && (
                        <Link
                          onClick={(e) => {
                            // e.preventDefault()
                            //handleToggleSettingsContent();
                          }}
                          key={setting.label}
                          to={`/settings/${setting.label}`}
                          className={
                            path.includes(setting.label)
                              ? "sublink_active"
                              : null
                          }
                        >
                          {setting.label}
                        </Link>
                      )
                    );
                  })}
                </div>
              </Link>
            </ListStyles>
          </ul>
        </div>
        <div className="status_div">
          {loading ? null : (
            <div className="status">
              <Switch
                initialState={mode === "test" ? false : true}
                color={"#5866E2"}
                switched={mode === "test" ? false : true}
                handleSwitchChange={handleSwitchChange}
              />
              {(switching || isRefetchingUser) ? (
                <CircularProgress
                  className="circular_icon"
                  sx={{ height: "10px", width: "10px" }}
                />
              ) : (
                <Typography>{mode} mode</Typography>
              )}
              <Tooltip
                title={`You are using Treasura in ${
                  mode === "test" ? "test" : "live"
                } mode`}
                placement="top"
                TransitionComponent={Zoom}
                followCursor
                arrow
              >
                <div className={"info_icon"}>
                  {" "}
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill={mode === "test" ? "#FFE9BD" : "#3AB75D"}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 0C2.91037 0 0 2.91037 0 6.5C0 10.0896 2.91037 13 6.5 13C10.0896 13 13 10.0896 13 6.5C13 2.91037 10.0896 0 6.5 0ZM5.6875 5.6875C5.6875 5.47201 5.7731 5.26535 5.92548 5.11298C6.07785 4.9606 6.28451 4.875 6.5 4.875C6.71549 4.875 6.92215 4.9606 7.07452 5.11298C7.2269 5.26535 7.3125 5.47201 7.3125 5.6875V9.75C7.3125 9.96549 7.2269 10.1722 7.07452 10.3245C6.92215 10.4769 6.71549 10.5625 6.5 10.5625C6.28451 10.5625 6.07785 10.4769 5.92548 10.3245C5.7731 10.1722 5.6875 9.96549 5.6875 9.75V5.6875ZM6.5 4.0755C6.39327 4.07547 6.2876 4.05443 6.18901 4.01356C6.09042 3.97269 6.00084 3.91281 5.92539 3.83732C5.84994 3.76184 5.7901 3.67223 5.74929 3.57362C5.70847 3.47501 5.68747 3.36932 5.6875 3.26259C5.68753 3.15587 5.70857 3.05019 5.74944 2.9516C5.79031 2.85301 5.85019 2.76343 5.92568 2.68799C6.00116 2.61254 6.09077 2.5527 6.18938 2.51188C6.28799 2.47106 6.39368 2.45007 6.50041 2.45009C6.71595 2.45015 6.92264 2.53582 7.07501 2.68827C7.22739 2.84072 7.31296 3.04746 7.31291 3.263C7.31285 3.47854 7.22718 3.68524 7.07473 3.83761C6.92228 3.98998 6.71554 4.07555 6.5 4.0755Z"
                      fill={``}
                    />
                  </svg>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
};

export default Drawer;
