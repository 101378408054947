import React, { useContext } from "react";
import BlueBackground from "../../../../UI-components/blueBg/BlueBg";
import styles from "./Security.module.css";
import SecurityMain from "./components/securityMain/SecurityMain";
import ActivityManager from "./components/activityManager/ActivityManager";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import SignedWithdrawals from "./components/signedWithdrawals/SignedWithdrawals";
import { DataCentral } from "../../../../../context/AppContext";
import Loader from "../../../../UI-components/loader/Loader";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";

const Security = ({ activeSecurityTab }) => {
  const { loadingUser, user } = useContext(DataCentral);
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  return (
    <SettingsLayout
      tab="security"
      child={
        <div className={styles.security}>
        
            <>
              <BlueBackground
                content={
                  <SecurityMain
                    user={user}
                    loadingUser={loadingUser}
                    openSnackbar={openSnackbar}
                  />
                }
              />
              {user?.own_merchant && (
                <BlueBackground
                  content={<SignedWithdrawals openSnackbar={openSnackbar} />}
                />
              )}

              <BlueBackground content={<ActivityManager />} />
            </>
          

          <SwapstaSnackbar
            open={openToast}
            message={message}
            severity={severity}
            setOpen={setOpenToast}
            duration={duration}
          />
        </div>
      }
    />
  );
};

export default Security;
