import axios from "axios";

export const GET_OVERDRAFT = async (data) => {

    return axios
      .get(`${process.env.REACT_APP_BASE_URL}liquidity`)
      .then((res) => {
        return res.data;
      });
  };

  export const GET_SINGLE_OVERDRAFT = async (data) => {

    return axios
      .get(`${process.env.REACT_APP_BASE_URL}liquidity/${data.queryKey[1]}`)
      .then((res) => {
        return res.data.data;
      });
  };