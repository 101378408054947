import { Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./DepositMethod.module.css";
import rightIcon from "../../../../../../assets/images/arrow-right.svg";
import HeadingOne from "../../../../../UI-components/headings/headingsOne/HeadingsOne";
import DepositCrypto from "../depositCrypto/DepositCrypto";
import Deposit from "../deposit/Deposit";
import DepositSwapPay from "../depositSwapPay/DepositSwapPay";
import DepositSuccessful from "./deposit-successful/DepositSuccessful";
import VerifyPayment from "./verify-payment/VerifyPayment";
import DepositForm from "./depositForm/DepositForm";
import BinancePayEnable from "./prompt/BinancePayEnable/BinancePayEnable";
import DynamicDeposit from "./dynamicDeposit/DynamicDeposit";
import DynamicDepositPending from "./dynamicDeposit/modals/DynamicDepositPending";

const DepositMethod = ({
  binancePay,
  handleGetBalances,
  handleClose,
  title,
  currency,
  availableDepositMethods,
  openSnackbar,
}) => {
  const [screen, setScreen] = useState("");
  const [requirements, setRequirements] = useState([]);
  const [method, setMethod] = useState({});
  const [fees, setFees] = useState("");

  const handleScreen = (e) => {
    setScreen(e);
  };

  const handleMethod = (method) => {
    setMethod(method);
    setRequirements(method.requirements);

    const selectedFee = method?.fees.find((fee) => {
      if (fee.currency === currency.code) {
        return fee;
      } else {
        return null;
      }
    });

    setFees(selectedFee);
  };


  switch (screen) {
    case "bank":
      return (
        <Deposit
          currency={currency}
          method={method}
          handleScreen={handleScreen}
        />
      );
    case "dynamic-bank-deposit":
      return (
        <DynamicDeposit
          openSnackbar={openSnackbar}
          fees={fees}
          currency={currency}
          method={method}
          handleScreen={handleScreen}
        />
      );
    case "dynamic-bank-pending-deposit":
      return <DynamicDepositPending handleClose={handleClose} />;
    case "mobile-money":
      return (
        <Deposit
          currency={currency}
          method={method}
          handleScreen={handleScreen}
        />
      );
    case "deposit-form":
      return (
        <DepositForm
          setRequirements={setRequirements}
          currency={currency}
          method={method}
          requirements={requirements}
          handleScreen={handleScreen}
        />
      );

    case "crypto":
      return (
        <DepositCrypto
          method={method}
          handleScreen={handleScreen}
          currency={currency}
        />
      );
    case "binance-pay":
      return <BinancePayEnable handleScreen={handleScreen} />;

    case "verify":
      return (
        <VerifyPayment
          requirements={requirements}
          openSnackbar={openSnackbar}
          handleGetBalances={handleGetBalances}
          handleClose={handleClose}
          method={method}
          currency={currency}
        />
      );
    case "swapPay":
      return <DepositSwapPay handleMethod={handleMethod} />;
    case "success":
      return <DepositSuccessful />;
    default:
      return (
        <DepositMethodList
          currency={currency}
          binancePay={binancePay}
          availableDepositMethods={availableDepositMethods}
          handleMethod={handleMethod}
          handleScreen={handleScreen}
          title={title}
        />
      );
  }
};

export default DepositMethod;

const DepositMethodList = ({
  binancePay,
  title,
  availableDepositMethods,
  handleMethod,

  handleScreen,
}) => {

 
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.deposit}
    >
      <HeadingOne
        title={title}
        description={"Choose a payment method to complete your deposit"}
      />
      {availableDepositMethods?.map((method) => {
        return (
          <div
            key={method.id}
            className={styles.deposit_method}
            onClick={(e) => {
              //handlePickMethod(method.requirements);
              handleMethod(method);

              //run check if user has activated binance pay
              method.slug === "binance-pay"
                ? binancePay.status
                  ? handleScreen("deposit-form")
                  : handleScreen(method.model)
                : handleScreen(
                    method.slug === "dynamic-bank-deposit"
                      ? "dynamic-bank-deposit"
                      : method.model
                  );
            }}
          >
            <div className={styles.deposit_method_item}>
              <img src={method.icon} alt="icon" />
              <div className={styles.deposit_method_item_text}>
                <Typography variant="h6">{method.name}</Typography>
                <Typography>{method.description}</Typography>
              </div>
              <img src={rightIcon} alt="swap pay icon" />
            </div>
          </div>
        );
      })}
    </div>
  );
};
