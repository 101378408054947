import { Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import GoBack from "../../../../UI-components/goBack/GoBack";
import Layout from "../../../../UI-components/layouts";
import { Styles } from "../styles";
import WalletCompliance from "../walletAccounts/components/compliance/WalletCompliance";
import styles from "./WalletCompliance.module.css";

function WalletCompliancePage() {
  const { state } = useLocation();

  return (
    <Layout title={"Compliance"} bgColor={"#ffffff"}>
      <Styles>
        <GoBack />
        <div className={styles.heading}>
          <Typography>Compliance </Typography>
        </div>
        <div>
          <WalletCompliance compliance={state?.compliance} />
        </div>
      </Styles>
    </Layout>
  );
}

export default WalletCompliancePage;
