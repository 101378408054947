import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import HeadingOne from "../headings/headingsOne/HeadingsOne";
import LabeledInputs from "../new-inputs/inputs/LabeledInputs";
import styles from "./DownloadReport.module.css";
import Button from "../button/index";
import { useForm } from "react-hook-form";
import ValidationMessage from "../validation-message/ValidationMessage";
import LabeledSelectTwo from "../select/labeledSelectTwo/LabeledSelect";
import axios from "axios";
import SwapstaSnackbar from "../AppSnackbars/SwapstaSnackbar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useOpenSnackbar from "../../../customHooks/useOpenSnackbar";
import { DOWNLOAD_REPORT } from "../../../api/queries/dashboard/transactions";

const DownloadReport = ({ type, exportReportData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const [downloading, setDownloading] = useState(false);

  const { mutate, isLoading } = useMutation(DOWNLOAD_REPORT, {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
  
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(`download`, `report.csv`);
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
      openSnackbar("success", "Download successful");
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });
  const onSubmit = async (data) => {
    if (type === "transaction") {
      setDownloading(true);
      handleTrxDownload(data);
    } else if (type === "liquidity") {
      setDownloading(true);
      handleLiquidityDownload(data);
    }
  };

  const handleLiquidityDownload = (data) => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}liquidity/${exportReportData.id}/reports?from=${data.from}&to=${data.to}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(`download`, `report.${data.file_type.toLowerCase()}`);
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
        openSnackbar("success", "Download successful");
      })
      .catch((error) => {
        setDownloading(false);
        if (error.response) {
          openSnackbar("error", "something went wrong please try again");
        } else if (error.request) {
          openSnackbar("error", "something went wrong please try again");
        } else {
          openSnackbar("error", "something went wrong please try again");
        }
      });
  };

  const handleTrxDownload = (data) => {
    mutate(data);
 
  };
  const fileTypes = [
    {
      label: "CSV",
      value: "CSV",
      id: 1,
    },
    // {
    //   label: "PDF",
    //   value: 'PDF',
    //   id: 2,
    // },
  ];

  const trxType = [
    {
      label: "All",
      value: "ALL",
      id: 1,
    },
    {
      label: "Swap",
      value: "Swap",
      id: 2,
    },
    {
      label: "Deposit",
      value: "Deposit",
      id: 3,
    },
    {
      label: "Withdrawal",
      value: "Withdrawal",
      id: 4,
    },
    {
      label: "Refunded",
      value: "Refunded",
      id: 5,
    },
  ];

  return (
    <form
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.download_report}
    >
      <HeadingOne title={"View Report"} />
      <div className={styles.form_field}>
        <LabeledSelectTwo
        required={true}
          label="Select file type"
          register={register}
          placeholder={"File type"}
          name={"file_type"}
          redOutline={errors.file_type ? true : false}
          data={fileTypes}
        />
        <ValidationMessage
          message={errors.file_type ? "File type is required" : null}
        />
      </div>
      {type === "transaction" && (
        <div className={styles.form_field}>
          <LabeledSelectTwo
          required={true}
            label="Select transaction type"
            register={register}
            placeholder={"Transaction type"}
            name={"transaction_type"}
            redOutline={errors.transaction_type ? true : false}
            data={trxType}
          />
          <ValidationMessage
            message={
              errors.transaction_type ? "Transaction type is required" : null
            }
          />
        </div>
      )}

      <div className={styles.text}>
        <Typography>Choose a timeframe for statement</Typography>
      </div>
      <div className={styles.input_boxes}>
        <Grid container>
          <Grid item xs={4}>
            <LabeledInputs
              placeholder={"DD/MM/YY"}
              label={"From"}
              redOutline={errors.from ? true : false}
              register={register}
              name={"from"}
              type={"date"}
              required={true}
            />
            <ValidationMessage
              message={errors.from ? "From is required" : null}
            />
          </Grid>
          <Grid item xs={4}>
            <hr
              style={{
                border: "1px solid #777777",
                width: "38px",
                marginTop: "48px",
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <LabeledInputs
              placeholder={"DD/MM/YY"}
              label={"To"}
              redOutline={errors.to ? true : false}
              register={register}
              name={"to"}
              type={"date"}
              required={true}
            />
            <ValidationMessage message={errors.to ? "To is required" : null} />
          </Grid>
        </Grid>
      </div>
      <div className={styles.btn}>
        <Button
          text={"Export Report"}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          fullwidth
          size="default"
          type="button"
          borderRadius={"5px"}
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        />
      </div>
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </form>
  );
};

export default DownloadReport;
