import axios from "axios";
import { endpointFilterParserStart } from "../../../../utils/helpers";



export const GET_BALANCES = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}wallet/balance`)
    .then((res) => {
      return res.data;
    });
};

export const GET_DEPOSIT_METHODS = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}transactions/deposit-methods`)
    .then((res) => {
      return res.data;
    });
};



export const GET_WITHDRAWAL_METHODS = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}transactions/withdrawal-methods`)
    .then((res) => {
      return res.data;
    });
};



export const CHECK_BINANCE_PAY = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}transactions/deposit-methods/binance-pay/sub-merchant/check`)
    .then((res) => {
      return res.data;
    });
};

export const GET_MCC = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}transactions/deposit-methods/binance-pay/mcc`)
    .then((res) => {
      return res.data;
    });
};



export const GET_REQUIREMENTS = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}transactions/deposit-methods/${data.queryKey[2]}/${data.queryKey[1]}${endpointFilterParserStart(data.queryKey[3], 'amount')}`)
    .then((res) => {
      return res.data;
    });
};