import styled from 'styled-components';

export const Styles = styled.div`
	/* background-color: #fff; */
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding: 30px 30px 0px;
	@media (max-width: 700px) {
		padding: 20px 4%;
	}
`;

export const Styled = styled.div`
	// min-width: 1200px;
	// height: 100%;
	// overflow-x: scroll;
`;
