import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from './ValidationMessage.module.css'

const ValidationMessage = ({message}) => {
    const [formattedMessage, setFormattedMessage] = useState('')


    useEffect(()=>{
        formatString(message)
    }, [message])


    const formatString=(message)=>{
        if(message){
            const m = message.replace('_', ' ')
            setFormattedMessage(m.replace(m[0],  m[0].toUpperCase()))
        }else{
            setFormattedMessage('')
        }
    }

    return ( 
        <div className={styles.validation_message}>
            <Typography>
                {formattedMessage}
            </Typography>
        </div>
     );
}
 
export default ValidationMessage;