import { useContext} from "react";
import { DataCentral } from "../context/AppContext";

const usePermission = () => {
  const { user} = useContext(DataCentral);

  const checkPermission = (entity) => {
  
      if (user?.role?.id === "user-owner") {
        return true;
      } else {
        return user?.role?.permitions?.some((permission) => {
          return permission === entity;
        });
      }
    
  };

  return {
    checkPermission,
  };
};

export default usePermission;
