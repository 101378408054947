import { useQuery } from "@tanstack/react-query";
import {GET_COUNTRIES} from './../api/queries/dashboard/misc/index'

const useGetCountries = () => {
  const {
    data: countries,
    isLoading,
  } = useQuery(["countries"], GET_COUNTRIES, {
    refetchOnWindowFocus: false,
  });

  return {
   countries: countries,
    isLoadingCountries: isLoading
  };
};

export default useGetCountries;
