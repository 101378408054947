import React, { useEffect, useRef, useState } from "react";
import styles from "./VerifyPayment.module.css";
import { Grid, Typography } from "@mui/material";
import LabeledInput from "../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import { Link } from "react-router-dom";
import Button from "../../../../../../UI-components/button/index";
import FileBox from "../../../../../../UI-components/new-inputs/fileBox/FileBox";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";
import LabeledInputsWithText from "../../../../../../UI-components/new-inputs/labeledInputsWithTexts/LabeledInputsWithTexts";
import { useMutation } from "@tanstack/react-query";
import { VERIFY_PAYMENT } from "../../../../../../../api/mutations/dashboard/balance";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifyPaymentSchema } from "../../../../../../../schemas/dashboard/deposit";

const VerifyPayment = ({
  requirements,
  handleClose,
  handleGetBalances,
  currency,
  method,
  openSnackbar,
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(verifyPaymentSchema),
  });

  const navigate = useNavigate();


  const payment_proof = watch("payment_proof", false); // you can supply default value as second argument

  useEffect(()=>{
    setValue('slug', method?.slug)
  }, [])


  const {
    mutate: mutateBinancePay,
    isLoading: isLoadingBinancePayPayment,
    isSuccess,
  } = useMutation(VERIFY_PAYMENT, {
    onSuccess: (response) => {
      openSnackbar("success", response?.message);
      window.location.replace(response?.data?.universalUrl);
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message || 'Something went wrong, please try again');
    },
  });

  const { mutate, isLoading } = useMutation(VERIFY_PAYMENT, {
    onSuccess: (response) => {
      openSnackbar("success", response?.data?.message);
      handleClose();
      navigate(`/transaction/${response?.data?.id}`);
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message || 'Something went wrong, please try again');
    },
  });

 



  const onSubmit = (data) => {
    if (method?.slug === "binance-pay") {
      handleSubmitWithoutProof(data);
    } else {
      handleSubmitWithProof(data);
    }
  };

  const handleSubmitWithoutProof = async (data) => {
    const tempData = requirements.map((method) => {
      return {
        [method.name]: method.value,
      };
    });

    const _beneficiary = Object.assign({}, ...tempData);

    mutateBinancePay({
      amount: data.amount,
      reference: data.reference,
      currency: currency.code,
      deposit_method: method.slug,
      requirement: _beneficiary,
    });
  };

  const handleSubmitWithProof = async (data) => {
    let formData = new FormData();
    formData.append("amount", parseFloat(data.amount));
    formData.append("reference", data.reference);
    formData.append("currency", currency.code);
    formData.append("deposit_method", method.slug);
    formData.append("payment_proof", data?.payment_proof[0]);

    mutate(formData);
  };



  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.verify_payment}>
      <div className={styles.heading}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <div className={styles.heading_left}>
              <Typography>VERIFY PAYMENT</Typography>
            </div>
          </Grid>
          <Grid item sm={12} md={6}></Grid>
        </Grid>
      </div>
      <div className={styles.info}>
        <Typography>
          Kindly provide us with the details of your deposit for verification
          purposes
        </Typography>
      </div>
      <div className={styles.field}>
        <LabeledInputsWithText
          setValue={setValue}
          watch={watch}
          name="amount"
          label={"Amount"}
          text={currency?.code}
          type={"number"}
          redOutline={errors.amount ? true : false}
          register={register}
        />
        <ValidationMessage
          message={errors.amount ? "Amount is required" : null}
        />
      </div>

      {method?.slug === "binance-pay" ? null : (
        <>
          <div className={styles.field}>
            <LabeledInput
              name={"reference"}
              label={"Transaction reference/ID"}
              placeholder={"Paste transaction ref"}
              redOutline={errors.reference ? true : false}
              register={register}
              required={true}
              handleChangeAuto={true}
            />
            <ValidationMessage
              message={errors.reference ? "Reference is required" : null}
            />
          </div>

          <div className={styles.field}>
            <FileBox
              trigger={trigger}
              multi={false}
              file={payment_proof}
              setValue={setValue}
              label={"Payment upload"}
              name={"payment_proof"}
              watch={watch}
              register={register}
              errors={errors}
            />
            {/* <div className={styles.fileBox}>
              <Typography>Payment upload (Max file 500KB)</Typography>
              <div
                className={`${
                  errors.payment_proof ? styles.red_outline : null
                } ${styles.document_upload_input}`}
              >
                <div className={styles.document_upload_text}>
                  <Typography>{payment_proof}</Typography>
                </div>
                <input
                  name={"document-payment"}
                  onChange={handleChange}
                  ref={inputRef}
                  type="file"
                />
                <input
                  name={"payment_proof"}
                  {...register("payment_proof", { required: true })}
                  type="text"
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleFileUploadDialog("document-payment");
                  }}
                >
                  Choose document
                </button>
              </div>
              {proof ? (
                <Link
                  onClick={(e) => {
                    handleRemoveDocument(e, "document-payment");
                  }}
                  to="/"
                >
                  Remove document
                </Link>
              ) : (
                <ValidationMessage
                  message={
                    errors.payment_proof ? "Payment proof is required" : null
                  }
                />
              )}
            </div> */}
          </div>
        </>
      )}
      <Button
        Styles
        text={"Confirm"}
        bgColor={"#1A2CCE"}
        color={"#FFFFFF"}
        size="md"
        fullwidth
        type="submit"
        loading={isLoading || isLoadingBinancePayPayment || isSuccess}
        boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
        borderRadius={"5px"}
        // onClick={() => {
        //   //handleSubmit();
        //   //handleMethod("verify");
        // }}
      />
    </form>
  );
};

export default VerifyPayment;
