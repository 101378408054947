import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./TransactionRow.module.css";
import usdt_icon from "../../../../../assets/images/usdt.svg";
import ngn_icon from "../../../../../assets/images/ngn.svg";
import { useNavigate } from "react-router-dom";

import { getDate, getTime, parseAmount } from "../../../../../utils/helpers";
import Tooltip from "../../../tooltip/Tooltip";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { INITIATE_REFUND } from "../../../../../api/mutations/dashboard/transactions";

const TransactionRow = ({
  openSnackbar,
  openToolTip,
  handleOpenTooltip,
  currencies,
  data,
  transactionType,
}) => {
  const navigate = useNavigate();

  const [currencyType, setCurrencyType] = useState("");
  const [swapCurrencyType, setSwapCurrencyType] = useState("");
  const [refundSuccessfull, setRefundSuccessfull] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(INITIATE_REFUND, {
    onSuccess: (response) => {
      openSnackbar("success", response?.message);
      setRefundSuccessfull(true);
      queryClient.invalidateQueries(["deposit-trx"]);
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });

  const handleNavigateToTransaction = (id) => {
    navigate(`/transaction/${id}`);
  };

  useEffect(() => {
    currencies?.result?.map((currency) => {
      if (currency.code === data.to_currency) {
        setCurrencyType(currency.type);
      } else {
        return null;
      }
    });

    if (data.status === "refunded") {
      setRefundSuccessfull(true);
    }
  }, [data]);

  useEffect(() => {
    currencies?.result?.map((currency) => {
      if (currency.code === data.from_currency) {
        setSwapCurrencyType(currency.type);
      } else {
        return null;
      }
    });
  }, [data]);

  const handleRequestRefund = () => {
    mutate({
      id: data.id,
    });
  };

  return (
    <tr
      onClick={() => {
        handleNavigateToTransaction(data.id);
        // handleTooltipClose()
      }}
      className={styles.row}
    >
      <td>
        <div className={styles.td_div}>
          <div className={styles.td_div_images}>
            {data.type === "SWAP" && (
              <>
                <div className={styles.z_index}>
                  {currencies?.result?.map((currency) => {
                    if (currency.code === data.from_currency) {
                      return (
                        <img
                          key={currency.id}
                          src={currency.icon}
                          alt={`flag`}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className={styles.translate_back}>
                  {currencies?.result?.map((currency) => {
                    if (currency.code === data.to_currency) {
                      return (
                        <img
                          key={currency.id}
                          src={currency.icon}
                          alt={`flag`}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </>
            )}
            {data.type === "DEPOSIT" && (
              <div className={styles.z_index}>
                {currencies?.result?.map((currency) => {
                  if (currency.code === data.to_currency) {
                    return (
                      <img key={currency.id} src={currency.icon} alt={`flag`} />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}
            {data.type === "WITHDRAWAL" && (
              <div className={styles.z_index}>
                {currencies?.result?.map((currency) => {
                  if (currency.code === data.from_currency) {
                    return (
                      <img key={currency.id} src={currency.icon} alt={`flag`} />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}
          </div>
          <div className={styles.td_div_text}>
            {data.type === "SWAP" && (
              <Typography>
                {data.from_currency}-{data.to_currency}
              </Typography>
            )}
            {data.type === "DEPOSIT" && (
              <Typography>{data.to_currency}</Typography>
            )}
            {data.type === "WITHDRAWAL" && (
              <Typography>{data.from_currency}</Typography>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className={styles.td_div2}>
          <Typography variant="h6">{getDate(data.created_at)}</Typography>
          <Typography>{getTime(data?.created_at)}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.td_div3}>
          {data.type === "SWAP" && (
            <Typography>
              {" "}
              <span>{data.from_currency}</span>{" "}
              {parseAmount(data?.from_amount, swapCurrencyType)}
            </Typography>
          )}

          {data.type === "DEPOSIT" && (
            <Typography>
              {" "}
              <span>{data.to_currency}</span>{" "}
              {parseAmount(data?.to_amount, currencyType)}
            </Typography>
          )}

          {data.type === "WITHDRAWAL" && (
            <Typography>
              {" "}
              <span>{data.from_currency}</span>{" "}
              {parseAmount(data?.from_amount, currencyType)}
            </Typography>
          )}
        </div>
      </td>
      {data.type === "SWAP" && (
        <td>
          <div className={styles.td_div3}>
            <Typography>
              {" "}
              <span>{data.to_currency}</span>{" "}
              {parseAmount(data?.to_amount, currencyType)}
            </Typography>
          </div>
        </td>
      )}
      <td>
        <div className={styles.td_div4}>
          <Typography> {data.reference.substring(0, 15)}...</Typography>
        </div>
      </td>
      <td>
        <div className={styles.td_div5}>{data.channel}</div>
      </td>
      <td>
        {data.status === "pending" ? (
          <div className={`${styles.pending} ${styles.td_div6}`}>
            <Typography>{data.status}</Typography>
          </div>
        ) : null}
        {data.status === "success" ? (
          <div className={`${styles.success} ${styles.td_div6}`}>
            <Typography>{data.status}</Typography>
          </div>
        ) : null}
        {data.status === "failed" ? (
          <div className={`${styles.failed} ${styles.td_div6}`}>
            <Typography>{data.status}</Typography>
          </div>
        ) : null}
        {data.status === "refunded" ? (
          <div className={`${styles.failed} ${styles.td_div6}`}>
            <Typography>{data.status}</Typography>
          </div>
        ) : null}
      </td>
      {data.type === "DEPOSIT" && (
        <td>
          <Tooltip
            refundSuccessfull={refundSuccessfull}
            handleRequestRefund={handleRequestRefund}
            type="trx"
            data={data}
            handleOpenTooltip={handleOpenTooltip}
            openToolTip={openToolTip}
            loading={isLoading}
          />
        </td>
      )}
    </tr>
  );
};

export default TransactionRow;
