import React from 'react';
import Button from '../button';
import styles from './ModalFrame2.module.css';

const ModalFrame2 = ({
	child,
	style,
	handleAction,
	handleActionTwo,
	btnTxt,
	btnText2,
	btnLoading,
	btnColor
}) => {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
			style={style}
			className={styles.modal_frame2}
		>
			<div className={styles.modal_frame2_upper}>{child}</div>
			<div className={styles.modal_frame2_btns}>
				<Button
					onClick={() => {
						handleActionTwo();
					}}
					text={btnText2 || 'Cancel'}
					bgColor={'#FFFFFF'}
					color={'#000'}
					size='sm'
					type={'click'}
					borderRadius={'5px'}
				/>
				<Button
					onClick={() => {
						handleAction('success');
					}}
					text={btnTxt}
					bgColor={btnColor || '#1A2CCE'}
					color={'#fff'}
					size='sm'
					type={'click'}
					borderRadius={'5px'}
					loading={btnLoading}
				/>
			</div>
		</div>
	);
};

export default ModalFrame2;
