import * as yup from "yup";
import { extractFileType } from "../../../utils/helpers";
const fileTypes = ["pdf", "png", "jpg", "jpeg"];

const fileValidation = (name) => {
  return yup
    .mixed()
    .nullable("Personal document file is required")
    .test({
      name: "required",
      message: "Document is required",
      test: (value) => value?.length > 0,
    })
    .test({
      name: "fileSize",
      message: "file uploaded is larger then 500kb",
      test: (value) => {
        if (typeof value === "object") {
          return value[0]?.size < 500000;
        } else {
          return true;
        }
      },
    })
    .test({
      name: "fileType",
      message: "Only PDF, PNG, JPG & JPEG are allowed",
      test: (value) => {
        if (typeof value === "object") {
          return fileTypes.includes(extractFileType(value[0]?.name));
        } else {
          return true;
        }
      },
    });
};

export const depositAmountSchema = yup.object().shape({
  amount: yup.string().required("Amount is required"),
});

export const verifyPaymentSchema = yup.object().shape({
  slug: yup.string(),
  amount: yup.string().required("Amount is required"),
  reference: yup.string().when("slug", {
    is: "binance-pay",
    then: yup.string(),
    otherwise: yup.string().required("Reference type is required"),
  }),
  payment_proof: fileValidation(),
});
