import styled from 'styled-components';
import { Flex } from '../../UI-components/box';

export const Container1 = styled(Flex)`
	width: 100%;
	flex: 1;
	position: relative;
	// border: 0.7px solid #dfdfdf;
	// box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
`;

export const Container2 = styled(Flex)`
	flex: 1;
	max-width: 100%;
	overflow: hidden;
	margin: 0 0 0 auto;
	height: 46px;

	background: #ffffff;
	border: 1px solid #D7D7D7;
	box-sizing: border-box;
	border-radius: 3px;
`;
export const Container3 = styled(Flex)`
	/* border: 1px solid red; */
`;
export const CloseSaerch = styled(Flex)`
	position: absolute;
	cursor: pointer;
	top: -6px;
	right: -9px;
	border-radius: 100%;
	background: #00000030;
`;

export const SearchContainer = styled(Flex)`
	flex: 1;
	/* border: 1px solid red; */
`;

export const Input = styled.input`
	width: 100%;
	padding: 10px 15px 10px 0;
`;
