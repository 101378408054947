// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GoBack_go_back__vzDF5 {\n    display: flex;\n\n\n    \n}\n.GoBack_go_back_div__VtoBy {\n    cursor: pointer;\n    align-items: center;\n    display: flex;\n}\n.GoBack_go_back__vzDF5 p {\n    margin-left: 10px;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n}", "",{"version":3,"sources":["webpack://./src/components/UI-components/goBack/GoBack.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;;;AAIjB;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".go_back {\n    display: flex;\n\n\n    \n}\n.go_back_div {\n    cursor: pointer;\n    align-items: center;\n    display: flex;\n}\n.go_back p {\n    margin-left: 10px;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"go_back": "GoBack_go_back__vzDF5",
	"go_back_div": "GoBack_go_back_div__VtoBy"
};
export default ___CSS_LOADER_EXPORT___;
