import styled from "styled-components";
import { Flex } from "../../../../UI-components/box";
import bg from "../../../../../assets/images/exchange.svg";

export const Styles = styled.div`
  width: 100%;
  background-color: #f3f6fb;
  .grid_box {
    width: 100%;
    @media (max-width: 1100px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const Container1 = styled(Flex)`
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
  h2 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: right 70px top 45%;
  .form_container {
    width: 100%;
  }
  .option_div {
    display: flex;
    align-items: center;
  }
  .img_div {
    border-radius: 16px;
    padding: 5px;
    background-color: #E1E1E1;
    
  }
  .label {
    margin-left: 7px;
    font-size: 14px;
  }
`;

export const InputStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  width: 100%;
  .error_container {
    color: red;
    margin-top: 5px;
    font-size: 12px;
  }
  span {
    font-size: 12px;
    color: #1a1f36;
    margin-top: 10px;
    @media (max-width: 400px) {
      font-size: 8px;
    }
  }
  .refresh {
    color: #5e5e5e;
    display: flex;
    align-items: center;
    font-size: 12px;
    @media (max-width: 400px) {
      font-size: 8px;
    }
    p {
      margin-left: 5px;
      small {
        color: #1a2cce;
        font-size: 12px;
        @media (max-width: 400px) {
          font-size: 8px;
        }
      }
    }
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #000000;
`;

export const FieldWrapper = styled.div`
  background: #f3f6fb;
  /* border: 1px solid #d7d7d7; */
  border-radius: 10px;
  width: 100%;
  height: 78px;
  padding-right: 50px;
  background-color: #f3f6fb;

  position: relative;

  > div {
    position: absolute;
    right: 15px;
    top: 17px;
  }
  input {
    width: 100%;
    padding: 10px;
    color: #000000;
    height: 100%;
    font-size: 18px;
  }
`;

export const Container2 = styled(Flex)`
  /* border: 1px solid green; */
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
  h2 {
    font-size: 18px;
  }
`;
