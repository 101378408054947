import React from 'react';
import styles from './ModalFrameOverdarft.module.css';

const ModalFrameOverdarft = ({ child, style, handleAction }) => {
	return (
		<div className={styles.modal_frame}>
			<div onClick={handleAction} className={styles.modal_frame_close_div}>
				{/* <img src={close_icon} alt={close_icon} /> */}
			</div>
			<div style={style} className={styles.child_div}>
				{child}
			</div>
		</div>
	);
};

export default ModalFrameOverdarft;
