import { Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./MarketRate.module.css";

import { useQuery } from "@tanstack/react-query";
import { GET_MARKET_RATES } from "../../../../../api/queries/dashboard/home";

const MarketRate = ({
  tableHeadData,
  fiatCurrencies,
  currencies,
  rates,
  isLoading,
}) => {
  return (
    <div className={styles.market_rate}>
      <div className={styles.heading}>
        <Typography>Market rates</Typography>
      </div>
      {isLoading ? (
        <div className={styles.loaders}>
          {Array.from({ length: 7 }).map((item, index) => {
            return (
              <Skeleton variant="rectangular" width={"100%"} height={"40px"} />
            );
          })}
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              {tableHeadData.map((item, index) => {
                return <th key={index}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {rates?.data?.map((item, index) => {
              return (
                <tr className={styles.row} key={item.pair}>
                  <td>
                    <div className={styles.td_div}>
                      <div>
                        {currencies?.result?.map((currency) => {
                          if (currency.code === item?.buy?.fiat) {
                            return (
                              <img
                                key={currency.id}
                                src={currency.icon}
                                alt={`flag`}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                      <div className={styles.space}>
                        {currencies?.result?.map((currency) => {
                          if (currency.code === item?.buy?.asset) {
                            return (
                              <img
                                key={currency.id}
                                src={currency.icon}
                                alt={`flag`}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                      <Typography>{item?.pair}</Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.td_div2}>
                      <Typography>
                        {Number(item?.sell?.rate)?.toFixed(2)}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.td_div3}>
                      <Typography>
                        {Number(item?.buy?.rate)?.toFixed(2)}
                      </Typography>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MarketRate;
