import * as yup from "yup";
import { Link } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { Flex } from "../../../UI-components/box";
import Button from "../../../UI-components/button";
import { LoginText, FormContainer } from "./styles";
import { Form, Field, ErrorMessage, Formik } from "formik";
import { HiddenVisibilityIcon, PasswordEyeIcon } from "../../../../assets/";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../UI-components/input/styles";

import { DataCentral } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import { useMutation } from "@tanstack/react-query";
import { LOGIN } from "../../../../api/mutations/auth";

const Fields = ({ response }) => {
  const navigate = useNavigate();
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const [visibility, setVisibility] = useState(false);
  
  const { handleSignIn } = useContext(DataCentral);
  const initialValues = {
    email: "",
    password: "",
  };

  const { mutate, isLoading } = useMutation(LOGIN, {
    onSuccess: (response) => {
      const { access_token } = response.data;

      if (response.data.user.two_factor_authentication) {
        navigate("/login-two-factor");
        localStorage.setItem("Access-token-2fa", access_token);
      } else {
        handleSignIn(access_token);
        //navigate('/dashboard')
      }
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message || 'An error occured, please try again ');
    },
  });

  useEffect(() => {
    if (response) {
      openSnackbar("success", response);
    }
  }, [response]);

  const handleLogin = async (email, password, setSubmitting) => {
    mutate({
      email,
      password,
    });
  };

  const validateSchema = yup.object().shape({
    email: yup.string().email('Email must be a valid email').required("Provide an email"),
    password: yup.string().min(5).required("Provide a password"),
  });

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={({ email, password }, { setSubmitting, resetForm }) => {
          handleLogin(email, password, setSubmitting);
        }}
        validationSchema={validateSchema}
      >
        {({
          isSubmitting,
          handleChange,
          values: { email, password },
          dirty,
          handleBlur,
        }) => (
          <Form>
            <Flex flexDir={"column"} gap={"30px"}>
              <InputStyle>
                <Label htmlFor="fullName"> Email </Label>{" "}
                <FieldWrapper>
                  <Field
                    type={"text"}
                    onChange={handleChange}
                    name={"email"}
                    value={email}
                    onBlur={handleBlur}
                  />{" "}
                </FieldWrapper>{" "}
                <ErrorMessage name={"email"} component={"div"} />{" "}
              </InputStyle>{" "}
              <InputStyle>
                <Label htmlFor="fullName"> Password </Label>{" "}
                <Link to={"/forgot-password"}> Forgot password ? </Link>{" "}
                <FieldWrapper btn={"btn"}>
                  <Field
                    type={visibility ? "text" : "password"}
                    onChange={handleChange}
                    name={"password"}
                    value={password}
                  />{" "}
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setVisibility(!visibility);
                    }}
                  >
                    {" "}
                    {visibility ? (
                      <PasswordEyeIcon width={"20px"} height={"20px"} />
                    ) : (
                      <HiddenVisibilityIcon width={"20px"} height={"20px"} />
                    )}{" "}
                  </div>{" "}
                </FieldWrapper>{" "}
                <ErrorMessage name={"password"} component={"div"} />{" "}
              </InputStyle>{" "}
              <Button
                text={"Login to account"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="md"
                fullwidth
                type="submit"
                borderRadius={"5px"}
                // disable={!dirty || isSubmitting}
                loading={isLoading}
              />{" "}
              <LoginText>
                Don 't have an account?{" "}
                <a href="mailto:start@trytreasura.com">Get Started</a>
              </LoginText>
            </Flex>
          </Form>
        )}
      </Formik>
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </FormContainer>
  );
};

export default Fields;
