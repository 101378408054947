import styled from "styled-components";

export const Styles = styled.div`
  padding: 30px 30px 0;
  background-color: #fff;
  .container {
    border-bottom: 0.3px solid #c4c4c4;
  }
  @media (max-width: 700px) {
    padding: 20px 4%;
  }
`;
