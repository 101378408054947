import { Typography } from "@mui/material";
import React, { useState } from "react";
import CryptosTab from "../../UI-components/crypto-tabs/CryptosTab";
import FiatsTab from "../../UI-components/fiat-tabs/FiatsTab";
import Search from "../../UI-components/search";
import SettlementTab from "../../UI-components/settlementTabs/SettlementTabs";
import styles from "./ChooseAssets.module.css";
import { useForm } from "react-hook-form";

const customStyles = {
  flex: 1,
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
};

const ChooseAssetsTwo = ({
  title,
  cryptoCurrencies,
  fiatCurrencies,
  handleSetAssets,
  currencies,
  handleClose,
  swapables,
}) => {
  const {
    register,

    formState: { errors },
  } = useForm();

  const [activeTab, setActiveTab] = useState("fiat");

  const handleSetActiveTab = (activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.choose_assets}
    >
      <div className={styles.choose_assets_title}>
        <Typography>{title}</Typography>
      </div>
      <Search
        register={register}
        name={"search"}
        required={false}
        placeholder={"Search assets"}
      />
      <div className={styles.choose_assets_tab}>
        <SettlementTab
          tabs={[{ label: "fiat" }, { label: "crypto" }]}
          style={customStyles}
          activeTab={activeTab}
          handleSetActiveTab={handleSetActiveTab}
        />
      </div>
      <div className={styles.choose_assets_assets}>
        {activeTab === "fiat" ? (
          <FiatsTab
            handleSetAssets={handleSetAssets}
            type={"fiats"}
            fiats={swapables.fiat}
            handleClose={handleClose}
          />
        ) : null}

        {activeTab === "crypto" ? (
          <CryptosTab
            handleSetAssets={handleSetAssets}
            type={"crypto"}
            cryptos={swapables.crypto}
            handleClose={handleClose}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChooseAssetsTwo;
