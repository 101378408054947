import styled from 'styled-components';

export const SelectStyles = styled.div`
	width: ${({ width }) => width || '100px'};
	height: 48px;
	padding: 10px;
	background: #ffffff;
	border: ${({ border }) => border || '1px solid  #BFBFBF'};
	border-radius: ${({ borderRad }) => borderRad || '4px'};
	color: #000000;
	font-size: 14px;
	select {
		width: 100%;
		background: #ffffff;
		outline: none;
		border: none;
		font-size: 14px;
		height: 100%;
		cursor: pointer;
	}
`;
