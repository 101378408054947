import { useState } from "react";

const useOpenSnackbar = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [message, setMessage] = useState('')
  const [openToast, setOpenToast] = useState(false)
  const [severity, setSeverity] =useState('')
  const [duration, setDuration] = useState('')

  const openSnackbar = (type, message, duration) => {

 
      setMessage(message)
    if (type === "success") {
      setOpenSuccess(true);
      setOpenError(false)
      setOpenInfo(false)
      setOpenToast(true)
      setSeverity(type)
      setDuration(duration)
    } else if (type === "error") {
      setOpenError(true);
      setOpenSuccess(false)
      setOpenInfo(false)
      setOpenToast(true)
      setSeverity(type)
      setDuration(duration)
    } else if (type === "info") {
      setOpenInfo(true);
      setOpenError(false)
      setOpenSuccess(false)
      setOpenToast(true)
      setSeverity(type)
      setDuration(duration)
    }
  };

  return {
    openSuccess,
    setOpenSuccess,
    openError,
    setOpenError,
    openInfo,
    setOpenInfo,
    openSnackbar,
    message,
    severity,
    openToast,
    setOpenToast,
    duration
  };
};

export default useOpenSnackbar;
