import React from 'react'
import styles from './CopyButton.module.css'


const CopyButton = ({copy,setCopied, copied}) => {
    
    const handleCopy = async () => {
        try {
          await navigator.clipboard.writeText(copy);
          setCopied(true);
        } catch (error) {}
      };
      
    return ( 
        <button className={styles.copy_btn} onClick={handleCopy}>
            {copied ? 'copied': 'copy'}
        </button>
     );
}
 
export default CopyButton;