import React from "react";
import styles from "./RadioBtn.module.css";
import { Typography } from "@mui/material";

const RadioBtn = ({ item, name, register }) => {
  return (
    <div className={styles.radio_btn}>
      <div className={styles.heading}>
        <Typography>{item.label}</Typography>
      </div>{" "}
      <div className={styles.radios}>
        <label htmlFor={`${name}_yes`}>
          <input
            type="radio"
            {...register(name)}
            value={"yes"}
            id={`${name}_yes`}
          />
          <Typography>Yes</Typography>
        </label>
        <label htmlFor={`${name}_no`}>
          <input
            type="radio"
            {...register(name)}
            value={"no"}
            id={`${name}_no`}
          />
          <Typography>No</Typography>
        </label>
      </div>
    </div>
  );
};

export default RadioBtn;
