import { Typography } from "@mui/material";
import React from "react";
import InputWithTexts from "../inputWithTexts/InputWithTexts";
import styles from "./LabeledInputsWithTexts.module.css";

const LabeledInputsWithText = ({
  setValue,
  watch,
  maxLength,
  minLength,
  pattern,
  type,
  text,
  register,
  redOutline,
  handleChange,
  name,
  value,
  label,
}) => {
  return (
    <label className={styles.label} htmlFor="">
      <div className={styles.heading}>
        <Typography>{label}</Typography>
      </div>

      <InputWithTexts
        watch={watch}
        setValue={setValue}
        name={name}
        text={text}
        redOutline={redOutline}
        register={register}
        pattern={pattern}
        type={type}
        maxLength={maxLength}
        minLength={minLength}
      />
    </label>
  );
};

export default LabeledInputsWithText;
