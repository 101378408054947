import React, { useContext } from "react";
import styles from "./NewSelect.module.css";
import { Typography } from "@mui/material";
import arrowDownIcon from "../../../assets/images/arrow-down.svg";


const NewSelect = ({currencies, name_src, img_src, handleAction }) => {
  //const { currencies } = useContext(DataCentral);
  return (
    <div onClick={handleAction} className={styles.new_select}>
      <div className={styles.img_div}>
        <div className={styles.shape}>
          {currencies?.result?.map((currency) => {
            if (currency.code === img_src) {
              return <img key={currency.id} src={currency.icon} alt={`flag`} />;
            } else {
              return null;
            }
          })}
        </div>
      </div>

      <Typography>{name_src}</Typography>
      <img src={arrowDownIcon} alt="arrow down icon" />
    </div>
  );
};

export default NewSelect;
