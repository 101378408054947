// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DepositForm_withdraw_via_bank__HfIf1{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n \n}\n\n\n.DepositForm_circular_progress__ywl1t{\n    display: flex;\n    justify-content: center;\n\n}\n\n.DepositForm_text__AiwNb{\n    margin-top: 17px;\n}\n.DepositForm_text__AiwNb p{\n   color: #727272;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 17px;\nletter-spacing: 0em;\ntext-align: left;\n\n}\n\n.DepositForm_fields__mO7s6 {\n    margin-top: 27px;\n}\n\n.DepositForm_field__Ykbgr{\n    margin-bottom: 15px;\n}\n\n.DepositForm_field_btn__Ims0s {\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/balances/modals/depositMethod/depositForm/DepositForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;;AAE3B;;;AAGA;IACI,aAAa;IACb,uBAAuB;;AAE3B;;AAEA;IACI,gBAAgB;AACpB;AACA;GACG,cAAc;AACjB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB,mBAAmB;AACnB,gBAAgB;;AAEhB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".withdraw_via_bank{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n \n}\n\n\n.circular_progress{\n    display: flex;\n    justify-content: center;\n\n}\n\n.text{\n    margin-top: 17px;\n}\n.text p{\n   color: #727272;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 17px;\nletter-spacing: 0em;\ntext-align: left;\n\n}\n\n.fields {\n    margin-top: 27px;\n}\n\n.field{\n    margin-bottom: 15px;\n}\n\n.field_btn {\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withdraw_via_bank": "DepositForm_withdraw_via_bank__HfIf1",
	"circular_progress": "DepositForm_circular_progress__ywl1t",
	"text": "DepositForm_text__AiwNb",
	"fields": "DepositForm_fields__mO7s6",
	"field": "DepositForm_field__Ykbgr",
	"field_btn": "DepositForm_field_btn__Ims0s"
};
export default ___CSS_LOADER_EXPORT___;
