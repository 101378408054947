import axios from "axios";
import React, { useContext } from "react";
import SwapstaSnackbar from "../components/UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../customHooks/useOpenSnackbar";
import { DataCentral } from "./AppContext";

export const AxiosProvider = React.createContext();

const AxiosContext = (props) => {
  const { calculateSessionIdleCountdown, handleLogout } =
    useContext(DataCentral);
    const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();



  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("Access-token");
      const token2FA =  localStorage.getItem("Access-token-2fa");
      config.headers.Authorization = `Bearer ${token || token2FA}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      calculateSessionIdleCountdown();
      return response;
    },
    function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          openSnackbar("error", "Your session has expired, please login again");
          handleLogout();
        }

        if (error.response.status === 413) {
        }
      } else if (error.request) {
      } else {
      }

      return Promise.reject(error);
    }
  );

  return (
    <AxiosProvider.Provider value={{
      
    }}>
      {props.children}

      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </AxiosProvider.Provider>
  );
};

export default AxiosContext;
