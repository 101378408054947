import React, { useContext, useEffect, useState } from "react";
import styles from "./VerifyEmail.module.css";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";

import { useMutation } from "@tanstack/react-query";
import { VERIFY_EMAIL } from "../../../../../api/mutations/auth";

const VerifyEmailAction = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutate, isLoading } = useMutation(VERIFY_EMAIL, {
    onSuccess: (response) => {
      navigate(`/signin`, {
        state: { response: response?.message },
      });
    },
    onError: (error) => {
      if (error.response.data.statusCode === 403) {
        navigate(`/verify/email/expired`);
      } else {
        navigate(`/verify/email/error`);
      }
    },
  });

  useEffect(() => {
    handleVerify();
  }, []);

  useEffect(() => {
    handleClearLocal();
  }, []);

  const handleClearLocal = () => {
    localStorage.clear("isLoggedIn");
    localStorage.clear("Access-token");
  };

  const handleVerify = async () => {
    mutate({
      hash: searchParams.get("hash"),
    });
  };

  return (
    <div className={styles.verify_email_action}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className={styles.verify_email_text}>
          <Typography>
            Something went wrong, Go back to Login page{" "}
            <Link to="/"> here</Link>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default VerifyEmailAction;
