import { Typography } from "@mui/material";
import React from "react";
import styles from "./WalletAccountTransactions.module.css";
import { getDate, getTime, parseAmount } from "../../../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import useGetDefaultCurrencies from "../../../../../customHooks/useGetDefaultCurrencies";

function WalletAccountTransactionsRow({ type, data }) {
  const navigate = useNavigate();
  const { currencies } = useGetDefaultCurrencies();
  const [currencyType, setCurrencyType] = useState("");

  const handleNavigateToTransaction = (id) => {
    navigate(`/transaction/${id}`);
  };

  useEffect(() => {
    currencies?.result?.map((currency) => {
      if (currency.code === data.to_currency) {
        setCurrencyType(currency.type);
      } else {
        return null;
      }
    });
  }, [data]);

  return (
    <tr
      onClick={() => {
        handleNavigateToTransaction(data.id);
      }}
      className={styles.row}
    >
      <td>
        <div className={styles.date}>
          <Typography variant="h6"> {getDate(data.created_at)}</Typography>
          <Typography>{getTime(data.created_at)}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.amount}>
          <Typography>
            {" "}
            <span>{data.currency}</span>{" "}
            {parseAmount(data?.amount, currencyType)}
          </Typography>
        </div>
      </td>
      {/* <td>
        <div className={styles.item}>
          <Typography>{JSON.parse(data?.beneficiary)?.bank_name || 'NA'}</Typography>
        </div>
      </td> */}
      <td>
        <div className={styles.item}>
          {type.includes("FIAT") ? (
            <Typography>
              {JSON.parse(data?.beneficiary)?.account_number || "NA"}
            </Typography>
          ) : (
            <Typography>
              {JSON.parse(data?.beneficiary)?.wallet_address || "NA"}
            </Typography>
          )}
        </div>
      </td>
      <td>
        <div className={styles.item}>
          <Typography>{data?.type || "NA"}</Typography>
        </div>
      </td>
      <td>
        <div
          className={`${styles.status} 
        ${data.status === "success" && styles.status_success}
        ${data.status === "failed" && styles.status_failed}
        ${data.status === "pending" && styles.status_pending}
        `}
        >
          <Typography>{data?.status}</Typography>
        </div>
      </td>
    </tr>
  );
}

export default WalletAccountTransactionsRow;
