import * as yup from "yup";
import { Link } from "react-router-dom";
import React from "react";
import { Flex } from "../../../UI-components/box";
import Button from "../../../UI-components/button";
import { ForgotPasswordLink, FormContainer } from "./styles";
import { Form, Field, ErrorMessage, Formik } from "formik";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../UI-components/input/styles";

import { useNavigate } from "react-router-dom";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import { useMutation } from "@tanstack/react-query";
import { FORGOT_PASSWORD, RESET_PASSWORD } from "../../../../api/mutations/auth";


const Fields = () => {
  const {
    openSnackbar,
    message,
    severity,
    duration,
    setOpenToast,
    openToast,
  } = useOpenSnackbar();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };

  const { mutate, isLoading } = useMutation(RESET_PASSWORD, {
    onSuccess: (response) => {
      navigate("/forgot-password-link");
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });

  const validateSchema = yup.object().shape({
    email: yup.string().email().required("provide an email"),
  });

  const handleResetPassword = async (email, setSubmitting) => {
    mutate({
      email
    })
  };
  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={({ email }, { setSubmitting, resetForm }) => {
          handleResetPassword(email, setSubmitting);
        }}
        validationSchema={validateSchema}
      >
        {({ isSubmitting, handleChange, values: { email }, handleBlur }) => (
          <Form>
            <Flex flexDir={"column"} gap={"30px"}>
              <InputStyle>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper>
                  <Field
                    type={"text"}
                    onChange={handleChange}
                    name={"email"}
                    value={email}
                    onBlur={handleBlur}
                  />
                </FieldWrapper>
                <ErrorMessage name={"email"} component={"div"} />
              </InputStyle>
              <Button
                text={"Send me a reset link"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="md"
                fullwidth
                type="submit"
                borderRadius={"5px"}
                loading={isLoading}
              />
              <ForgotPasswordLink>
                <Link to={"/signin"}>Return to Login</Link>
              </ForgotPasswordLink>
            </Flex>
          </Form>
        )}
      </Formik>
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </FormContainer>
  );
};

export default Fields;
