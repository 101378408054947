// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChooseAssets_choose_assets__I1aVA {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.ChooseAssets_choose_assets_title__ojCm3 {\n\tmargin-bottom: 18px;\n}\n\n.ChooseAssets_choose_assets_tab__nAN20 {\n\tmargin-top: 26px;\n\tborder-bottom: 0.7px solid #d7d7d7;\n}\n\n.ChooseAssets_choose_assets_assets__veIh- {\n\tmargin-top: 31px;\n\toverflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI-components/chooseAsserts/ChooseAssets.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,kCAAkC;AACnC;;AAEA;CACC,gBAAgB;CAChB,gBAAgB;AACjB","sourcesContent":[".choose_assets {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.choose_assets_title {\n\tmargin-bottom: 18px;\n}\n\n.choose_assets_tab {\n\tmargin-top: 26px;\n\tborder-bottom: 0.7px solid #d7d7d7;\n}\n\n.choose_assets_assets {\n\tmargin-top: 31px;\n\toverflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"choose_assets": "ChooseAssets_choose_assets__I1aVA",
	"choose_assets_title": "ChooseAssets_choose_assets_title__ojCm3",
	"choose_assets_tab": "ChooseAssets_choose_assets_tab__nAN20",
	"choose_assets_assets": "ChooseAssets_choose_assets_assets__veIh-"
};
export default ___CSS_LOADER_EXPORT___;
