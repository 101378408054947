import { Typography } from "@mui/material";
import React from "react";
import { cutText } from "../../../../../../../utils/helpers";
import styles from "./Overview.module.css";
import copy_icon from "../../../../../../../assets/images/copy_icon.svg";
import { Link } from "react-router-dom";
import compliance_icon from "../../../../../../../assets/images/compliance_icon.svg"

function Overview({ openSnackbar, data, }) {
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      openSnackbar("success", "Copied successfully");
    } catch (error) {}
  };

  return (
    <div className={styles.overview}>
      <div className={styles.overview_item}>
        <div className={styles.title}>
          <Typography variant="h6">Email</Typography>
        </div>

        <Typography>{data?.compliance?.email}</Typography>
      </div>

      <div className={`${styles.overview_item} ${styles.overview_center}`}>
        <div className={styles.title}>
          <Typography variant="h6">Reference ID</Typography>
        </div>

        <div className={styles.address}>
          <Typography>
            {cutText(data?.wallet_reference, 15, "start")}...
            {cutText(data?.wallet_reference, -2, "end")}
          </Typography>
          <img
            onClick={(e) => {
              e.stopPropagation();
              handleCopy(data.wallet_reference);
            }}
            src={copy_icon}
            alt=""
          />
        </div>
      </div>

      <div className={`${styles.overview_item}`}>
        <div className={styles.title}>
          <Typography variant="h6">Compliance</Typography>
          <img src={compliance_icon} alt="" />
        </div>

        <div className={`${styles.wallet_details} ${styles.compliance_link}`}>
          <Link state={data} to={`/wallets/${data.id}/compliance`}>
            View compliance
          </Link>
        </div>
      </div>

    </div>
  );
}

export default Overview;
