// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.Password_password_context__2BV5G{\n    display: flex;\n    flex-direction: column;\n\n}\n\n.Password_password_context_item__zYVlq{\n    margin-bottom: 12px;\n    align-items: center;\n    display: flex;\n}\n\n.Password_password_context_item__zYVlq p{\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400; \n    color: black;\n}\n\n.Password_password_context_item__zYVlq img{\n    margin-right: 18px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI-components/password-context/Password.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n.password_context{\n    display: flex;\n    flex-direction: column;\n\n}\n\n.password_context_item{\n    margin-bottom: 12px;\n    align-items: center;\n    display: flex;\n}\n\n.password_context_item p{\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400; \n    color: black;\n}\n\n.password_context_item img{\n    margin-right: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password_context": "Password_password_context__2BV5G",
	"password_context_item": "Password_password_context_item__zYVlq"
};
export default ___CSS_LOADER_EXPORT___;
