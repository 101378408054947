import React from "react";
import styles from "../styles/OverdraftHeading.module.css";
import SearchBox from "../../../../../UI-components/searchBox";
import Button from "../../../../../UI-components/button";
import useModal from "../../../../../../customHooks/useModal";
import RequestOverdraft from "./modals/request-overdraft/RequestOverdraft";
import AppBackdrop from "../../../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrameOverdarft from "../../../../../UI-components/modalFrameOverdarft/ModalFrameOverdarft";

// import Styles from "./styles"
const fundJson = {
  choice: [
    { text: "Unlimited payout", value: "Unlimited payout" },
    { text: "Limited payout", value: "Limited payout" },
  ],
};

const OverdraftHeading = ({
  val,
  openSnackbar,

  currencyPairs,
}) => {
  const { toggle, handleOpenModal, handleCloseModal, opened } = useModal();
  return (
    <>
      <div
        className={styles.overdraft_heading}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.overdraft_search}>
          <SearchBox placeholder={"Search currency pair"} />
        </div>
        <div className={styles.overdraft_btn}>
          <Button
            Styles
            text={"Request Liquidity"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="sm"
            fullwidth
            type="submit"
            border={"0.4px solid #1A2CCE"}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"5px"}
            onClick={() => {
              handleOpenModal("request-overdraft");
            }}
          />
        </div>
      </div>
      {opened.some((item) => item === "request-overdraft") && (
        <AppBackdrop
          handleCloseModal={() => {}}
          openModal={opened.some((item) => item === "request-overdraft")}
          child={
            <ModalFrameOverdarft
              style={{ width: "fit-content" }}
              handleAction={() => {
                toggle("request-overdraft");
              }}
              child={
                <RequestOverdraft
                  currencyPairs={currencyPairs}
                  val={val}
                  options={fundJson.choice}
                  openSnackbar={openSnackbar}
                  handleActionTwo={() => {
                    handleCloseModal("request-overdraft");
                  }}
                />
              }
            />
          }
        />
      )}
    </>
  );
};

export default OverdraftHeading;
