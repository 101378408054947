import styled from "styled-components";
import { Container } from "../../../UI-components/box";

export const SignupContainer = styled(Container)`
  min-height: 100vh;
  padding-top: 2vh;
  background-color: #f3f6fb;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: 10px 4% 20px;

  }
  .form-container {
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const SignUpText = styled.h1`
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: #000000;

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const FormContainer = styled(Container)`
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 5px;
  width: 100%;
  margin-top: 2em;
  padding: 30px;
`;

export const ConditionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #424242;
  margin-top: 1em;
  a {
    color: #1a2cce;
  }
`;

export const LoginText = styled.span`
  font-size: 14px;
  a {
    color: #1a2cce;
  }
`;
