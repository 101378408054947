// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BankTransfer_withdraw_via_bank__83AFA {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.BankTransfer_circular_progress__VsU3B {\n  display: flex;\n  justify-content: center;\n}\n\n.BankTransfer_text__2U82T {\n  margin-top: 17px;\n}\n.BankTransfer_text__2U82T p {\n  color: #727272;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 17px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.BankTransfer_fields__lxSDT {\n  margin-top: 27px;\n}\n\n.BankTransfer_field__Wt6NS {\n  margin-bottom: 15px;\n}\n\n.BankTransfer_field_btn__gD-ro {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/balances/modals/bankTransfer/BankTransfer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".withdraw_via_bank {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.circular_progress {\n  display: flex;\n  justify-content: center;\n}\n\n.text {\n  margin-top: 17px;\n}\n.text p {\n  color: #727272;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 17px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.fields {\n  margin-top: 27px;\n}\n\n.field {\n  margin-bottom: 15px;\n}\n\n.field_btn {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withdraw_via_bank": "BankTransfer_withdraw_via_bank__83AFA",
	"circular_progress": "BankTransfer_circular_progress__VsU3B",
	"text": "BankTransfer_text__2U82T",
	"fields": "BankTransfer_fields__lxSDT",
	"field": "BankTransfer_field__Wt6NS",
	"field_btn": "BankTransfer_field_btn__gD-ro"
};
export default ___CSS_LOADER_EXPORT___;
