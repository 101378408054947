// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LabeledInputsWithTexts_label__hXa7i {\n    display: flex;\n    flex-direction: column;\n}\n\n.LabeledInputsWithTexts_label__hXa7i p{\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n\n}\n.LabeledInputsWithTexts_heading__xH7Gu {\n  margin-bottom: 9px;\n\n}\n\n.LabeledInputsWithTexts_heading__xH7Gu p{\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n}\n.LabeledInputsWithTexts_red_outline__bgZ8z {\n\toutline: 1px solid red !important;\n}", "",{"version":3,"sources":["webpack://./src/components/UI-components/new-inputs/labeledInputsWithTexts/LabeledInputsWithTexts.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;EACE,0BAA0B;CAC3B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,cAAc;;AAEf;AACA;EACE,kBAAkB;;AAEpB;;AAEA;EACE,0BAA0B;CAC3B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,cAAc;AACf;AACA;CACC,iCAAiC;AAClC","sourcesContent":[".label {\n    display: flex;\n    flex-direction: column;\n}\n\n.label p{\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n\n}\n.heading {\n  margin-bottom: 9px;\n\n}\n\n.heading p{\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n}\n.red_outline {\n\toutline: 1px solid red !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "LabeledInputsWithTexts_label__hXa7i",
	"heading": "LabeledInputsWithTexts_heading__xH7Gu",
	"red_outline": "LabeledInputsWithTexts_red_outline__bgZ8z"
};
export default ___CSS_LOADER_EXPORT___;
