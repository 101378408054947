import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./DepositForm.module.css";
import line from "../../../../../../../assets/images/Line.svg";
import Button from "../../../../../../UI-components/button/index";
import { useForm } from "react-hook-form";
import { DataCentral } from "../../../../../../../context/AppContext";
import HeadingTwo from "../../../../../../UI-components/headings/headingTwo/HeadingTwo";
import LabeledSelectTwo from "../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";
import LabeledInputs from "../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import useGetCountries from "../../../../../../../customHooks/useGetCountries";

const DepositForm = ({
  openSnackbar,
  usdcNetworkData,
  setRequirements,
  requirements,
  banks,
  loadingBanks,
  handleScreen,
  currency,
  method,
  branches,
  setBranches,
}) => {
  const { user } = useContext(DataCentral);
  const { countries, isLoadingCountries } = useGetCountries();
  const {
    register,
    handleSubmit,

    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    requirements.map((requirement) => {
      if (requirement.name === "email") {
        setValue("email", user?.email);
      }
    });
  }, [requirements]);

  const onSubmit = async (data) => {
    const tempRequirements = [...requirements];
    Object.keys(data).map((item) => {
      const selectedIndex = tempRequirements.findIndex((itemRequirement) => {
        return itemRequirement.name === item;
      });
      tempRequirements[selectedIndex].value = data[item];
    });
    setRequirements(tempRequirements);
    handleScreen("verify");
  };

  return (
    <div className={styles.withdraw_via_bank}>
      {loadingBanks || isLoadingCountries ? (
        <div className={styles.circular_progress}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <HeadingTwo currency={currency?.code} title={method?.modal_title} />
          <img src={line} alt="line" />
          <div className={styles.text}>
            <Typography>{method?.modal_description}</Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.fields}>
            {requirements?.map((requirement) => {
              if (requirement.render === "select") {
                if (requirement.name === "bank") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        data={banks?.map((bank) => {
                          return {
                            label: bank?.name,
                            value: bank?.code,
                          };
                        })}
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "provider") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={banks?.map((bank) => {
                          return {
                            label: bank?.name,
                            value: bank?.code,
                          };
                        })}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "country") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={countries.map((country) => {
                          return {
                            label: country.name,
                            value: country.iso2,
                          };
                        })}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "branch") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={branches?.map((branch) => {
                          return {
                            label: branch.branch_name,
                            value: branch.swift_code,
                          };
                        })}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "network") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        data={usdcNetworkData}
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "bank_country") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        data={countries.map((country) => {
                          return {
                            label: country.name,
                            value: country.name,
                          };
                        })}
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "account_type") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={[
                          {
                            label: "Corporate",
                            value: "corporate",
                          },
                          {
                            label: "Individual",
                            value: "individual",
                          },
                        ]}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        data={[]}
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={requirement.label}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                }
              } else if (requirement.render === "input") {
                return (
                  <div key={requirement.label} className={styles.field}>
                    <LabeledInputs
                      guide={
                        requirement.required === "required"
                          ? requirement.guide
                          : "optional"
                      }
                      name={requirement.name}
                      label={`${requirement.label} `}
                      redOutline={errors[requirement.name] ? true : false}
                      register={register}
                      required={
                        requirement.required === "required" ? true : false
                      }
                    />
                    <ValidationMessage
                      message={
                        errors[requirement.name]
                          ? `${requirement.name} is required`
                          : null
                      }
                    />
                  </div>
                );
              }
            })}

            <div className={styles.field_btn}>
              <Button
                text={"Confirm"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="md"
                fullwidth
                type={"click"}
                borderRadius={"5px"}
              />
            </div>
            <div className={styles.field_btn}>
              <Button
                Styles
                text={"Change payment method"}
                color={"#676767"}
                size="md"
                fullwidth
                type="submit"
                onClick={() => {
                  handleScreen("");
                }}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default DepositForm;
