import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";


const urlPattern = new RegExp(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/);


export const ProfileSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Business email is required"),
  full_name: yup.string().required("Full name is required"),
  default_currency: yup.string().required("Country is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .test({
      name: "valid",
      message: "Phone number must be valid",
      test: function (value) {
        
        return isPossiblePhoneNumber(value) && isValidPhoneNumber(value);
      },
    }).nullable(),

});