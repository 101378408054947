import axios from "axios";

export const GENERATE_KEYS = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}settings/api/generate`)
    .then((res) => {
      return res.data;
    });
};

export const SUBMIT_WEBHOOK_URL = async (data) => {
    return axios
      .patch(`${process.env.REACT_APP_BASE_URL}settings/api/webhook`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const SUBMIT_IP = async (data) => {
    return axios
      .patch(`${process.env.REACT_APP_BASE_URL}settings/api/ips`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const TOGGLE_MODE = async (data) => {
    return axios
      .patch(`${process.env.REACT_APP_BASE_URL}settings/api/mode`, data)
      .then((res) => {
        return res.data;
      });
  };