// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wallets_overview_tabs__PSLFl {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .Wallets_filter__fUtag {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-between;\n  }\n  .Wallets_filter_fields__u9jYc {\n    display: flex;\n    align-items: center;\n  }\n  .Wallets_field__cxzUf {\n    margin-right: 20px;\n    width: 200px;\n  }\n  .Wallets_search_field__X5l31 {\n    width: 348px;\n  }\n  .Wallets_search_field__X5l31 p {\n    text-transform: capitalize;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    text-align: left;\n    margin-bottom: 9px;\n    line-height: 17px;\n    color: #505050;\n  }\n  \n  .Wallets_table__dbRbo {\n    margin-top: 32px;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/wallets/Wallets.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,qBAAqB;IACrB,8BAA8B;EAChC;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".overview_tabs {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .filter {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-between;\n  }\n  .filter_fields {\n    display: flex;\n    align-items: center;\n  }\n  .field {\n    margin-right: 20px;\n    width: 200px;\n  }\n  .search_field {\n    width: 348px;\n  }\n  .search_field p {\n    text-transform: capitalize;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    text-align: left;\n    margin-bottom: 9px;\n    line-height: 17px;\n    color: #505050;\n  }\n  \n  .table {\n    margin-top: 32px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overview_tabs": "Wallets_overview_tabs__PSLFl",
	"filter": "Wallets_filter__fUtag",
	"filter_fields": "Wallets_filter_fields__u9jYc",
	"field": "Wallets_field__cxzUf",
	"search_field": "Wallets_search_field__X5l31",
	"table": "Wallets_table__dbRbo"
};
export default ___CSS_LOADER_EXPORT___;
