// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.MemberTab_member_main__I56FW {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    margin-top: 20px;\n  }\n  .MemberTab_member_heading__PI\\+Ml p{\n    font-size: 15px;\n    font-weight: 600;\n    color: black;\n  }\n  \n  .MemberTab_members__qGKg3{\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n  }\n  .MemberTab_member_div__al23s {\n    display: flex;\n    flex-direction: column;\n    gap: 9px;\n  }\n  .MemberTab_remove_member__v9jEd{\n    cursor: pointer;\n  }\n  .MemberTab_remove_member__v9jEd p{\n    color: #D80027;\n    font-size: 12px;\n    font-weight: 500;\n    text-decoration: underline;\n  }", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/compliance/doc-upload/modules/MemberTab.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;EACA;IACE,eAAe;EACjB;EACA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,0BAA0B;EAC5B","sourcesContent":["\n.member_main {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    margin-top: 20px;\n  }\n  .member_heading p{\n    font-size: 15px;\n    font-weight: 600;\n    color: black;\n  }\n  \n  .members{\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n  }\n  .member_div {\n    display: flex;\n    flex-direction: column;\n    gap: 9px;\n  }\n  .remove_member{\n    cursor: pointer;\n  }\n  .remove_member p{\n    color: #D80027;\n    font-size: 12px;\n    font-weight: 500;\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"member_main": "MemberTab_member_main__I56FW",
	"member_heading": "MemberTab_member_heading__PI+Ml",
	"members": "MemberTab_members__qGKg3",
	"member_div": "MemberTab_member_div__al23s",
	"remove_member": "MemberTab_remove_member__v9jEd"
};
export default ___CSS_LOADER_EXPORT___;
