import React from "react";
import Button from "../../../../../../../UI-components/button";
import Disclaimer from "../../../../../../../UI-components/disclaimer/Disclaimer";
import LabeledInputsWithText from "../../../../../../../UI-components/new-inputs/labeledInputsWithTexts/LabeledInputsWithTexts";
import ValidationMessage from "../../../../../../../UI-components/validation-message/ValidationMessage";
import styles from "./styles.module.css";
import ModalModeAlert from "../../../../../../../UI-components/modeAlerts/ModalModeAlert";

function DynamicDepositStep1({
  fee,
  openSnackbar,
  setValue,
  watch,
  handleSubmit,
  errors,
  register,
  handleScreen,
  step,
  setStep,
  currency,
}) {
  const onSubmit = (data) => {
    if (data.amount < currency.min_deposit) {
      openSnackbar(
        "error",
        `Minimum withdrawal is ${currency?.min_deposit?.toLocaleString()} ${
          currency?.code
        }`
      );
    } else if (data.amount > currency.max_deposit) {
      openSnackbar(
        "error",
        `Maximum withdrawal is ${currency?.max_depisit?.toLocaleString()} ${
          currency?.code
        }`
      );
    } else {
      setStep(2);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {/* <Disclaimer
          message={
            "Please ensure that the deposit amount entered is the amount you send"
          }
        /> */}
        <div className={styles.field}>
          <LabeledInputsWithText
            watch={watch}
            setValue={setValue}
            name="amount"
            label={"Deposit amount"}
            text={currency?.code}
            type={"number"}
            redOutline={errors?.amount ? true : false}
            register={register}
          />
          <ValidationMessage
            message={errors?.amount ? "amount is required" : null}
          />
        </div>
        <div className={styles.btns}>
          <div className={styles.btn}>
            <Button
              Styles
              text={"Proceed to deposit"}
              bgColor={"#1A2CCE"}
              color={"#FFFFFF"}
              size="md"
              fullwidth
              type="submit"
              border={"0.4px solid #1A2CCE"}
              boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
              borderRadius={"5px"}
            />
          </div>
          <div className={styles.btn}>
            <Button
              Styles
              text={"Change payment method"}
              color={"#676767"}
              size="md"
              bgColor={"white"}
              fullwidth
              type="submit"
              onClick={() => {
                handleScreen("");
              }}
              boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
              borderRadius={"5px"}
            />
          </div>
        </div>
      </form>
    </>
  );
}

export default DynamicDepositStep1;
