// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sessions_sessions__92m1l {\n    padding: 47px 0;\n}\n\n.Sessions_filter_field__XwWvJ {\n    margin-top: 40px;\n    margin-bottom: 32px;\n    width: 166px;\n    margin-right: 24px;\n    }", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/sessions/Sessions.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB","sourcesContent":[".sessions {\n    padding: 47px 0;\n}\n\n.filter_field {\n    margin-top: 40px;\n    margin-bottom: 32px;\n    width: 166px;\n    margin-right: 24px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sessions": "Sessions_sessions__92m1l",
	"filter_field": "Sessions_filter_field__XwWvJ"
};
export default ___CSS_LOADER_EXPORT___;
