import styled from "styled-components";
import { Container } from "../../../UI-components/box";

export const ResetPasswordContainer = styled(Container)`
  min-height: 100vh;
  padding-top: 15vh;
  background-color: #f3f6fb;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: 15vh 4% 20px;
  }
  .form-container {
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const ResetPasswordText = styled.h1`
  text-align: center;
  color: #000000;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
export const SubText = styled.h4`
  padding-top: 20px;
  text-align: center;
  color: #000000;
  font-weight: 300;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const FormContainer = styled(Container)`
  background: #ffffff;
  border: 2px solid #efefef;
  border-radius: 5px;
  width: 100%;
  margin-top: 2em;
  padding: 30px;
  width: 100%;
`;
