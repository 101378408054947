import React, { useContext, useEffect, useState } from "react";
import styles from "./Table.module.css";
import SettlementRow from "./rows/settlementRow/SettlementRow";
import TransactionRow from "./rows/transaction/TransactionRow";
import BalanceRow from "./rows/balance/BalanceRow";
import EmptyState from "../null state/EmptyState";
import TeamRow from "./rows/teamRow/TeamRow";
import ActivitiesRow from "./rows/activities/ActivitiesRow";
import SessionsRow from "./rows/sessions/SessionsRow";
import WalletsRows from "./rows/wallets/WalletsRow";
import WalletsAccountsRow from "./rows/wallets/WalletsAccountsRow";
import WalletsAccountsTransactionsRow from "./rows/walletAccountTransactions/WalletAccountTransactionsRow";
import WalletsAccountsRowCrypto from "./rows/wallets/WalletsAccountRowCrypto";

const TableOne = ({
  walletActiveTab,
  currencies,
  openSnackbar,
  handleEditMember,
  walletCheckbox,
  tableHeadData,
  tableBody,
  type,
  handleOpenSwap,
  handleOpenTooltip,
  handleCloseTooltip,
  openToolTip,
  handleSetCurrency,
  transactionType,
  handleRefresh,
  handleOpenExport,
  handleSetBalance,
  handleRemoveMember,
  handleResetLogin,
  handleOpenTerminatePrompt,
  handleDeleteWallet,
  handleSelectAll,
  handleSelectSingleCheckbox,
}) => {
  //const { currencies } = useGetDefaultCurrencies();

  useEffect(() => {
    handleAssignMessage();
  }, []);

  useEffect(()=>{
    handleAssignMessage()
  }, [walletActiveTab])

  const [primaryMessage, setPrimaryMessage] = useState("");
  const [SecondaryMessage, setSecondaryMessage] = useState("");

  const handleAssignMessage = () => {
    if (type === "wallets") {
      setPrimaryMessage("Wallet not found");
      setSecondaryMessage("it looks like you dont have any wallet yet");
    } else if (type === "balance") {
      setPrimaryMessage("Balance not found");
      setSecondaryMessage("it looks like you dont have any balance yet");
    } else if (type === "teams") {
      setPrimaryMessage("Team doesnt exist yet");
      setSecondaryMessage("it looks like you dont have any team yet");
    } else if (type === "activities") {
      setPrimaryMessage("Activity doesnt exist yet");
      setSecondaryMessage("it looks like you dont have any activity yet");
    } else if (type === "sessions") {
      setPrimaryMessage("Session doesnt exist yet");
      setSecondaryMessage("it looks like you dont have any session yet");
    } else if (type === "teams") {
      setPrimaryMessage("Team doesnt exist yet");
      setSecondaryMessage("it looks like you dont have any team yet");
    } else if (type === "wallet_accounts") {
      setPrimaryMessage("Fiat Wallet accounts doesnt exist yet");
      setSecondaryMessage(
        "it looks like you dont have any fiat wallet account yet"
      );
    } else if (type === "wallet_accounts_crypto") {

      setPrimaryMessage("Crypto Wallet accounts doesnt exist yet");
      setSecondaryMessage(
        "it looks like you dont have any crypto wallet account yet"
      );
    } else if (type === "wallet_account_transactions") {
      setPrimaryMessage("Wallet accounts transactions doesnt exist yet");
      setSecondaryMessage(
        "it looks like you dont have any wallet account transactions yet"
      );
    }
  };

  return (
    <div
      onClick={() => {
        openToolTip?.open && handleCloseTooltip();
      }}
      className={styles.table_div}
    >
      {tableBody?.length === 0 ? (
        <EmptyState
          primaryText={primaryMessage || "No data exist yet"}
          secondaryText={
            SecondaryMessage || "it looks like you dont have any data yet"
          }
          handleRefresh={handleRefresh}
        />
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              {/* {(type === "wallets" ||
                type === "wallet_accounts" ||
                type === "wallet_accounts_crypto") && (
                <th>
                  <input
                    onChange={(e) => {
                      const { checked } = e.target;
                      handleSelectAll(checked);
                    }}
                    checked={tableBody?.length === walletCheckbox?.length}
                    type="checkbox"
                    name="all"
                    id=""
                  />
                </th>
              )} */}
              {tableHeadData?.map((item, index) => {
                return <th key={index}>{item}</th>;
              })}
            </tr>
          </thead>

          <tbody>
            {type === "settlement" &&
              tableBody?.map((item) => {
                return (
                  <SettlementRow
                    currencies={currencies}
                    key={item.id}
                    data={item}
                  />
                );
              })}
            {type === "transaction" &&
              tableBody?.map((item) => {
                return (
                  <TransactionRow
                    openSnackbar={openSnackbar}
                    openToolTip={openToolTip}
                    handleOpenTooltip={handleOpenTooltip}
                    currencies={currencies}
                    transactionType={transactionType}
                    key={item.id}
                    data={item}
                  />
                );
              })}
            {type === "balance" &&
              tableBody?.map((item) => {
                return (
                  <BalanceRow
                    currencies={currencies}
                    handleOpenExport={handleOpenExport}
                    key={item.id}
                    handleOpenSwap={handleOpenSwap}
                    handleOpenTooltip={handleOpenTooltip}
                    openToolTip={openToolTip}
                    data={item}
                    handleSetBalance={handleSetBalance}
                    handleSetCurrency={handleSetCurrency}
                  />
                );
              })}
            {type === "teams" &&
              tableBody?.map((item) => {
                return (
                  <TeamRow
                    handleEditMember={handleEditMember}
                    handleRemoveMember={handleRemoveMember}
                    key={item.id}
                    data={item}
                    handleResetLogin={handleResetLogin}
                  />
                );
              })}
            {type === "activities" &&
              tableBody?.map((item) => {
                return <ActivitiesRow key={item.id} data={item} />;
              })}
            {type === "sessions" &&
              tableBody?.map((item) => {
                return (
                  <SessionsRow
                    handleOpenTerminatePrompt={handleOpenTerminatePrompt}
                    key={item.id}
                    data={item}
                  />
                );
              })}
            {type === "wallets" &&
              tableBody?.map((item) => {
                return (
                  <WalletsRows
                    type={type}
                    openSnackbar={openSnackbar}
                    handleSelectSingleCheckbox={handleSelectSingleCheckbox}
                    walletCheckbox={walletCheckbox}
                    handleDeleteWallet={handleDeleteWallet}
                    openToolTip={openToolTip}
                    handleOpenTooltip={handleOpenTooltip}
                    key={item.wallet_reference}
                    data={item}
                  />
                );
              })}

            {type === "wallet_accounts" &&
              tableBody?.map((item) => {
                return (
                  <WalletsAccountsRow
                    walletActiveTab={walletActiveTab}
                    type={type}
                    openSnackbar={openSnackbar}
                    handleSelectSingleCheckbox={handleSelectSingleCheckbox}
                    walletCheckbox={walletCheckbox}
                    handleDeleteWallet={handleDeleteWallet}
                    openToolTip={openToolTip}
                    handleOpenTooltip={handleOpenTooltip}
                    key={item.account_reference}
                    data={item}
                  />
                );
              })}

            {type === "wallet_accounts_crypto" &&
              tableBody?.map((item) => {
                return (
                  <WalletsAccountsRowCrypto
                    walletActiveTab={walletActiveTab}
                    type={type}
                    openSnackbar={openSnackbar}
                    handleSelectSingleCheckbox={handleSelectSingleCheckbox}
                    walletCheckbox={walletCheckbox}
                    handleDeleteWallet={handleDeleteWallet}
                    openToolTip={openToolTip}
                    handleOpenTooltip={handleOpenTooltip}
                    key={item.account_reference}
                    data={item}
                  />
                );
              })}
            {/* {type === "fiat" &&
              tableBody?.map((item) => {
                return (
                  <CryptoWallets
                    type={type}
                    openSnackbar={openSnackbar}
                    handleSelectSingleCheckbox={handleSelectSingleCheckbox}
                    walletCheckbox={walletCheckbox}
                    handleDeleteWallet={handleDeleteWallet}
                    openToolTip={openToolTip}
                    handleOpenTooltip={handleOpenTooltip}
                    key={item.id}
                    data={item}
                  />
                );
              })} */}
            {type.includes("wallet_account_transactions") &&
              tableBody?.map((item) => {
                return (
                  <WalletsAccountsTransactionsRow
                    type={type}
                    key={item.id}
                    data={item}
                  />
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TableOne;
