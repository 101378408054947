import { Tooltip, Typography } from "@mui/material";
import React from "react";
import styles from "./LabeledInputs.module.css";
import info_icon from "../../../../assets/images/info_grey.svg";
import Zoom from "@mui/material/Zoom";

const LabeledInputs = ({
  icon,
  guide,
  type,
  register,
  redOutline,
  name,
  placeholder,
  label,
  pattern,
  required,
  readOnly,
  tooltip,
}) => {
  return (
    <label className={styles.labeled_input} htmlFor="">
      <div className={styles.heading}>
        <Typography variant='h6' >{label}</Typography>
        {guide && (
          <Typography
            style={{
              color: guide === "optional" ? "rgb(126, 121, 121)" : "red",
            }}
          >
            {guide}
          </Typography>
        )}
        {tooltip && (
          <Tooltip
            TransitionComponent={Zoom}
            followCursor
            arrow
            title={`Hi there, sorry no info yet`}
            placement="top"
          >
            <div className={styles.info_icon}>
              {" "}
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill={"#A3A3A3"}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 0C2.91037 0 0 2.91037 0 6.5C0 10.0896 2.91037 13 6.5 13C10.0896 13 13 10.0896 13 6.5C13 2.91037 10.0896 0 6.5 0ZM5.6875 5.6875C5.6875 5.47201 5.7731 5.26535 5.92548 5.11298C6.07785 4.9606 6.28451 4.875 6.5 4.875C6.71549 4.875 6.92215 4.9606 7.07452 5.11298C7.2269 5.26535 7.3125 5.47201 7.3125 5.6875V9.75C7.3125 9.96549 7.2269 10.1722 7.07452 10.3245C6.92215 10.4769 6.71549 10.5625 6.5 10.5625C6.28451 10.5625 6.07785 10.4769 5.92548 10.3245C5.7731 10.1722 5.6875 9.96549 5.6875 9.75V5.6875ZM6.5 4.0755C6.39327 4.07547 6.2876 4.05443 6.18901 4.01356C6.09042 3.97269 6.00084 3.91281 5.92539 3.83732C5.84994 3.76184 5.7901 3.67223 5.74929 3.57362C5.70847 3.47501 5.68747 3.36932 5.6875 3.26259C5.68753 3.15587 5.70857 3.05019 5.74944 2.9516C5.79031 2.85301 5.85019 2.76343 5.92568 2.68799C6.00116 2.61254 6.09077 2.5527 6.18938 2.51188C6.28799 2.47106 6.39368 2.45007 6.50041 2.45009C6.71595 2.45015 6.92264 2.53582 7.07501 2.68827C7.22739 2.84072 7.31296 3.04746 7.31291 3.263C7.31285 3.47854 7.22718 3.68524 7.07473 3.83761C6.92228 3.98998 6.71554 4.07555 6.5 4.0755Z"
                  fill={``}
                />
              </svg>
            </div>
          </Tooltip>
        )}
      </div>

      <div
        className={`${styles.input_box} ${
          redOutline ? styles.red_outline : null
        }`}
      >
        <input
          readOnly={readOnly}
          step="any"
          className={` ${readOnly && styles.read_only_bg} `}
          {...register(name, {
            required: required,
          })}
          type={type ? type : "text"}
          name={name}
          placeholder={placeholder}
        />
        {icon && <img src={info_icon} alt="" />}
      </div>
    </label>
  );
};

export default LabeledInputs;
