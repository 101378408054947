import React from 'react';
import Backdrop from '@mui/material/Backdrop';

const AppBackdrop = ({ child, openModal, handleCloseModal }) => {
	return (
		<Backdrop
			sx={{overflow: 'scroll', display: '-webkit-box' ,color: 'black', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={openModal}
			onClick={handleCloseModal}
			style={{ background: '#8b8b8b91', paddingTop: '2rem', paddingBottom: '2rem' }}
		>
			{child}
		</Backdrop>
	);
};

export default AppBackdrop;
