import { Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../assets/images/loader.json";
import styles from './Loader.module.css'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loader = () => {
  return (
    <div className={styles.loader}>
      <Lottie options={defaultOptions} height={200} width={200} />
      <Typography>Loading, Please wait...</Typography>
    </div>
  );
};

export default Loader;
