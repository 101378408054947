import React from "react";
import { useForm } from "react-hook-form";
import LabeledInputs from "../../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import LabeledSelectTwo from "../../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import ValidationMessage from "../../../../../../../UI-components/validation-message/ValidationMessage";
import styles from "../NewAccount.module.css";

const chain = [
  {
    label: "ETH",
    value: "eth",
  },
  {
    label: "TRX",
    value: "trx",
  },
];

function NewAccountCrypto({ activeTab, currencies, register, errors }) {
  return (
    <div>
      <div className={styles.field}>
        <LabeledInputs
          redOutline={errors.account_reference ? true : false}
          icon
          name="account_reference"
          placeholder={"Enter reference ID"}
          label="Reference ID"
          register={register}
          errors={errors}
        />
        <ValidationMessage message={errors?.account_reference?.message} />
      </div>
      <div className={styles.field}>
        <LabeledSelectTwo
          redOutline={errors.currency ? true : false}
          label="Currency"
          placeholder="Choose Currency"
          name="currency"
          register={register}
          data={currencies?.result
            ?.filter((currency) => {
              return currency.type === activeTab.toUpperCase();
            })
            ?.map((currency) => {
              return {
                label: currency.code,
                value: currency.code,
              };
            })}
        />
        <ValidationMessage message={errors?.currency?.message} />
      </div>

      <div className={styles.field}>
        <LabeledSelectTwo
          placeholder="Choose Network"
          redOutline={errors?.chain ? true : false}
          label="Network"
          required={true}
          name="chain"
          register={register}
          data={chain}
        />
        <ValidationMessage message={errors?.chain?.message} />
      </div>
    </div>
  );
}

export default NewAccountCrypto;
