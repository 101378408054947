import React, { useEffect, useRef, useState } from "react";
import BlueBackground from "../../../../UI-components/blueBg/BlueBg";
import styles from "./Profile.module.css";
import user_icon from "../../../../../assets/images/user.svg";
import { Grid, Typography } from "@mui/material";
import LabeledInputs from "../../../../UI-components/new-inputs/inputs/LabeledInputs";
import Button from "../../../../UI-components/button";
import HeadingOne from "../../../../UI-components/headings/headingsOne/HeadingsOne";
import LabeledCheckbox from "../../../../UI-components/new-inputs/labeledCheckbox/LabeledCheckbox";
import Loader from "../../../../UI-components/loader/Loader";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../UI-components/validation-message/ValidationMessage";
import "../../../../../index.css";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import LabeledSelectTwo from "../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import PhoneNumberInput from "../../../../UI-components/phoneNumberInput/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import useGetProfile from "../../../../../customHooks/useGetProfile";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_PIC,
} from "../../../../../api/mutations/dashboard/settings/profile";
import useGetDefaultCurrencies from "../../../../../customHooks/useGetDefaultCurrencies";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProfileSchema } from "../../../../../schemas/dashboard/profile";

const Profile = () => {
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ProfileSchema) });
  const fileInputRef = useRef();
  const [imageUrl, setImageUrl] = useState(null);

  const phone = watch("phone", "");
  const { profile, isLoading } = useGetProfile();
  const { currencies } = useGetDefaultCurrencies();
  const queryClient = useQueryClient();

  useEffect(() => {
    if(phone){
      trigger("phone");
    }

  }, [phone]);

  const { mutate, isLoading: isLoadingUpdateProfile } = useMutation(
    UPDATE_PROFILE,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["profile"]);
        openSnackbar("success", response.message);
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    }
  );

  

  const {
    mutate: handleProfilePicUpload,
    isLoading: isLoadingUpdateProfilePic,
  } = useMutation(UPDATE_PROFILE_PIC, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["profile"]);
      openSnackbar("success", response.message);
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  useEffect(() => {
    if (profile?.merchant !== undefined) {
      setValue("default_currency", profile?.merchant?.default_currency);
    }
  }, [profile, currencies]);

  useEffect(() => {
    if (profile?.merchant !== undefined) {
      handlePrepopulation(profile);
    }
  }, [profile]);

  const handlePrepopulation = (profile) => {
    setValue("full_name", profile?.full_name);
    setValue("email", profile?.email);
    setValue("phone", profile?.phone);
    setImageUrl(profile?.avatar);
  };

  const handleOpenFileUploadDialog = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleChange = (e) => {
    const { name } = e.target;
    if (name === "image") {
      handleSubmitImage(e.target.files[0]);
    }
  };

  const handleSubmitImage = async (data) => {
    // setSavingImage(true);
    let formData = new FormData();
    formData.append("file", data);
    handleProfilePicUpload(formData);
  };

  const onSubmit = async (data) => {
    mutate(data);
  };


 
  return (
    <SettingsLayout
      tab="profile"
      child={
        isLoading ? (
          <Loader />
        ) : (
          <>
            <SwapstaSnackbar
              open={openToast}
              message={message}
              severity={severity}
              setOpen={setOpenToast}
              duration={duration}
            />
            <BlueBackground
              content={
                <div className={styles.profile_blue_content}>
                  <div className={styles.change_profile}>
                    <div className={styles.avatar}>
                      <img src={imageUrl || user_icon} alt="avatar" />
                    </div>
                    {isLoadingUpdateProfilePic ? (
                      <div className={styles.saving_profile_pic}>
                        <Typography>Uploading profile picture</Typography>
                      </div>
                    ) : (
                      <div className={styles.change_profile_link}>
                        <input
                          onChange={handleChange}
                          name="image"
                          ref={fileInputRef}
                          type="file"
                        />
                        <a
                          href="/dashboard/settings"
                          onClick={handleOpenFileUploadDialog}
                        >
                          Change profile picture
                        </a>
                      </div>
                    )}
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.profile_form}
                  >
                    <div className={styles.profile_form_fields}>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className={styles.field}>
                            <LabeledInputs
                              placeholder={"Christain Jumbo"}
                              label={"full name"}
                              name={"full_name"}
                              redOutline={errors.full_name ? true : false}
                              register={register}
                              handleChangeAuto={true}
                              required
                            />
                            <ValidationMessage
                              message={errors?.full_name?.message}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className={styles.field}>
                            <LabeledInputs
                              placeholder={"christainjumbo@gmail.com"}
                              label={"email address"}
                              name={"email"}
                              redOutline={errors.email ? true : false}
                              register={register}
                              required
                              handleChangeAuto={true}
                              pattern={
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                              }
                            />
                            <ValidationMessage
                              message={errors?.email?.message}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className={styles.field}>
                            <PhoneNumberInput
                              value={phone}
                              register={register}
                              name="phone"
                              setValue={setValue}
                            />

                            <ValidationMessage
                              message={errors?.phone?.message}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className={styles.field}>
                            <LabeledSelectTwo
                              data={[
                                {
                                  label: "USD",
                                  value: "USD",
                                },
                              ]}
                              placeholder="Currencies"
                              required={true}
                              name="default_currency"
                              register={register}
                              label="Set default currency"
                              redOutline={errors.default_currency}
                            />

                            <ValidationMessage
                              message={errors?.default_currency?.message}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={styles.form_btn}>
                      <Button
                        text={"Save changes"}
                        bgColor={"#1A2CCE"}
                        color={"#fff"}
                        size="md"
                        type={"click"}
                        borderRadius={"5px"}
                        loading={isLoadingUpdateProfile}
                      />
                    </div>
                  </form>
                </div>
              }
            />

            <BlueBackground
              content={
                <div className={styles.profile_blue_content}>
                  <div className={styles.heading}>
                    <HeadingOne
                      title={"currencies"}
                      description={`These are all the currencies with which you are allowed to transact on Treasura.  Please, Contact Support to add or remove a currency from your list`}
                    />
                  </div>
                  <div className={styles.currency}>
                    <div className={styles.currency_heading}>
                      <Typography>Crypto</Typography>
                    </div>

                    <div className={styles.currency_field}>
                      <Grid container spacing={3}>
                        {currencies?.result?.map((item) => {
                          if (item.type === "CRYPTO") {
                            return (
                              <Grid key={item.code} item xs={12} sm={6} md={3}>
                                <LabeledCheckbox
                                  readOnly={true}
                                  checked={true}
                                  label={item.code}
                                />
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Grid>
                    </div>
                  </div>
                  <div
                    className={` ${styles.currency_last} ${styles.currency}`}
                  >
                    <div className={styles.currency_heading}>
                      <Typography>Local currency</Typography>
                    </div>
                    <div className={styles.currency_field}>
                      <Grid container spacing={3}>
                        {currencies?.result?.map((item) => {
                          if (item.type === "FIAT") {
                            return (
                              <Grid key={item.code} item xs={12} sm={6} md={3}>
                                <LabeledCheckbox
                                  readOnly={true}
                                  checked={true}
                                  label={item.code}
                                />
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Grid>
                    </div>
                  </div>
                </div>
              }
            />
          </>
        )
      }
    ></SettingsLayout>
  );
};

export default Profile;
