import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LabeledInputs from "../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import styles from "./BankTransfer.module.css";
import line from "../../../../../../assets/images/Line.svg";
import Button from "../../../../../UI-components/button";
import HeadingTwo from "../../../../../UI-components/headings/headingTwo/HeadingTwo";
import LabeledSelectTwo from "../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../../UI-components/validation-message/ValidationMessage";
import { DataCentral } from "../../../../../../context/AppContext";
import useGetAccountName from "../../../../../../customHooks/useGetAccountName";

const BankTransfer = ({
  openSnackbar,
  usdcNetworkData,
  setRequirements,
  requirements,
  loadingBanks,
  handleScreen,
  countries,
  amount,
  currency,

  banks,
  method,
  branches,
  setBranches,
}) => {
  const { user } = useContext(DataCentral);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const bank = watch("bank", ""); // you can supply default value as second argument
  const account_number = watch("account_number", "");
  const provider = watch("provider", "");
  const { accountName, isLoadingAccountName } = useGetAccountName(
    account_number,
    bank
  );

  useEffect(() => {
    if (currency.type === "FIAT") {
      if (accountName) {
        setValue("account_name", accountName);
      } else {
        setValue("account_name", "");
      }
    } else {
    }
  }, [accountName]);

  useEffect(() => {
    requirements.map((requirement) => {
      if (requirement.name === "email") {
        setValue("email", user?.email);
      }
    });
  }, [requirements]);

  useEffect(() => {
    if (method.provider === "DUSUPAY") {
      const selectedBank = banks?.find((bank) => {
        return bank.code === provider;
      });

      if (amount < selectedBank?.min_amount) {
        openSnackbar(
          "error",
          `Minimum withdrawal for ${
            banks?.find((bank) => {
              return bank.code === provider;
            })?.name
          } is ${selectedBank.min_amount.toLocaleString()} ${currency.code}`
        );
      } else if (amount > selectedBank?.max_amount) {
        openSnackbar(
          "error",
          `Maximum withdrawal for  ${
            banks?.find((bank) => {
              return bank.code === provider;
            })?.name
          } is ${selectedBank.max_amount.toLocaleString()} ${currency.code}`
        );
      } else {
      }
    }
  }, [provider]);

  const onSubmit = async (data) => {
    if (method.provider === "DUSUPAY") {
      const selectedBank = banks.find((bank) => {
        return bank.code === provider;
      });

      if (amount < selectedBank.min_amount) {
        openSnackbar(
          "error",
          `Minimum withdrawal for ${
            banks?.find((bank) => {
              return bank.code === provider;
            })?.name
          } is ${selectedBank.min_amount.toLocaleString()} ${currency.code}`
        );
      } else if (amount > selectedBank.max_amount) {
        openSnackbar(
          "error",
          `Maximum withdrawal for  ${
            banks?.find((bank) => {
              return bank.code === provider;
            })?.name
          } is ${selectedBank.max_amount.toLocaleString()} ${currency.code}`
        );
      } else {
        handleProceedToConfirm(data);
      }
    } else {
      handleProceedToConfirm(data);
    }
  };

  useEffect(() => {
    if (currency?.code === "GHS" && method?.slug === "instant-bank-payout") {
      handleSetBranch(bank);
    } else if (
      currency.code === "KES" &&
      method.slug === "instant-bank-payout"
    ) {
      handleSetBranch(bank);
    } else {
    }
  }, [bank]);

  const handleProceedToConfirm = (data) => {
    const tempRequirements = [...requirements];
    Object.keys(data).map((item) => {
      if (item === "narration") {
      } else {
        const selectedIndex = tempRequirements.findIndex((itemRequirement) => {
          return itemRequirement.name === item;
        });

        tempRequirements[selectedIndex].value = data[item];
      }
    });
  
    setRequirements([
      ...tempRequirements,
      {
        label: "Narration",
        name: "narration",
        value: data.narration,
      },
    ]);
    handleScreen("confirm");
  };
  const handleSetBranch = (code) => {
    const selectedBank = banks?.find((bank) => {
      return bank.code === code;
    });
    if (selectedBank) {
      setBranches(selectedBank.branches);
    }
  };

  return (
    <div className={styles.withdraw_via_bank}>
      {currency?.type === "FIAT" && loadingBanks ? (
        <div className={styles.circular_progress}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <HeadingTwo
            currency={currency?.code}
            title={method?.modal_title}
            amount={amount}
          />
          <img src={line} alt="line" />
          <div className={styles.text}>
            <Typography>{method?.modal_description}</Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.fields}>
            {requirements?.map((requirement) => {
              if (requirement.render === "select") {
                if (requirement.name === "bank") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={banks?.map((bank) => {
                          return {
                            label: bank?.name,
                            value: bank?.code,
                          };
                        })}
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={`${requirement.label}`}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "provider") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={banks?.map((bank) => {
                          return {
                            label: bank?.name,
                            value: bank?.code,
                          };
                        })}
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={`${requirement.label}`}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "country") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={countries.map((country) => {
                          return {
                            label: country.name,
                            value: country.iso2,
                          };
                        })}
                        name={requirement.name}
                        placeholder={requirement.label}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        label={`${requirement.label}`}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "branch") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={branches?.map((branch) => {
                          return {
                            label: branch.branch_name,
                            value: branch.swift_code,
                          };
                        })}
                        name={requirement.name}
                        placeholder={requirement.label}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        label={`${requirement.label}`}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "network") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={usdcNetworkData}
                        name={requirement.name}
                        placeholder={requirement.label}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        label={`${requirement.label}`}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "bank_country") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={countries.map((country) => {
                          return {
                            label: country.name,
                            value: country.iso2,
                          };
                        })}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={`${requirement.label}`}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else if (requirement.name === "account_type") {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={[
                          {
                            label: "Corporate",
                            value: "corporate",
                          },
                          {
                            label: "Individual",
                            value: "individual",
                          },
                        ]}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        name={requirement.name}
                        placeholder={requirement.label}
                        label={`${requirement.label}`}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={requirement.label} className={styles.field}>
                      <LabeledSelectTwo
                        data={[]}
                        name={requirement.name}
                        placeholder={requirement.label}
                        guide={
                          requirement.required === "required"
                            ? requirement.guide
                            : "optional"
                        }
                        label={`${requirement.label} `}
                        redOutline={errors[requirement.name] ? true : false}
                        register={register}
                        required={
                          requirement.required === "required" ? true : false
                        }
                      />
                      <ValidationMessage
                        message={
                          errors[requirement.name]
                            ? `${requirement.name} is required`
                            : null
                        }
                      />
                    </div>
                  );
                }
              } else if (requirement.render === "input") {
                return (
                  <div key={requirement.label} className={styles.field}>
                    <LabeledInputs
                      guide={
                        requirement.required === "required"
                          ? requirement.guide
                          : "optional"
                      }
                      name={requirement.name}
                      label={`${requirement.label}`}
                      redOutline={errors[requirement.name] ? true : false}
                      register={register}
                      required={
                        requirement.required === "required" ? true : false
                      }
                    />
                    <ValidationMessage
                      message={
                        errors[requirement.name]
                          ? `${requirement.name} is required`
                          : null
                      }
                    />
                  </div>
                );
              }
            })}
            {currency.type === "FIAT" && (
              <div className={styles.field}>
                <LabeledInputs
                  guide={"*"}
                  name={"narration"}
                  label={`Narration`}
                  redOutline={errors["narration"] ? true : false}
                  register={register}
                  required={true}
                />
                <ValidationMessage
                  message={errors["narration"] ? `Narration is required` : null}
                />
              </div>
            )}
            <div className={styles.field_btn}>
              <Button
                text={"Confirm"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="md"
                fullwidth
                type={"click"}
                borderRadius={"5px"}
              />
            </div>
            <div className={styles.field_btn}>
              <Button
                Styles
                text={"Change payment method"}
                color={"#676767"}
                size="md"
                fullwidth
                type="submit"
                onClick={() => {
                  handleScreen("");
                }}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default BankTransfer;
