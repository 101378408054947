import axios from "axios";

export const WITHDRAWAL = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}transaction/withdraw`, data)
    .then((res) => {
      return res.data;
    });
};

export const VERIFY_PAYMENT = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}transaction/deposit`, data)
    .then((res) => {
      return res.data;
    });
};

export const CREATE_MERCHANT = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}transactions/deposit-methods/binance-pay/sub-merchant/create`, data)
    .then((res) => {
      return res.data;
    });
};

