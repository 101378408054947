import { Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./ChangePassword.module.css";
import Button from "../../../../../../UI-components/button";
import eye_icon from "../../../../../../../assets/images/eye.svg";
import eye_off_icon from "../../../../../../../assets/images/EyeOff.svg";
import PasswordContext from "../../../../../../UI-components/password-context/PasswordContext";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";

const ChangePassword = ({
  error,
  passwordErrorEightChar,
  passwordErrorNumber,
  passwordErrorSpecialChar,
  loadingPasswordChange,
  handleConfirm,
  oldPassword,
  newPassword,
  handleChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);


  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleTogglePassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.change_password}
    >
      <div className={styles.heading}>
        <Typography>Change your password</Typography>
      </div>
      <div className={styles.body}>
        <div className={styles.field}>
          <label htmlFor="">
            <Typography>Old Password</Typography>
            <div
              className={`${error.old_password ? styles.red_outline : null} ${
                styles.input_box
              }`}
            >
              <input
                value={oldPassword}
                onChange={handleChange}
                name="oldpassword"
                type={showPassword ? "text" : "password"}
              />
                <div onClick={handleTogglePassword}>
                    {showPassword ? (
                      <img src={eye_icon} alt="" />
                    ) : (
                      <img src={eye_off_icon} alt="" />
                    )}
                  </div>
            </div>
            <ValidationMessage message={error.old_password} />
          </label>
        </div>
        <div className={styles.field}>
          <label htmlFor="">
            <Typography>New Password</Typography>
            <div className={styles.input_box}>
              <input
                value={newPassword}
                onChange={handleChange}
                name="newpassword"
                type={showPassword2 ? "text" : "password"}
              />
              <div onClick={handleTogglePassword2}>
                    {showPassword2 ? (
                      <img src={eye_icon} alt="" />
                    ) : (
                      <img src={eye_off_icon} alt="" />
                    )}
                  </div>
            </div>
          </label>
        </div>
        <PasswordContext
          passwordErrorEightChar={passwordErrorEightChar}
          passwordErrorNumber={passwordErrorNumber}
          passwordErrorSpecialChar={passwordErrorSpecialChar}
        />
      </div>
      <div className={styles.btn}>
        <Button
          text={"Change password"}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type="button"
          borderRadius={"5px"}
          onClick={handleConfirm}
          loading={loadingPasswordChange}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
