import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "../components/pages/dashboard/home";
import Balances from "../components/pages/dashboard/balances";
import Transactions from "../components/pages/dashboard/transactions";
import Transaction from "../components/pages/dashboard/transactions/transaction/Transaction";

import Signup from "../components/pages/auth/signup";
import ResetPassword from "../components/pages/auth/reset-password";
import ForgotPassword from "../components/pages/auth/forgot-password";
import VerifyEmail from "../components/pages/auth/verify/verify-email";
import ForgotPasswordLink from "../components/pages/auth/verify/forgot-password-link";
import PasswordResetSuccessful from "../components/pages/auth/verify/reset-password-successful";
import Login from "../components/pages/auth/login/";
import ProtectedRouteIn from "./ProtectedRouteIn";
import ProtectedRouteOut from "./ProtectedRouteOut";
import { DataCentral } from "../context/AppContext";
import Overdraft from "../components/pages/dashboard/overdraft/Overdraft";
import VerifyEmailAction from "../components/pages/auth/verify/verify-email-action/VerifyEmail";
import TwoFa from "../components/pages/auth/TwoFa/TwoFa";
import VerifyEmailExpired from "../components/pages/auth/verify-email-expired/index";
import VerifyEmailError from "../components/pages/auth/verify-email-error/index";
import OverdraftSingle from "../components/pages/dashboard/overdraft/overdraft-single/OverdraftSingle";
import Activities from "../components/pages/dashboard/settings/activities/Activities";
import Profile from "../components/pages/dashboard/settings/profile/Profile";
import Compliance from "../components/pages/dashboard/settings/compliance/Compliance";
import Teams from "../components/pages/dashboard/settings/teams/index";
import Security from "../components/pages/dashboard/settings/security/Security";
import API from "../components/pages/dashboard/settings/api";
import Preferences from "../components/pages/dashboard/settings/preferences/index";
import Sessions from "../components/pages/dashboard/settings/sessions/Sessions";
import Fiat from "../components/pages/dashboard/wallets/fiat/Fiat";
import BinancePayForm from "../components/pages/dashboard/binancePay/form/BinancePayForm";
import BinancePaySuccessful from "../components/pages/dashboard/binancePay/binancePaySuccessful/BinancePaySuccessful";
import PermissionedRoute from "./PermissionedRoute";
import usePermission from "../customHooks/usePermissions";
import TransactionReceipt from "../components/pages/dashboard/transactionReceipt/TransactionReceipt";
import Loader from "../components/UI-components/loader/Loader";
import WalletAccounts from "../components/pages/dashboard/wallets/walletAccounts/WalletAccounts";
import Wallets from "../components/pages/dashboard/wallets/Wallets";
import WalletCompliancePage from "../components/pages/dashboard/wallets/walletCompliancePage/WalletCompliancePage";
import WalletAccountsTransactions from "../components/pages/dashboard/wallets/walletsTrxs/WalletTrxs";
import { isMobile } from "react-device-detect";
import DeviceAlert from "../components/UI-components/deviceAlert/DeviceAlert";

const Index = () => {
  const { isLoggedIn, loadingUser } = useContext(DataCentral);
  const { checkPermission } = usePermission();

 

  if (isMobile) {
    return <DeviceAlert/>;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Navigate to="/signin" replace />} />
        <Route
          path={"/signin"}
          exact
          element={
            <ProtectedRouteIn auth={isLoggedIn}>
              <Login />
            </ProtectedRouteIn>
          }
        />{" "}
        <Route
          path={"/dashboard"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Home />
            </ProtectedRouteOut>
          }
        />{" "}
        <Route
          path={"/balances"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Balances />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/balances/:currency/:amount/:reference/:status"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Balances />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/binance-pay"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <BinancePayForm />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/binance-pay-successful"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <BinancePaySuccessful />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/wallets"}
          exact
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"wallets"}
                checkPermission={checkPermission}
              >
                <Wallets />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/wallets/:id/compliance"}
          exact
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"wallets"}
                checkPermission={checkPermission}
              >
                <WalletCompliancePage />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/wallets/:id/accounts/:a_id/transactions"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"wallets"}
                checkPermission={checkPermission}
              >
                <WalletAccountsTransactions />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/wallets/:id"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"wallets"}
                checkPermission={checkPermission}
              >
                <WalletAccounts />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        {/* <Route
          path={"/wallets/fiat"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"wallets"}
                checkPermission={checkPermission}
              >
                <Fiat />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        /> */}
        <Route
          path={"/liquidity"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"liquidity"}
                checkPermission={checkPermission}
              >
                <Overdraft />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/liquidity/:id"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"liquidity"}
                checkPermission={checkPermission}
              >
                <OverdraftSingle />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/transactions"}
          exact
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Transactions />
            </ProtectedRouteOut>
          }
        />{" "}
        <Route
          path={"/transactions/:tab"}
          exact
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Transactions />
            </ProtectedRouteOut>
          }
        />{" "}
        <Route
          path={"/transaction/:transactionid"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Transaction />
            </ProtectedRouteOut>
          }
        />{" "}
        <Route
          path={"/receipt"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <TransactionReceipt />
            </ProtectedRouteOut>
          }
        />{" "}
        <Route
          path="/settings"
          exact
          element={<Navigate to="/settings/profile" replace />}
        />
        <Route
          path={"/settings/profile"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Profile />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/settings/compliance"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"settings.compliance"}
                checkPermission={checkPermission}
              >
                <Compliance />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/settings/team"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"settings.team"}
                checkPermission={checkPermission}
              >
                <Teams />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/settings/security"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Security />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/settings/API"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"settings.api"}
                checkPermission={checkPermission}
              >
                <API />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/settings/preferences"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <PermissionedRoute
                entity={"transactions.preferences"}
                checkPermission={checkPermission}
              >
                <Preferences />
              </PermissionedRoute>
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/settings/activities"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Activities />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/settings/sessions"}
          element={
            <ProtectedRouteOut auth={isLoggedIn}>
              <Sessions />
            </ProtectedRouteOut>
          }
        />
        <Route
          path={"/login-two-factor"}
          element={
            <ProtectedRouteIn auth={isLoggedIn}>
              <TwoFa />
            </ProtectedRouteIn>
          }
        />{" "}
        <Route path={"/signup/:id"} element={<Signup />} />{" "}
        <Route path={"/verify-email"} element={<VerifyEmail />} />{" "}
        <Route path={"/forgot-password"} element={<ForgotPassword />} />{" "}
        <Route
          path={"/forgot-password-link"}
          element={<ForgotPasswordLink />}
        />{" "}
        <Route path={"/verify/email"} element={<VerifyEmailAction />} />{" "}
        <Route path={"/verify/email/error"} element={<VerifyEmailError />} />{" "}
        <Route
          path={"/verify/email/expired"}
          element={<VerifyEmailExpired />}
        />{" "}
        <Route path={"/reset-password"} element={<ResetPassword />} />{" "}
        <Route
          path={"/password-reset-successful"}
          element={<PasswordResetSuccessful />}
        />{" "}
      </Routes>{" "}
    </BrowserRouter>
  );
};

export default Index;
