import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GET_CURRENCIES } from "../api/queries/dashboard/misc";

const useGetDefaultCurrencies = () => {
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: currencies } = useQuery(["currencies"], GET_CURRENCIES, {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const tempCryptoCurrencies = response.data.result.filter((currency) => {
        return currency.type === "CRYPTO";
      });
      setCryptoCurrencies(tempCryptoCurrencies);

      const tempFiatCurrencies = response.data.result.filter((currency) => {
        return currency.type === "FIAT";
      });
      setFiatCurrencies(tempFiatCurrencies);

      setIsLoading(false);
    },
  });

  return {
    currencies: currencies?.data,
    isLoading,
    cryptoCurrencies,
    fiatCurrencies,
  };
};

export default useGetDefaultCurrencies;
