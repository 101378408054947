import { Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import styles from "./WalletCompliance.module.css";
import FsLightbox from "fslightbox-react";

function WalletCompliance({ compliance }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [document, setDocument] = useState();

  return (
    <div className={styles.wallet_compliance}>
      <div className={styles.compliance_item}>
        <Typography variant="h6">first name</Typography>
        <Typography>{compliance?.firstname}</Typography>
      </div>
      <div className={styles.compliance_item}>
        <Typography variant="h6">last name</Typography>
        <Typography>{compliance?.lastname}</Typography>
      </div>
      <div className={styles.compliance_item}>
        <Typography variant="h6">phone number</Typography>
        <Typography>{compliance?.phone}</Typography>
      </div>
      <div className={styles.compliance_item}>
        <Typography variant="h6">email</Typography>
        <Typography>{compliance?.email}</Typography>
      </div>
      <div className={styles.compliance_item}>
        {compliance?.documents?.map((document, index) => {
          return (
            <div key={index} className={styles.compliance_docu_item}>
              <div className={styles.compliance_docu_heading}>
                <Typography variant="h6">
                  Document{" "}
                  {compliance?.documents?.length < 2 ? null : index + 1}
                </Typography>
              </div>
              <div className={styles.compliance_document}>
                <Typography variant="h6">{document.document_type}</Typography>
                <Typography>{document.document_no}</Typography>
                {document.document_file_type === "LINK" ? (
                  <a
                    href={document.document_file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                ) : (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setDocument(document.document_file);
                      setOpenLightbox(!openLightbox);
                    }}
                    href={document.document_file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <FsLightbox toggler={openLightbox} sources={[document]} type="image" />
    </div>
  );
}

export default WalletCompliance;
