import React from "react";
import Button from "../../../../../../UI-components/button";
import LabeledInputs from "../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import LabeledInputsWithAction from "../../../../../../UI-components/new-inputs/inputWithAction/InputWithAction";
import PhoneNumberInput from "../../../../../../UI-components/phoneNumberInput/PhoneNumberInput";
import LabeledSelectTwo from "../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";
import styles from "./NewWalletStepOne.module.css";
import { v4 as uuidv4 } from "uuid";
import { cutText, removeDash } from "../../../../../../../utils/helpers";

function NewWalletStepOne({

  handleCloseModal,
  handleSubmit,
  onSubmit,
  errors,
  register,
  phone,
  setValue,
 
}) {
  return (
    <div>
        <div className={styles.field}>
        <LabeledInputs
          redOutline={errors.full_name ? true : false}
          label="Account name"
          name="full_name"
          register={register}
        />
        <ValidationMessage message={errors?.account_name?.message} />
      </div>
      <div className={styles.field}>
        <LabeledInputs
          redOutline={errors.email ? true : false}
          label="Email"
          name="email"
          register={register}
        />
        <ValidationMessage message={errors?.email?.message} />
      </div>
    
      <div className={styles.field}>
        <PhoneNumberInput
          value={phone}
          register={register}
          name="phone"
          setValue={setValue}
          redOutline={errors?.phone ? true : false}
        />
        <ValidationMessage message={errors?.phone?.message} />
      </div>
      {/* <div className={styles.field}>
        <LabeledSelectTwo
          redOutline={errors.currency ? true : false}
          label="Currency"
          placeholder="Choose Currency"
          name="currency"
          register={register}
          data={currencies?.result
            ?.filter((currency) => {
              return currency.type === type.toUpperCase();
            })
            ?.map((currency) => {
              return {
                label: currency.code,
                value: currency.code,
              };
            })}
        />
        <ValidationMessage message={errors?.currency?.message} />
      </div>
      {type === "crypto" && (
        <div className={styles.field}>
          <LabeledSelectTwo
            placeholder="Choose Network"
            redOutline={errors?.network ? true : false}
            label="Network"
            required={true}
            name="network"
            register={register}
            data={chain}
          />
          <ValidationMessage message={errors?.network?.message} />
        </div>
      )}

      {type === "fiat" && (
        <div className={styles.field}>
          <LabeledSelectTwo
            placeholder="Choose Account type"
            redOutline={errors?.account_type ? true : false}
            label="Account Type"
            name="account_type"
            register={register}
            data={[
              {
                label: "Static",
                value: "static",
              },
              {
                label: "Dynamic",
                value: "dynamic",
              },
            ]}
          />
          <ValidationMessage message={errors?.account_type?.message} />
        </div>
      )} */}

      <div className={styles.field}>
        <LabeledInputsWithAction
          handleClickBtn={() => {
            setValue("merchant_reference", cutText(removeDash(uuidv4()), 22));
          }}
          btnText={"Generate"}
          redOutline={errors.merchant_reference ? true : false}
          label="Reference ID"
          name="merchant_reference"
          register={register}
          required={true}
        />
        <ValidationMessage message={errors?.merchant_reference?.message} />
      </div>
      <div className={styles.btns}>
        <div className={styles.btn}>
          <Button
            Styles
            text={"Cancel"}
            bgColor={"white"}
            color={"#404040"}
            size="sm"
            type="submit"
            borderRadius={"5px"}
            onClick={handleCloseModal}
          />
        </div>

        <div className={styles.btn}>
          <Button
            // disabled={Object.keys(errors).length > 0}
            Styles
            text={"Next - Compliance"}
            bgColor={"#1A2CCE"}
            color={"white"}
            size="sm"
            type="submit"
            border={"0.4px solid #1A2CCE"}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"5px"}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
}

export default NewWalletStepOne;
