import React from "react";
import styles from "./AddMore.module.css";
import { Typography } from "@mui/material";
import addIcon from "../../../assets/images/add_icon.svg";

const AddMore = ({ text, handleClick }) => {
  return (
    <div onClick={()=>{
        handleClick()
    }} className={styles.add_new_member}>
      <span></span>
      <div className={styles.add_new_member_main}>
        <img src={addIcon} alt="" />
        <Typography>{text}</Typography>
      </div>
      <span></span>
    </div>
  );
};

export default AddMore;
