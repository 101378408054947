import { Typography } from '@mui/material';
import React from 'react';
import styles from './LabeledCheckbox.module.css';

const LabeledCheckbox = ({readOnly, label, checked }) => {
	return (
		<label className={styles.label} htmlFor=''>
			<input readOnly={readOnly} type='checkbox' checked={checked} />
			<Typography>{label}</Typography>
		</label>
	);
};

export default LabeledCheckbox;
