import React from 'react';
import { SignupContainer, SignUpText } from './styles';
import { Flex, Frame } from '../../../UI-components/box';
import logo from '../../../../assets/images/logo-dark.svg';

import Fields from './fields';

const Signup = () => {
	return (
		<SignupContainer>
			<Flex
				width={'530px'}
				flexDir={'column'}
				margin={'auto'}
				className='form-container'
			>
				<Flex>
					<Frame height={'150px'} width={'150px'}>
						<img alt='logo' src={logo} />
					</Frame>
				</Flex>
				<SignUpText className='signUp-text'>
					Create Your Merchant account
				</SignUpText>
				<Fields />
			</Flex>
		</SignupContainer>
	);
};

export default Signup;
