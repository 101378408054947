// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeadingsOne_deposit_heading__285Eh {\n\tmargin-bottom: 14px;\n}\n\n.HeadingsOne_deposit_heading__285Eh h6 {\n\ttext-transform: capitalize;\n\tfont-size: 16px;\n\tfont-style: normal;\n\tfont-weight: 600;\n\ttext-align: left;\n\tmargin-bottom: 8px;\n}\n\n.HeadingsOne_deposit_heading__285Eh p {\n\tcolor: #686868;\n\tfont-size: 15px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\ttext-align: left;\n\tline-height: 23px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI-components/headings/headingsOne/HeadingsOne.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,cAAc;CACd,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,gBAAgB;CAChB,iBAAiB;AAClB","sourcesContent":[".deposit_heading {\n\tmargin-bottom: 14px;\n}\n\n.deposit_heading h6 {\n\ttext-transform: capitalize;\n\tfont-size: 16px;\n\tfont-style: normal;\n\tfont-weight: 600;\n\ttext-align: left;\n\tmargin-bottom: 8px;\n}\n\n.deposit_heading p {\n\tcolor: #686868;\n\tfont-size: 15px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\ttext-align: left;\n\tline-height: 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deposit_heading": "HeadingsOne_deposit_heading__285Eh"
};
export default ___CSS_LOADER_EXPORT___;
