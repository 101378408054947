import React from 'react';
import styles from './DepositSuccessful.module.css';
import orange_icon from '../../../../../../../assets/images/orange-mark.svg';
import { Link } from 'react-router-dom';
import HeadingOne from '../../../../../../UI-components/headings/headingsOne/HeadingsOne';
import Button from '../../../../../../UI-components/button';

const DepositSuccessful = () => {
	return (
		<div className={styles.order_successful}>
			<img src={orange_icon} alt='successful icon' />
			<div className={styles.heading}>
				<HeadingOne
					title={'Deposit successful'}
					description={'We would confirm your deposit and credit your account as soon as we recieve your payment'}
				/>
			</div>
			<div className={styles.order_successful_btn}>
				<Link to='/dashboard'>
					<Button
						text={'Back to home'}
						bgColor={'#1A2CCE'}
						color={'#fff'}
						size='md'
						type={'click'}
						borderRadius={'5px'}
						
					/>
				</Link>

				
			</div>
		</div>
	);
};

export default DepositSuccessful;
