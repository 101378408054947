import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../UI-components/layouts";
import { Styles } from "./styles";
import Swap from "./swap/Swap";
import Deposit from "./deposit/Deposit";
import WithDraw from "./Withdrawals/Withdrawals";
import SettlementTab from "../../../UI-components/settlementTabs/SettlementTabs";
import Loader from "../../../UI-components/loader/Loader";
import useModal from "../../../../customHooks/useModal";
import ModalFrame from "../../../UI-components/modalFrame/ModalFrame";
import DownloadReport from "../../../UI-components/downloadReport/DownloadReport";
import AppBackdrop from "../../../UI-components/appBackdrop/AppBackdrop";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { DataCentral } from "../../../../context/AppContext";
import { GET_TRANSACTIONS } from "../../../../api/queries/dashboard/transactions";
import { useQuery } from "@tanstack/react-query";
import useGetDefaultCurrencies from "../../../../customHooks/useGetDefaultCurrencies";

const Index = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { transactionsTabs } = useContext(DataCentral);
  const [activeTab, setActiveTab] = useState("");
  const [exportReportData, setExportReportData] = useState({});
  const [swapFilterData, setSwapFilterData] = useState({});
  const [depositFilterData, setDepositFilterData] = useState({});
  const [withdrawFilterData, setWithdrawFilterData] = useState({});
  const [swapPage, setSwapPage] = useState(1);
  const [depositPage, setDepositPage] = useState(1);
  const [withdrawalPage, setWithdrawalPage] = useState(1);
  const [swapSearch, setSwapSearch] = useState("");
  const [depositSearch, setDepositSearch] = useState("");
  const [withdrawalSearch, setWithdrawalSearch] = useState("");
  const { currencies } = useGetDefaultCurrencies();

  const { toggle, handleOpenModal, handleCloseModal, opened } = useModal();

  const {
    isFetched: swapIsFetched,
    data: swaps,
    isLoading: isLoadingSwaps,
    refetch: refetchSwaps,
  } = useQuery(
    [
      "swap-trx",
      "swap",
      swapPage,
      swapSearch,
      swapFilterData.currency,
      swapFilterData.status,
    ],
    GET_TRANSACTIONS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    isFetched: dptIsFetched,
    data: deposits,
    isLoading: isLoadingDeposits,
    refetch: refetchDeposits,
  } = useQuery(
    [
      "deposit-trx",
      "deposit",
      depositPage,
      depositSearch,
      depositFilterData.currency,
      depositFilterData.status,
    ],
    GET_TRANSACTIONS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    isFetched: withdrawIsFetched,
    data: withdrawals,
    isLoading: isLoadingWithdrawals,
    refetch: refetchWithdrawals,
  } = useQuery(
    [
      "withdrawal-trx",
      "withdrawal",
      withdrawalPage,
      withdrawalSearch,
      withdrawFilterData.currency,
      withdrawFilterData.status,
    ],
    GET_TRANSACTIONS,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setActiveTab(params.tab);
  });

  const handleFilterSwaps = async (type, filterData) => {
    if (type === "search") {
      setSwapFilterData({});
      setSwapSearch(filterData.search);
    } else {
      setSwapSearch("");
      setSwapFilterData(filterData);
    }
  };

  const handleFilterDeposits = async (type, filterData) => {
    if (type === "search") {
      setDepositFilterData({});
      setDepositSearch(filterData.search);
    } else {
      setDepositSearch("");
      setDepositFilterData(filterData);
    }
  };

  const handleFilterWithdrawals = async (type, filterData) => {
    if (type === "search") {
      setWithdrawFilterData({});
      setWithdrawalSearch(filterData.search);
    } else {
      setWithdrawalSearch("");
      setWithdrawFilterData(filterData);
    }
  };

  const handlePageChange = async (page, type) => {
    if (type === "withdrawal") {
      setWithdrawalPage(page);
    } else if (type === "swap") {
      setSwapPage(page);
    } else if (type === "deposit") {
      setDepositPage(page);
    } else {
    }
  };

  const handleSetActiveTab = (activeTab) => {
    setActiveTab(activeTab);
    setExportReportData({ ...exportReportData, type: activeTab });
    navigate(`/transactions/${activeTab}`);
  };

  return (
    <Layout title={"Transactions"}>
      {false ? (
        <Loader />
      ) : (
        <Styles>
          <SettlementTab
            export_tab={true}
            activeTab={activeTab}
            tabs={transactionsTabs}
            handleSetActiveTab={handleSetActiveTab}
            handleOpenExport={() => {
              handleOpenModal("download-report");
            }}
          />
          {activeTab === "swap" ? (
            <Swap
              swapIsFetched={swapIsFetched}
              currencies={currencies}
              swapFilterData={swapFilterData}
              loadingSwap={isLoadingSwaps}
              handlePageChange={handlePageChange}
              handleFilter={handleFilterSwaps}
              handleGetSwaps={() => {
                setSwapFilterData({});
                setSwapSearch("");
                refetchSwaps();
              }}
              swaps={swaps?.data}
            />
          ) : null}
          {activeTab === "deposit" ? (
            <Deposit
              depositIsFetched={dptIsFetched}
              currencies={currencies}
              depositFilterData={depositFilterData}
              loadingDeposit={isLoadingDeposits}
              handlePageChange={handlePageChange}
              handleFilter={handleFilterDeposits}
              handleGetDeposits={() => {
                setDepositFilterData({});
                setDepositSearch("");
                refetchDeposits();
              }}
              deposits={deposits?.data}
            />
          ) : null}
          {activeTab === "withdrawal" ? (
            <WithDraw
              withdrawIsFetched={withdrawIsFetched}
              currencies={currencies}
              withdrawFilterData={withdrawFilterData}
              loadingWithdrawal={isLoadingWithdrawals}
              handlePageChange={handlePageChange}
              handleFilter={handleFilterWithdrawals}
              handleGetWithdrawals={() => {
                setWithdrawFilterData({});
                setWithdrawalSearch("");
                refetchWithdrawals();
              }}
              withdrawals={withdrawals?.data}
            />
          ) : null}
        </Styles>
      )}
      {opened.some((item) => item === "download-report") && (
        <AppBackdrop
          openModal={opened.some((item) => item === "download-report")}
          handleCloseModal={() => {
            handleCloseModal("download-report");
          }}
          child={
            <ModalFrame
            style={{
          
                height: "auto",
                width: 'auto',
           
            }}
              handleAction={() => {
                toggle("download-report");
              }}
              child={
                <DownloadReport type="transaction" exportReportData={""} />
              }
            />
          }
        />
      )}
    </Layout>
  );
};

export default Index;
