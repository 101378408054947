import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {GET_COUNTRIES, GET_STATES} from './../api/queries/dashboard/misc/index'

const useGetStates = () => {
  const [countryId, setCountryId] = useState('')
  const {
    data: states,
    isLoading,
  } = useQuery(["states", countryId], GET_STATES, {
    refetchOnWindowFocus: false,
  });

  return {
    setCountryId,
    states: states,
    isLoadingStates: isLoading
  };
};

export default useGetStates;
