import React, { useContext, useEffect, useState } from "react";
import styles from "./Transaction.module.css";
import Layout from "../../../../UI-components/layouts";
import { Styles } from "./styles";
import { Grid, Typography } from "@mui/material";
import CopyButton from "../../../../UI-components/copy-button/CopyButton";
import info_icon from "../../../../../assets/images/info.svg";
import { useParams } from "react-router-dom";
import Loader from "../../../../UI-components/loader/Loader";
import GoBack from "../../../../UI-components/goBack/GoBack";
import Button from "../../../../UI-components/button/index";
import AppBackdrop from "../../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrame from "../../../../UI-components/modalFrame/ModalFrame";
import useModal from "../../../../../customHooks/useModal";
import ShareReceipt from "./modal/shareReceipt/ShareReceipt";
import { useQuery } from "@tanstack/react-query";
import { GET_SINGLE_TRANSACTIONS } from "../../../../../api/queries/dashboard/transactions";
import {
  cutText,
  getDate,
  getTime,
  parseAmount,
} from "../../../../../utils/helpers";
import useGetDefaultCurrencies from "../../../../../customHooks/useGetDefaultCurrencies";

const modalFrameStyle = {
  height: "auto",
  width: 516,
};

const TransactionDetails = () => {
  const { currencies } = useGetDefaultCurrencies();
  const { toggle, isShowingShareReceipt } = useModal();
  const params = useParams();

  const [copied, setCopied] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [copied3, setCopied3] = useState(false);

  const [currencyType, setCurrencyType] = useState("");
  const [swapCurrencyType, setSwapCurrencyType] = useState("");
  const [expiryTime, setExpiryTime] = useState(Date.now());

  const {
    data: transaction,
    isLoading: isLoadingTransaction,
    refetch,
  } = useQuery(["transaction", params.transactionid], GET_SINGLE_TRANSACTIONS, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (transaction) {
      var date = new Date(transaction?.settlement_date);
      setExpiryTime(date.getTime() - Date.now());
    }
  }, [transaction]);

  useEffect(() => {
    let timer;
    if (expiryTime <= 0) {
      setExpiryTime(0);
      clearInterval(timer);
    } else {
      timer = setInterval(() => {
        const tempTime = expiryTime - 1000;

        setExpiryTime(tempTime);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [expiryTime]);

  useEffect(() => {
    currencies?.result?.map((currency) => {
      if (currency.code === transaction?.currency) {
        setCurrencyType(currency.type);
      }
      if (currency.code === transaction?.from_currency) {
        setSwapCurrencyType(currency.type);
      }
    });
  }, [transaction, currencies]);

  return (
    <Layout title={"Transaction Details"} bgColor={"#fff"}>
      <Styles>
        {isLoadingTransaction ? (
          <Loader />
        ) : (
          <div className={styles.transaction}>
            <GoBack />
            <div className={styles.heading}>
              <div className={styles.heading_amount}>
                <>
                  <Typography variant="h6">{transaction?.currency}</Typography>

                  <Typography>
                    {parseAmount(transaction?.amount, currencyType)}
                  </Typography>
                </>

                <span
                  className={`${
                    transaction?.status === "pending"
                      ? styles.span_pending
                      : null
                  } ${
                    transaction?.status === "success"
                      ? styles.span_approved
                      : null
                  }  ${
                    transaction?.status === "failed" ? styles.span_failed : null
                  }  ${
                    transaction?.status === "refunded"
                      ? styles.span_failed
                      : null
                  } `}
                >
                  {transaction?.status}
                </span>
              </div>
              {false && (
                <div className={styles.heading_btn}>
                  <Button
                    onClick={() => {
                      toggle("share-receipt");
                    }}
                    text={"Share receipt"}
                    bgColor={"#C6DDFF"}
                    color={"#1A2CCE"}
                    size="md"
                    type={"click"}
                    borderRadius={"5px"}
                  />
                </div>
              )}
            </div>
            <div className={styles.heading_details}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <div className={styles.heading_details_item}>
                    <div className={styles.heading_details_title}>
                      <Typography variant="h6">date</Typography>
                    </div>
                    <div className={styles.heading_details_result}>
                      <Typography variant="h6">
                        {getDate(transaction?.created_at)}
                      </Typography>
                      <Typography>
                        {getTime(transaction?.created_at)}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <div className={styles.heading_details_item}>
                    <div className={styles.heading_details_title}>
                      <Typography variant="h6">reference</Typography>
                    </div>

                    <div className={styles.heading_details_result}>
                      <Typography variant="h6">
                        {transaction?.reference}
                      </Typography>
                      <CopyButton
                        copy={transaction?.reference}
                        setCopied={setCopied}
                        copied={copied}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <div className={styles.heading_details_item}>
                    <div className={styles.heading_details_title}>
                      <Typography variant="h6">TRANSACTION TYPE</Typography>
                    </div>

                    <div className={styles.heading_details_title}>
                      <Typography>{transaction?.type}</Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.transaction_details}>
              <div>
                <div className={styles.transaction_details_heading}>
                  <Typography>TRANSACTION DETAILS</Typography>
                </div>
                <div className={styles.transaction_details_body}>
                  {transaction?.purpose === "SWAP" && (
                    <div className={styles.details_item}>
                      <Typography>Swap details</Typography>

                      {transaction?.purpose === "SWAP" ? (
                        <Typography>
                          {transaction?.from_currency}{" "}
                          {parseAmount(
                            transaction?.from_amount,
                            swapCurrencyType
                          )}
                          {"<->"} {transaction?.to_currency}{" "}
                          {parseAmount(transaction?.to_amount, currencyType)}
                        </Typography>
                      ) : null}
                    </div>
                  )}
                  {transaction?.purpose === "SWAP" && (
                    <>
                      <div className={styles.details_item}>
                        <Typography>Rate</Typography>
                        <Typography>
                          {transaction?.from_amount < transaction?.to_amount
                            ? ` 1 ${transaction?.from_currency} = ${
                                transaction?.to_currency
                              } ${parseAmount(transaction?.trx_rate)}`
                            : ` 1 ${transaction?.to_currency} = ${
                                transaction?.from_currency
                              } ${parseAmount(transaction?.trx_rate)}`}
                        </Typography>
                      </div>
                      <div className={styles.details_item}>
                        <Typography>Settlement time</Typography>

                        <Typography>
                          {new Date(expiryTime)?.toISOString()?.slice(11, -5)}
                        </Typography>
                      </div>
                    </>
                  )}
                  {transaction?.purpose?.includes("SWAP") ? null : (
                    <div className={styles.details_item}>
                      {transaction?.purpose?.includes("WITHDRAWAL") ? (
                        <Typography>Amount settled</Typography>
                      ) : (
                        <Typography>Amount received</Typography>
                      )}

                      <Typography>
                        {parseAmount(transaction?.amount_settled, currencyType)}{" "}
                        {transaction?.currency ||
                          transaction?.liquidity?.to_currency}
                      </Typography>
                    </div>
                  )}

                  <div className={styles.details_item}>
                    <Typography>
                      Fees (VAT inclusive){" "}
                      <span>
                        <img src={info_icon} alt="" />
                      </span>{" "}
                    </Typography>
                    <Typography>
                      {parseAmount(transaction?.fee)}{" "}
                      {transaction?.fee_currency}
                    </Typography>
                  </div>

                  <div className={styles.details_item}>
                    <Typography>Fee bearer</Typography>
                    <Typography>
                      {transaction?.fee_bearer
                        ? transaction?.fee_bearer === "merchant"
                          ? "SELF"
                          : transaction?.fee_bearer
                        : "NA"}
                    </Typography>
                  </div>
                  <div className={styles.details_item}>
                    <Typography>Status</Typography>
                    <Typography>{transaction?.status}</Typography>
                  </div>
                  {transaction?.purpose?.includes("SWAP") ? null : (
                    <div className={styles.details_item}>
                      <Typography>Payment method</Typography>
                      <Typography>
                        {transaction?.payment_slug?.replace(/-/g, " ")}
                      </Typography>
                    </div>
                  )}

                  <div className={styles.details_item}>
                    <Typography>Channel</Typography>
                    <Typography>{transaction?.channel}</Typography>
                  </div>

                  <div className={styles.details_item}>
                    <Typography>Merchant Reference</Typography>
                    <div className={styles.merchant_ref}>
                      <Typography>
                        {" "}
                        {transaction?.merchant_reference ? (
                          <>
                            {" "}
                            {transaction?.merchant_reference}
                          
                          </>
                        ) : (
                          "NA"
                        )}
                      </Typography>
                      {transaction?.merchant_reference ? (
                        <div className={styles.copy_btn}>
                          <CopyButton
                            copy={transaction?.merchant_reference}
                            setCopied={setCopied2}
                            copied={copied2}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.details_item}>
                    <Typography>Description</Typography>
                    <Typography>{transaction?.narration || "NA"}</Typography>
                  </div>

                  {currencyType === "CRYPTO" && (
                    <div className={styles.details_item}>
                      <Typography>Transaction hash</Typography>
                      <div className={styles.merchant_ref}>
                        <Typography>
                          {" "}
                          {transaction?.trace_id || "NA"}
                        </Typography>
                        {transaction?.trace_id ? (
                          <div className={styles.copy_btn}>
                            <CopyButton
                              copy={transaction?.trace_id}
                              setCopied={setCopied3}
                              copied={copied3}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}

                  {(transaction?.status === "refunded" ||
                    transaction?.status === "failed") && (
                    <div className={styles.details_item}>
                      <Typography>Failure reason</Typography>
                      <Typography>
                        {transaction?.reject_reason || "NA"}
                      </Typography>
                    </div>
                  )}

                  <div
                    className={` ${styles.last_item} ${styles.details_item}`}
                  >
                    {transaction?.purpose.includes("DEPOSIT") ? (
                      <Typography>Total amount</Typography>
                    ) : (
                      <Typography>Total amount</Typography>
                    )}

                    <Typography>
                      {parseAmount(transaction?.amount_charged, currencyType)}{" "}
                      {transaction?.purpose?.includes("SWAP")
                        ? transaction?.from_currency
                        : transaction?.currency}
                    </Typography>
                  </div>
                  {transaction?.purpose?.includes("WITHDRAWAL") && (
                    <div className={styles.recipient}>
                      <div className={styles.recipient_heading}>
                        <Typography>Recipient</Typography>
                      </div>
                      {currencyType === "CRYPTO" ? (
                        <div className={styles.recipient_body}>
                          <div className={styles.details_item}>
                            <Typography>Wallet address</Typography>
                            <Typography>
                              {JSON.parse(transaction?.beneficiary)?.address}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.recipient_body}>
                          <div className={styles.details_item}>
                            <Typography>Account name</Typography>
                            <Typography>
                              {
                                JSON.parse(transaction?.beneficiary)
                                  ?.account_name
                              }
                            </Typography>
                          </div>
                          <div className={styles.details_item}>
                            <Typography>Bank</Typography>
                            <Typography>
                              {
                                JSON.parse(transaction?.beneficiary)
                                  ?.name_of_bank
                              }
                            </Typography>
                          </div>
                          <div className={styles.details_item}>
                            <Typography>Account number</Typography>
                            <Typography>
                              {
                                JSON.parse(transaction?.beneficiary)
                                  ?.account_number
                              }
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {transaction?.purpose?.includes("DEPOSIT") &&
                Boolean(JSON.parse(transaction?.beneficiary || null)) && (
                  <div>
                    <div className={styles.transaction_details_heading}>
                      <Typography>BENEFICIARY DETAILS</Typography>
                    </div>
                    <div className={styles.transaction_details_body}>
                      <div className={styles.details_item}>
                        <Typography>Account Name</Typography>
                        <Typography>
                          {JSON.parse(transaction?.beneficiary)?.account_name ||
                            "NA"}
                        </Typography>
                      </div>
                      <div className={styles.details_item}>
                        <Typography>Account Number</Typography>
                        <Typography>
                          {JSON.parse(transaction?.beneficiary)
                            ?.account_number || "NA"}
                        </Typography>
                      </div>
                      <div className={styles.details_item}>
                        <Typography>Bank Name</Typography>
                        <Typography>
                          {JSON.parse(transaction?.beneficiary)?.bank_name ||
                            "NA"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </Styles>
      {isShowingShareReceipt && (
        <AppBackdrop
          handleCloseModal={() => {
            toggle("share-receipt");
          }}
          openModal={isShowingShareReceipt}
          child={
            <ModalFrame
              style={modalFrameStyle}
              handleAction={() => {
                toggle("share-receipt");
              }}
              child={<ShareReceipt transaction={transaction} />}
            />
          }
        />
      )}
    </Layout>
  );
};

export default TransactionDetails;
