import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Styles, Card } from "./styles";
import { Grid } from "../../../../UI-components/box";
import { BrowseIcon } from "../../../../../assets";
import Button from "../../../../UI-components/button";
import { Skeleton } from "@mui/material";
import { DataCentral } from "../../../../../context/AppContext";
import usePermission from "../../../../../customHooks/usePermissions";
import { parseAmount } from "../../../../../utils/helpers";

const Index = ({
  loadingTotalPendingTransactions,
  totalPendingTransactions,
  balanceData,
  totalTransactions,
  loadingTotalTransactions,
  loadingBalances,
}) => {
  const { user } = useContext(DataCentral);
  const [active, setActive] = useState("");
  const [activeLink, setActiveLink] = useState("total");
  const { checkPermission } = usePermission();
  useEffect(() => {
    setActive(balanceData?.total);
  }, [balanceData]);

  const handleOnSelectActive = (value) => {
    setActive(balanceData[value]);
    setActiveLink(value);
  };

  return (
    <Styles>
      <Grid
        justifyContent={"space-around"}
        gap="50px"
        gridCol="repeat(auto-fit, minmax(270px, 1fr))"
      >
        <Card
          border={"1px solid green"}
          alignItem={"flex-start"}
          flexDir={"column"}
        >
          <>
            <div className="heading">
              <p>Balances</p>
            </div>
            <div className="card-item">
              {loadingBalances ? (
                <Skeleton variant="rectangular" width={"50%"} height={"18px"} />
              ) : (
                <p className="volume">
                  {balanceData?.currency?.code_position === "left" &&
                    balanceData?.currency?.code}{" "}
                  {parseAmount(active, balanceData?.currency.type)}
                  {/* {active?.toLocaleString(
                    "en-US",
                    currencies?.result?.find((currency) => {
                      if (currency.code === balanceData?.currency?.code) {
                        return currency.name;
                      } else {
                        return null;
                      }
                    })?.type === "CRYPTO"
                      ? { minimumFractionDigits: 8 }
                      : { minimumFractionDigits: 2 }
                  )}{" "} */}
                  {balanceData?.currency?.code_position === "right" &&
                    balanceData?.currency?.code}
                </p>
              )}
            </div>
            <div className="card-footer-item">
              <Button
                className={activeLink === "total" ? "active" : "non-active"}
                text={"Total"}
                size="sm"
                type="button"
                borderRadius={"5px"}
                onClick={() => {
                  handleOnSelectActive("total");
                }}
              />
              <Button
                className={activeLink === "main" ? "active" : "non-active"}
                text={"Main"}
                size="sm"
                type="button"
                borderRadius={"5px"}
                onClick={() => {
                  handleOnSelectActive("main");
                }}
              />
              {checkPermission("liquidity") && (
                <Button
                  className={
                    activeLink === "liquidity" ? "active" : "non-active"
                  }
                  text={"Liquidity"}
                  size="sm"
                  type="button"
                  borderRadius={"5px"}
                  onClick={() => {
                    handleOnSelectActive("liquidity");
                  }}
                />
              )}
            </div>
          </>
        </Card>

        <Card>
          <div className="heading">
            <p>Transactions</p>
            <Link to="/transactions/swap">View all</Link>
          </div>
          <div className="card-item">
            <h6>Total</h6>
            {loadingTotalTransactions ? (
              <Skeleton variant="rectangular" width={"50%"} height={"100%"} />
            ) : (
              <p className="blue">
                {totalTransactions?.currency?.code_position === "left" &&
                  totalTransactions?.currency?.code}{" "}
                {parseAmount(
                  totalTransactions?.result,
                  totalPendingTransactions?.currency.type
                )}
                {/* {totalTransactions?.result?.toLocaleString(
                  "en-US",
                  currencies?.result?.find((currency) => {
                    if (
                      currency.code === totalPendingTransactions?.currency?.code
                    ) {
                      return currency.name;
                    } else {
                      return null;
                    }
                  })?.type === "CRYPTO"
                    ? { minimumFractionDigits: 8 }
                    : { minimumFractionDigits: 2 }
                )}{" "} */}
                {totalTransactions?.currency?.code_position === "right" &&
                  totalTransactions?.currency?.code}
              </p>
            )}
          </div>
          <div className="card-item">
            <h6>Pending</h6>
            {loadingTotalPendingTransactions ? (
              <Skeleton variant="rectangular" width={"50%"} height={"100%"} />
            ) : (
              <p className="red">
                {totalPendingTransactions?.currency?.code_position === "left" &&
                  totalPendingTransactions?.currency?.code}{" "}
                {parseAmount(
                  totalPendingTransactions?.result,
                  totalPendingTransactions?.currency.type
                )}
                {/* {totalPendingTransactions?.result?.toLocaleString(
                  "en-US",
                  currencies?.result?.find((currency) => {
                    if (
                      currency.code === totalPendingTransactions?.currency?.code
                    ) {
                      return currency.name;
                    } else {
                      return null;
                    }
                  })?.type === "CRYPTO"
                    ? { minimumFractionDigits: 8 }
                    : { minimumFractionDigits: 2 }
                )}{" "} */}
                {totalPendingTransactions?.currency?.code_position ===
                  "right" && totalPendingTransactions?.currency?.code}
              </p>
            )}
          </div>
        </Card>
        {checkPermission("settings.api") && (
          <Card>
            <div className="heading">
              <p>API</p>
              <a href="https://developers.shutterscore.io/">
                {" "}
                Browse docs <BrowseIcon />
              </a>
            </div>
            <div className="card-item">
              <h6>Status</h6>
              <div
                className={`${
                  user?.mode === "live" ? "chip_live chip" : "chip_test chip"
                }`}
              >
                <p>{user?.mode}</p>
              </div>
            </div>
            <div className="card-item">
              <h6>Config</h6>
              <Link to="/settings/API">View keys</Link>
            </div>
          </Card>
        )}
      </Grid>
    </Styles>
  );
};

export default Index;
