import { Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./ShareReceipt.module.css";
import Button from "../../../../../../UI-components/button/index";
import share_icon from "../../../../../../../assets/images/share-icon.svg";
import link_icon from "../../../../../../../assets/images/link-icon.svg";
import { useNavigate } from "react-router";


function ShareReceipt({transaction}) {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false)

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (error) {}
  };
  return (
    <div className={styles.share_receipt}>
      <div className={styles.heading}>
        <div className={styles.heading_img}>
          <img src={share_icon} alt="share" />
        </div>
        <div className={styles.heading_text}>
          <Typography variant="h6">Share receipt</Typography>
          <Typography>
            Share this receipt by sending the transaction link to your
            recipients or downloading as pdf
          </Typography>
        </div>
      </div>
      <div className={styles.input}>
        <label htmlFor="">
          <div className={styles.label}>
            <Typography>Transaction link</Typography>
          </div>

          <div className={styles.input_box}>
            <input type="text" />
            <div  onClick={() => {
              navigate('/receipt', {
                state: transaction 
              })
            }} className={styles.input_icon}>
              <img src={link_icon} alt="link" />
              <Typography>Visit</Typography>
            </div>
          </div>
        </label>
      </div>
      <div className={styles.btns}>
        <div className={styles.btn}>
          <Button
            border={"1px solid #D7D7D7"}
            fullwidth
            onClick={() => {
              handleCopy('link')
            }}
            text={copied ? 'Copied' : "Copy link"}
            bgColor={"#FFFFFF"}
            color={"#212121"}
            size="sm"
            type={"click"}
            borderRadius={"5px"}
          />
        </div>
        <div className={styles.btn}>
          <Button
            onClick={() => {
              navigate('/receipt', {
                state: {
                  transaction }
              })
            }}
            fullwidth
            text={"Download receipt"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="sm"
            type={"click"}
            borderRadius={"5px"}
          />
        </div>
      </div>
    </div>
  );
}

export default ShareReceipt;
