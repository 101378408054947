import axios from "axios";
import { endpointFilterParser } from "../../../../utils/helpers";

export const GET_TRANSACTIONS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}transactions?order=DESC&page=${
        data.queryKey[2]
      }&take=10&type=${data.queryKey[1]}${endpointFilterParser(
        data.queryKey[3],
        "ref"
      )}${endpointFilterParser(
        data.queryKey[4],
        "currency"
      )}${endpointFilterParser(data.queryKey[5], "status")} `
    )
    .then((res) => {
      return res.data;
    });
};

export const GET_REFUND_TRANSACTIONS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}transactions/refund?order=DESC&page=${
        data.queryKey[2]
      }&take=10&type=${data.queryKey[1]}${endpointFilterParser(
        data.queryKey[3],
        "ref"
      )}${endpointFilterParser(
        data.queryKey[4],
        "currency"
      )}${endpointFilterParser(data.queryKey[5], "status")} `
    )
    .then((res) => {
      return res.data;
    });
};

export const GET_SINGLE_TRANSACTIONS = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}transactions/${data.queryKey[1]}`)
    .then((res) => {
      return res.data.data;
    });
};

export const GET_SINGLE_REFUND_TRANSACTIONS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}transactions/refund/${data.queryKey[1]}`
    )
    .then((res) => {
      return res.data.data;
    });
};
export const DOWNLOAD_REPORT = async (data) => {
  return axios({
    url: `${
      process.env.REACT_APP_BASE_URL
    }transactions/report?order=ASC&page=1&take=10&to=${data.to}&from=${
      data.from
    }&type=${data.transaction_type === "ALL" ? "" : data.transaction_type}`,
    method: "GET",
  }).then((res) => {
    return res.data;
  });
};
