import axios from "axios";

export const GET_TOTAL_TRANSACTIONS_SUMMARY = async (data) => {

    return axios
      .get(`${process.env.REACT_APP_BASE_URL}transactions/summary?column=amount&math=sum`)
      .then((res) => {
        return res.data;
      });
  };

  export const GET_TOTAL_PENDING_TRANSACTIONS_SUMMARY = async (data) => {

    return axios
      .get(`${process.env.REACT_APP_BASE_URL}transactions/summary?column=amount&math=sum&status=pending`)
      .then((res) => {
        return res.data;
      });
  };


  export const GET_WALLET_SUMMARY = async (data) => {

    return axios
      .get(`${process.env.REACT_APP_BASE_URL}wallet/summary`)
      .then((res) => {
        return res.data;
      });
  };

  export const GET_MARKET_RATES= async (data) => {

    return axios
      .get(`${process.env.REACT_APP_BASE_URL}market/rates`)
      .then((res) => {
        return res.data;
      });
  };