
import React, { useContext, useEffect, useState } from "react";
import { Flex } from "../../../UI-components/box";
import Button from "../../../UI-components/button";

import { FormContainer } from "./styles";
import { HiddenVisibilityIcon, PasswordEyeIcon } from "../../../../assets/";
import {
  Label,
  InputStyle,
  FieldWrapper,
} from "../../../UI-components/input/styles";
import { useSearchParams, useNavigate } from "react-router-dom";
import PasswordContext from "../../../UI-components/password-context/PasswordContext";
import ValidationMessage from "../../../UI-components/validation-message/ValidationMessage";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import { useMutation } from "@tanstack/react-query";
import { FORGOT_PASSWORD } from "../../../../api/mutations/auth";

const Fields = () => {
  const {
    openSnackbar,
    message,
    severity,
    duration,
    setOpenToast,
    openToast,
  } = useOpenSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState();
  const [visibility2, setVisibility2] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorSpecialChar, setPasswordErrorSpecialChar] = useState({});
  const [passwordErrorEightChar, setPasswordErrorEightChar] = useState({});
  const [passwordErrorNumber, setPasswordErrorNumber] = useState({});

  const { mutate, isLoading } = useMutation(FORGOT_PASSWORD, {
    onSuccess: (response) => {
      navigate("/signin"); 
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });


  useEffect(() => {
    handleSpecialChar(confirmPassword);
    handleNumberValidation(confirmPassword);
    handleEightChar(confirmPassword);
  }, [confirmPassword]);

  const handleEightChar = (value) => {
    if (value.length < 8) {
      setPasswordErrorEightChar({ ...passwordErrorEightChar, eightChar: true });
    } else {
      const tempPass = { ...passwordErrorEightChar };
      tempPass.eightChar = false;
      setPasswordErrorEightChar(tempPass);
    }
  };

  const handleNumberValidation = (value) => {
    const numberRegex = /\d/;
    if (numberRegex.test(value)) {
      const tempPass = { ...passwordErrorNumber };
      tempPass.number = false;
      setPasswordErrorNumber(tempPass);
    } else {
      setPasswordErrorNumber({ ...passwordErrorNumber, number: true });
    }
  };
  const handleSpecialChar = (value) => {
    const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialCharRegex.test(value)) {
      const tempPass = { ...passwordErrorSpecialChar };
      tempPass.specialChar = false;
      setPasswordErrorSpecialChar(tempPass);
    } else {
      setPasswordErrorSpecialChar({
        ...passwordErrorSpecialChar,
        specialChar: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "new-password") {
      setNewPassword(value);
    } else if (name === "confirm-password") {
      setconfirmPassword(value);
    } else {
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      if (
        !passwordErrorSpecialChar.specialChar &&
        !passwordErrorNumber.number &&
        !passwordErrorEightChar.eightChar
      ) {
        mutate({
          password: newPassword,
          hash: searchParams.get("hash"),
        })
     
      } else {
      }
    } else {
      setErrorMessage("Password has to match");
    }
  };

  return (
    <FormContainer>
      <Flex flexDir={"column"} gap={"30px"}>
        <InputStyle>
          <Label htmlFor="fullName">Enter new password </Label>
          <FieldWrapper btn={"btn"}>
            <input
              type={visibility ? "text" : "password"}
              onChange={handleChange}
              name={"new-password"}
              value={newPassword}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setVisibility(!visibility);
              }}
            >
              {visibility ? (
                <PasswordEyeIcon width={"20px"} height={"20px"} />
              ) : (
                <HiddenVisibilityIcon width={"20px"} height={"20px"} />
              )}
            </button>
          </FieldWrapper>
        </InputStyle>
        <InputStyle>
          <Label htmlFor="fullName">Confirm password</Label>
          <FieldWrapper btn={"btn"}>
            <input
              type={visibility2 ? "text" : "password"}
              onChange={handleChange}
              name={"confirm-password"}
              value={confirmPassword}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setVisibility2(!visibility2);
              }}
            >
              {visibility2 ? (
                <PasswordEyeIcon width={"20px"} height={"20px"} />
              ) : (
                <HiddenVisibilityIcon width={"20px"} height={"20px"} />
              )}
            </button>
          </FieldWrapper>
          <ValidationMessage message={errorMessage} />
          <PasswordContext
            passwordErrorEightChar={passwordErrorEightChar}
            passwordErrorNumber={passwordErrorNumber}
            passwordErrorSpecialChar={passwordErrorSpecialChar}
          />
        </InputStyle>
        <Button
          text={"Reset Password"}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type="submit"
          borderRadius={"5px"}
          loading={isLoading}
          onClick={handleResetPassword}
        />
      </Flex>
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </FormContainer>
  );
};

export default Fields;
