import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import GoBack from "../../../../UI-components/goBack/GoBack";
import Layout from "../../../../UI-components/layouts";
import TableOne from "../../../../UI-components/new -tables/Table";
import { Styles } from "../styles";
import OverviewCards from "./components/overviewCards/OverviewCards";
import styles from "./WalletAccounts.module.css";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../UI-components/loader/Loader";
import Pagination from "../../../../UI-components/pagination/Pagination";
import Overview from "./components/overview/Overview";
import { useParams } from "react-router-dom";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import SettlementTab from "../../../../UI-components/settlementTabs/SettlementTabs";
import WalletAccountsFilter from "./components/filter/WalletAccountsFilter";
import AppBackdrop from "../../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrame from "../../../../UI-components/modalFrame/ModalFrame";
import useModal from "../../../../../customHooks/useModal";
import NewAccount from "./components/newAccount/NewAccount";
import { GET_WALLETS_ACCOUNTS } from "../../../../../api/queries/dashboard/wallets";
import BottomFilter from "../components/bottomFilter/BottomFilter";
import { useForm } from "react-hook-form";

const modalFrameStyle = {
  height: "auto",
  width: 470,
};

const tableHeadDataFiat = [
  "CREATION DATE",
  "ACCOUNT NAME",
  "ACCOUNT NUMBER",
  "CURRENCY",
  "TYPE",
  "",
];

const tableHeadDataCrypto = [
  "CREATION DATE",
  "WALLET ADDRESS",
  "NETWORK",
  "CURRENCY",
  "TYPE",
  "",
];

const accountType = [
  {
    label: "Fiat Accounts",
  },
  {
    label: "Crypto Accounts",
  },
];

function WalletAccounts() {
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const { isShowingAddAccount, toggle } = useModal();
  const params = useParams();
  const { state } = useLocation();
  const [page, setPage] = useState("1");
  const [activeTab, setActiveTab] = useState("Fiat Accounts");
  const [openToolTip, setOpenToolTip] = useState({});
  const [walletCheckbox, setWalletCheckbox] = useState([]);
  const [walletCryptoCheckbox, setWalletCryptoCheckbox] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      currency: "",
    },
  });

  const {
    data: walletsAccounts,
    isLoading,
    refetch,
  } = useQuery(
    ["wallets-accounts", "FIAT", params.id, page, searchValue],
    GET_WALLETS_ACCOUNTS,
    {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: walletsCryptoAccounts,
    isLoading: isLoadingWalletCryptoAccounts,
    refetch: refetchCryptoAccounts
  } = useQuery(
    ["wallets-accounts", "CRYPTO", params.id, page, searchValue],
    GET_WALLETS_ACCOUNTS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleConfirmWalletDelete = () => {};

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSetActiveTab = (activeTab) => {
    setActiveTab(activeTab);
  };

  const handleTooltipClose = () => {
    setOpenToolTip({});
  };

  const handleOpenTooltip = (id) => {

    if (openToolTip.open) {
      setOpenToolTip({});
    } else {
      setOpenToolTip({ open: true, id });
    }
  };

  const handleSelectAll = (value) => {
    if (value) {
      setWalletCheckbox(
        walletsAccounts?.data?.data?.map((item) => {
          return item.account_reference;
        })
      );
    } else {
      setWalletCheckbox([]);
    }
  };

  const handleSelectSingleCheckbox = (e, id) => {
    const { checked } = e.target;

    if (checked) {
      setWalletCheckbox([...walletCheckbox, id]);
    } else {
      const remWalletCheckbox = walletCheckbox.filter((item) => {
        return item !== id;
      });
      setWalletCheckbox(remWalletCheckbox);
    }
  };

  const handleSelectAllCrypto = (value) => {
    if (value) {
      setWalletCryptoCheckbox(
        walletsCryptoAccounts?.data?.data?.map((item) => {
          return item.account_reference;
        })
      );
    } else {
      setWalletCryptoCheckbox([]);
    }
  };

  const handleSelectSingleCheckboxCrypto = (e, id) => {
    const { checked } = e.target;

    if (checked) {
      setWalletCryptoCheckbox([...walletCryptoCheckbox, id]);
    } else {
      const remWalletCheckbox = walletCryptoCheckbox.filter((item) => {
        return item !== id;
      });
      setWalletCryptoCheckbox(remWalletCheckbox);
    }
  };

 
  const onSubmit = () => {};

  return (
    <Layout title={state?.data?.full_name} bgColor={"#ffffff"}>
      <Styles>
        <div className={styles.heading}>
          <GoBack />
        </div>
        <OverviewCards params={params} />
        <Overview openSnackbar={openSnackbar} data={state?.data} />

        {isLoading || isLoadingWalletCryptoAccounts ? (
          <Loader />
        ) : (
          <div className={styles.table}>
            <div className={styles.table_heading}>
              <SettlementTab
                activeTab={activeTab}
                tabs={accountType.filter((tab) => {
                  return tab;
                })}
                handleSetActiveTab={handleSetActiveTab}
              />
            </div>
            <WalletAccountsFilter setSearchValue={setSearchValue} toggle={toggle} />
            <div className={styles.body}>
              <>
                {activeTab === "Fiat Accounts" ? (
                  <>
                    <TableOne
                      handleSelectAll={handleSelectAll}
                      handleSelectSingleCheckbox={handleSelectSingleCheckbox}
                      walletCheckbox={walletCheckbox}
                      walletActiveTab={activeTab}
                      openToolTip={openToolTip}
                      handleCloseTooltip={handleTooltipClose}
                      handleOpenTooltip={handleOpenTooltip}
                      handleRefresh={refetch}
                      tableBody={walletsAccounts?.data?.data}
                      type="wallet_accounts"
                      tableHeadData={tableHeadDataFiat}
                      openSnackbar={openSnackbar}
                    />
                    {walletsAccounts?.data?.data?.length > 0 ? (
                      <>
                        <BottomFilter
                          walletCheckbox={walletCheckbox}
                          onSubmit={onSubmit}
                          handleSubmit={handleSubmit}
                          handleSelectAll={handleSelectAll}
                        />
                        <Pagination
                          handlePageChange={handlePageChange}
                          meta={walletsAccounts?.data?.meta}
                        />
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    <TableOne
                        walletCheckbox={walletCryptoCheckbox}
                      handleSelectAll={handleSelectAllCrypto}
                      handleSelectSingleCheckbox={handleSelectSingleCheckboxCrypto}
                      walletActiveTab={activeTab}
                      openToolTip={openToolTip}
                      handleCloseTooltip={handleTooltipClose}
                      handleOpenTooltip={handleOpenTooltip}
                      handleRefresh={refetchCryptoAccounts}
                      tableBody={walletsCryptoAccounts?.data?.data}
                      type="wallet_accounts_crypto"
                      tableHeadData={tableHeadDataCrypto}
                      openSnackbar={openSnackbar}
                    />
                    {walletsCryptoAccounts?.data?.data?.length > 0 ? (
                      <>
                        <BottomFilter
                          walletCheckbox={walletCryptoCheckbox}
                          onSubmit={onSubmit}
                          handleSubmit={handleSubmit}
                          handleSelectAll={handleSelectAll}
                        />
                        <Pagination
                          handlePageChange={handlePageChange}
                          meta={walletsCryptoAccounts?.data?.meta}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </>
            </div>
          </div>
        )}
      </Styles>

      {/* {isShowingPrompt && (
        <AppBackdrop
          openModal={isShowingPrompt}
          handleCloseModal={() => {
            toggle("confirmation-prompt");
          }}
          child={
            <ModalFrame2
              btnColor={"#D80027"}
              btnLoading={""}
              handleActionTwo={() => {
                toggle("confirmation-prompt");
              }}
              handleAction={() => {
                handleConfirmWalletDelete();
              }}
              btnTxt={"Yes, delete"}
              child={
                <ConfirmationPrompt
                  header="Delete this wallet??"
                  text={"Funds stored in deleted wallets cannot be recovered."}
                />
              }
            />
          }
        />
      )} */}

      {isShowingAddAccount && (
        <AppBackdrop
          handleCloseModal={() => {
            //toggle("new-wallets");
          }}
          openModal={isShowingAddAccount}
          child={
            <ModalFrame
              style={modalFrameStyle}
              handleAction={() => {
                toggle("new-wallet-account");
              }}
              child={
                <NewAccount
                  wallet_info={state.data}
                  openSnackbar={openSnackbar}
                  handleCloseModal={() => {
                    toggle("new-wallet-account");
                  }}
                />
              }
            />
          }
        />
      )}

      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </Layout>
  );
}

export default WalletAccounts;
