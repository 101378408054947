import React from 'react';
import styles from './RequestOverdraftSuccessful.module.css';
import successful_icon from '../../../../../../../../assets/images/successful.svg';
import { Link } from 'react-router-dom';
import HeadingOne from '../../../../../../../UI-components/headings/headingsOne/HeadingsOne';

const RequestOverdraftSuccessful = ({amount, currency_pair, handleAction }) => {
	return (
		<div
			className={styles.order_successful}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<img src={successful_icon} alt='successful icon' />
			<div className={styles.heading}>
				<HeadingOne
					title={'Request submitted'}
					description={
						`Your request USDT ${amount} on ${currency_pair} for overdraft has been submitted successfully`
					}
				/>
			</div>
			<div className={styles.order_successful_btn}>
				<Link to='/overdraft'>Back to home</Link>
			</div>
		</div>
	);
};

export default RequestOverdraftSuccessful;
