import React from "react";
import styles from "./SettlementTabs.module.css";
import { Typography } from "@mui/material";

const SettlementTab = ({
  handleOpenExport,
  tabs,
  activeTab,
  style,
  handleSetActiveTab,
  export_tab,
}) => {
  return (
    <div style={{ width: "100%", flex: 1 }} className={styles.settlement_tabs}>
      <div className={styles.tabs}>
        {tabs.map((tab) => {
          return (
            <div
              style={style}
              className={`${styles.settlement_tab} ${
                activeTab === tab.label ? styles.settlement_tab_active : null
              }`}
              onClick={() => {
                handleSetActiveTab(tab.label);
              }}
              key={tab.label}
            >
              <Typography>{tab.label}</Typography>
            </div>
          );
        })}
      </div>
      {export_tab ? (
        <div
          onClick={() => {
            handleOpenExport();
          }}
          className={styles.export_data}
        >
          <Typography>Download Report</Typography>
        </div>
      ) : null}
    </div>
  );
};

export default SettlementTab;
