import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../../../assets";
import styles from "./GoBack.module.css";

function GoBack() {
  const navigate = useNavigate();

  const handleGoBack = (e) => {
  
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className={styles.go_back}>
      <div onClick={handleGoBack} className={styles.go_back_div}>
      <BackIcon /> <Typography>Go Back</Typography>
      </div>

    </div>
  );
}

export default GoBack;
