import { Typography } from '@mui/material';
import React from 'react';
import HeadingOne from '../../../../../UI-components/headings/headingsOne/HeadingsOne';
import styles from './DepositSwapPay.module.css';
import Button from '../../../../../UI-components/button';
import CopyButton from '../../../../../UI-components/copy-button/CopyButton';

const DepositSwapPay = ({ handleMethod }) => {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
			className={styles.deposit_crypto}
		>
			<HeadingOne
				title={'deposit via Swap pay'}
				description={'Choose a payment method to complete your deposit'}
			/>

			<div className={styles.deposit_crypto_content_main}>
				<Typography>Make deposit to</Typography>

				<div className={styles.deposit_bank_name}>
					<Typography>Bank Name</Typography>
					<Typography>Providus Bank</Typography>
				</div>
				<div className={styles.deposit_bank_name}>
					<Typography>Account Number</Typography>
					<Typography>0083775638</Typography>
				</div>
				<div
					style={{ marginTop: '12px', marginBottom: '26px', display: 'flex' }}
				>
					<CopyButton />
				</div>
				<Typography>Expires in 30:00</Typography>
			</div>
			<Button
				Styles
				text={'I have done this'}
				bgColor={'#1A2CCE'}
				color={'#FFFFFF'}
				size='md'
				fullwidth
				type='submit'
				border={'0.4px solid #1A2CCE'}
				boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
				borderRadius={'5px'}
				onClick={() => {
					handleMethod('success');
				}}
			/>
			<Button
				Styles
				text={'Change payment method'}
				color={'#676767'}
				size='md'
				fullwidth
				type='submit'
				onClick={() => {
					handleMethod('');
				}}
			/>
		</div>
	);
};

export default DepositSwapPay;
