import { Typography } from "@mui/material";
import React from "react";
import HeadingOne from "../headings/headingsOne/HeadingsOne";
import styles from "./EnterOtp.module.css";
import Button from "../button/index";
import OtpInput from "react-otp-input";
import ValidationMessage from "../validation-message/ValidationMessage";

const EnterOtp = ({
  otpError,
  otp,
  handleChange,
  type,
  loadingConfirm,
  handleConfirm,
  handleBackToScan,
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.enter_otp}
    >
      <div className={styles.heading}>
        <HeadingOne
          title={type === "enter" ? "enter your OTP" : "Disable 2FA?"}
          description={
            type === "enter"
              ? "Enter the 6-digit code generated on your authenicator app"
              : "Turning this off will make disable access and security provided by authenticator "
          }
        />
      </div>
      {type === "disable" ? (
        <div className={styles.info}>
          <Typography>Enter code generated to proceed</Typography>
        </div>
      ) : null}
      <div className={`${otpError?.otp ? styles.red_outline : null} ${styles.inputs}`}>
        <OtpInput
          onChange={handleChange}
          value={otp}
          numInputs={6}
          separator={<span></span>}
        />
        <ValidationMessage message={otpError?.otp ? '6 digit OTP code is required' : null} />
      </div>
      <div className={styles.otp_btns}>
        <Button
          text={"Confirm"}
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type="button"
          borderRadius={"5px"}
          onClick={handleConfirm}
          loading={loadingConfirm}
        />
        {type === "enter" ? (
          <button onClick={handleBackToScan}>Back to scan</button>
        ) : null}
      </div>
    </div>
  );
};

export default EnterOtp;
