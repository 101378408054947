import React from 'react';
import HeadingOne from '../../../../../../UI-components/headings/headingsOne/HeadingsOne';
import styles from './DisablePrompt.module.css';
const DisablePrompt = () => {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
			className={styles.heading}
			width={'419px'}
		>
			<HeadingOne
				title={'Disable Two-factor authentication? '}
				description={`Turning off 2FA will make your account less secure.
					Do you wish to proceed?`}
			/>
		</div>
	);
};

export default DisablePrompt;
