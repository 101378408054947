import styled from 'styled-components';
import bg from '../../../../../assets/images/strip.svg';

export const VerifyBuisnessStyle = styled.div`
	width: 100%;
	padding: 23px;
	background-color: #fff;
	display: flex;
	align-items: center;
	border-radius: 5px;
	mix-blend-mode: normal;
	position: relative;

	> div > span {
		border-radius: 50%;
		width: 44px;
		height: 44px;
		background-color: #f3f6fb;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 13px;
		z-index: 2;
	}
	> img {
		position: absolute;
		right: 0;
		z-index: 1;
	}
	> div > div > p {
		color: #383838;
		padding-top: 10px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		color: #383838;
	}
	h2 {
		color: #1a1f36;
		font-size: 18px;
		font-weight: bold;
		font-size: 18px;
		line-height: 23px;
	}
	.link_container {
		display: flex;
		z-index: 2;
		// background-image: url(${bg});
		// background-repeat: no-repeat;
		// background-position: right;
	}
	.link_box {
		background-color: #3ab75d;
		text-align: center;
		height: 48px;
		width: 173px;
		color: #fff;
		border-radius: 5px;
		cursor: pointer;
		margin-right: 30px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		:hover {
			background-color: #3ab75db9
		}
	}
	.link_box_grey {
		background-color: #f3f3f3;
		// background-color: #c6ddff;
		width: 120px;
		padding: 0.8em 0;
		color: #1a1f36;
		:hover {
			background-color: #dadada
		}
	}
	@media (max-width: 1000px) {
		flex-direction: column;
		.verify_container {
			width: 100%;
		}
		.link_container {
			width: 100%;
			margin-top: 15px;
			justify-content: space-between;
			background-image: none;
		}
		h2 {
			font-size: 18px;
		}
		> div > div > p {
			font-size: 14px;
		}
		div > span {
			margin-right: 20px;
		}
	}
`;
