import React from "react";
import { Typography } from "@mui/material";
import styles from "./LabeledTextarea.module.css";

const LabeledTextarea = ({required,  register, label,redOutline, placeholder, name }) => {
  return (
    <label className={styles.labeled_input} htmlFor="">
      <div className={styles.heading}>
      <Typography>{label}</Typography>
      </div>

      <textarea
        className={redOutline? styles.red_outline : null}
        name={name}
        placeholder={placeholder}
        {...register(name, { required:required})}
      ></textarea>
    </label>
  );
};

export default LabeledTextarea;
