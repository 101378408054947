import { Typography } from "@mui/material";
import React from "react";
import styles from "./ModeAlerts.module.css";

const ModalModeAlert = () => {
  return (
    <div className={styles.mode_alert}>
      <Typography>
        This is a test account. Sending real funds into this account can result
        to loss of money
      </Typography>
    </div>
  );
};

export default ModalModeAlert;
