import React, { useContext, useEffect, useState } from "react";
import styles from "./ConfirmWithdrawal.module.css";
import { Typography } from "@mui/material";
import Button from "../../../../../UI-components/button";
import { calculateFee, parseAmount } from "../../../../../../utils/helpers";

const ConfirmWithdrawal = ({
  usdcNetworkData,
  fees,
  requirements,
  paymentMethod,
  amount,
  withdrawalLoading,
  handleConfirm,
  countries,
  currency,
  branches,
  banks,
}) => {
  const [fee, setFee] = useState(Number);

  useEffect(() => {
    setFee(calculateFee(fees, amount));
  }, []);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.confirm_withdrawal}
    >
      <div className={styles.review}>
        <Typography>
          Please review these details before approving transaction for
          withdrawal
        </Typography>
      </div>
      <div className={styles.heading}>
        <Typography>Confirm Withdrawal</Typography>
      </div>
      <div className={styles.main_text}>
        <Typography>
          {currency.code_position === "left" && currency.code}{" "}
          {parseAmount(amount, currency.type)}
          {/* {Number(amount).toLocaleString()}{" "} */}
          {currency.code_position === "right" && currency.code}
        </Typography>
      </div>

      <hr />
      <div className={styles.details}>
        <div className={styles.detail}>
          <Typography variant="h6">Payment Method</Typography>
          <Typography>{paymentMethod}</Typography>
        </div>

        {requirements.map((item) => {
          if (item.value !== "") {
            if (item.name === "bank") {
              return (
                <div key={item.name} className={styles.detail}>
                  <Typography variant="h6">{item.label}</Typography>
                  <Typography>
                    {banks?.find((bank) => {
                      return bank.code === item.value;
                    })?.name || item?.value}
                  </Typography>
                </div>
              );
            } else if (item.name === "provider") {
              return (
                <div key={item.name} className={styles.detail}>
                  <Typography variant="h6">{item.label}</Typography>
                  <Typography>
                    {banks?.find((bank) => {
                      return bank.code === item.value;
                    })?.name || item?.value}
                  </Typography>
                </div>
              );
            } else if (item.name === "network") {
              return (
                <div key={item.name} className={styles.detail}>
                  <Typography variant="h6">{item.label}</Typography>
                  <Typography>
                    {usdcNetworkData?.find((network) => {
                      return network.value === item.value;
                    })?.label || item?.value}
                  </Typography>
                </div>
              );
            } else if (item.name === "branch") {
              return (
                <div key={item.name} className={styles.detail}>
                  <Typography variant="h6">{item.label}</Typography>
                  <Typography>
                    {
                      branches?.find((branch) => {
                        return branch.swift_code === item.value;
                      })?.branch_name
                    }
                  </Typography>
                </div>
              );
            } else if (item.name === "country") {
              return (
                <div key={item.name} className={styles.detail}>
                  <Typography variant="h6">{item.label}</Typography>
                  <Typography>
                    {
                      countries?.find((country) => {
                        return country.iso2 === item.value;
                      })?.name
                    }
                  </Typography>
                </div>
              );
            } else if (item.name === "bank_country") {
              return (
                <div key={item.name} className={styles.detail}>
                  <Typography variant="h6">{item.label}</Typography>
                  <Typography>
                    {
                      countries?.find((country) => {
                        return country.iso2 === item.value;
                      })?.name
                    }
                  </Typography>
                </div>
              );
            } else {
              return (
                <div key={item.name} className={styles.detail}>
                  <Typography variant="h6">{item.label}</Typography>
                  <Typography>{item.value}</Typography>
                </div>
              );
            }
          }
        })}
        <div className={styles.detail}>
          <Typography variant="h6">Beneficiary receives</Typography>
          <Typography>
            {currency.code} {Number(amount * 1 - fee)?.toLocaleString()}
          </Typography>
        </div>
        {requirements?.narration && (
          <div className={styles.detail}>
            <Typography variant="h6">Narration</Typography>
            <Typography>{requirements?.narration}</Typography>
          </div>
        )}
        <div className={styles.detail}>
          <Typography variant="h6">Fee</Typography>
          <Typography>
            {fees.currency} {fee}
          </Typography>
        </div>
        <div className={styles.detail}>
          <Typography variant="h6">Total</Typography>
          <Typography>
            {currency.code} {Number(amount * 1).toLocaleString()}
          </Typography>
        </div>
      </div>
      <div className={styles.confirm_btn}>
        <Button
          onClick={() => {
            handleConfirm();
          }}
          text="Confirm"
          bgColor={"#1A2CCE"}
          color={"#fff"}
          size="md"
          fullwidth
          type={"button"}
          loading={withdrawalLoading}
          // borderRadius={'5px'}
        />
      </div>
    </div>
  );
};

export default ConfirmWithdrawal;
