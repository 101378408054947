import React from "react";
import styles from "./BinancePayForm.module.css";
import LabeledInputs from "../../../../UI-components/new-inputs/inputs/LabeledInputs";
import ValidationMessage from "../../../../UI-components/validation-message/ValidationMessage";
import LabeledSelectTwo from "../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import Button from "../../../../UI-components/button/index";
import arrow_icon from "../../../../../assets/images/arrow-left.svg";

function BinancePayForm2({
  isCreating,
  handleSubmit,
  countries,
  register,
  errors,
  setFormLevel,
  watch,
}) {
  const storeType = watch("store_type", "");

  return (
    <>
      <div className={styles.body}>
        <div className={styles.previous}>
          <img
            onClick={() => {
              setFormLevel(1);
            }}
            src={arrow_icon}
            alt=""
          />
        </div>
        <div className={styles.form_field}>
          <LabeledInputs
            required={true}
            name="registration_number"
            register={register}
            label="Registration Number"
            redOutline={errors?.registration_number ? true : false}
          />
          <ValidationMessage
            message={
              errors?.registration_number?.message
            }
          />
        </div>
        <div className={styles.form_field}>
          <LabeledInputs
            required={true}
            name="registration_address"
            register={register}
            label="Company registration address"
            redOutline={errors?.registration_address ? true : false}
          />
          <ValidationMessage
            message={
              errors?.registration_address?.message
            }
          />
        </div>

        <div className={styles.form_field}>
          <LabeledSelectTwo
            required={true}
            register={register}
            data={countries?.map((country) => {
              return {
                label: country.name,
                value: country.iso2,
              };
            })}
            placeholder={"Country"}
            label={"Registration country"}
            name={"registration_country"}
            redOutline={errors?.registration_country ? true : false}
          />
          <ValidationMessage
            message={
              errors?.registration_country?.message
            }
          />
        </div>

        <div className={styles.form_field}>
          <LabeledInputs
            type="date"
            required={true}
            name="incorporation_date"
            register={register}
            label="Incorporation date"
            redOutline={errors?.incorporation_date ? true : false}
          />
          <ValidationMessage
            message={
              errors?.incorporation_date?.message
            }
          />
        </div>

        <div className={styles.form_field}>
          <LabeledSelectTwo
            required={true}
            data={[
              {
                label: "Online",
                value: 0,
              },
              {
                label: "Physical",
                value: 1,
              },
            ]}
            placeholder="Store type"
            name="store_type"
            register={register}
            label="Store type"
            redOutline={errors?.store_type ? true : false}
          />
          <ValidationMessage
            message={errors?.store_type?.message}
          />
        </div>

        {storeType === "0" && (
          <>
            <div className={styles.form_field}>
              <LabeledSelectTwo
                required={true}
                data={[
                  {
                    label: "Web",
                    value: 1,
                  },
                  {
                    label: "App",
                    value: 2,
                  },
                  {
                    label: "Binance applets",
                    value: 3,
                  },
                  {
                    label: "Others",
                    value: 4,
                  },
                ]}
                placeholder="Website type"
                name="website_type"
                register={register}
                label="Website type"
                redOutline={errors?.website_type ? true : false}
              />
              <ValidationMessage
                message={
                  errors?.website_type?.message
                }
              />
            </div>

            <div className={styles.form_field}>
              <LabeledInputs
                required={true}
                name="website_url"
                register={register}
                label="Website Url"
               
                redOutline={errors?.website_url ? true : false}
              />
              <ValidationMessage
                message={errors?.website_url?.message}
              />
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                required={true}
                name="site_name"
                register={register}
                label="Site name"
                redOutline={errors?.site_name ? true : false}
              />
              <ValidationMessage
                message={errors?.site_name?.message}
              />
            </div>
          </>
        )}

        <div className={styles.form_btn}>
          <Button
            loading={isCreating}
            Styles
            text={"Create merchant account"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            fullwidth
            type="submit"
            border={"0.4px solid #1A2CCE"}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"5px"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

export default BinancePayForm2;
