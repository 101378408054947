import axios from "axios";

export const REMOVE_MEMBER = async (data) => {
  return axios
    .delete(`${process.env.REACT_APP_BASE_URL}settings/team/${data.id}`)
    .then((res) => {
      return res.data;
    });
};

export const RESET_LOGIN = async (data) => {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}settings/team/${data.id}/reset`)
      .then((res) => {
        return res.data;
      });
  };

  export const INVITE_MEMBER = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}settings/team`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const EDIT_MEMBER = async (data) => {
    return axios
      .patch(`${process.env.REACT_APP_BASE_URL}settings/team/${data.member.id}`, data.data)
      .then((res) => {
        return res.data;
      });
  };