import axios from "axios";

export const GET_ACTIVITIES = async (data) => {

    return axios
      .get(`${process.env.REACT_APP_BASE_URL}activities?order=DESC&page=${data.queryKey[1]}&take=10`)
      .then((res) => {
        return res.data;
      });
  };
  
  export const GET_SESSIONS = async (data) => {
  
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}sessions?order=DESC&page=${data.queryKey[1]}&take=10`)
      .then((res) => {
        return res.data;
      });
  };