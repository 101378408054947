import axios from "axios";

//POST
export const handlePostRequestCalls = (endpoint, data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}${endpoint}`, { ...data })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const handlePostForImages = async (endpoint, formData) => {

   return axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${endpoint}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(function(response){
      return response;
    }).catch(function (error){
      return error;
    })

};

//GET

export const handleGetRequestCalls = (endpoint, data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}${endpoint}`)
    .then(function (response) {
      // ;
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

//PATCH

export const handlePatchRequestCalls = (endpoint, data) => {
  return axios
    .patch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, { ...data })
    .then(function (response) {
      // ;
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

//DELETE

export const handleDeleteRequestCalls = (endpoint, data) => {
  return axios
    .delete(`${process.env.REACT_APP_BASE_URL}${endpoint}`)
    .then(function (response) {
      // ;
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
