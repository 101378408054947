import { Typography } from "@mui/material";
import React from "react";
import styles from "./SessionsRow.module.css";

function SessionsRow({ data, handleOpenTerminatePrompt }) {
  return (
    <tr className={styles.row}>
      <td>
        <div className={styles.operating_system}>
          <div
            className={`${styles.ball} ${
              true ? styles.green_ball : styles.grey_ball
            }`}
          ></div>
          <Typography>OS {data?.os_name} Webkit/{data?.client_name} {data?.client_version}</Typography>
          <Typography></Typography>
          <Typography></Typography>
        </div>
      </td>
      <td>
        <div className={styles.date_started}>
          <Typography> {new Date(data?.created_at)?.toLocaleString("en-US", {
             year: 'numeric',
             month: 'short',
              day: 'numeric',
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.last_seen}>
          <Typography>{new Date(data?.updated_at)?.toLocaleString("en-US", {
             year: 'numeric',
             month: 'short',
              day: 'numeric',
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.action}>
          <Typography onClick={()=>{handleOpenTerminatePrompt(data)}}>Terminate</Typography>
        </div>
      </td>
    </tr>
  );
}

export default SessionsRow;
