import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import HeadingOne from "../../../../../UI-components/headings/headingsOne/HeadingsOne";
import styles from "./DepositCrypto.module.css";
import scan_icon from "../../../../../../assets/images/scan.svg";
import Button from "../../../../../UI-components/button";
import BlueBackground from "../../../../../UI-components/blueBg/BlueBg";
import LabeledSelectTwo from "../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import { useForm } from "react-hook-form";
import { GET_REQUIREMENTS } from "../../../../../../api/queries/dashboard/balances";
import { useQuery } from "@tanstack/react-query";
import { DataCentral } from "../../../../../../context/AppContext";
import ModalModeAlert from "../../../../../UI-components/modeAlerts/ModalModeAlert";

const DepositCrypto = ({ currency, handleScreen, method }) => {
  const [copied, setCopied] = useState([]);
  const [networkRequirements, setNetworkRequirements] = useState([]);
  const { register, watch, setValue } = useForm();
  const choosenNetwork = watch("network", "");
  const { user } = useContext(DataCentral);

  const {
    data: requirements,
    isLoading,
    refetch,
  } = useQuery(["requirements", currency.code, method.slug], GET_REQUIREMENTS, {
    refetchOnWindowFocus: false,
  });

  const handleCopy = async (text, label) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied([...copied, { id: label, copy: "copied" }]);
    } catch (error) {}
  };

  useEffect(() => {
    if (requirements) {
      setValue("network", requirements?.data[0][0]?.value);
    }
  }, [requirements]);

  useEffect(() => {
    const _requirement = requirements?.data?.find((requirement) => {
      if (requirement[0]?.value.includes(choosenNetwork)) {
        return requirement;
      } else {
        return null;
      }
    });
    setNetworkRequirements(_requirement);
  }, [choosenNetwork]);

  useEffect(() => {
    return setCopied([]);
  }, []);

  return (
    <div className={styles.deposit_crypto}>
      {user.mode === "test" && <ModalModeAlert />}
      <HeadingOne
        title={"deposit"}
        description={"Make deposits into your wallet address below"}
      />
      <div className={styles.deposit_crypto_content}>
        <div className={styles.deposit_crypto_content_heading}>
          <img src={scan_icon} alt="scan icon" />
          <Typography>wallet address</Typography>
        </div>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={styles.network_select}>
              <LabeledSelectTwo
                label="Choose network"
                register={register}
                name="network"
                placeholder={"Choose network"}
                data={requirements?.data
                  ?.map((requirement) => {
                    return requirement[0]?.value;
                  })
                  ?.map((network) => {
                    return {
                      label: network,
                      value: network,
                    };
                  })}
              />
            </div>
            <div className={styles.deposit_crypto_content_main}>
              {networkRequirements?.map((requirement) => {
                if (requirement.render === "image") {
                  return (
                    <div key={requirement.name} className={styles.barcode}>
                      <BlueBackground
                        content={
                          <div className={styles.qr_img_div}>
                            <img src={requirement.value} alt="" />
                          </div>
                        }
                      />
                      <Typography>
                        Send only {currency.code} to this wallet address
                      </Typography>
                    </div>
                  );
                } else {
                  return null;
                }
              })}

              <div className={styles.wallet_info}>
                {networkRequirements?.map((requirement) => {
                  if (requirement.render === "text") {
                    return (
                      <div
                        key={requirement.name}
                        className={styles.wallet_info_item}
                      >
                        <div className={styles.wallet_info_item_text}>
                          <Typography variant="h6">
                            {requirement.name}
                          </Typography>
                          <Typography>{requirement.value}</Typography>
                        </div>
                        {requirement.name === "address" && (
                          <button
                            className={styles.copy_btn}
                            onClick={() => {
                              handleCopy(requirement.value, requirement.label);
                            }}
                          >
                            {copied.some(
                              (item) => item.id === requirement.label
                            )
                              ? "copied"
                              : "copy"}
                          </button>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.btns}>
        {isLoading ? null : (
          <Button
            Styles
            text={"I have done this"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            fullwidth
            type="submit"
            border={"0.4px solid #1A2CCE"}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"5px"}
            onClick={() => {
              //check for auto or manual
              method.type === "MANUAL"
                ? handleScreen("verify")
                : handleScreen("success");
            }}
          />
        )}

        <Button
          Styles
          text={"Change payment method"}
          color={isLoading ? "white" : "#676767"}
          bgColor={isLoading ? "#1A2CCE" : null}
          size="md"
          fullwidth
          type="submit"
          onClick={() => {
            handleScreen("");
          }}
        />
      </div>
    </div>
  );
};

export default DepositCrypto;
