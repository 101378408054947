import { Typography } from "@mui/material";
import React from "react";
import styles from "./LabeledSelect.module.css";

const LabeledSelectTwo = ({
  guide,
  name,
  data,
  placeholder,
  label,
  register,
  redOutline,
  required,
}) => {
  return (
    <label className={styles.labeled_select} htmlFor="">
      {label && (
        <div className={styles.heading}>
          {label && <Typography variant="h6">{label}</Typography>}
          {guide && (
            <Typography
              style={{
                color: guide === "optional" ? "rgb(126, 121, 121)" : "red",
              }}
            >
              {guide}
            </Typography>
          )}
        </div>
      )}

      <select
        {...register(name, { required: required })}
        name={name}
        label={label}
        className={redOutline ? styles.red_outline : null}
      >
        <option value="">{placeholder}</option>
        {data?.map((option, index) => {
          return (
            <option key={option.id || index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </label>
  );
};

export default LabeledSelectTwo;
