import React from 'react'
import styles from './BlueBg.module.css'


const BlueBackground = ({content}) => {

    return ( 
        <div className={styles.blue_bg}>
            {content}
        </div>
     );
}
 
export default BlueBackground;