
import React from "react";
import { SwitchWrap } from "./switch.styles";

const Switch = ({  switched, handleSwitchChange, color }) => {
 

  return (
    <SwitchWrap onClick={()=>{handleSwitchChange(switched)}} switched={switched} color={color}>
      <div className="switch-main">
        <div className="switch-btn">
          <span>
            <input
              type="checkbox"
              className="switch-check"
            />
            <span className="switch-ball" />
          </span>
        </div>
        <div className="switch-overlay" />
      </div>
    </SwitchWrap>
  );
};

export default Switch;
