import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../UI-components/layouts";
import { Styles } from "./styles";
import { balanceTableHead } from "../../../../lib/constant";
import TableOne from "../../../UI-components/new -tables/Table";
import AppBackdrop from "../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrame from "../../../UI-components/modalFrame/ModalFrame";
import useModal from "../../../../customHooks/useModal";
import Swap from "./modals/swap/Swap";
import { handleGetRequestCalls } from "../../../../axiosConfig/apiCalls";
import DepositMethod from "./modals/depositMethod/DepositMethod";
import Loader from "../../../UI-components/loader/Loader";
import WithdrawalMethod from "./modals/withdraw/Withdraw";
import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import DownloadReport from "../../../UI-components/downloadReport/DownloadReport";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import { useSearchParams } from "react-router-dom";
import BinancePayResponse from "./modals/binancePayResponse/BinancePayResponse";
import md5 from "md5";

import { useQuery } from "@tanstack/react-query";
import {
  CHECK_BINANCE_PAY,
  GET_BALANCES,
  GET_DEPOSIT_METHODS,
  GET_WITHDRAWAL_METHODS,
} from "../../../../api/queries/dashboard/balances";
import useGetDefaultCurrencies from "../../../../customHooks/useGetDefaultCurrencies";

const modalFrameStyle = {
  height: "auto",
  width: 470,
};

const Index = () => {
  const [searchParams] = useSearchParams();

  const [openToolTip, setOpenToolTip] = useState({});
  const {
    toggle,
    opened,
    handleCloseModal,
    handleOpenModal,
    isShowingDeposit,
    isShowingSwap,
    isShowingWithdraw,
    isShowingBinancePayTrxResult,
  } = useModal();
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  const {
    isLoading: isLoadingCurrencies,
    currencies,
    fiatCurrencies,
    cryptoCurrencies,
  } = useGetDefaultCurrencies();

  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [balance, setBalance] = useState("");
  const [availableDepositMethods, setAvailablePaymentMethods] = useState([]);
  const [availableWithdrawalsMethods, setAvailableWithdrawalsMethods] =
    useState([]);
  const [queryStringData, setQueryStringData] = useState({});
  const [transaction, setTransaction] = useState({});
  const [loadingTransaction, setLoadingTransaction] = useState(true);

  const {
    data: balances,
    isLoading: isLoadingBalances,
    refetch,
  } = useQuery(["balances"], GET_BALANCES, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["user"],
  });

  const {
    data: depositMethods,
    isLoading: isLoadingDepositMethods,
    refetch: refetchDepositMethods,
  } = useQuery(["deposit-methods"], GET_DEPOSIT_METHODS, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["user"],
  });

  const {
    data: withdrawalMethods,
    isLoading: isLoadingWithdrawalMethods,
    refetch: refetchWithdrawalMethods,
  } = useQuery(["withdrawal-methods"], GET_WITHDRAWAL_METHODS, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["user"],
  });

  const {
    data: binancePay,
    isLoading: isLoadingBinancePay,
    refetch: refetchBinancePay,
  } = useQuery(["binance-pay"], CHECK_BINANCE_PAY, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["user"],
  });

  useEffect(() => {
    const { currency, amount, reference, status, nonce } = Object.fromEntries([
      ...searchParams,
    ]);
    setQueryStringData(Object.fromEntries([...searchParams]));

    if (currency && amount && reference && status && nonce) {
      if (md5(amount + currency + reference) === nonce) {
        toggle("binance-pay-response");
        handleGetTransaction(reference);
      }
    } else {
    }
  }, []);

  const handleGetTransaction = async (reference) => {
    setLoadingTransaction(true);
    const response = await handleGetRequestCalls(`transactions/${reference}`);

    if (response.status < 300 && response.status >= 200) {
      const { data } = response.data;
      setTransaction(data);
      setLoadingTransaction(false);
    } else if (response.response) {
    } else if (response.request) {
    } else {
    }
  };



  const handleSetCurrency = (currency, type) => {
    handlePickExactMethods(currency?.code, type);
    setCurrency(currency);
  };

  const handleSetBalance = (balance, liquidity) => {
    setBalance(balance + liquidity);
  };

  const handlePickExactMethods = (currency, type) => {
    if (type === "deposit") {
      const tempMethods = [];
      depositMethods?.data?.map((method) => {

        if (method.currencies.includes(currency)) {
          tempMethods.push(method);
        }
        return "";
      });

      if (tempMethods.length > 0) {
        toggle("deposit");
      } else {
        openSnackbar("error", `No available Deposit method for ${currency}`);
      }
      setAvailablePaymentMethods(tempMethods);
    } else if (type === "withdrawal") {
      const tempMethods = [];
      withdrawalMethods?.data?.map((method) => {
        if (method.currencies.includes(currency)) {
          tempMethods.push(method);
        }
        return "";
      });
      if (tempMethods.length > 0) {
        toggle("withdraw");
      } else {
        openSnackbar("error", `No available Withdrawal method for ${currency}`);
      }
      setAvailableWithdrawalsMethods(tempMethods);
    }
  };

  const handleTooltipClose = () => {
    setOpenToolTip({});
  };

  const handleTooltipOpen = (currency) => {
    if (openToolTip.open) {
      setOpenToolTip({});
    } else {
      setOpenToolTip({ open: true, id: currency });
    }
  };

  const handleRefresh = () => {
    refetch();
    refetchBinancePay();
    refetchDepositMethods();
    refetchWithdrawalMethods();
  };

  return (
    <Layout title={"Balances"} bgColor={"#fff"}>
      {isLoadingBalances && isLoadingDepositMethods && isLoadingWithdrawalMethods ? (
        <Loader />
      ) : (
        <Styles onClick={handleTooltipClose}>
          <TableOne
            currencies={currencies}
            handleRefresh={handleRefresh}
            openToolTip={openToolTip}
            handleOpenTooltip={handleTooltipOpen}
            handleSetCurrency={handleSetCurrency}
            handleSetBalance={handleSetBalance}
            handleOpenExport={() => {
              handleOpenModal("download-report");
            }}
            handleOpenSwap={() => {
              toggle("swap");
            }}
            tableBody={balances?.data}
            type={"balance"}
            tableHeadData={balanceTableHead}
          />

          {isShowingBinancePayTrxResult && (
            <AppBackdrop
              openModal={isShowingBinancePayTrxResult}
              child={
                <BinancePayResponse
                  loadingTransaction={loadingTransaction}
                  transaction={transaction}
                  openSnackbar={openSnackbar}
                  handleCloseModal={() => {
                    toggle("binance-pay-response");
                  }}
                  queryStringData={queryStringData}
                />
              }
            />
          )}
        </Styles>
      )}

      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
      {isShowingDeposit && (
        <AppBackdrop
          openModal={isShowingDeposit}
          child={
            <ModalFrame
              style={{
                height: "auto",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
              handleAction={() => {
                toggle("deposit");
              }}
              child={
                <DepositMethod
                  handleClose={() => {
                    toggle("deposit");
                  }}
                
             
                  openSnackbar={openSnackbar}
                  handleGetBalances={refetch}
                  currency={currency}
                  binancePay={binancePay}
                  availableDepositMethods={availableDepositMethods}
                  title="deposit"
                />
              }
            />
          }
        />
      )}
      {isShowingWithdraw && (
        <AppBackdrop
          openModal={isShowingWithdraw}
          child={
            <ModalFrame
              handleAction={() => {
                toggle("withdraw");
              }}
              style={modalFrameStyle}
              child={
                <WithdrawalMethod
                  toggle={() => {
                    toggle("withdraw");
                  }}
                  openSnackbar={openSnackbar}
           
               
                  currency={currency}
                  amount={amount}
                  balance={balance}
                  setAmount={setAmount}
                  availableWithdrawalMethods={availableWithdrawalsMethods}
                  handleGetBalances={refetch}
                  title="withdraw"
                />
              }
            />
          }
        />
      )}

   

      {opened.some((item) => item === "download-report") && (
        <AppBackdrop
          openModal={opened.some((item) => item === "download-report")}
          handleCloseModal={() => {
            handleCloseModal("download-report");
          }}
          child={
            <ModalFrame
              handleAction={() => {
                toggle("download-report");
              }}
              child={<DownloadReport />}
            />
          }
        />
      )}

     

      {isShowingSwap && (
        <AppBackdrop
          openModal={isShowingSwap}
          child={
            <ModalFrame
              handleAction={() => {
                toggle("swap");
              }}
              style={{ ...modalFrameStyle, height: "auto" }}
              child={
                <Swap
                  cryptoCurrencies={cryptoCurrencies}
                  currencies={currencies}
                  fiatCurrencies={fiatCurrencies}
                  handleAction={() => {
                    toggle("confirm-order");
                  }}
                />
              }
            />
          }
        />
      )}
    </Layout>
  );
};

export default Index;
