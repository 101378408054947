import React, { useEffect, useState } from "react";
import { Flex } from "../../../../UI-components/box";
import { Styles } from "./styles";
import Button from "../../../../UI-components/button";
import { Typography } from "@mui/material";

import Loader from "../../../../UI-components/loader/Loader";
import { useForm } from "react-hook-form";

import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import SettingsLayout from "../SettingsLayout/SettingsLayout";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_PREFERENCE } from "../../../../../api/queries/dashboard/settings/preference";
import { SUBMIT_PREFERENCE } from "../../../../../api/mutations/dashboard/settings/preference";

const Index = () => {
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [login, setLogin] = useState(Boolean);
  const [marketing, setMarketing] = useState(Boolean);

  const queryClient = useQueryClient()
  const {
    data: preference,
    isLoading,
    refetch,
  } = useQuery(["preference"], GET_PREFERENCE, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
 
     
    },
  });

  useEffect(()=>{
    if(preference){
      setValue("swap_alert", preference?.data?.swap_alert);
      setValue("deposit_alert", preference?.data?.deposit_alert);
      setValue("withdrawal_alert", preference?.data?.withdrawal_alert);
      setLogin(preference?.data?.login_alert);
      setMarketing(preference?.data?.marketing_email);
    }
  }, [preference])

  const { mutate: submitPreference, isLoading: isLoadingSubmitPreference } =
  useMutation(SUBMIT_PREFERENCE, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["preference"]);
      openSnackbar("success", response.message);
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  

  const onSubmit = async (data) => {
    submitPreference({
      swap_alert: data.swap_alert,
      deposit_alert: data.deposit_alert,
      withdrawal_alert: data.withdrawal_alert,
      login_alert: login,
      marketing_email: marketing,
    })

   
  };


  const handleSetStatus = (type) => {
    if (type === "login_alert") {
      openSnackbar("info", "Sorry, Login alerts can not be disabled", 2000);
    } else if (type === "marketing") {
      setMarketing(!marketing);
    }
  };

  return (
    <SettingsLayout
      tab="preferences"
      child={
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <Styles alignItem={"flex-start"} flexDir={"column"}>
              <Flex flexDir="column" className="container">
                <Flex
                  justifyContent="space-between"
                  alignItem="center"
                  className="invite-team"
                >
                  <h2>Notifications</h2>
                </Flex>
                <Flex
                  flexDir="column"
                  alignItem="flex-start"
                  className="transaction"
                >
                  <h4>Transaction alerts</h4>
                  <Typography>
                    Once enabled, you will receive relevant notifications via
                    push notification and email
                  </Typography>
                  <Flex justifyContent="flex-start">
                    <div className="checkbox_field">
                      <input
                        {...register("swap_alert", {})}
                        type="checkbox"
                        name="swap_alert"
                      />
                      <Typography>Swap</Typography>
                    </div>
                    <div className="checkbox_field">
                      <input
                        {...register("deposit_alert", {})}
                        type="checkbox"
                        name="deposit_alert"
                      />
                      <Typography>Deposit</Typography>
                    </div>
                    <div className="checkbox_field">
                      <input
                        {...register("withdrawal_alert", {})}
                        type="checkbox"
                        name="withdrawal_alert"
                      />
                      <Typography>Withdrawals</Typography>
                    </div>
                  </Flex>
                </Flex>
                <hr />
                <Flex flexDir="column" alignItem="flex-start" className="login">
                  <h4>Login alerts</h4>
                  <Flex justifyContent="space-between" flexDir="row">
                    <Flex
                      alignItem="flex-start"
                      justifyContent="flex-start"
                      flexDir="column"
                    >
                      <Typography>
                        Get notified each time there’s a new login on this
                        account
                      </Typography>
                      <Typography>
                        Status:{" "}
                        <span style={{ color: login ? "#00831d" : "#cd0000" }}>
                          {" "}
                          {login ? "Enabled" : "Disabled"}{" "}
                        </span>
                      </Typography>
                    </Flex>
                    <Flex width="30%">
                      <Button
                        text={login ? "Disable" : "Enable"}
                        size="sm"
                        type="submit"
                        bgColor={login ? "#ffd9d9" : "#daffd9"}
                        color={login ? "#cd0000" : "#00831d"}
                        borderRadius={"5px"}
                        onClick={() => {
                          handleSetStatus("login_alert");
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <hr />
                <Flex flexDir="column" alignItem="flex-start" className="login">
                  <h4>Marketing email</h4>
                  <Flex justifyContent="space-between" flexDir="row">
                    <Flex
                      alignItem="flex-start"
                      justifyContent="flex-start"
                      flexDir="column"
                    >
                      <Typography>
                        Select whether you want to receive marketing emails from
                        us.
                      </Typography>
                      <Typography>
                        Status:{" "}
                        <span
                          style={{ color: marketing ? "#00831d" : "#cd0000" }}
                        >
                          {" "}
                          {marketing ? "Enabled" : "Disabled"}{" "}
                        </span>
                      </Typography>
                    </Flex>
                    <Flex width="30%">
                      <Button
                        text={marketing ? "Disable" : "Enable"}
                        size="sm"
                        type="submit"
                        bgColor={marketing ? "#ffd9d9" : "#daffd9"}
                        color={marketing ? "#cd0000" : "#00831d"}
                        borderRadius={"5px"}
                        onClick={() => {
                          handleSetStatus("marketing");
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Button
                className="save"
                text={"Save Changes"}
                size="sm"
                type="submit"
                bgColor={"#1A2CCE"}
                color={"#FFFFFF"}
                borderRadius={"5px"}
                onClick={handleSubmit(onSubmit)}
                loading={isLoadingSubmitPreference}
              />
              <SwapstaSnackbar
                open={openToast}
                message={message}
                severity={severity}
                setOpen={setOpenToast}
                duration={duration}
              />
            </Styles>
          )}
        </>
      }
    />
  );
};

export default Index;
