import React from "react";
import styles from "./MemberTab.module.css";
import { Typography } from "@mui/material";
import FileBox from "../../../../../../UI-components/new-inputs/fileBox/FileBox";
import { getOrdinalNumber } from "../../../../../../../utils/helpers";
import AddMore from "../../../../../../UI-components/addMore/AddMore";

const MemberTab = ({
    trigger,
  label,
  memberLabel,
  counts,
  setCounts,
  documents,
  item,
  setValue,
  watch,
  register,
  errors,
  
}) => {
  return (
    <div className={styles.member_main}>
      <div className={styles.member_heading}>
        <Typography>{label} </Typography>
      </div>
      <div className={styles.members}>
        {Array.from({ length: counts }).map((_item, index) => {
          return (
            <div key={index} className={styles.member_div}>
              <FileBox
                multi={false}
                trigger={trigger}
                file={documents[`${item?.name}${index}`]}
                setValue={setValue}
                label={`ID of ${getOrdinalNumber(index + 1)} ${memberLabel}`}
                name={`${item.name}${index}`}
                watch={watch}
                register={register}
                errors={errors}
              />
              {index > 0 && (
                <div
                  onClick={() => {
                    setCounts((prev) => prev - 1);
                  }}
                  className={styles.remove_member}
                >
                  <Typography>Remove this director</Typography>
                </div>
              )}
            </div>
          );
        })}
        <AddMore
          handleClick={() => {
            setCounts((prev) => prev + 1);
          }}
          text={`New ${memberLabel}`}
        />
      </div>
    </div>
  );
};

export default MemberTab;
