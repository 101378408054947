import { useState } from "react";
import {
  Container2,
  SearchContainer,
  Input,
  Container3,
  Container1,
} from "./styles";
import { SearchIcon, CloseIcon } from "../../../assets";
import { Container, Flex } from "../../UI-components/box";
import { useForm } from "react-hook-form";
// import { theme } from "../../config/theme";

const Search = ({
  placeholder,
  handleFilter,
  type,
  name,
  required,
  setShowSearch,
  showSearch,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [input, setInput] = useState("");

  const handleClearInput = () => setInput("");

  const onSubmit = async (data) => {
    if (type === "filter") {
      handleFilter("search", data);
    } else {
    }
  };

  return (
    <Container1 height="max-content">
      <Container2
        margin="0 30px 0 auto"
        height="max-content"
        justifyContent="flex-end"
      >
        <Container3>
          <Flex bgColor={"#fff"} wrap="nowrap">
            <div
              onClick={handleSubmit(onSubmit)}
              style={{
                width: 50,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SearchIcon
                width="14px"
                height="14px"

                // color={""}
              />
            </div>

            <SearchContainer height="max-content">
              <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <Input
                  {...register(name, { required: required })}
                  name={name}
                  tye="text"
                  placeholder={placeholder}
                />
              </form>
            </SearchContainer>

            {input.length ? (
              <Container
                width="max-content"
                height="max-content"
                pad="10px"
                onClick={handleClearInput}
                cursor="pointer"
              >
                <CloseIcon
                  width="14px"
                  height="14px"
                  // color={["grey", "1500", theme]}
                />
              </Container>
            ) : null}
          </Flex>
        </Container3>
      </Container2>
      {/* 
      {showSearch ? (
        <CloseSaerch
          width="17px"
          height="17px"
          onClick={() => setShowSearch(false)}
          cursor="pointer"
          className="close-search"
        >
          <CloseIcon
            width="10px"
            height="10px"
            // color={["grey", "200", theme]}
          />
        </CloseSaerch>
      ) : null} */}
    </Container1>
  );
};
export default Search;
