import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const businessInfoSchema = yup.object().shape({
  business_email: yup
    .string()
    .email("Email must be a valid email")
    .required("Business email is required"),
  business_name: yup.string().required("Business name is required"),
  business_description: yup
    .string()
    .required("Business description is required"),

  website: yup
    .string()
    .required("Website URL is required")
    .url("URL is not valid"),

  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .test({
      name: "valid",
      message: "Phone number must be valid",
      test: function (value) {
        return value?.length == 14 && isValidPhoneNumber(value);
      },
    })
    .nullable(),
  address: yup.string().required("Address is required"),
  trading_name: yup.string().required("Trading name is required"),
  authorized_signatory: yup
    .string()
    .required("Authorized signatory is required"),
  ultimate_beneficial_owner: yup
    .string()
    .required("Ultimate beneficial owner is requied"),
  expected_monthly_inflow: yup
    .string()
    .required("Expected monthly inflow is required"),
  licensable_in_country: yup
    .string()
    .nullable()
    .required("Please pick yes or no"),
  has_license: yup.string().nullable().required("Please pick yes or no"),
  has_political: yup.string().nullable().required("Please pick yes or no"),
  politically_exposed_person: yup.string().when("has_political", {
    is: (val) => {
      if (val === "yes") {
        return true;
      }
    },
    then: (schema) => schema.required("Politically exposed person is required"),
    otherwise: (schema) => schema.optional(),
  }),
});

export const documentSchema = yup.object().shape({
  country: yup.string().required("Document country of issuance is required"),
  identity_document_type: yup
    .string()
    .required("Identity document type is required"),

  document_registeration: yup
    .mixed()
    .test({
      name: "required",
      message: "Document registeration is required",
      test: (value) => value?.length > 0,
    })
    .test({
      name: "fileSize",
      message: "file uploaded is larger then 500kb",
      test: (value) => {
        if (typeof value === "object") {
          return value[0]?.size < 500000;
        } else {
          return true;
        }
      },
    }),

  document_memorandum: yup
    .mixed()
    .test({
      name: "required",
      message: "Document memorandum is required",
      test: (value) => value?.length > 0,
    })
    .test({
      name: "fileSize",
      message: "file uploaded is larger then 500kb",
      test: (value) => {
        if (typeof value === "object") {
          return value[0]?.size < 500000;
        } else {
          return true;
        }
      },
    }),

  document_incorporation: yup
    .mixed()
    .test({
      name: "required",
      message: "Document incorporation is required",
      test: (value) => value?.length > 0,
    })
    .test({
      name: "fileSize",
      message: "file uploaded is larger then 500kb",
      test: (value) => {
        if (typeof value === "object") {
          return value[0]?.size < 500000;
        } else {
          return true;
        }
      },
    }),

  document_identity: yup
    .mixed()
    .test({
      name: "required",
      message: "Document identity is required",
      test: (value) => value?.length > 0,
    })
    .test({
      name: "fileSize",
      message: "file uploaded is larger then 500kb",
      test: (value) => {
        if (typeof value === "object") {
          return value[0]?.size < 500000;
        } else {
          return true;
        }
      },
    }),
});

export const bankInfoSchema = yup.object().shape({
  country: yup
    .string()
    .required("Please select country in the Business information tab"),
  bank: yup.string().when("country", {
    is: "Nigeria",
    then: yup.string().required("Bank name is required"),
    otherwise: yup.string(),
  }),
  account_number: yup.string().when("country", {
    is: "Nigeria",
    then: yup.string().required("Account number is required"),
    otherwise: yup.string(),
  }),
  bvn: yup.string().when("country", {
    is: "Nigeria",
    then: yup
      .string()
      .required("BVN is required")
      .min(11, "BVN must be 11 characters")
      .max(11, "BVN must be 11 characters"),
    otherwise: yup.string(),
  }),
  bvn_name: yup.string().when("country", {
    is: "Nigeria",
    then: yup.string().required("Name on BVN is required"),
    otherwise: yup.string(),
  }),

  bank_details: yup.string().when("country", {
    is: "Nigeria",
    then: yup.string(),
    otherwise: yup.string().required("Bank details is required").nullable(),
  }),
});
