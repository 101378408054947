// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletCompliance_heading__xBjoW {\n    margin-bottom: 32px;\n    margin-top: 48px;\n}\n\n.WalletCompliance_heading__xBjoW p{\n    color: #212121;\n    font-size: 16px;\n    font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/wallets/walletCompliancePage/WalletCompliance.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".heading {\n    margin-bottom: 32px;\n    margin-top: 48px;\n}\n\n.heading p{\n    color: #212121;\n    font-size: 16px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "WalletCompliance_heading__xBjoW"
};
export default ___CSS_LOADER_EXPORT___;
