import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const BinancePaySchemaStep1 = yup.object().shape({
  business_name: yup
    .string()
    .required("Busines name is required")
    .max(128, "Maximum of 128 characters"),
  business_logo: yup
    .string()
    .url("Business logo must be in a valid url")
    .required("Business logo is required"),
  business_type: yup.string().required("Business type is required"),
  business_category: yup.string().required("Business category is required"),
  country: yup.string().required("Country is required"),
});

export const BinancePaySchemaStep2 = yup.object().shape({
  registration_number: yup
    .string()
    .required("Registeration number is required")
    .max(64, "Maximum of 64 characters"),
  registration_address: yup
    .string()
    .required("Registeration address is required")
    .max(1024, "Maximum of 1024 characters"),
  incorporation_date: yup.string().required("Incorporation date is required"),

  store_type: yup.string().required("Store type is required"),

  registration_country: yup.string().required("Country is required"),

  website_type: yup.string().when("store_type", {
    is: "0",
    then: yup.string().required("Website type is required"),
    otherwise: yup.string(),
  }),
  website_url: yup.string().when("website_type", {
    is: "1",
    then: yup
      .string()
      .url("Website url must be a valid url")
      .max(256, "Maximum of 256 characters")
      .required("Website type is required"),
    otherwise: yup.string(),
  }),
  site_name: yup.string().when("website_type", {
    is: "4",
    then: yup
      .string()
   ,
    otherwise: yup.string().max(32, "Maximum of 32 characters")
    .required("Site name is required"),
  }),
});
