import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {  createWalletSchema1 } from "../../../../../../schemas/dashboard/wallets";
import { DataCentral } from "../../../../../../context/AppContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CREATE_WALLET } from "../../../../../../api/mutations/dashboard/wallets";
import useGetDefaultCurrencies from "../../../../../../customHooks/useGetDefaultCurrencies";
import NewWalletStepOne from "./step1/NewWalletStepOne";
import NewWalletStepTwo from "./step2/NewWalletStepTwo";

const chain = [
  {
    label: "ETH",
    value: "eth",
  },
  {
    label: "TRX",
    value: "trx",
  },
];

function NewWalletMain({ handleCloseModal, openSnackbar }) {
  const { user } = useContext(DataCentral);
  const { currencies } = useGetDefaultCurrencies();

  const [step, setStep] = useState("1");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(createWalletSchema1),
  });

  const phone = watch("phone", "");
  const merchant_reference = watch("merchant_reference", "");
  const [step1Data, setStep1Data] = useState({});



  useEffect(() => {
    if (phone) {
      trigger("phone");
    }
  }, [phone]);

  useEffect(() => {
    if (merchant_reference) {
      trigger("merchant_reference");
    }
  }, [merchant_reference]);

  useEffect(() => {
    setValue("email", user?.email);
  }, []);

  const onSubmit = (data) => {

    setStep1Data(data);
    setStep("2");
  };

  return (
    <>
      {step === "1" ? (
        <NewWalletStepOne
          currencies={currencies}
          chain={chain}
          phone={phone}
          register={register}
          errors={errors}
          setValue={setValue}
          onSubmit={onSubmit}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
        />
      ) : (
        <NewWalletStepTwo
          step1Data={step1Data}
          openSnackbar={openSnackbar}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
}

export default NewWalletMain;
