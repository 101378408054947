// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioBtn_radio_btn__Mz26v {\n  display: flex;\n  flex-direction: column;\n  padding-top: 24px ;\n  gap: 16px;\n}\n.RadioBtn_heading__Znxfd p {\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n}\n\n.RadioBtn_radios__esUsw {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.RadioBtn_radios__esUsw label {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.RadioBtn_radios__esUsw label p {\n  font-size: 14px;\n  font-weight: 400;\n  color: black;\n  margin-top: 3px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI-components/new-inputs/radioBtn/RadioBtn.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,0BAA0B;CAC3B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,cAAc;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".radio_btn {\n  display: flex;\n  flex-direction: column;\n  padding-top: 24px ;\n  gap: 16px;\n}\n.heading p {\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n}\n\n.radios {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.radios label {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.radios label p {\n  font-size: 14px;\n  font-weight: 400;\n  color: black;\n  margin-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio_btn": "RadioBtn_radio_btn__Mz26v",
	"heading": "RadioBtn_heading__Znxfd",
	"radios": "RadioBtn_radios__esUsw"
};
export default ___CSS_LOADER_EXPORT___;
