import React from "react";
import Asset from "../asset/Asset";


const FiatsTab = ({ fiats, handleSetAssets, handleClose, type }) => {
  return (
    <div>
      {fiats?.map((fiat) => {
        return (
          <Asset
            key={fiat.code}
            handleClose={handleClose}
            handleSetAssets={handleSetAssets}
            asset={fiat}
            type={type}
          />
        );
      })}
    </div>
  );
};

export default FiatsTab;
