import axios from "axios";
import { endpointFilterParser, endpointFilterParserStart } from "../../../../utils/helpers";

export const GET_CURRENCIES = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}setting/currencies`)
    .then((res) => {
      return res.data;
    });
};

export const GET_CURRENCY_PAIRS = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}setting/pairs`)
    .then((res) => {
      return res.data;
    });
};

export const GET_COUNTRIES = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}misc/countries`)
    .then((res) => {
      return res.data;
    });
};

export const GET_STATES = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}misc/states/${data.queryKey[1]}`)
    .then((res) => {
      return res.data;
    });
};

export const GET_BANKS = async (data) => {

  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}misc/${
        data.queryKey[1]
      }${endpointFilterParserStart(data.queryKey[2], "country")}`
    )
    .then((res) => {
      return res.data;
    });
};

export const GET_ACCOUNT_NAME = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}misc/banks/resolve`, {
      bank: data.queryKey[1],
      account: data.queryKey[2],
    })
    .then((res) => {
      return res.data;
    });
};
