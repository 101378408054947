import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Deposit.module.css";
import bankIcon from "../../../../../../assets/images/bank.svg";
import HeadingOne from "../../../../../UI-components/headings/headingsOne/HeadingsOne";
import Button from "../../../../../UI-components/button";
import BankAccountDetails from "../../../../../UI-components/bankAccountDetails/BankAccountDetails";
import useGetRequirements from "../../../../../../customHooks/useGetRequirements";
import ModalModeAlert from "../../../../../UI-components/modeAlerts/ModalModeAlert";
import { DataCentral } from "../../../../../../context/AppContext";

const Deposit = ({ method, handleScreen, currency }) => {
  const [copied, setCopied] = useState([]);
  const { requirements, isLoading } = useGetRequirements(method, currency);
  const { user } = useContext(DataCentral);
  const handleCopy = async (text, label) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied([...copied, { id: label, copy: "copied" }]);
    } catch (error) {}
  };

  useEffect(() => {
    return setCopied([]);
  }, []);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.deposit}
    >
      {user?.mode === "test" && <ModalModeAlert />}

      <HeadingOne
        title={method?.modal_title}
        description={method?.modal_description}
      />

      <div className={styles.deposit_bank_account}>
        <div className={styles.bank_account_heading}>
          <img src={bankIcon} alt="bank icon" />
          <Typography>payment details</Typography>
        </div>
        <div className={styles.bank_account_details}>
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            requirements?.map((requirement, index) => {
              return (
                <BankAccountDetails
                  key={requirement.label}
                  requirement={requirement}
                  handleCopy={handleCopy}
                  copied={copied}
                  lastItem={requirements?.length === index + 1}
                  firstItem={index === 0}
                />
              );
            })
          )}
        </div>
      </div>
      {isLoading ? null : (
        <Button
          Styles
          text={"I have done this"}
          bgColor={"#1A2CCE"}
          color={"#FFFFFF"}
          size="md"
          fullwidth
          type="submit"
          border={"0.4px solid #1A2CCE"}
          boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
          borderRadius={"5px"}
          onClick={() => {
            method.slug === "binance-pay"
              ? handleScreen("verify")
              : method.type === "MANUAL"
              ? handleScreen("verify")
              : handleScreen("success");
          }}
        />
      )}

      <Button
        Styles
        text={"Change payment method"}
        color={isLoading ? "white" : "#676767"}
        size="md"
        bgColor={isLoading ? "#1A2CCE" : null}
        fullwidth
        type="submit"
        onClick={() => {
          handleScreen("");
        }}
      />
    </div>
  );
};

export default Deposit;
