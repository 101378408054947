import { Typography } from "@mui/material";
import React, { useState } from "react";
import { SearchIcon, CloseIcon } from "../../../../../assets";
import { cutText } from "../../../../../utils/helpers";
import styles from "./SearchInput.module.css";

function SearchInput({
  searched,
  setSearched,
  setValue,
  register,
  name,
  handleSubmit,
  onSubmit,
  searchedValue,
  handleClose,
}) {
  return (
    <div className={styles.search}>
      <SearchIcon width="12px" height="12px" />
      {searched ? (
        <div className={styles.after_search}>
          <Typography>Showing search results for</Typography>
          <div className={styles.after_search_right}>
            <Typography>
              {cutText(searchedValue, 15, "start")}...
              {cutText(searchedValue, -2, "end")}
            </Typography>
            <div
              className={styles.close}
              onClick={() => {
                setSearched(false);
                handleClose();
              }}
            >
              <CloseIcon width="8px" height="8px" />
            </div>
          </div>
        </div>
      ) : (
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register(name)}
            name={name}
            type="text"
            placeholder="Search reference no."
          />
        </form>
      )}
    </div>
  );
}

export default SearchInput;
