// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Activities_activities__KkJpT {\n    padding: 47px 0;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/activities/Activities.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[".activities {\n    padding: 47px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activities": "Activities_activities__KkJpT"
};
export default ___CSS_LOADER_EXPORT___;
