import React from "react";
import { VerifyBuisnessStyle } from "./styles";
import { Flex } from "../../../../UI-components/box";
import { VerifyIcon } from "../../../../../assets";
import { Link } from "react-router-dom";
import strip from "../../../../../assets/images/stripe.svg";
import usePermission from "../../../../../customHooks/usePermissions";

const Index = () => {
  const { checkPermission } = usePermission();
  return (
    <>
      {checkPermission("settings.compliance") && (
        <VerifyBuisnessStyle>
          <img src={strip} alt="" />
          <Flex
            justifyContent={"flex-start"}
            alignItem={"center"}
            flexDir={"row"}
            className="verify_container"
          >
            <span>
              <VerifyIcon />
            </span>
            <div>
              <h2>Verify your business</h2>
              <p>Submit your KYC details to verify your business account</p>
            </div>
          </Flex>
          <Flex
            className="link_container"
            justifyContent={"flex-end"}
            width={"60%"}
          >
            <Link className="link_box" to={"/settings/compliance"}>
              Activate business
            </Link>

            <div className="link_box link_box_grey">
              <Link to={"/dashboard"}>Take a tour</Link>
            </div>
          </Flex>
        </VerifyBuisnessStyle>
      )}
    </>
  );
};

export default Index;
