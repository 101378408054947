import axios from "axios";

export const CHANGE_PASSWORD = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}settings/security/password`, data)
    .then((res) => {
      return res.data;
    });
};

export const GENERATE_2FA = async (data) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}settings/security/2fa`, data)
    .then((res) => {
      return res.data;
    });
};

export const OTP_ACTIONS = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}settings/security/2fa`, data)
    .then((res) => {
      
      return res.data;
    });
};

export const TERMINATE_SESSION = async (data) => {
  return axios
    .delete(`${process.env.REACT_APP_BASE_URL}sessions/${data.id}`)
    .then((res) => {
     
      return res.data;
    });
};


export const SIGN_WITHDRAWALS = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}settings/security/transactions`, data)
    .then((res) => {
     
      return res.data;
    });
};


