import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import HeadingOne from "../../../../../../UI-components/headings/headingsOne/HeadingsOne";
import styles from "./SecurityMain.module.css";
import shield_icon from "../../../../../../../assets/images/shield.svg";
import padlock_icon from "../../../../../../../assets/images/padlock.svg";
import Button from "../../../../../../UI-components/button";
import Loader from "../../../../../../UI-components/loader/Loader";
import { DataCentral } from "../../../../../../../context/AppContext";
import useOpenSnackbar from "../../../../../../../customHooks/useOpenSnackbar";

import useModal from "../../../../../../../customHooks/useModal";
import AppBackdrop from "../../../../../../UI-components/appBackdrop/AppBackdrop";
import ModalFrame from "../../../../../../UI-components/modalFrame/ModalFrame";
import Authenticator from "../../modals/authenticator/Authenticator";
import EnterOtpModal from "../../modals/enterOTP/EnterOtpModal";
import DisableOtp from "../../modals/disableOtp/DisableOtp";
import ChangePassword from "../../modals/changePassword/ChangePassword";
import SwapstaSnackbar from "../../../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CHANGE_PASSWORD, GENERATE_2FA, OTP_ACTIONS } from "../../../../../../../api/mutations/dashboard/settings/security";

function SecurityMain({user, openSnackbar}) {

  const {
    toggle,
    isShowingAuthenticator,
    isShowingChangePassword,
    isShowingDisable2FA,
    isShowingEnterOtp,
  } = useModal();
 
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  
  const queryClient = useQueryClient()
  const [qrCode, setQRCode] = useState("");
  const [otp, setOtp] = useState("");

  const [passwordErrorSpecialChar, setPasswordErrorSpecialChar] = useState({});
  const [passwordErrorEightChar, setPasswordErrorEightChar] = useState({});
  const [passwordErrorNumber, setPasswordErrorNumber] = useState({});
  const [error, setError] = useState({});
  const [otpError, setOtpError] = useState({});

  const { mutate: changePassword, isLoading: isLoadingChangePassword } =
    useMutation(CHANGE_PASSWORD, {
      onSuccess: (response) => {
        toggle("change-password");
        openSnackbar("success", response.message);
      
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    });

    const { mutate: generate2FA, isLoading: isLoading2FA } =
    useMutation(GENERATE_2FA, {
      onSuccess: (response) => {
        setQRCode(response.data);
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
      },
    });

    const { mutate: authOTP, isLoading: isLoadingAuthOTP } =
    useMutation(OTP_ACTIONS, {
      onSuccess: (response) => {
      
        localStorage.setItem("Access-token", response.data.accessToken);
       toggle("enter-otp");
        setOtp("");
        toggle("authenticator");
        setOtpError(false);
        openSnackbar("success", response.message);
        queryClient.invalidateQueries(['user'])
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
        setOtp("");
      },
    });

    const { mutate: deAuthOTP, isLoading: isLoadingDeAuthOTP } =
    useMutation(OTP_ACTIONS, {
      onSuccess: (response) => {
       
        localStorage.setItem("Access-token", response.data.accessToken);
       
        setOtp("");
        toggle("disable-2fa");
        toggle("disable-prompt");
        setOtpError(false);
        openSnackbar("success", response.message);
        queryClient.invalidateQueries(['user'])
      },
      onError: (error) => {
        openSnackbar("error", error.response.data.message);
        setOtp("");
      },
    });

  useEffect(() => {
    handleSpecialChar("");
    handleNumberValidation("");
    handleEightChar("");
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    if (name === "oldpassword") {
      setOldPassword(value);
    } else if (name === "newpassword") {
      setNewPassword(value);
    }
  };

  const handleValidation = (name, value) => {
    if (name === "newpassword") {
      handleEightChar(value);
      handleSpecialChar(value);
      handleNumberValidation(value);
    } else if (name === "oldpassword") {
      if (value.length < 1) {
        setError({ old_password: "Old password is required" });
      } else {
        const tempError = { ...error };
        tempError.old_password = false;
        setError(tempError);
      }
    }
  };

  const handleEightChar = (value) => {
    if (value.length < 8) {
      setPasswordErrorEightChar({ ...passwordErrorEightChar, eightChar: true });
    } else {
      const tempPass = { ...passwordErrorEightChar };
      tempPass.eightChar = false;
      setPasswordErrorEightChar(tempPass);
    }
  };

  const handleNumberValidation = (value) => {
    const numberRegex = /\d/;
    if (numberRegex.test(value)) {
      const tempPass = { ...passwordErrorNumber };
      tempPass.number = false;
      setPasswordErrorNumber(tempPass);
    } else {
      setPasswordErrorNumber({ ...passwordErrorNumber, number: true });
    }
  };
  const handleSpecialChar = (value) => {
    const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialCharRegex.test(value)) {
      const tempPass = { ...passwordErrorSpecialChar };
      tempPass.specialChar = false;
      setPasswordErrorSpecialChar(tempPass);
    } else {
      setPasswordErrorSpecialChar({
        ...passwordErrorSpecialChar,
        specialChar: true,
      });
    }
  };

  const handleConfirmChangePassword = async () => {
    if (error.old_password === false) {
      if (
        !passwordErrorSpecialChar.specialChar &&
        !passwordErrorNumber.number &&
        !passwordErrorEightChar.eightChar
      ) {
        changePassword({
          old_password,
          new_password,
        });
       
      } else {
      }
    } else {
      handleValidation("oldpassword", old_password);
    }
  };

  const handleGenerate2FA = async () => {
    generate2FA()
    
  };

  const handleOTPChange = (otp) => {
    setOtp(otp);
    validateOtp(otp);
  };

  const validateOtp = (otp) => {
    if (otp.length < 6) {
    } else {
      delete otpError.otp;
    }
  };
  const handleAuthenticateOTP = async (type) => {
    if (otp.length < 6) {
      setOtpError({
        otp: {
          message: "",
          type: "required",
        },
      });
    } else {
      authOTP({
        status: true,
        twoFactorAuthenticationCode: otp,
      })

  
    }
  };

  const handleDeAuthOTP = async () => {
    if (otp.length < 6) {
      setOtpError({
        otp: {
          message: "",
          type: "required",
        },
      });
    } else {
      deAuthOTP({
        status: false,
       twoFactorAuthenticationCode: otp,
      })

    
    }
  };
  return (
    <div className={styles.security}>
    
        <>
          <HeadingOne title={"Secure your Account"} />
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={styles.security_item}>
                <img src={shield_icon} alt="shield" />
                <div className={styles.security_item_text}>
                  <div className={styles.security_item_text_up}>
                    <Typography>Two-factor Authentication</Typography>

                    {user?.two_factor_authentication ? (
                      <button className={styles.security_item_button_enabled}>
                        Enabled
                      </button>
                    ) : (
                      <button
                        className={styles.security_item_button_not_enabled}
                      >
                        Disabled
                      </button>
                    )}
                  </div>
                  <Typography>
                    Add extra security on your account to authenticate your
                    login and transactions.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className={styles.security_main_btn}>
                {user?.two_factor_authentication ? (
                  <Button
                    fullwidth={true}
                    text={"Disable 2FA"}
                    bgColor={"#FFD3D3"}
                    color={"#A50000"}
                    size="default"
                    type="button"
                    borderRadius={"5px"}
                    onClick={() => {
                      toggle("disable-2fa");
                    }}
                  />
                ) : (
                  <Button
                    fullwidth={true}
                    text={"Enable 2FA"}
                    bgColor={"#1A2CCE"}
                    color={"#fff"}
                    size="default"
                    type="button"
                    borderRadius={"5px"}
                    onClick={() => {
                      toggle("authenticator");
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div
                className={`${styles.security_item_last} ${styles.security_item}`}
              >
                <img src={padlock_icon} alt="lock" />
                <div className={styles.security_item_text}>
                  <div className={styles.security_item_text_up}>
                    <Typography>Change password</Typography>
                  </div>
                  <Typography>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab, esse.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className={styles.security_main_btn}>
                <Button
                  fullwidth={true}
                  text={"Change password"}
                  bgColor={"#1A2CCE"}
                  color={"#fff"}
                  size="default"
                  type="button"
                  borderRadius={"5px"}
                  onClick={() => {
                    toggle("change-password");
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </>
      

      {isShowingAuthenticator && (
        <AppBackdrop
          openModal={isShowingAuthenticator}
          child={
            <ModalFrame
              style={{ width: 413 }}
              handleAction={() => {
                toggle("authenticator");
              }}
              child={
                <Authenticator
                  qrCode={qrCode}
                  loading2FA={isLoading2FA}
                  handleLoadData={() => {
                    handleGenerate2FA();
                  }}
                  handleAction={() => {
                    toggle("enter-otp");
                  }}
                />
              }
            />
          }
        />
      )}

      {isShowingEnterOtp && (
        <AppBackdrop
          openModal={isShowingEnterOtp}
          child={
            <EnterOtpModal
              otp={otp}
              otpError={otpError}
              loadingConfirm={isLoadingAuthOTP}
              handleOTPChange={handleOTPChange}
              handleAuthenticateOTP={handleAuthenticateOTP}
              handleBackToScan={() => {
                toggle("enter-otp");
              }}
            />
          }
        />
      )}

      {isShowingDisable2FA && (
        <AppBackdrop
          openModal={isShowingDisable2FA}
          handleCloseModal={() => {
            toggle("disable-2fa");
          }}
          child={
            <DisableOtp
              handleConfirm={() => {
                handleDeAuthOTP();
              }}
              handleCloseModal={() => {
                toggle("disable-2fa");
              }}
              otpError={otpError}
              otp={otp}
              loadingConfirm={isLoadingDeAuthOTP}
              handleOTPChange={handleOTPChange}
            />
          }
        />
      )}

      {isShowingChangePassword && (
        <AppBackdrop
          handleCloseModal={() => {
            toggle("change-password");
          }}
          openModal={isShowingChangePassword}
          child={
            <ChangePassword
              oldPassword={old_password}
              newPassword={new_password}
              handleChange={handleChange}
              handleConfirm={handleConfirmChangePassword}
              error={error}
              loadingPasswordChange={isLoadingChangePassword}
              passwordErrorEightChar={passwordErrorEightChar}
              passwordErrorNumber={passwordErrorNumber}
              passwordErrorSpecialChar={passwordErrorSpecialChar}
            />
          }
        />
      )}
   
    </div>
  );
}

export default SecurityMain;
