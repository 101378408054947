// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewWalletStepOne_field__UNO7K {\n    margin-bottom: 22px;\n  }\n\n  .NewWalletStepOne_btns__NHriq {\n    margin-top: 18px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n  }\n  \n  .NewWalletStepOne_btns__NHriq {\n    margin-left: 12px;\n  }", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/wallets/components/newWallet/step1/NewWalletStepOne.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".field {\n    margin-bottom: 22px;\n  }\n\n  .btns {\n    margin-top: 18px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n  }\n  \n  .btns {\n    margin-left: 12px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "NewWalletStepOne_field__UNO7K",
	"btns": "NewWalletStepOne_btns__NHriq"
};
export default ___CSS_LOADER_EXPORT___;
