import styled from 'styled-components';

export const SwitchWrap = styled('div')`
	width: 42px;
	height: 38px;
	display: flex;
	align-items: center;
	position: relative;
	.switch-main {
		width: 70px;
		height: 30px;
		// position: relative;
		// padding: 5px;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
	}
	.switch-btn {
		width: 50px;
		margin: auto;
		height: 30px;
		padding: 5px;

		border-radius: 20px;
		background: ${({ switched, color }) =>
			switched ? '#3AB75D' : '#FFE9BD'};

		transition: all 0.5s ease;
		&:focus .switch-ball,
		&:active .switch-ball {
			box-shadow: ${({ color }) =>
				color
					? `  0 1px 3px 1px rgba(0, 0, 0, 0.54), 00 1px 1px 10px ${
							color + '8a'
					  }`
					: ' 0 1px 3px 1px rgba(0, 0, 0, 0.54), 00 1px 1px 10px #ff66008a'};
			/* box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.54), 00 1px 1px 10px #ff66008a; */
		}
	}

	.switch-check {
		position: absolute;
		z-index: 12;
		height: 40px;
		width: 40px;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		opacity: 0;
		cursor: pointer;
	}
	.switch-ball {
		transition: all 0.5s ease;
		height: 20px;
		width: 20px;
		background: ${(props)=>(props.switched ? '#FFFFFF' : '#D08C3D')};
		border-radius: 50%;
		position: absolute;
		top: 0px;
		bottom: 0;
		right: ${(props) => (props.switched ? 0 : 'auto')};

		left: ${(props) => (!props.switched ? 50 : 'auto')};
		box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);

		margin: auto;
	}

	@keyframes yeah {
		0% {
			transform: scale(0.5);
		}
		100% {
			transform: scale(1);
			background: transparent;
		}
	}
	@keyframes back {
		0% {
			transform: scale(0.5);
		}
		100% {
			transform: scale(1);
			background: transparent;
		}
	}

	// .switch-overlay {
	// 	background: #6a666654;
	// 	position: absolute;
	// 	z-index: 11;
	// 	height: 38px;
	// 	width: 38px;
	// 	border-radius: 50%;
	// 	top: -11px;

	// bottom: 0;
	// transition: all 0.7s ease;
	// }
`;
// animation: ${(props) =>
//   props.switched
//     ? ' yeah .5s ease-out forwards'
//     : 'back .5s ease-out forwards'};
// right: ${(props) => (props.switched ? ' -9px' : 'auto')};
// left: ${(props) => (!props.switched ? ' -9px' : 'auto')};

// border: ${
// 	({ switched, color }) =>
// 		switched
// 			? color
// 				? `1px solid ${color}`
// 				: '1px solid #ff6600'
// 			: '0.4px solid #CFCFCF'
// 	"1px solid  #6a666694"
// };

// &:hover .switch-ball {
//   box-shadow: ${({ switched, color }) =>
//     switched
//       ? color
//         ? '0 1px 3px 1px rgba(0, 0, 0, 0.54), 00 1px 0px 10px ' + color + 36
//         : '0 1px 3px 1px rgba(0, 0, 0, 0.54), 00 1px 0px 10px #ff660036'
//       : ' 0 1px 3px 1px rgba(0, 0, 0, 0.54), 00 1px 0px 10px  #6a66662b'};
// }
