import { Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import ValidationMessage from "../../validation-message/ValidationMessage";
import styles from "./FileBox.module.css";
import { cutText, removeUrl } from "../../../../utils/helpers";
import { useEffect } from "react";

function FileBox({
  link,
  label,
  errors,
  file,
  register,
  trigger,
  name,
  setValue,
  multi,
}) {
  useEffect(() => {
    trigger(name);
  }, [file]);

  const handleRemoveDocument = (e) => {
    e.preventDefault();
    setValue(name, "");
    trigger(name);
  };

  return (
    <div className={styles.fileBox}>
      <div className={styles.heading}>
        <Typography>{label} (Max file 500KB)</Typography>
      </div>

      <div
        className={`${errors[name]?.message && styles.red_outline} ${
          styles.document_upload_input
        }`}
      >
        <div className={styles.document_upload_text}>
          {file?.length > 0 ? (
            <Typography>
              {typeof file === "object"
                ? cutText(file[0]?.name, 20)
                : cutText(removeUrl(file), 20)}
            </Typography>
          ) : (
            <Typography>Choose document</Typography>
          )}
        </div>
        <div className={styles.upload_btn}>
          <Typography>Choose Document</Typography>
          <input {...register(name)} type="file" multiple={multi} name={name} />
        </div>
      </div>

      {file?.length > 0 ? (
        <Link
          onClick={(e) => {
            handleRemoveDocument(e);
          }}
          to="/"
        >
          Remove document
        </Link>
      ) : null}
      {errors[name]?.message && (
        <ValidationMessage message={errors[name]?.message} />
      )}
      {link && (
        <a
          style={{
            color: "#1A2CCE",
          }}
          href={link}
        >
          Download and fill this template
        </a>
      )}
    </div>
  );
}

export default FileBox;
