import axios from "axios";

export const LOGIN = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}auth/login`, data)
    .then((res) => {
      return res.data;
    });
};

export const CREATE_ACCOUNT = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/register`, data)
      .then((res) => {
        return res.data;
      });
  };

export const RESET_PASSWORD = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/recover/password`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const FORGOT_PASSWORD = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/forgot/password`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const LOGIN_2FA = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/2fa/authenticate`, data)
      .then((res) => {
        return res.data;
      });
  };
  
  export const VERIFY_EMAIL = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}auth/verify`, data)
      .then((res) => {
        return res.data;
      });
  };