import React from "react";
import { useForm } from "react-hook-form";
import LabeledInputs from "../../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import LabeledSelectTwo from "../../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import ValidationMessage from "../../../../../../../UI-components/validation-message/ValidationMessage";
import styles from "../NewAccount.module.css";

function NewAccountFiat({ activeTab, currencies, register, errors, watch }) {
  const accountType = watch("account_type");

  return (
    <div>
      <div className={styles.field}>
        <LabeledSelectTwo
          redOutline={errors.currency ? true : false}
          label="Currency"
          placeholder="Choose Currency"
          name="currency"
          register={register}
          data={currencies?.result
            ?.filter((currency) => {
              return currency.type === activeTab.toUpperCase();
            })
            ?.map((currency) => {
              return {
                label: currency.code,
                value: currency.code,
              };
            })}
        />
        <ValidationMessage message={errors?.currency?.message} />
      </div>
      <div className={styles.field}>
        <LabeledSelectTwo
          placeholder="Choose Account type"
          redOutline={errors?.account_type ? true : false}
          label="Account Type"
          name="account_type"
          register={register}
          data={[
            {
              label: "Static",
              value: "static",
            },
            {
              label: "Dynamic",
              value: "dynamic",
            },
          ]}
        />
        <ValidationMessage message={errors?.account_type?.message} />
      </div>
      {accountType === "dynamic" && (
        <div className={styles.field}>
          <LabeledInputs
          type={'number'}
            redOutline={errors.amount ? true : false}
            name="amount"
            placeholder={""}
            label="Amount to be collected"
            register={register}
            errors={errors}
          />
          <ValidationMessage message={errors?.amount?.message} />
        </div>
      )}
    </div>
  );
}

export default NewAccountFiat;
