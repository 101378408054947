import React from "react";
import Swapp from "../../../../../UI-components/swap/Swap";
import styles from "./Swap.module.css";

const Swap = ({ type, cryptoCurrencies, currencies, fiatCurrencies }) => {
  return (
    <div>
      <Swapp
        cryptoCurrencies={cryptoCurrencies}
        currencies={currencies}
        fiatCurrencies={fiatCurrencies}
        type={"balance"}
      />
    </div>
  );
};

export default Swap;
