// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhatIsBinancePay_binance_pay__w2Izg {\n    display: flex;\n    flex-direction: column;\n  }\n  .WhatIsBinancePay_binance_pay__w2Izg h6 {\n    font-size: 18px;\n    font-weight: 500;\n    margin-bottom: 13px;\n  }\n  \n  .WhatIsBinancePay_binance_pay__w2Izg p {\n    font-size: 14px;\n    font-weight: 400;\n    color: #666565;\n    margin-bottom: 38px;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/binancePay/form/modal/whatIsBinancePay/WhatIsBinancePay.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,mBAAmB;EACrB","sourcesContent":[".binance_pay {\n    display: flex;\n    flex-direction: column;\n  }\n  .binance_pay h6 {\n    font-size: 18px;\n    font-weight: 500;\n    margin-bottom: 13px;\n  }\n  \n  .binance_pay p {\n    font-size: 14px;\n    font-weight: 400;\n    color: #666565;\n    margin-bottom: 38px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"binance_pay": "WhatIsBinancePay_binance_pay__w2Izg"
};
export default ___CSS_LOADER_EXPORT___;
