import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SwapstaSnackbar = ({ open, message, setOpen, duration, severity }) => {
  const [vertical] = useState("top");
  const [horizontal] = useState("right");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={duration ? duration : 4000}
      onClose={handleClose}
    >
    
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%", wordBreak: "break", zIndex: 10 }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SwapstaSnackbar;
