// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalFrame2_modal_frame2__bNaqJ {\n\tbackground-color: white;\n\tposition: relative;\n\tborder: 0.5px solid #d1d1d1;\n\tborder-radius: 5px;\n\tjustify-content: space-between;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.ModalFrame2_modal_frame2_upper__SJXjf {\n\tpadding: 50px 30px;\n}\n\n.ModalFrame2_modal_frame2_btns__P5l8F {\n\tpadding: 14px 30px;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tbackground: #f3f3f3;\n\tborder: 1px solid #ebebeb;\n}\n\n.ModalFrame2_modal_frame2_btns__P5l8F button {\n\tmargin-left: 1rem;\n}\n\n@media (max-width: 457px) {\n\t.ModalFrame2_modal_frame2__bNaqJ {\n\t\twidth: 95% !important;\n\t}\n\t.ModalFrame2_modal_frame2_upper__SJXjf {\n\t\tpadding: 30px 20px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI-components/modalFrame2/ModalFrame2.module.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,kBAAkB;CAClB,2BAA2B;CAC3B,kBAAkB;CAClB,8BAA8B;CAC9B,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,yBAAyB;CACzB,mBAAmB;CACnB,yBAAyB;AAC1B;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC;EACC,qBAAqB;CACtB;CACA;EACC,kBAAkB;CACnB;AACD","sourcesContent":[".modal_frame2 {\n\tbackground-color: white;\n\tposition: relative;\n\tborder: 0.5px solid #d1d1d1;\n\tborder-radius: 5px;\n\tjustify-content: space-between;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.modal_frame2_upper {\n\tpadding: 50px 30px;\n}\n\n.modal_frame2_btns {\n\tpadding: 14px 30px;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tbackground: #f3f3f3;\n\tborder: 1px solid #ebebeb;\n}\n\n.modal_frame2_btns button {\n\tmargin-left: 1rem;\n}\n\n@media (max-width: 457px) {\n\t.modal_frame2 {\n\t\twidth: 95% !important;\n\t}\n\t.modal_frame2_upper {\n\t\tpadding: 30px 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_frame2": "ModalFrame2_modal_frame2__bNaqJ",
	"modal_frame2_upper": "ModalFrame2_modal_frame2_upper__SJXjf",
	"modal_frame2_btns": "ModalFrame2_modal_frame2_btns__P5l8F"
};
export default ___CSS_LOADER_EXPORT___;
