import { CircularProgress, Typography } from "@mui/material";
import React, {useEffect} from "react";
import styles from "./BinancePayForm.module.css";
import LabeledInputs from "../../../../UI-components/new-inputs/inputs/LabeledInputs";
import ValidationMessage from "../../../../UI-components/validation-message/ValidationMessage";
import LabeledSelectTwo from "../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import Button from "../../../../UI-components/button/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BinancePaySchema1, BinancePaySchemaStep1 } from "../../../../../schemas/dashboard/binancePay";

function BinancePayForm1({
  isLoadingCountries,
  countries,
  mcc,
  setMerchantData,
  setFormLevel,
  loadingMcc,
  merchantData
}) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BinancePaySchemaStep1),
  });

  useEffect(()=>{
    setValue('business_name', merchantData?.business_name)
    setValue('business_logo', merchantData?.business_logo)
    setValue('business_type', merchantData?.business_type)
    setValue('country', merchantData?.country)
    setValue('business_category', merchantData?.business_category)
  }, [])
  const onSubmit = (data) => {
    setMerchantData({...data});
    setFormLevel(2);
  };

  return (
    <div className={styles.deposit_form}>
      {loadingMcc || isLoadingCountries ? (
        <div className={styles.circular_centered}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.body}>
            <div className={styles.form_field}>
              <LabeledInputs
                required={true}
                name="business_name"
                register={register}
                label="Business name"
                redOutline={errors?.business_name ? true : false}
              />
              <ValidationMessage
                message={
                  errors?.business_name?.message}
              />
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                required={true}
                name="business_logo"
                register={register}
                label="Business logo (URL)"

                redOutline={errors?.business_logo ? true : false}
              />
              <ValidationMessage
                message={
                  errors?.business_logo?.message
                }
              />
            </div>
            <div className={styles.form_field}>
              <LabeledSelectTwo
               required={true}
                placeholder="Business type"
                data={[
          
                  {
                    label: "Sole proprietor",
                    value: 2,
                  },
                  {
                    label: "Partnership",
                    value: 3,
                  },
                  {
                    label: "Private company",
                    value: 4,
                  },
                  {
                    label: "Others",
                    value: 5,
                  },
                ]}
                name="business_type"
                register={register}
                label="Business Type"
                redOutline={errors?.business_type ? true : false}
              />
              <ValidationMessage
                message={
                  errors?.business_type?.message
                }
              />
            </div>
            <div className={styles.form_field}>
              <LabeledSelectTwo
               required={true}
                data={mcc?.map((item) => {
                  return {
                    label: item.category,
                    value: item.code,
                  };
                })}
                placeholder="Business category"
                name="business_category"
                register={register}
                label="Business category"
                redOutline={errors?.business_category ? true : false}
              />
              <ValidationMessage
                message={
                  errors?.business_category?.message
                }
              />
            </div>
            <div className={styles.form_field}>
              <LabeledSelectTwo
               required={true}
                register={register}
                data={countries?.map((country) => {
                  return {
                    label: country.name,
                    value: country.iso2,
                  };
                })}
                placeholder={"Country"}
                label={"Country"}
                name={"country"}
                redOutline={errors?.country ? true : false}
              />
              <ValidationMessage
                message={errors?.country?.message}
              />
            </div>
            <div className={styles.form_btn}>
              <Button
                Styles
                text={"Next"}
                bgColor={"#1A2CCE"}
                color={"#FFFFFF"}
                size="md"
                fullwidth
                type="submit"
                border={"0.4px solid #1A2CCE"}
                boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
                borderRadius={"5px"}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BinancePayForm1;
