import { Typography } from "@mui/material";
import React from "react";
import styles from "./WalletsRows.module.css";
import green_dot from "../../../../../assets/images/dot-green.svg";
import red_dot from "../../../../../assets/images/dot-red.svg";
import { useNavigate } from "react-router-dom";
import { cutText, getDate } from "../../../../../utils/helpers";
import copy_icon from "../../../../../assets/images/copy_icon.svg";

function WalletsRows({
  type,
  openSnackbar,
  handleSelectSingleCheckbox,
  data,
  openToolTip,
  handleOpenTooltip,
  handleDeleteWallet,
  walletCheckbox,
}) {
  const navigate = useNavigate();

  const handleNavigateToTransaction = (data) => {
    navigate(`/wallets/${data.wallet_reference}`, {
      state: {
        data,
      },
    });
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      openSnackbar("success", "Copied successfully");
    } catch (error) {}
  };

  return (
    <tr
      onClick={() => {
        handleNavigateToTransaction(data);
      }}
      className={styles.row}
    >
      {/* <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <input
          onChange={(e) => {
            handleSelectSingleCheckbox(e, data.wallet_reference);
          }}
          type="checkbox"
          checked={walletCheckbox?.some((item) => {
            return item === data.wallet_reference;
          })}
          name=""
          id=""
        />
      </td> */}
      <td>
        <div className={styles.email}>
          {/* {data.status ? (
            <img src={green_dot} alt="" />
          ) : (
            <img src={red_dot} alt="" />
          )} */}

          <Typography>{getDate(data.created_at)}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.reference}>
          <Typography>{data?.full_name || "NA"}</Typography>
        </div>
      </td>

      <td>
        <div className={styles.reference}>
          <Typography>
            {cutText(data?.wallet_reference, 15, "start")}...
            {cutText(data?.wallet_reference, -2, "end")}
          </Typography>
          <img
            onClick={(e) => {
              e.stopPropagation();
              handleCopy(data?.wallet_reference);
            }}
            src={copy_icon}
            alt=""
          />
        </div>
      </td>
      {/* <td>
        <div className={styles.network}>
          <Typography>{data?.currency}</Typography>
        </div>
      </td> */}

      <td>
        <div
          className={`${styles.status} ${
            data?.crypto_compliance_status === "verified"
              ? styles.verified_status
              : null
          }
          
          ${
            data?.crypto_compliance_status === "pending"
              ? styles.pending_status
              : null
          }
          ${
            data?.crypto_compliance_status === "failed"
              ? styles.failed_status
              : null
          }`}
        >
          <Typography>{data?.crypto_compliance_status}</Typography>
        </div>
      </td>
      <td>
        <div className={styles.view_details}>
          <Typography
            onClick={(e) => {
              e.stopPropagation();

              handleNavigateToTransaction(data);
            }}
          >
            View details
          </Typography>
        </div>
        {/* <div
          onClick={(e) => {
            e.stopPropagation();
            handleOpenTooltip(data.id);
          }}
          className={styles.action}
        >
          <img src={horizontal_dot} alt="" />
        </div> */}
        {/* {openToolTip?.id === data.id ? (
          <div className={styles.tooltip}>
            <div className={styles.tooltip_container}>
              {/* <img className={styles.pointer_img} src={pointer_icon} alt="" /> */}
        {/* <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleNavigateToTransaction(data);
                }}
                className={styles.tooltip_item}
              >
                <div className={styles.tooltip_item_img}>
                  <img src={eye_icon} alt="eye" />
                </div>
                <Typography>View wallet</Typography>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  //handleNavigateToTransaction(data);
                }}
                className={styles.tooltip_item}
              >
                <div className={styles.tooltip_item_img}>
                  <img src={eye_icon} alt="eye" />
                </div>
                <Typography>View Compliance</Typography>
              </div> */}
        {/* <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteWallet(data);
                }}
                className={` ${styles.tooltip_item_last} ${styles.tooltip_item}`}
              >
                <div className={styles.tooltip_item_img}>
                  <img src={bin_icon} alt="export" />
                </div>
                <Typography>Delete wallet</Typography>
              </div> */}
        {/* </div>
          </div>
        ) : null} */}
      </td>
    </tr>
  );
}

export default WalletsRows;
