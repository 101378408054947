import React from "react";
import logo from "../../../assets/images/logo-dark.svg";
import styles from "./DeviceAlert.module.css";
import { Typography } from "@mui/material";

function DeviceAlert() {
  return (
    <div className={styles.device_alert}>
      <div className={styles.heading}>
        <img height={"150px"} width={"150px"} src={logo} alt="" />
      </div>
      <div className={styles.body}>
        <Typography variant="h5">Desktop Access Only</Typography>

        <Typography variant="h6">Welcome to Treasura!</Typography>
        <Typography>
          Please note that our web application is optimized for desktop usage
          only. Unfortunately, mobile devices are not supported at this time.
          For the best experience and full access to all features, we kindly ask
          you to visit our web app from a desktop or laptop computer. Thank you
          for your understanding.
        </Typography>
      </div>
    </div>
  );
}

export default DeviceAlert;
