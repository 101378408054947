import { useQuery } from "@tanstack/react-query";
import { GET_ROLES } from "../api/queries/dashboard/teams";
import {GET_BANKS} from './../api/queries/dashboard/misc/index'

const useGetRoles = () => {
    const {
        data: roles,
        isLoading,
        refetch,
      } = useQuery(["roles"], GET_ROLES, {
        refetchOnWindowFocus: false,
      });


  return {
     roles: roles,
    isLoadingRoles: isLoading
  };
};

export default useGetRoles;
