import React, { useContext, useEffect, useState } from "react";
import { HeaderStyles } from "./styles";
import { LogoutIcon, ProfileIcon } from "../../../assets/index";
import { Flex } from "../box";
import { DataCentral } from "../../../context/AppContext";
import AppBackdrop from "../appBackdrop/AppBackdrop";
import ConfirmationPrompt from "../prompt/confirmationPrompt/ConfirmationPrompt";
import useModal from "../../../customHooks/useModal";
import ModalFrame2 from "../modalFrame2/ModalFrame2";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const Index = ({ title }) => {
  const { setOpenWalletsDropDown } = useContext(DataCentral);
  const [showDropDown, setShowDropDown] = useState(false);
  const { handleLogout } = useContext(DataCentral);
  const { toggle, isShowingPrompt } = useModal();
  const [profileSvgIconColor, setProfileSvgIconColor] = useState("#9E9E9E");
  const [logoutSvgIconColor, setLogoutSvgIconColor] = useState("#9E9E9E");

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);
    var config = {
      selector: ".headway-badge",
      account: process.env.REACT_APP_HEADWAY_ACCOUNT,
      trigger: ".headway_trigger",
    };
    script.onload = function () {
      window.Headway.init(config);
    };
  }, []);

  return (
    <>
      <HeaderStyles>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Flex
            justifyContent={"flex-start"}
            width={"18%"}
            className="logo_container"
          >
            <h2>{title}</h2>
          </Flex>
          <div className="icon_box">
            {showDropDown && (
              <div className="profile_dropdown">
                {/* existing dropdown div */}
              </div>
            )}
            <div className="headway-badge whats_new" id="headway-badge">
              <Typography className="headway_trigger">What's new?</Typography>
            </div>
            <div
              className="icon_div"
              onClick={() => {
                //setShowDropDown(!showDropDown);
              }}
              onMouseOver={() => {
                setProfileSvgIconColor("#1a2cce");
              }}
              onMouseLeave={() => {
                setProfileSvgIconColor("#9E9E9E");
              }}
            >
              <Link
                onClick={() => {
                  setOpenWalletsDropDown(false);
                }}
                to="/settings/profile"
              >
                <ProfileIcon color={profileSvgIconColor} />
              </Link>
            </div>
            <div
              onClick={() => {
                toggle("confirmation-prompt");
              }}
              className="logout_icon_div"
              onMouseOver={() => {
                setLogoutSvgIconColor("red");
              }}
              onMouseLeave={() => {
                setLogoutSvgIconColor("#9E9E9E");
              }}
            >
              <LogoutIcon color={logoutSvgIconColor} />
            </div>
          </div>
        </Flex>
      </HeaderStyles>
      {isShowingPrompt && (
        <AppBackdrop
          openModal={isShowingPrompt}
          handleCloseModal={() => {
            toggle("confirmation-prompt");
          }}
          child={
            <ModalFrame2
              handleActionTwo={() => {
                toggle("confirmation-prompt");
              }}
              handleAction={() => {
                handleLogout();
              }}
              btnTxt="Confirm"
              child={
                <ConfirmationPrompt text="Are you sure you want to log out ?" />
              }
            />
          }
        />
      )}
    </>
  );
};

export default Index;
