import React, { useContext } from "react";
import styles from "./SignedWithdrawals.module.css";
import icon from "../../.././../../../../assets/images/signed_wth_icon.svg";
import Button from "../../../../../../UI-components/button";
import { Grid, Typography } from "@mui/material";
import { DataCentral } from "../../../../../../../context/AppContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SIGN_WITHDRAWALS } from "../../../../../../../api/mutations/dashboard/settings/security";

function SignedWithdrawals({ openSnackbar }) {
  const { user, isFetchingUser } = useContext(DataCentral);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(SIGN_WITHDRAWALS, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["user"]);
      openSnackbar("success", response.message);
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  return (
    <div className={styles.signed_withdrawals}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6}>
          <div className={styles.signed_withdrawal_item}>
            <img src={icon} alt="shield" />
            <div className={styles.signed_withdrawal_item_text}>
              <div className={styles.signed_withdrawal_item_text_up}>
                <Typography>Signed withdrawals</Typography>
                {user?.preference?.sign_withdrawal ? (
                  <button className={styles.item_button_enabled}>
                    Enabled
                  </button>
                ) : (
                  <button className={styles.item_button_not_enabled}>
                    Disabled
                  </button>
                )}
              </div>
              <Typography>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste, dolore!
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={styles.signed_withdrawal_btn}>
            {user?.preference?.sign_withdrawal ? (
              <Button
                fullwidth={true}
                text={"Disable"}
                bgColor={"#FFD3D3"}
                color={"#A50000"}
                size="default"
                type="button"
                loading={isLoading}
                borderRadius={"5px"}
                onClick={() => {
                  mutate({
                    sign_withdrawal: false,
                  });
                }}
              />
            ) : (
              <Button
                fullwidth={true}
                text={"Enable"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="default"
                type="button"
                borderRadius={"5px"}
                loading={isLoading}
                onClick={() => {
                  mutate({
                    sign_withdrawal: true,
                  });
                }}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignedWithdrawals;
