import React from 'react';
import EnterOtp from '../../../../../../UI-components/enter-otp/EnterOtp';

const EnterOtpModal = ({otpError, otp, loadingConfirm, handleAuthenticateOTP, handleOTPChange, handleBackToScan }) => {
	return (
		<EnterOtp
		otpError={otpError}
		otp={otp}
		handleConfirm={handleAuthenticateOTP}
		handleChange={handleOTPChange}
		type={"enter"}
		handleBackToScan={handleBackToScan}
		loadingConfirm={loadingConfirm}
	  />
	);
};

export default EnterOtpModal;
