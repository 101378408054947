// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmationPrompt_confirmation_prompt__Gz3W\\+ p {\n    color: #000a62;\n    font-size: 18px;\n      width: 400px;\n    font-weight: 600;\n  }\n\n\n  .ConfirmationPrompt_header__erYIf {\n      display: flex;\n      align-items: center;\n      margin-bottom: 16px;\n  }\n\n  .ConfirmationPrompt_header__erYIf p{\n\n    color: #000a62;\n    font-size: 18px;\n    font-weight: 600;\n  }\n\n  .ConfirmationPrompt_header__erYIf img {\n      margin-right: 13px;\n  }", "",{"version":3,"sources":["webpack://./src/components/UI-components/prompt/confirmationPrompt/ConfirmationPrompt.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;MACb,YAAY;IACd,gBAAgB;EAClB;;;EAGA;MACI,aAAa;MACb,mBAAmB;MACnB,mBAAmB;EACvB;;EAEA;;IAEE,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB;;EAEA;MACI,kBAAkB;EACtB","sourcesContent":[".confirmation_prompt p {\n    color: #000a62;\n    font-size: 18px;\n      width: 400px;\n    font-weight: 600;\n  }\n\n\n  .header {\n      display: flex;\n      align-items: center;\n      margin-bottom: 16px;\n  }\n\n  .header p{\n\n    color: #000a62;\n    font-size: 18px;\n    font-weight: 600;\n  }\n\n  .header img {\n      margin-right: 13px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation_prompt": "ConfirmationPrompt_confirmation_prompt__Gz3W+",
	"header": "ConfirmationPrompt_header__erYIf"
};
export default ___CSS_LOADER_EXPORT___;
