// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalletAccounts_heading__jyAWH {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 37px;\n}\n\n.WalletAccounts_heading__jyAWH h6 {\n  font-size: 14px;\n  font-weight: 400;\n  text-decoration: underline;\n  color: #d80027;\n  cursor: pointer;\n}\n.WalletAccounts_table__POGzF {\n  margin-top: 32px;\n \n}\n.WalletAccounts_table_heading__kqZp4 p {\n  font-size: 16px;\n  font-weight: 600;\n  color: #6f6f6f;\n}\n.WalletAccounts_body__7xRpL {\n  padding: 30px 0;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/wallets/walletAccounts/WalletAccounts.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,cAAc;EACd,eAAe;AACjB;AACA;EACE,gBAAgB;;AAElB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;AACjB","sourcesContent":[".heading {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 37px;\n}\n\n.heading h6 {\n  font-size: 14px;\n  font-weight: 400;\n  text-decoration: underline;\n  color: #d80027;\n  cursor: pointer;\n}\n.table {\n  margin-top: 32px;\n \n}\n.table_heading p {\n  font-size: 16px;\n  font-weight: 600;\n  color: #6f6f6f;\n}\n.body {\n  padding: 30px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "WalletAccounts_heading__jyAWH",
	"table": "WalletAccounts_table__POGzF",
	"table_heading": "WalletAccounts_table_heading__kqZp4",
	"body": "WalletAccounts_body__7xRpL"
};
export default ___CSS_LOADER_EXPORT___;
