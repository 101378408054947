import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import { extractFileType } from "../../../utils/helpers";
import { isPossiblePhoneNumber } from "react-phone-number-input";
const fileTypes = ["jpg", "pdf", "png", "doc", "jpeg", "docx"];

export const createWalletSchema1 = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  full_name: yup.string().required("Account name is required"),
  merchant_reference: yup.string().required("Reference ID is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .test({
      name: "valid",
      message: "Phone number must be valid",
      test: function (value) {
   
        if(value){
          return isPossiblePhoneNumber(value) && isValidPhoneNumber(value);
        }
        
      },
    }),
});

export const createWalletSchema2 = yup.object().shape({
  firstname: yup.string().required("Firstname is required"),
  lastname: yup.string().required("Lastname is required"),
  bvn: yup
    .string()
    .required("BVN is required")
    .min(11, "BVN must be 11 characters")
    .max(11, "BVN must be 11 characters"),
  verification_id_0: yup.string().required("Verification ID is required"),
  id_number_0: yup.string().required("ID number is required"),
  source_0: yup.string().required("Source is required"),
  document_0: yup.mixed().when(`source_0`, {
    is: "upload",
    then: yup
      .mixed()
      .test({
        name: "required",
        message: "Document is required",
        test: (value) => {
          return value?.length > 0;
        },
      })
      .test({
        name: "fileSize",
        message: "file uploaded is larger then 500kb",
        test: (value) => {
          if (value) {
            return value[0]?.size < 500000;
          }
        },
      })
      .test({
        name: "fileType",
        message: "file uploaded is not png, jpeg, jpg, pdf, doc, or docx.",
        test: (value) => {
          if (value) {
            return fileTypes.includes(extractFileType(value[0].name));
          }
        },
      }),
    otherwise: yup.mixed(),
  }),

  document_link_0: yup.string().when(`source_0`, {
    is: "link",
    then: yup.string().required("'Link is required").url("URL is not valid"),
    otherwise: yup.string(),
  }),
});

export const createWalletAccount = yup.object().shape({
  type: yup.string(),
  chain: yup.string().when("type", {
    is: "crypto",
    then: yup.string().required("Network is required"),
    otherwise: yup.string(),
  }),
  account_type: yup.string().when("type", {
    is: "fiat",
    then: yup.string().required("Account type is required"),
    otherwise: yup.string(),
  }),
  currency: yup.string().required("Currency is required"),

  account_reference: yup.string().when("type", {
    is: "crypto",
    then: yup.string().required("Reference ID is required"),
    otherwise: yup.string(),
  }),

  amount: yup.string().when("account_type", {
    is: "dynamic",
    then: yup.string().required("Amount is required"),
    otherwise: yup.string(),
  }),
});
// export const createCryptoWalletSchema2 = yup.object().shape({
//   verification_id: yup.string().required("Verification ID is required"),
//   source: yup.string().required("Source is required"),

//   id_number: yup.string().when("source", {
//     is: "id_no",
//     then: yup.string().required("ID number is required"),
//     otherwise: yup.string(),
//   }),

//   document_link: yup.string().when("source", {
//     is: "link",
//     then: yup.string().required("'Link is required"),
//     otherwise: yup.string(),
//   }),
//   document: yup.mix().when("source", {
//     is: "upload",
//     then: yup
//       .mixed()
//       .test({
//         name: "required",
//         message: "Document is required",
//         test: (value) => value?.length > 0,
//       })
//       .test({
//         name: "fileSize",
//         message: "file uploaded is larger then 500kb",
//         test: (value) => {
//           if (typeof value === "object") {
//             return value[0]?.size < 500000;
//           } else {
//             return true;
//           }
//         },
//       }),
//     otherwise: yup.mixed(),
//   }),
// });
