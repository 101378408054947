import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SearchInput from "../searchInput/SearchInput";
import styles from "../swap/Swap.module.css";
import Select from "../../../../UI-components/select/index";
import {
  filterByCurrency,
  filterByStatus,
  filterByDate,
  DPTfilterByStatus,
  DPTtranscationTableHead,
} from "../../../../../lib/constant";
import Button from "../../../../UI-components/button";
import Loader from "../../../../UI-components/loader/Loader";
import Pagination from "../../../../UI-components/pagination/Pagination";
import TableOne from "../../../../UI-components/new -tables/Table";
import EmptyState from "../../../../UI-components/null state/EmptyState";
import { Typography } from "@mui/material";
import SwapstaSnackbar from "../../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useOpenSnackbar from "../../../../../customHooks/useOpenSnackbar";

const swapTransactionTableHead = [
  "CURRENCY",
  "DATE/TIME",
  "AMOUNT FROM",
  "AMOUNT TO",
  "TNX REFERENCE",
  "CHANNEL",
  "STATUS",
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: 12,
    border: "none",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 12,
  }),
};

function Deposit({
  
  currencies,
  depositFilterData,
  loadingDeposit,
  handlePageChange,
  handleGetDeposits,
  handleFilter,
  deposits,
  depositIsFetched,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  const {
    register: registerSearch,
    setValue: setValueSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    formState: { errors: errorsSearch },
  } = useForm();
  const [searched, setSearched] = useState(false);
  const searchedValue = watchSearch("search");
  const [openToolTip, setOpenToolTip] = useState({});

  useEffect(() => {
    setValue("");
    Object.keys(depositFilterData).map((item) => {
      setValue(item, depositFilterData[item]);
      return null;
    });
  }, [depositFilterData]);

  const onSubmit = async (data) => {
    handleFilter("filter", data);
  };

  const onSubmitSearch = (data) => {
    setSearched(!searched);
    handleFilter("search", data);
  };

  const handleTooltipClose = () => {
    setOpenToolTip({});
  };

  const handleTooltipOpen = (trx_id) => {
    if (openToolTip.open) {
      setOpenToolTip({});
    } else {
      setOpenToolTip({ open: true, id: trx_id });
    }
  };

  return (
    <div className={styles.trx_page}>
      <div className={styles.filters}>
        <div className={styles.search_main}>
          <div
            style={{ width: searched && depositIsFetched ? "auto" : 300 }}
            className={styles.search}
          >
            <SearchInput
              handleClose={() => {
                handleGetDeposits();
              }}
              searchedValue={searchedValue}
              setSearched={setSearched}
              searched={searched && depositIsFetched}
              handleSubmit={handleSubmitSearch}
              onSubmit={onSubmitSearch}
              register={registerSearch}
              setValue={setValueSearch}
              name={"search"}
            />
          </div>
          {searched && depositIsFetched && (
            <div className={styles.search_result_info}>
              <Typography>"{deposits?.data?.length} result found"</Typography>
            </div>
          )}
        </div>

        {searched && depositIsFetched ? null : (
          <div className={styles.filter_selects}>
            <div className={styles.select}>
              <span>Date</span>
              <Select
                border={"none"}
                styles={customStyles}
                listOptions={filterByDate}
                register={register}
                name={"date"}
                required={false}
              />
            </div>
            <div className={styles.select}>
              <span>Currency</span>
              <Select
                border={"none"}
                styles={customStyles}
                listOptions={filterByCurrency}
                register={register}
                name={"currency"}
                required={false}
              />
            </div>
            <div className={styles.select}>
              <span>Status</span>
              <Select
                border={"none"}
                styles={customStyles}
                listOptions={DPTfilterByStatus}
                register={register}
                name={"status"}
                required={false}
              />
            </div>
            <div className={styles.filter_btn}>
              <Button
                Styles
                width="200px"
                text={"Apply filters"}
                bgColor={"#E7F1FF"}
                color={"#1A2CCE"}
                size="sm"
                //fullwidth
                type="submit"
                border={"0.4px solid #1A2CCE"}
                boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
                borderRadius={"5px"}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        )}
      </div>
      {loadingDeposit ? (
        <Loader />
      ) : (
        <>
          <div>
            {deposits?.data?.length === 0 ? (
              <EmptyState
                primaryText={
                  deposits?.from === "filter"
                  ? "Deposit transactions not found"
                  : "No Deposit transactions here yet"
                }
                secondaryText={
                  deposits?.from === "filter"
                  ? "No Deposit transactions with filtered parameters"
                  : "it looks like you dont have any Deposit transactions yet"
                }
                handleRefresh={handleGetDeposits}
              />
            ) : (
              <>
                <TableOne
                  openSnackbar={openSnackbar}
                  handleCloseTooltip={handleTooltipClose}
                  handleOpenTooltip={handleTooltipOpen}
                  openToolTip={openToolTip}
                  currencies={currencies}
                  handleRefresh={handleGetDeposits}
                  type={"transaction"}
                  transactionType={"deposit"}
                  tableBody={deposits?.data}
                  tableHeadData={DPTtranscationTableHead}
                />
                {deposits?.data?.length === 0 ? null : (
                  <Pagination
                    type={"deposit"}
                    handlePageChange={handlePageChange}
                    meta={deposits?.meta}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
      <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      />
    </div>
  );
}

export default Deposit;
