import React from "react";
import { useForm } from "react-hook-form";
import Search from "../../../../../UI-components/search";
import LabeledSelectTwo from "../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import styles from "./ActivitiesFilter.module.css";

function ActivitiesFilter({events}) {
  const { register } = useForm();

  return (
    <div className={styles.activities_filter}>
      <div className={styles.filter_field}>
        <LabeledSelectTwo name="event" register={register} data={events?.map((event)=>{
          return ({
            label: event.event,
            value: event.event
          })
        })} />
      </div>

      <div className={`${styles.filter_field} ${styles.search_field}`}>
        <Search
          type={"filter"}
          name={"search"}
          placeholder={"Search reference no."}
        />
      </div>
      <div className={styles.filter_field}>
        <LabeledSelectTwo name="day" register={register} data={[]} />
      </div>
    </div>
  );
}

export default ActivitiesFilter;
