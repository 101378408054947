import React, { useState } from "react";
import { VerifyEmailContainer } from "./styles";
import { Flex, Frame } from "../../../UI-components/box";
import logo from "../../../../assets/images/logo-dark.svg";
import email from "../../../../assets/images/email-icon.svg";

const Index = () => {
  return (
    <VerifyEmailContainer>
      <Flex flexDir={"column"} margin={"auto"}>
        <Frame height={"150px"} width={"150px"}>
          <img alt="logo" src={logo} />
        </Frame>
        <h1 className="verify_mail_title">Email Verification Failed</h1>
        <Flex
          className="flex_container"
          width={"550px"}
          pad={"2em"}
          flexDir={"column"}
          bgColor={"#ffff"}
        >
          <Frame height={"90px"} width={"90px"} margin={"0px 0px 40px"}>
            <img alt="email-svg" src={email} />
          </Frame>
          <p className="verify_mail_text">
            Email verification was not successful because we could not verify
            your ownership of the email address used for the registration. We
            have sent a new verification link, please check your email inbox
            or spam for the email verification link and click on it to verify
            your email.
          </p>
          {/* <Button
					onClick={handleResendLink}
						text={'Resend email verifcation'}
						bgColor={'#1A2CCE'}
						color={'#fff'}
						size='md'
						fullwidth
						type='submit'
						borderRadius={'5px'}
						// loading={isSubmitting}
					/> */}
        </Flex>
      </Flex>
    </VerifyEmailContainer>
  );
};

export default Index;
