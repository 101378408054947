import styled from 'styled-components';
import { Flex } from '../../../../UI-components/box';

export const Styles = styled.div`
	width: 100%;
	background-color: #fff;
	padding: 25px;
	border-radius: 5px;
`;

export const Card = styled.div`
	height: 158px;
	border: 1px solid #dfdfdf;
	box-sizing: border-box;
	// border-radius: 6px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	.card-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 23px;
		> a {
			color: #3f3f3f;
			text-decoration: underline;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: 0em;
			text-align: left;
		}
		> h6 {
			color: #1b1b1b;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: 0em;
			text-align: left;
		}
		.volume {
			color: #0f0f0f;
			font-size: 24px;
			font-style: normal;
			font-weight: 700 !important;
			text-align: left;
		}
		.blue {
			color: #1a2cce;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
			letter-spacing: 0em;
			text-align: right;
		}
		.red {
			color: #b70000;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
			letter-spacing: 0em;
			text-align: right;
		}
		.chip {
			width: 53px;
			height: 23px;
			
			border-radius: 11.5px;
			display: flex;
			align-items: center;
			justify-content: center;
			> p {
				color: #723d00;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 14px;
				letter-spacing: 0em;
				text-align: center;
				text-transform: capitalize;
			}
		}
		.chip_test{
			background: #ffe9bd;
		}
		.chip_live{
			background: rgb(58, 183, 93);
			>p{
				color: white;
			}
			
		}
	}

	.card-footer-item {
		display: flex;
		align-items: center;
		margin-top: -23px;
		// justify-content: space-between;
		// margin-bottom: 23px;

		Button {
			padding: 0;
			margin-right: 10px;
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
		}
		.active {
			color: #1a2cce;
			text-decoration-line: underline;
		}
		.non-active {
			color: #aeaeae;
		}
	}
	.heading {
		margin-bottom: 29px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		> p {
			color: #5b5b5b;
			font-size: 16px;
			font-style: normal;
			font-weight: 800;
			line-height: 18px;
			letter-spacing: 0em;
			text-align: left;
		}
		> a {
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: right;
			// color: #aa4c00;
			color: #1a2cce;
			text-decoration: underline;
		}
	}
`;
