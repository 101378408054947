import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./DocUpload.module.css";
import Button from "../../../../../UI-components/button";
import { Typography } from "@mui/material";
import LabeledSelectTwo from "../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import DocumentUploadPreview from "./DocumentUploadPreview";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../../UI-components/validation-message/ValidationMessage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UPDATE_DOCUMENT_COMPLIANCE } from "../../../../../../api/mutations/dashboard/settings/compliance";
import { yupResolver } from "@hookform/resolvers/yup";
import FileBox from "../../../../../UI-components/new-inputs/fileBox/FileBox";
import {
  cutText,
  removeUrl,
} from "../../../../../../utils/helpers";
import useDynamicSchema2 from "../../../../../../customHooks/useDynamicSchema2";

import MemberTab from "./modules/MemberTab";
import useSortDocumentUploadDataPayload from "../../../../../../customHooks/useSortDocumentUploadPayload";

const document_upload_data = [

  {
    name: "document_incorporation",
    label: "Certificate Of Incorporation",
  },
  {
    name: "document_memorandum",
    label: "Memorandum and Articles of Association",
  },
 
  {
    name: "proof_of_address",
    label: "Proof of Address of Company",
  },

  {
    name: "letter_of_authorization",
    label: "Letter of Authorization",
    link: 'https://api.staging.trytreasura.com/template/letter_of_authorization.docx'
  },
  {
    name: "register_of_members",
    label: "Register of Members",
  },
  {
    name: "register_of_directors",
    label: "Register of Directors",
  },
  {
    name: 'document_aml',
    label: 'AML form',
    link: 'https://api.staging.trytreasura.com/template/aml.docx'
  },
  {
    name: "id_directors_",
    label: "ID of Directors",
  },
  {
    name: "id_shareholders_",
    label: "ID of Shareholders",
  },
];

const DocumentUpload = ({
  countries,
  openSnackbar,
  setComplianceInfo,
  complianceInfo,
}) => {
  const queryClient = useQueryClient();
  const [id_directors_count, setIdDirectorCount] = useState(1);
  const [id_shareholders_count, setIdShareholdersCount] = useState(1);

  const { schema } = useDynamicSchema2(
    id_directors_count,
    id_shareholders_count,
    document_upload_data?.map((item) => {
      return item?.name;
    })
  );

  const { handleSort, payload } = useSortDocumentUploadDataPayload(
    id_directors_count,
    id_shareholders_count
  );
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const documents = watch();
 

  const { mutate, isLoading } = useMutation(UPDATE_DOCUMENT_COMPLIANCE, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["compliance"]);
      openSnackbar("success", response.message);
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });

  useEffect(() => {
    setValue("country", complianceInfo.document_country_issuance);
  }, [countries]);

  useEffect(() => {
    document_upload_data.map((item, index) => {
      if (item.name === "id_directors_") {
        setIdDirectorCount(complianceInfo?.id_directors?.length || 1);
        complianceInfo?.id_directors?.map((_item, _index) => {
          setValue(
            `${item?.name}${_index}`,
            complianceInfo?.id_directors[_index] || ""
          );
        });
      } else if (item.name === "id_shareholders_") {
        setIdShareholdersCount(complianceInfo?.id_shareholders?.length || 1);
        complianceInfo?.id_shareholders?.map((_item, _index) => {
          setValue(
            `${item?.name}${_index}`,
            complianceInfo?.id_shareholders[_index] || ""
          );
        });
      } else {
        setValue(item?.name, complianceInfo[item.name] || "");
      }
    });
  }, [complianceInfo]);

  // const documentType = [
  //   { value: "International Passport", label: "International Passport", id: 1 },
  //   { value: "Driver's License", label: "Driver's License", id: 2 },
  //   { value: "Residence Permit/Card", label: "Residence Permit/Card", id: 3 },
  //   { value: "National ID Card", label: "National ID Card", id: 4 },
  // ];

  const onSubmit = async (data) => {
    handleSort(data);
  };

  useEffect(() => {
    if (Object.keys(payload).length > 0) {
      let formData = new FormData();

      Object.keys(payload).map((item) => {
        if (item === "id_directors") {
          for (var i = 0; i < payload[item].length; i++) {
            formData.append("id_directors", payload[item][i]);
          }
        } else if (item === "id_shareholders") {
          for (var i = 0; i < payload[item].length; i++) {
            formData.append("id_shareholders", payload[item][i]);
          }
        } else {
          formData.append(
            item,
            typeof payload[item] === "object" ? payload[item][0] : payload[item]
          );
        }
      });
      mutate(formData);
    }
  }, [payload]);

  return (
    <>
      {complianceInfo?.verified_document ? (
        <DocumentUploadPreview
          complianceInfo={complianceInfo}
          documentUploadData={document_upload_data}
          cutText={cutText}
          removeUrl={removeUrl}
          country={complianceInfo.document_country_issuance}
          identity_document_type={complianceInfo.identity_document_type}
          document_business={complianceInfo.document_registration}
          document_memorandum={complianceInfo.document_memorandum}
          document_incorporation={complianceInfo.document_incorporation}
          document_identity={complianceInfo.document_identity}
        />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.document_upload}
        >
          <div className={styles.document_upload_field}>
            <LabeledSelectTwo
              register={register}
              data={countries?.map((country) => {
                return {
                  label: country.name,
                  value: country.name,
                };
              })}
              placeholder={"Country"}
              name={"country"}
              redOutline={errors.country ? true : false}
              label={"country of issuance"}
            />
            <ValidationMessage message={errors.country?.message} />
          </div>
          <div className={styles.business_docu_title}>
            <Typography>Business document upload</Typography>
          </div>
          <div className={styles.document_upload_field}>
            {document_upload_data?.map((item) => {
              if (item.name === "id_directors_") {
                return (
                  <MemberTab
                    key={item.name}
                    label={"Directors Identity Document Upload"}
                    memberLabel={"Director"}
                    documents={documents}
                    trigger={trigger}
                    item={item}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    errors={errors}
                    counts={id_directors_count}
                    setCounts={setIdDirectorCount}
                  />
                );
              } else if (item.name === "id_shareholders_") {
                return (
                  <MemberTab
                    key={item.name}
                    memberLabel={"Shareholder"}
                    label={"Shareholders Identity Document Upload  "}
                    documents={documents}
                    trigger={trigger}
                    item={item}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    errors={errors}
                    counts={id_shareholders_count}
                    setCounts={setIdShareholdersCount}
                  />
                );
              } else {
                return (
                  <FileBox
                    key={item.name}
                    trigger={trigger}
                    multi={false}
                    link={item?.link}
                    file={documents[`${item?.name}`]}
                    setValue={setValue}
                    label={item.label}
                    name={item.name}
                    watch={watch}
                    register={register}
                    errors={errors}
                  />
                );
              }
            })}
          </div>
          <div className={styles.document_upload_btn}>
            <Button
              text={"Save changes"}
              bgColor={"#1A2CCE"}
              color={"#fff"}
              size="sm"
              type="submit"
              borderRadius={"5px"}
              loading={isLoading}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default DocumentUpload;
