// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivitiesFilter_activities_filter__V39kD {\n    display: flex;\n    align-items: center;\n    margin-top: 40px;\n    margin-bottom: 32px;\n}\n\n.ActivitiesFilter_filter_field__ltpw6 {\nwidth: 166px;\nmargin-right: 24px;\n}\n\n.ActivitiesFilter_search_field__kyhAU {\n    width: 348px;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/activities/filter/ActivitiesFilter.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;AACA,YAAY;AACZ,kBAAkB;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".activities_filter {\n    display: flex;\n    align-items: center;\n    margin-top: 40px;\n    margin-bottom: 32px;\n}\n\n.filter_field {\nwidth: 166px;\nmargin-right: 24px;\n}\n\n.search_field {\n    width: 348px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activities_filter": "ActivitiesFilter_activities_filter__V39kD",
	"filter_field": "ActivitiesFilter_filter_field__ltpw6",
	"search_field": "ActivitiesFilter_search_field__kyhAU"
};
export default ___CSS_LOADER_EXPORT___;
