import { useState } from "react";

const useModal = () => {
  const [isShowingChooseAssets, setIsShowingChooseAssets] = useState(false);
  const [isShowingChooseAssetsTwo, setIsShowingChooseAssetsTwo] = useState(false);
  const [isShowingConfirmOrder, setIsShowingConfirmOrder] = useState(false);
  const [isShowingDeposit, setIsShowingDeposit] = useState(false);
  const [isShowingWithdraw, setIsShowingWithdraw] = useState(false);
  const [isShowingDepositCrypto, setIsShowingDepositCrypto] = useState(false);
  const [isShowingWithdrawCrypto, setIsShowingWithdrawCrypto] = useState(false);
  const [isShowingSwap, setIsShowingSwap] = useState(false);
  const [isShowingWithdrawViaBank, setIsShowingWithdrawViaBank] =
    useState(false);
  const [isShowingDebitCard, setIsShowingDebitCard] = useState(false);
  const [isShowingConfirmWithdrawal, setIsShowingConfirmWithdrawal] = useState(false);
  const [isShowingOrderSuccessful, setIsShowingOrderSuccessful] = useState(false);
  const [isShowingDownloadReport, setIsShowingDownloadReport] = useState(false);
  const [isShowingAuthenticator, setIsShowingAuthenticator] = useState(false)
  const [isShowingEnterOtp, setIsShowingEnterOtp] = useState(false)
  const [isShowingDisablePrompt, setIsShowingDisablePrompt] = useState(false)
  const [isShowingDisable2FA, setIsShowingDisable2FA] = useState(false)
  const [isShowingChangePassword, setIsShowingChangePassword] = useState(false)
  const [isShowingPrompt, setIsShowingPrompt] = useState(false)
  const [isShowingResetLoginPrompt, setIsShowingResetLoginPrompt] = useState(false)
  const [isShowingAddWallet, setIsShowingAddWallet] = useState(false)
  const [isShowingAddAccount, setIsShowingAddAccount] = useState(false)
  const [isShowingShareReceipt, setIsShowingShareReceipt] = useState(false)
  const [isShowingBinancePayTrxResult, setIsShowingBinancePayTrxResult] = useState(false)
  const [opened, setOpened] = useState([])

  const handleOpenModal=(type)=>{
    setOpened([...opened, type])
  }
  const handleCloseModal=(type)=>{
   const remOpened = opened.filter((item)=>{
       return item !== type
    })
    setOpened(remOpened)
  }
  function toggle(type) {
    if (type === "choose-assets") {
      setIsShowingChooseAssets(!isShowingChooseAssets);
    } else  if (type === "choose-assets-two") {
      setIsShowingChooseAssetsTwo(!isShowingChooseAssetsTwo);
    } else if (type === "confirm-order") {
      setIsShowingConfirmOrder(!isShowingConfirmOrder);
    } else if (type === "deposit") {
      setIsShowingDeposit(!isShowingDeposit);
    } else if (type === "withdraw") {
      setIsShowingWithdraw(!isShowingWithdraw);
    } else if (type === "deposit-crypto") {
      setIsShowingDepositCrypto(!isShowingDepositCrypto);
    } else if (type === "withdraw-crypto") {
      setIsShowingWithdrawCrypto(!isShowingWithdrawCrypto);
    } else if (type === "swap") {
      setIsShowingSwap(!isShowingSwap);
    } else if (type === "withdraw-via-bank") {
      setIsShowingWithdrawViaBank(!isShowingWithdrawViaBank);
    } else if (type === "debit-card") {
      setIsShowingDebitCard(!isShowingDebitCard);
    }else if (type === 'confirm-withdrawal'){
      setIsShowingConfirmWithdrawal(!isShowingConfirmWithdrawal)
    }else if(type === 'order-successful'){
      setIsShowingOrderSuccessful(!isShowingOrderSuccessful)
    }else if(type === 'download-report'){
      setIsShowingDownloadReport(!isShowingDownloadReport)
    }else if(type=== 'authenticator'){
      setIsShowingAuthenticator(!isShowingAuthenticator)
    }else if(type === 'enter-otp'){
      setIsShowingEnterOtp(!isShowingEnterOtp)
    }else if(type === 'disable-prompt'){
      setIsShowingDisablePrompt(!isShowingDisablePrompt)
    }else if(type === 'disable-2fa'){
      setIsShowingDisable2FA(!isShowingDisable2FA)
    }else if(type === 'change-password'){
      setIsShowingChangePassword(!isShowingChangePassword)
    }else if(type === 'confirmation-prompt'){
      setIsShowingPrompt(!isShowingPrompt)
    }else if(type === 'confirmation-prompt'){
      setIsShowingPrompt(!isShowingPrompt)
    }else if(type === 'new-wallets'){
      setIsShowingAddWallet(!isShowingAddWallet)
    }else if(type === 'share-receipt'){
      setIsShowingShareReceipt(!isShowingShareReceipt)
    }else if(type === 'binance-pay-response'){
      setIsShowingBinancePayTrxResult(!isShowingBinancePayTrxResult)
    }else if(type === 'new-wallet-account'){
      setIsShowingAddAccount(!isShowingAddAccount)
    }
    else {

    }
  }

  return {
    isShowingChooseAssets,
    isShowingChooseAssetsTwo,
    isShowingConfirmOrder,
    isShowingDeposit,
    isShowingWithdraw,
    isShowingDepositCrypto,
    isShowingWithdrawCrypto,
    isShowingSwap,
    isShowingWithdrawViaBank,
    isShowingDebitCard,
    isShowingConfirmWithdrawal,
    isShowingOrderSuccessful,
    isShowingDownloadReport,
    isShowingAuthenticator,
    isShowingEnterOtp,
    isShowingDisablePrompt,
    isShowingDisable2FA,
    isShowingChangePassword,
    isShowingPrompt,
    isShowingResetLoginPrompt,
    isShowingAddWallet,
    isShowingShareReceipt,
    isShowingBinancePayTrxResult,
    isShowingAddAccount,
    toggle,
    handleOpenModal, 
    handleCloseModal,
    opened
  };
};

export default useModal;
