// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LabeledInputs_labeled_input__uurxP {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n}\n\n.LabeledInputs_labeled_input__uurxP p {\n\ttext-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 700;\n\ttext-align: left;\n\tmargin-bottom: 9px;\n\tline-height: 17px;\n}\n\n.LabeledInputs_labeled_input__uurxP input {\n\tline-height: 17px;\n\tborder: 1px solid #d7d7d7;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\ttext-align: left;\n\tcolor: #595959;\n\theight: 46px;\n\tpadding: 0 14px;\n\tbackground-color: white;\n\tborder-radius: 5px;\n}\n\n.LabeledInputs_red_outline__80Vx5 {\n\toutline: 1px solid red !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/pages/auth/signup/signup-inputs/LabeledInputs.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,WAAW;AACZ;;AAEA;CACC,0BAA0B;CAC1B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,gBAAgB;CAChB,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;CACjB,yBAAyB;CACzB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,gBAAgB;CAChB,cAAc;CACd,YAAY;CACZ,eAAe;CACf,uBAAuB;CACvB,kBAAkB;AACnB;;AAEA;CACC,iCAAiC;AAClC","sourcesContent":[".labeled_input {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n}\n\n.labeled_input p {\n\ttext-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 700;\n\ttext-align: left;\n\tmargin-bottom: 9px;\n\tline-height: 17px;\n}\n\n.labeled_input input {\n\tline-height: 17px;\n\tborder: 1px solid #d7d7d7;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\ttext-align: left;\n\tcolor: #595959;\n\theight: 46px;\n\tpadding: 0 14px;\n\tbackground-color: white;\n\tborder-radius: 5px;\n}\n\n.red_outline {\n\toutline: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labeled_input": "LabeledInputs_labeled_input__uurxP",
	"red_outline": "LabeledInputs_red_outline__80Vx5"
};
export default ___CSS_LOADER_EXPORT___;
