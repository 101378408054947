// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LabeledTextarea_labeled_input__3ZnbM {\n  display: flex;\n  flex-direction: column;\n  gap: 9px;\n}\n\n.LabeledTextarea_labeled_input__3ZnbM textarea {\n  border: 1px solid #d7d7d7;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 14px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: #595959;\n  height: 90px;\n  padding: 14px;\n  background-color: white;\n  border-radius: 5px;\n}\n.LabeledTextarea_heading__W79ZM{\n\n  margin-bottom: 9px;\n}\n.LabeledTextarea_heading__W79ZM p {\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n}\n.LabeledTextarea_red_outline__eowe7 {\n  outline: 1px solid red !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI-components/textArea/labeledTextarea/LabeledTextarea.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;AACA;;EAEE,kBAAkB;AACpB;AACA;EACE,0BAA0B;CAC3B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,cAAc;AACf;AACA;EACE,iCAAiC;AACnC","sourcesContent":[".labeled_input {\n  display: flex;\n  flex-direction: column;\n  gap: 9px;\n}\n\n.labeled_input textarea {\n  border: 1px solid #d7d7d7;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 14px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: #595959;\n  height: 90px;\n  padding: 14px;\n  background-color: white;\n  border-radius: 5px;\n}\n.heading{\n\n  margin-bottom: 9px;\n}\n.heading p {\n  text-transform: capitalize;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tcolor: #343434;\n}\n.red_outline {\n  outline: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labeled_input": "LabeledTextarea_labeled_input__3ZnbM",
	"heading": "LabeledTextarea_heading__W79ZM",
	"red_outline": "LabeledTextarea_red_outline__eowe7"
};
export default ___CSS_LOADER_EXPORT___;
