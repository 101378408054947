// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlueBg_blue_bg__Fzs6D {\n    background: #F3F6FB;\n    border-radius: 4px;\n}", "",{"version":3,"sources":["webpack://./src/components/UI-components/blueBg/BlueBg.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".blue_bg {\n    background: #F3F6FB;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue_bg": "BlueBg_blue_bg__Fzs6D"
};
export default ___CSS_LOADER_EXPORT___;
