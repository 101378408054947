import { Typography } from "@mui/material";
import React from "react";
import styles from "./ModeAlerts.module.css";

const LayoutModeAlert = ({ user, handleDismiss }) => {
  return (
    <div
      style={{
        background: user?.mode === "test" ? "#ffe9bd" : "#3AB75D",
      }}
      className={styles.alert}
    >
      <div className={styles.alert_div}>
        <Typography
          style={{
            color: user?.mode === "test" ? "black" : "white",
          }}
        >
          You are currently on {user?.mode} mode{" "}
          <span onClick={handleDismiss}>Dismiss</span>
        </Typography>
      </div>
    </div>
  );
};

export default LayoutModeAlert;
