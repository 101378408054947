import React, { useState } from "react";
import EnterOtp from "../../../../../../UI-components/enter-otp/EnterOtp";
import ModalFrame2 from "../../../../../../UI-components/modalFrame2/ModalFrame2";
import DisablePrompt from "../disablePrompt/DisablePrompt";

const DisableOtp = ({
  otp,
  handleOTPChange,
  loadingConfirm,
  otpError,
  handleConfirm,
  handleCloseModal
}) => {

  const [screen, setScreen] = useState("prompt");

  switch (screen) {
    case "prompt":
      return (
        <ModalFrame2
          btnTxt={"Confirm"}
          child={<DisablePrompt />}
          handleActionTwo={() => {
           handleCloseModal()
          }}
          handleAction={() => {
            setScreen('otp-form')
          }}
        />
      );
    case "otp-form":
      return (
        <EnterOtp
          otpError={otpError}
          otp={otp}
          handleChange={handleOTPChange}
          type={"disable"}
          handleConfirm={handleConfirm}
          loadingConfirm={loadingConfirm}
        />
      );
    default:
      return (
        <ModalFrame2
          btnTxt={"Confirm"}
          child={<DisablePrompt />}
          handleActionTwo={() => {
            handleCloseModal()
          }}
          handleAction={() => {
            setScreen("otp-form");
          }}
        />
      );
  }

  // return (
  //   <EnterOtp
  //   otpError={otpError}
  //     otp={otp}
  //     handleChange={handleOTPChange}
  //     type={"disable"}
  //     handleConfirm={handleConfirm}
  //     loadingConfirm={loadingConfirm}
  //   />
  // );
};

export default DisableOtp;
