import { Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./UploadBox.module.css";
import upload_icon from "../../../assets/images/box_upload_icon.svg";
import { useState } from "react";
import { cutText, extractFileType } from "../../../utils/helpers";
import ValidationMessage from "../validation-message/ValidationMessage";

function UploadBox({ trigger, errors, index, setValue, label, name }) {
  const [file, setFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setValue(name, acceptedFiles);

    trigger(`document_${index}`);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={styles.upload_box}>
      <div className={styles.heading}>
        <Typography>{label}</Typography>
      </div>
      <div
        className={`${styles.box} ${
          errors[`document_${index}`] && styles.red_outline
        }`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <img src={upload_icon} alt="" />
        {file ? (
          <Typography variant="h6">
            {cutText(file?.name, 10)}.{extractFileType(file?.name)}
          </Typography>
        ) : (
          "Select a JPG, PNG or PDF file to upload"
        )}

        <Typography>or drag and drop it here</Typography>
      </div>
      <ValidationMessage message={errors[`document_${index}`]?.message} />
    </div>
  );
}

export default UploadBox;
