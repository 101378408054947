import React, { useEffect} from "react";
import { useForm } from "react-hook-form";
import { Container, Flex } from "../../../../../../UI-components/box";
import {
  FieldWrapper,
  InputStyle,
  Label,
} from "../../../../../../UI-components/input/styles";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";
import Button from "../../../../../../UI-components/button";
import { BrowseIcon } from "../../../../../../../assets";
import { Link } from "react-router-dom";

const Webhook = ({user, handleSubmitWebhookURL, api, submittingWebhook }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("webhook_url", api[`${user?.mode}_webhook_url`]);
  }, [api]);

  const onSubmit = async (data) => {
    handleSubmitWebhookURL(data.webhook_url);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" alignItem="flex-start">
        <h4>Webhooks and callbacks</h4>
        <span>Set up your Webhook URLs back to Treasura</span>
        <InputStyle>
          <Label>Webhook Url</Label>
          <Flex className="url">
            <FieldWrapper width="70%">
              <input
                className={errors.webhook_url ? "red_outline" : null}
                {...register("webhook_url", {
                  required: true,
                  pattern:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                })}
                name="webhook_url"
                placeholder="Https://"
              />
            </FieldWrapper>
            <Container width="35%">
              <Button
                text={"Save URL"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="sm"
                type="submit"
                fullwidth
                borderRadius={"5px"}
                loading={submittingWebhook}
              />
            </Container>
          </Flex>
          <ValidationMessage
              message={errors.webhook_url ? "Input a valid URL" : null}
            />
        </InputStyle>
        <span>
          <Link to={"/settings/API"}>
            Browse docs <BrowseIcon />
          </Link>
        </span>
      </Flex>
    </form>
  );
};

export default Webhook;
