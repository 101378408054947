import { Typography } from "@mui/material";
import React from "react";
import styles from "./BinancePayEnable.module.css";
import Button from "../../../../../../../UI-components/button/index";
import { useNavigate } from "react-router";

function BinancePayEnable({ handleScreen }) {
  const navigate = useNavigate();
  return (
    <div className={styles.binance_pay_enable}>
      <Typography variant="h6">Enable Binance Pay</Typography>
      <Typography>
        You are not enrolled to Binance Pay. Please click the button below to complete your
        Binance Pay merchant enrollment
      </Typography>
      <Button
        text={"Set up merchant account"}
        bgColor={"#1A2CCE"}
        color={"#fff"}
        size="md"
        fullwidth
        type={"click"}
        borderRadius={"5px"}
        onClick={() => {
          navigate("/binance-pay");
        }}
      />
      <Button
        Styles
        text={"Change payment method"}
        color={"#676767"}
        size="md"
        fullwidth
        type="submit"
        onClick={() => {
          handleScreen("");
        }}
      />
    </div>
  );
}

export default BinancePayEnable;
