import React, { useState, useEffect } from "react";
import Button from "../../../../../../UI-components/button";
import styles from "./NewWalletStepTwo.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import StepTwoForm from "./module/StepTwoForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CREATE_WALLET } from "../../../../../../../api/mutations/dashboard/wallets";
import LabeledInputs from "../../../../../../UI-components/new-inputs/inputs/LabeledInputs";
import ValidationMessage from "../../../../../../UI-components/validation-message/ValidationMessage";
import { useNavigate } from "react-router-dom";
import {
  convertToBase64,
} from "../../../../../../../utils/helpers";
import { createWalletSchema2 } from "../../../../../../../schemas/dashboard/wallets";


function NewWalletStepTwo({step1Data, handleCloseModal, openSnackbar }) {
  //const [schema, setSchema] = useState({});
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(createWalletSchema2),
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(CREATE_WALLET, {
    onSuccess: (response) => {
      openSnackbar("success", response?.message);

      navigate(`/wallets`);

      handleCloseModal();
      queryClient.invalidateQueries(["wallets"]);
      queryClient.invalidateQueries(["wallet-summary"]);
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });
  //const [addition, setAddition] = useState(true);

  const [formCount, setFormCount] = useState(1);
 



  useEffect(() => {
    setValue("source_0", "upload");
  }, []);

 

  const onSubmit = async (data) => {

    const documentArray = Array.from({ length: formCount }).map((e, index) => {
      if (data[`source_${index}`] === "link") {
        return {
          document_type: data[`verification_id_${index}`],
          document_no: data[`id_number_${index}`],
          document_file: data[`document_link_${index}`],
          document_file_type: "LINK",
        };
      }
      // if (data[`source_${index}`] === "id_no") {
      //   return {
      //     document_type: data[`verification_id_${index}`],
      //     document_no: data[`id_number_${index}`],
      //     document_file: data[`id_number_${index}`],
      //     document_file_type: data[`source_${index}`],
      //   };
      // }
      if (data[`source_${index}`] === "upload") {
        return {
          document_type: data[`verification_id_${index}`],
          document_no: data[`id_number_${index}`],
          document_file: data[`document_${index}`][0],
          document_file_type: "FILE",
        };
      }
    });

    const newDocumentArray = await Promise.all(
      documentArray.map(async (docu) => {
        if (docu.document_file_type === "FILE") {
          const documentFile = await convertToBase64(docu.document_file);

          return {
            ...docu,
            document_file: documentFile,
          };
        } else {
          return docu;
        }
      })
    );

    const _data = {
      compliance: {
        phone: step1Data.phone,
        firstname: data.firstname,
        lastname: data.lastname,
        bvn: data.bvn,
        email: step1Data.email,
        documents: newDocumentArray,
        full_name: step1Data.full_name,
      },
      wallet_reference: step1Data.merchant_reference,  
    };



    mutate(_data);
  };

  return (
    <div>
      <div className={styles.field}>
        <LabeledInputs
          redOutline={errors.firstname ? true : false}
          label="Firstname"
          name={`firstname`}
          register={register}
          placeholder=""
        />
        <ValidationMessage message={errors.firstname?.message} />
      </div>
      <div className={styles.field}>
        <LabeledInputs
          redOutline={errors.lastname ? true : false}
          label="Lastname"
          name={`lastname`}
          register={register}
          placeholder=""
        />
        <ValidationMessage message={errors.lastname?.message} />
      </div>
      <div className={styles.field}>
        <LabeledInputs
          redOutline={errors.bvn ? true : false}
          label="BVN"
          name={`bvn`}
          register={register}
          placeholder=""
          type={'number'}
        />
        <ValidationMessage message={errors.bvn?.message} />
      </div>
      {Array.from({ length: formCount }).map((e, index) => {
        return (
          <StepTwoForm
            trigger={trigger}
            // setAddition={setAddition}
            key={index}
            formCount={formCount}
            setFormCount={setFormCount}
            index={index}
            register={register}
            setValue={setValue}
            errors={errors}
          />
        );
      })}

      <div className={styles.btns}>
        <div className={styles.btn}>
          <Button
            Styles
            text={"Cancel"}
            bgColor={"white"}
            color={"#404040"}
            size="sm"
            type="submit"
            borderRadius={"5px"}
            onClick={handleCloseModal}
          />
        </div>

        <div className={styles.btn}>
          <Button
            // disabled={Object.keys(errors).length > 0}
            Styles
            text={"Submit"}
            loading={isLoading}
            bgColor={"#1A2CCE"}
            color={"white"}
            size="sm"
            type="submit"
            border={"0.4px solid #1A2CCE"}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"5px"}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
}

export default NewWalletStepTwo;



// const [fields, setFields] = useState([
//   "verification_id_",
//   "source_",
//   "id_number_",
//   "document_link_",
//   "document_",
//   "firstname",
//   "lastname",
//   "bvn",
// ]);

// const createSchema = (keys) => {
//   let schema = {};

//   keys.forEach((key) => {
//     schema[key.name] = key.validation;
//   });

//   return Yup.object().shape(schema);
// };



// useEffect(() => {
//   if (addition) {
//     setValue(`source_${formCount - 1}`, "upload");
//   }

//   const schemaKeys = [];

//   Array.from({ length: formCount }).map((e, index) => {
//     return fields.map((field) => {
//       if (field === "verification_id_") {
//         const fieldToPush = {
//           name: `${field}${index}`,
//           validation: Yup.string().required("Verification ID is required"),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//       if (field === "source_") {
//         const fieldToPush = {
//           name: `${field}${index}`,
//           validation: Yup.string().required("Source is required"),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//       if (field === "id_number_") {
//         const fieldToPush = {
//           name: `${field}${index}`,
//           validation: Yup.string().required("ID number is required"),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//       if (field === "document_link_") {
//         const fieldToPush = {
//           name: `${field}${index}`,
//           validation: Yup.string().when(`source_${index}`, {
//             is: "link",
//             then: Yup.string()
//               .required("'Link is required")
//               .url("URL is not valid"),
//             otherwise: Yup.string(),
//           }),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//       if (field === "document_") {
//         const fieldToPush = {
//           name: `${field}${index}`,
//           validation: Yup.mixed().when(`source_${index}`, {
//             is: "upload",
//             then: Yup.mixed()
//               .test({
//                 name: "required",
//                 message: "Document is required",
//                 test: (value) => {
//                   return value?.length > 0;
//                 },
//               })
//               .test({
//                 name: "fileSize",
//                 message: "file uploaded is larger then 500kb",
//                 test: (value) => {
//                   if (value) {
//                     return value[0]?.size < 500000;
//                   }
//                 },
//               })
//               .test({
//                 name: "fileType",
//                 message:
//                   "file uploaded is not png, jpeg, jpg, pdf, doc, or docx.",
//                 test: (value) => {
//                   if (value) {
//                     return fileTypes.includes(extractFileType(value[0].name));
//                   }
//                 },
//               }),
//             otherwise: Yup.mixed(),
//           }),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//       if (field === "firstname") {
//         const fieldToPush = {
//           name: "firstname",
//           validation: Yup.string().required("Firstname is required"),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//       if (field === "lastname") {
//         const fieldToPush = {
//           name: "lastname",
//           validation: Yup.string().required("Lastname is required"),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//       if (field === "bvn") {
//         const fieldToPush = {
//           name: "bvn",
//           validation: Yup.string()
//             .required("BVN is required")
//             .min(11, "BVN must be 11 characters")
//             .max(11, "BVN must be 11 characters"),
//         };
//         schemaKeys.push(fieldToPush);
//       }
//     });
//   });

//   setSchema(createSchema(schemaKeys));
// }, [formCount]);