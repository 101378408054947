// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisablePrompt_heading__kaCa7 h6 {\n    color: #000A62;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 22px;\n    letter-spacing: 0em;\n    text-align: left;\n}\n\n.DisablePrompt_heading__kaCa7 p {\n    color: #000A62;\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 23px;\n    letter-spacing: 0em;\n    text-align: left;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/security/modals/disablePrompt/DisablePrompt.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".heading h6 {\n    color: #000A62;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 22px;\n    letter-spacing: 0em;\n    text-align: left;\n}\n\n.heading p {\n    color: #000A62;\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 23px;\n    letter-spacing: 0em;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "DisablePrompt_heading__kaCa7"
};
export default ___CSS_LOADER_EXPORT___;
