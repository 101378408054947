// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Compliance_compliance__lD77o {\n    padding: 46px;\n    display: flex;\n    flex-direction: column;\n    margin-top: 10px;\n}\n\n.Compliance_compliance_heading__6sr1Z {\n    margin-bottom: 47px;\n}\n\n.Compliance_accordions__wRrkU {\n    display: flex;\n    flex-direction: column;\n}\n\n.Compliance_accordion_item__LORcW {\n    margin-bottom: 11px;\n}\n\n@media (max-width: 699px) {\n    .Compliance_compliance__lD77o{\n        padding: 30px 20px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/pages/dashboard/settings/compliance/Compliance.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".compliance {\n    padding: 46px;\n    display: flex;\n    flex-direction: column;\n    margin-top: 10px;\n}\n\n.compliance_heading {\n    margin-bottom: 47px;\n}\n\n.accordions {\n    display: flex;\n    flex-direction: column;\n}\n\n.accordion_item {\n    margin-bottom: 11px;\n}\n\n@media (max-width: 699px) {\n    .compliance{\n        padding: 30px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compliance": "Compliance_compliance__lD77o",
	"compliance_heading": "Compliance_compliance_heading__6sr1Z",
	"accordions": "Compliance_accordions__wRrkU",
	"accordion_item": "Compliance_accordion_item__LORcW"
};
export default ___CSS_LOADER_EXPORT___;
