import { LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CopyButton from "../../../../../../UI-components/copy-button/CopyButton";
import styles from "./Authenticatior.module.css";
import Button from "../../../../../../UI-components/button";

import BlueBackground from "../../../../../../UI-components/blueBg/BlueBg";

const Authenticator = ({
  loading2FA,
  handleLoadData,
  qrCode,
  handleAction,
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
  
    handleLoadData();
  }, []);

 

 
  return (
    <div className={styles.authenticator}>
      {loading2FA ? (
       <LinearProgress/>
      ) : (
        <>
       
          <div className={styles.heading}>
            <Typography variant="h6">
             
              Enable Two Factor Authentication
            </Typography>
            <Typography>
              Scan this QR code with the authentication app on your trusted
              device.
            </Typography>
          </div>
          <div className={styles.qr}>
            <BlueBackground
              content={
                <div style={{ padding: 20 }}>
                  <img src={qrCode?.qr} alt="" />
                </div>
              }
            />
          </div>
          <div className={styles.qr_text}>
            <Typography variant="h6">
              Or copy this code into the app:
            </Typography>
            <Typography>{qrCode.secret}</Typography>
          </div>
          <div className={styles.qr_btn}>
            <CopyButton copied={copied} setCopied={setCopied} copy={qrCode.secret} />
          </div>
          <div className={styles.authenticator_btn}>
            <Button
              onClick={() => {
                handleAction();
              }}
              text={"I have done this"}
              bgColor={"#1A2CCE"}
              color={"#fff"}
              size="md"
              fullwidth
              type={"click"}
              borderRadius={"5px"}
             
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Authenticator;
