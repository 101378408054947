import axios from "axios";

export const UPDATE_BUSINESS_COMPLIANCE = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}settings/compliance/business`, data)
    .then((res) => {
      return res.data;
    });
};

export const UPDATE_DOCUMENT_COMPLIANCE = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}settings/compliance/document`, data)
    .then((res) => {
      return res.data;
    });
};

export const UPDATE_BANK_COMPLIANCE = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}settings/compliance/bank`, data)
    .then((res) => {
      return res.data;
    });
};