import styled from "styled-components";

export const Styles = styled.div`
  height: 100vh;
  position: relative;
  overflow-y: auto;
  width: calc(100% - 240px);
  margin: 0 0 0 auto;
  background-color: ${({ bgColor }) => bgColor};
  > section {
    padding: 80px 0 40px 0;
    max-width: 1280px;
    margin: 0 auto;
    height: 100%;
  
  }

  @media (max-width: 700px) {
    width: 100%;
    > section {
      /* padding: 20px 4%; */
      margin: 40px 0 40px;
    }
  }
`;
