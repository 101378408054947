import { Typography } from "@mui/material";
import React from "react";
import styles from './LabeledInputs.module.css'


const LabeledInputs = ({
  
  type,
  register,
  redOutline,
  name,
  placeholder,
  label,
  pattern,
  required,
  handleBlur, 
  handleChange,
  value
}) => {
  return (
    <label className={styles.labeled_input} htmlFor="">
      <Typography>{label}</Typography>
      <input
        className={redOutline ? styles.red_outline : null}
        onChange={handleChange}
        type={type ? type : "text"}
        name={name}
      value={value}
        placeholder={placeholder}
    onBlue={handleBlur}
      />
    </label>
  );
};

export default LabeledInputs;
