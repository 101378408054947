import styled from 'styled-components';

export const Styles = styled.aside`
	position: fixed;
	top: 0;
	left: 0;
	width: 240px;
	bottom: 0;
	background-color: #000531;
	// background-color: #f3f6fb;
	border-right: 0.1px solid #b1b1b165;
	z-index: 5;
	.logo_box {
		padding: 30px 33px;
		// margin-left: 30px;
		// padding-bottom: 20px;
		cursor: pointer;
		width: 100%;
		>img{
			height: 25px;
			width: 133px;
			
		}
	}
	.drawer{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.status_div {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: 50px;

			.status {
				display: flex;
				align-items: center;
				width: 191px;
				height: 57px;
				justify-content: space-between;
				padding: 0 20px;
				>p{
					color: #c6ddff;
					margin-left: 10px;
					margin-right: 2px;
					text-transform: capitalize;
					font-size: 14px
				}
				.circular_icon {
					height: 20px !important;
					width: 20px !important;
				}
				.info_icon {
					cursor: pointer;	
				}
				
			}
		}
	}
	
	@media (max-width: 700px) {
		position: fixed;
		width: 100%;
		padding: 20px 5% 0;
		z-index: 99;
		right: 0;
		left: 0;
		bottom: 0;
		top: unset;
		border-top: 0.5px solid #b1b1b1;
		border-right: none;
		.logo_box {
			display: none;
		}
		.drawer {
		
			margin: 0;
			ul {
				flex-direction: row;
				display: flex;
				justify-content: space-between;
				overflow-x: auto;
			}
		}
	}
`;

export const ListStyles = styled.li`
	margin: 15px 0;


	width: 100%;
	cursor: pointer;
	font-size: 14px;
	&:hover {
		.drawerText {
			/* background-color: #c6ddff; */
			color: #c6ddff;
		}
		.icon_container {
			background-color: #fcf1e8;
			color: #ef6606;
		}
	}
	a {
		
		// font-weight: normal;
		color: #5b5551;
		text-align: start;
		display: flex;
		flex-direction: column;
		background-color: transparent;

		.link_container {
			padding-left: 30px;
			border-left: ${({ active }) => (active ? '3px solid #C6DDFF' : '3px solid transparent')};
		}
		.icon_container {
			margin-right: 20px;
			// color: ${({ active }) => (active ? '#CE701A' : '#fff')};
			color: ${({ active }) => (active ? '#ef6606' : '#fff')};
			// background-color: ${({ active }) => (active ? '#ffd3aa' : '#8D8EA2')};
			background-color: ${({ active }) => (active ? '#fcf1e8' : '#8D8EA2')};
			border-radius: 50%;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		// color: #ffd3aa;

		.drawerText {
			// color: ${({ active }) => (active ? '#ffd3aa' : '#DADADA')};
			color: ${({ active }) => (active ? '#c6ddff' : '#DADADA')};
			// color: ${({ active }) => (active ? '#1A2CCE' : '#000000')};
			font-weight: ${({ active }) => (active ? '900' : '400')};
			font-size: ${({ active }) => (active ? '16px' : '16px')};
			margin-right: 10px;
		}
		.link_arrow {


			>img {
				height: 7px;
			}
		}
		.link_arrow_active {
			transform: rotate(180deg);
			>img {
				height: 7px;
			
			}
		}
		
		
		.settings_content_disabled {
			display: none;
		}
		.settings_content_active {
			display: flex;
		}
		.settings_content {
			padding: 10px 0;
			width: 100%;
			padding-left: 88px;
			flex-direction: column;
			>a {
				margin-bottom: 15px;
				font-size: 16px;
				color: #DADADA;
				text-transform: capitalize;
				
			}
		}
		.sublink_active {
			color: #c6ddff !important;
		}
	}

	@media (max-width: 700px) {
		.link_container {
			display: flex;
			flex-direction: column;
		}
		padding: 0;
		margin: 0;
		width: unset;
		/* border: 1px solid red; */
		border-bottom: ${({ active }) => active && '2px solid #CE701A'};
		border-left: none;
		> a {
			margin-bottom: 10px;
			margin-left: 2px;
			white-space: nowrap;
			.icon_container {
				margin: 0 !important;
				padding: 7px;
				svg {
					height: 12px;
					width: 12px;
				}
			}
		}
		.drawerText {
			font-size: 9px !important;
			margin-top: 5px;
		}
	}
`;
