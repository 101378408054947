import styled from 'styled-components';

export const HeaderStyles = styled.div`
	height: 70px;
	padding: 20px 2%;
	width: calc(100% - 240px);
	margin: 0 0 0 auto;
	position: fixed;
	background: #ffff;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
	bottom: unset;
	// background-color: #f3f6fb;
	border-bottom: 0.1px solid #b1b1b165;
	z-index: 3;
	> div {
		margin: 0 auto;
		max-width: 1280px;
	}
	h2 {
		color: #1a1f36;
		font-size: 18px;
		text-transform: capitalize;
	}

	.icon_box {
		
		display: flex;
    	align-items: center;
		.logout_icon_div{
		
			cursor: pointer;
			margin-left: 10px;
		
		}
		.icon_div {
		
			cursor: pointer;

			>svg{
				color: 'red'
			}
		  }
		}
		.whats_new{
			margin-right: 16px;
			display: flex;
			align-items: center;

			>p{
				color: #1a2cce;
				font-weight: 700;
				cursor: pointer;
			}
			>span{
				
			}
		}
		.profile_dropdown {
		  position: absolute;
		  right: 0;
		  height: 100px;
		  bottom: -80px;
		  border: 0.3px solid #ababab49;
		  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
		  border-radius: 3px;
		  padding: 16px 16px;
		  z-index: 2;
		  background: white;
		  width: 178px;
	  >a{
		text-decoration: underline;
		font-size: 14px;
	  text-transform: capitalize
	  }
	}
	@media (max-width: 700px) {
		padding: 20px 5%;
		width: 100%;
		h2 {
			font-size: 20px;
		}
	}
`;
