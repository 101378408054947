import React, { useEffect, useContext, useState } from "react";
import Layout from "../../../UI-components/layouts";
import OverdraftHeading from "./compoments/header/OverdraftHeading";
import { Styles } from "./styles";
import OverdraftListBox from "./compoments/listbox/OverdraftListBox";
import { handleGetRequestCalls } from "../../../../axiosConfig/apiCalls";
import Loader from "../../../UI-components/loader/Loader";
import EmptyState from "../../../UI-components/null state/EmptyState";
import { DataCentral } from "../../../../context/AppContext";

import useOpenSnackbar from "../../../../customHooks/useOpenSnackbar";
import Pagination from "../../../UI-components/pagination/Pagination";
import SwapstaSnackbar from "../../../UI-components/AppSnackbars/SwapstaSnackbar";
import useGetCurrencyPairs from "../../../../customHooks/useGetCurrencyPairs";
import { useQuery } from "@tanstack/react-query";
import { GET_OVERDRAFT } from "../../../../api/queries/dashboard/overdraft";

const Overdraft = () => {
  
  const { openSnackbar, message, severity, duration, setOpenToast, openToast } =
    useOpenSnackbar();

  const [val] = useState("");

  const { currencyPairs, isLoadingPairs } = useGetCurrencyPairs();

  const {
    data: overdraft,
    isLoading,
    refetch,
  } = useQuery(["overdraft"], GET_OVERDRAFT, {
    refetchOnWindowFocus: false,
  });

  const handlePageChange = async (page, type) => {
    // setLoadingOverdraft(true);
    // const response = await handleGetRequestCalls(
    //   `liquidity?order=DESC&page=${page}&take=10`
    // );
    // if (response.status < 300 && response.status >= 200) {
    //   const { data } = response.data;
    //   setOverdraft(data)
    //   setLoadingOverdraft(false);
    // } else if (response.response) {
    //   setLoadingOverdraft(false);
    // } else if (response.request) {
    //   setLoadingOverdraft(false);
    // } else {
    //   setLoadingOverdraft(false);
    // }
  };

  return (
    <Layout title={"Liquidity"} bgColor={"#ffffff"}>
      {isLoading ? (
        <Loader />
      ) : (
        <Styles>
          <OverdraftHeading
            currencyPairs={currencyPairs}
            val={val}
            openSnackbar={openSnackbar}
          />
          {overdraft?.data?.data?.length === 0 ? (
            //when we introduce filter for overdraft, remember to add from key
            <EmptyState
              primaryText={
                overdraft?.from === "filter"
                  ? "Liquidity transactions not found"
                  : "No Liquidity transactions here yet"
              }
              secondaryText={
                overdraft?.from === "filter"
                  ? "No Liquidity transactions with filtered parameters"
                  : "it looks like you dont have any Liquidity transactions yet"
              }
              handleRefresh={() => {
                refetch();
              }}
            />
          ) : (
            <>
              <OverdraftListBox overdraft={overdraft?.data?.data} />

              <Pagination
                type={"overdraft"}
                handlePageChange={handlePageChange}
                meta={overdraft?.data?.meta}
              />
            </>
          )}
          <SwapstaSnackbar
            open={openToast}
            message={message}
            severity={severity}
            setOpen={setOpenToast}
            duration={duration}
          />
        </Styles>
      )}
    </Layout>
  );
};

export default Overdraft;
