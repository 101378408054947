import React from 'react'
import Asset from '../asset/Asset';


const CryptosTab = ({cryptos, handleClose, handleSetAssets, type }) => {

    return ( 
        <div>
        {cryptos?.map((crypto) => {
          return <Asset  key={crypto.code}
          handleClose={handleClose}
          handleSetAssets={handleSetAssets}
          asset={crypto}
          type={type}/>;
        })}
      </div>
     );
}
 
export default CryptosTab;