import { Typography, Grid } from "@mui/material";
import React from "react";
import styles from "./HeadingTwo.module.css";
const HeadingTwo = ({ amount, title, currency }) => {
  return (
    <div className={styles.heading}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} lg={6}>
          <div className={styles.heading_text_left}>
            <Typography>{title}</Typography>
          </div>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          {amount && (
            <div className={styles.heading_text_right}>
              <Typography variant="h6">AMOUNT</Typography>
              <Typography>
                {currency} {Number(amount).toLocaleString()}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default HeadingTwo;
