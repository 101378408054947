import { Typography } from "@mui/material";
import React from "react";
import Button from "../../../../../../UI-components/button/index";
import styles from './WhatIsBinancePay.module.css'

function WhatIsBinancePay({ handleClose }) {
  return (
    <div className={styles.binance_pay}>
      <Typography variant="h6">What is Binance Pay?</Typography>
      <Typography>
        Binance Pay is a contactless, borderless, secure cryptocurrency payment
        system designed by Binance. The platform lets your users send, spend,
        and pay with crypto anywhere in the world
      </Typography>
      <Button
        text={"I understand"}
        bgColor={"#1A2CCE"}
        color={"#fff"}
        size="md"
        fullwidth
        type={"click"}
        borderRadius={"5px"}
        onClick={() => {
          handleClose();
        }}
      />
    </div>
  );
}

export default WhatIsBinancePay;
