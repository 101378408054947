import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  CREATE_WALLET,
  CREATE_WALLET_ACCOUNTS,
} from "../../../../../../../api/mutations/dashboard/wallets";
import useGetDefaultCurrencies from "../../../../../../../customHooks/useGetDefaultCurrencies";
import { createWalletAccount } from "../../../../../../../schemas/dashboard/wallets";
import Button from "../../../../../../UI-components/button";
import NewAccountCrypto from "./components/NewAccountCrypto";
import NewAccountFiat from "./components/NewAccountFiat";
import styles from "./NewAccount.module.css";

function NewWalletAccount({ wallet_info, handleCloseModal, openSnackbar }) {
  const [activeTab, setActiveTab] = useState("crypto");
  const { currencies } = useGetDefaultCurrencies();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    register,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(createWalletAccount),
  });

  const { mutate, isLoading } = useMutation(CREATE_WALLET_ACCOUNTS, {
    onSuccess: (response) => {
      openSnackbar("success", response?.message);
      handleCloseModal();
      queryClient.invalidateQueries(["wallets-accounts"]);
      //queryClient.invalidateQueries(["wallet-summary"]);
    },
    onError: (error) => {
      openSnackbar("error", error?.response?.data?.message);
    },
  });

  useEffect(() => {
    reset();
    setValue("type", activeTab);
  }, [activeTab]);

  const onSubmit = (_data) => {
    const data = {
      data: _data,
      wallet_reference: wallet_info.wallet_reference,
    };
    mutate(data);
  };

  return (
    <div className={styles.new_wallet}>
      <div className={styles.heading}>
        <Typography variant="h6">Create a new virtual account</Typography>
        <Typography>Create a new crypto or fiat virtual account</Typography>
      </div>
      <div className={styles.new_wallet_tab}>
        <div
          onClick={() => {
            setActiveTab("crypto");
          }}
          className={activeTab === "crypto" && styles.active_tab}
        >
          <Typography>Crypto</Typography>
        </div>
        <div
          onClick={() => {
            setActiveTab("fiat");
          }}
          className={activeTab === "fiat" && styles.active_tab}
        >
          <Typography>Fiat</Typography>
        </div>
      </div>
      {activeTab === "crypto" && (
        <NewAccountCrypto
          register={register}
          errors={errors}
          currencies={currencies}
          openSnackbar={openSnackbar}
          activeTab={activeTab}
          handleCloseModal={handleCloseModal}
        />
      )}
      {activeTab === "fiat" && (
        <NewAccountFiat
          watch={watch}
          register={register}
          errors={errors}
          currencies={currencies}
          openSnackbar={openSnackbar}
          activeTab={activeTab}
          handleCloseModal={handleCloseModal}
        />
      )}
      <div className={styles.btns}>
        <div className={styles.btn}>
          <Button
            Styles
            text={"Cancel"}
            bgColor={"white"}
            color={"#404040"}
            size="sm"
            type="submit"
            borderRadius={"5px"}
            onClick={handleCloseModal}
          />
        </div>

        <div className={styles.btn}>
          <Button
            // disabled={Object.keys(errors).length > 0}
            Styles
            text={"Submit"}
            loading={isLoading}
            bgColor={"#1A2CCE"}
            color={"white"}
            size="sm"
            type="submit"
            border={"0.4px solid #1A2CCE"}
            boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
            borderRadius={"5px"}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
}

export default NewWalletAccount;
