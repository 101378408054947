import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import usePermission from "../../../../../customHooks/usePermissions";
import Layout from "../../../../UI-components/layouts";
import SettlementTab from "../../../../UI-components/settlementTabs/SettlementTabs";
import { Styles } from "./styles";
import { DataCentral } from "../../../../../context/AppContext";
import Loader from "../../../../UI-components/loader/Loader";

const settingsTabs = [
  {
    label: "profile",
  },
  {
    label: "compliance",
  },
  {
    label: "team",
  },
  {
    label: "security",
  },
  {
    label: "API",
  },

  {
    label: "preferences",
  },
];

function SettingsLayout({ activeSecurityTab, child, tab }) {
  const navigate = useNavigate();
  const {user, loadingUser} = useContext(DataCentral)
  const { checkPermission } = usePermission();
  const handleSetActiveTab = (activeTab) => {
    navigate(`/settings/${activeTab}`);
  };

  return (
    <Layout title={activeSecurityTab || "Settings"}>
      {
        loadingUser ? <Loader/> : 
        <Styles>
        <SettlementTab
          activeTab={tab}
          tabs={settingsTabs.filter((tab) => {
            return (
              checkPermission(`settings.${tab.label.toLowerCase()}`) && tab
            );
          })}
          handleSetActiveTab={handleSetActiveTab}
        />
        {child}
      </Styles>
      }
  
    </Layout>
  );
}

export default SettingsLayout;
