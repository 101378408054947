// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_table_div__UEZsS{\n\theight: 100%;\n\t\n   }\n   .Table_table__OA0l5 {\n\t width: 100%;\n\t min-width: 1100px;\n\t\n\t border-collapse: collapse;\n   }\n  \n   .Table_table__OA0l5 thead tr{\n\t background: #f3f6fb;\n\n   }\n   \n   .Table_table__OA0l5 thead tr th {\n\t padding: 13px 15px;\n\t text-transform: uppercase;\n\t color: #565656;\n\t font-size: 14px;\n\t font-style: normal;\n\t font-weight: 400;\n\t line-height: 14px;\n\t letter-spacing: 0.05em;\n\t text-align: left;\n\t font-size: 14px;\n\t font-style: normal;\n\t font-weight: 400;\n\t line-height: 14px;\n\t letter-spacing: 0.05em;\n\t text-align: left;\n\t\n   }\n   ", "",{"version":3,"sources":["webpack://./src/components/UI-components/new -tables/Table.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;;GAEV;GACA;EACD,WAAW;EACX,iBAAiB;;EAEjB,yBAAyB;GACxB;;GAEA;EACD,mBAAmB;;GAElB;;GAEA;EACD,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;;GAEf","sourcesContent":[".table_div{\n\theight: 100%;\n\t\n   }\n   .table {\n\t width: 100%;\n\t min-width: 1100px;\n\t\n\t border-collapse: collapse;\n   }\n  \n   .table thead tr{\n\t background: #f3f6fb;\n\n   }\n   \n   .table thead tr th {\n\t padding: 13px 15px;\n\t text-transform: uppercase;\n\t color: #565656;\n\t font-size: 14px;\n\t font-style: normal;\n\t font-weight: 400;\n\t line-height: 14px;\n\t letter-spacing: 0.05em;\n\t text-align: left;\n\t font-size: 14px;\n\t font-style: normal;\n\t font-weight: 400;\n\t line-height: 14px;\n\t letter-spacing: 0.05em;\n\t text-align: left;\n\t\n   }\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_div": "Table_table_div__UEZsS",
	"table": "Table_table__OA0l5"
};
export default ___CSS_LOADER_EXPORT___;
