// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_loader__hYI-x{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: calc(100vh - 70px);\n    justify-content: center;\n}\n\n.Loader_loader__hYI-x p{\n    font-size: 18px;\n    font-weight: 800;\n    color: rgb(63, 58, 58);\n}", "",{"version":3,"sources":["webpack://./src/components/UI-components/loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,0BAA0B;IAC1B,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".loader{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: calc(100vh - 70px);\n    justify-content: center;\n}\n\n.loader p{\n    font-size: 18px;\n    font-weight: 800;\n    color: rgb(63, 58, 58);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "Loader_loader__hYI-x"
};
export default ___CSS_LOADER_EXPORT___;
