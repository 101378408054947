import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/OverdraftListBox.module.css";
import useModal from "../../../../../../customHooks/useModal";
import AppBackdrop from "../../../../../UI-components/appBackdrop/AppBackdrop";
import DownloadReport from "../../../../../UI-components/downloadReport/DownloadReport";
import Button from "../../../../../UI-components/button";
import ModalFrame from "../../../../../UI-components/modalFrame/ModalFrame";
import useGetDefaultCurrencies from '../../../../../../customHooks/useGetDefaultCurrencies'


export default function ListBox({ overdraft }) {
  const {
    toggle,

    handleOpenModal,
    handleCloseModal,
    opened,
  } = useModal();
  const {
    isLoading: isLoadingCurrencies,
    currencies,
  
  } = useGetDefaultCurrencies();
  const [overdraftID, setOverdraftID] = useState("");

  return (
    <>
      {overdraft?.map((item, index) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.overdraft_list_box_container}
          key={item.id}
        >
          <div className={styles.overdraft_list_box_header}>
            <div className={styles.icon_box_container}>
              <div className={styles.icon_box}>
                <div className={`${styles.icon_flag} ${styles.space_reduced}`}>
                  {currencies?.result?.map((currency) => {
                    if (currency.code === item.to_currency) {
                      return (
                        <img
                          key={currency.id}
                          src={currency.icon}
                          alt={`flag`}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div
                  className={`${styles.icon_flag} ${
                    item.flag2 === "kesflag.svg" && styles.icon_change_size
                  } ${item.flag2 === "usflag.svg" && styles.icon_change_size} `}
                >
                  {currencies?.result?.map((currency) => {
                    if (currency.code === item.from_currency) {
                      return (
                        <img
                          key={currency.id}
                          src={currency.icon}
                          alt={`flag`}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <Typography>{item.currency_pair}</Typography>
            </div>
            <div className={styles.link_container}>
              <Link to={`/liquidity/${item.id}`} className={styles.link}>
                <Button
                  Styles
                  className={`${styles.link_spacing} ${styles.link_remove_weight}`}
                  text={"View transaction"}
                  size="md"
                  type="button"
                />
              </Link>
              <Button
                Styles
                className={`${styles.link_spacing} ${styles.link_remove_weight}`}
                text={"Export data"}
                size="md"
                type="button"
                onClick={() => {
                  setOverdraftID(item.id)
                  handleOpenModal("download-report");
                }}
              />
            </div>
          </div>

          <hr className={styles.line} />

          <div className={styles.details_container}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <div className={`${styles.item}`}>
                  <p className={styles.item_title}>CREDIT CAP</p>
                  <h4 className={styles.item_text}>
                    {item.to_currency}{" "}
                    {item.credit_cap.toLocaleString(
                      "en-US",
                      currencies?.result?.find((currency) => {
                        if (currency.code === item.to_currency) {
                          return currency.name;
                        } else {
                          return null;
                        }
                      })?.type === "CRYPTO"
                        ? { minimumFractionDigits: 8 }
                        : { minimumFractionDigits: 2 }
                    )}
                  </h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <div className={`${styles.item}`}>
                  <p className={styles.item_title}>AVAILABLE</p>
                  <h4 className={styles.item_text}>
                    {item.to_currency} {item.balance?.toLocaleString( "en-US",
                      currencies?.result?.find((currency) => {
                        if (currency.code === item.to_currency) {
                          return currency.name;
                        } else {
                          return null;
                        }
                      })?.type === "CRYPTO"
                        ? { minimumFractionDigits: 8 }
                        : { minimumFractionDigits: 2 })}
                  </h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                {" "}
                <div className={`${styles.item}`}>
                  <p className={styles.item_title}>PAYOUTS MADE</p>
                  <h4 className={styles.item_text}>
                    {item.to_currency} {item.amount_used?.toLocaleString(
                       "en-US",
                       currencies?.result?.find((currency) => {
                         if (currency.code === item.to_currency) {
                           return currency.name;
                         } else {
                           return null;
                         }
                       })?.type === "CRYPTO"
                         ? { minimumFractionDigits: 8 }
                         : { minimumFractionDigits: 2 }
                    )}
                  </h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                {" "}
                <div className={`${styles.item}`}>
                  <p className={styles.item_title}>STATUS</p>
                  {item.status === "active" && (
                    <h4 className={`${styles.item_text} ${styles.active}`}>
                      {item.status}
                    </h4>
                  )}
                  {item.status === "stopped" && (
                    <h4 className={`${styles.item_text} ${styles.stopped}`}>
                      {item.status}
                    </h4>
                  )}
                  {item.status === "pending" && (
                    <h4 className={`${styles.item_text} ${styles.paused}`}>
                      {item.status}
                    </h4>
                  )}
                   {item.status === "paused" && (
                    <h4 className={`${styles.item_text} ${styles.paused}`}>
                      {item.status}
                    </h4>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      ))}
      {opened.some((item) => item === "download-report") && (
        <AppBackdrop
          openModal={opened.some((item) => item === "download-report")}
          handleCloseModal={() => {
            handleCloseModal("download-report");
          }}
          child={
            <ModalFrame
              handleAction={() => {
                toggle("download-report");
              }}
              child={<DownloadReport type='liquidity' exportReportData={{id: overdraftID}} />}
            />
          }
        />
      )}

      {/* <AppBackdrop
        handleCloseModal={() => {
          handleCloseModal("deposit");
        }}
        // openModal={isShowingDeposit}
        openModal={opened.some((item) => item === "deposit")}
        child={
          <ModalFrame
            style={{ ...modalFrameStyle, height: "auto", width: 450 }}
            handleAction={() => {
              toggle("deposit");
            }}
            // style={modalFrameStyle}
            child={<MakeSettlement id={overdraftId} />}
          />
        }
      /> */}
    </>
  );
}
