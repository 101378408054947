import React, { useState } from "react";
import styles from "./BinancePayResponse.module.css";
import green_mark from "../../../../../../assets/images/successful.svg";
import { CircularProgress, Typography } from "@mui/material";
import failed_mark from "../../../../../../assets/images/failed_mark.svg";
import Button from "../../../../../UI-components/button/index";
import { handlePostRequestCalls } from "../../../../../../axiosConfig/apiCalls";
import { Navigate, useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { VERIFY_PAYMENT } from "../../../../../../api/mutations/dashboard/balance";

function BinancePayResponse({
  loadingTransaction,
  transaction,
  openSnackbar,
  handleCloseModal,
  queryStringData,
}) {
  const navigate = useNavigate();

  const { mutate: mutateBinancePay, isLoading: isLoadingBinancePayPayment } =
    useMutation(VERIFY_PAYMENT, {
      onSuccess: (response) => {
        openSnackbar("success", response?.message);
        window.location.replace(response?.data?.universalUrl);
      },
      onError: (error) => {
        openSnackbar("error", error?.response?.data?.message);
      },
    });

  const handleTryAgain = async () => {
    //setLoadingTryAgain(true);

    const _extraData = JSON.parse(transaction?.extra_data);

    mutateBinancePay({
      amount: transaction.amount,
      currency: transaction.currency,
      deposit_method: transaction.payment_slug,
      requirement: {
        failure_url: _extraData?.failure_url,
        product_description: _extraData?.product_description,
        product_name: _extraData?.product_name,
        success_url: _extraData?.success_url,
      },
    });
    // const response = await handlePostRequestCalls("transaction/deposit", {
    //   amount: transaction.amount,
    //   currency: transaction.currency,
    //   deposit_method: transaction.payment_slug,
    //   requirement: {
    //     failure_url: _extraData?.failure_url,
    //     product_description: _extraData?.product_description,
    //     product_name: _extraData?.product_name,
    //     success_url: _extraData?.success_url,
    //   },
    // });

    // if (response.status < 300 && response.status >= 200) {
    //   openSnackbar("success", response?.data?.message);
    //   window.location.replace(response?.data?.data?.universalUrl);
    // } else if (response.response) {
    //   setLoadingTryAgain(false);
    //   openSnackbar("error", response?.response?.data?.message);
    // } else if (response.request) {
    //   setLoadingTryAgain(false);
    //   openSnackbar("error", "something went wrong, please try again");
    // } else {
    //   setLoadingTryAgain(false);
    //   openSnackbar("error", "something went wrong, please try again");
    // }
  };

  const handleGoToTransaction = () => {
    navigate(`/transaction/${transaction.id}`);
  };

  return (
    <div className={styles.binance_pay_response}>
      <div className={styles.heading}>
        <div className={styles.heading_icon}>
          {queryStringData?.status === "success" ? (
            <img src={green_mark} alt="successful" />
          ) : (
            <img src={failed_mark} alt="failed" />
          )}
        </div>
        <Typography variant="h6">
          {" "}
          {queryStringData?.status === "success"
            ? "Transaction successful"
            : "Transaction failed"}{" "}
        </Typography>
        <Typography>
          {" "}
          {queryStringData?.status === "success"
            ? "Your Binance Pay transaction was successful!"
            : "Your Binance Pay transaction was not successful!"}{" "}
        </Typography>
      </div>
      <div className={styles.body}>
        {queryStringData?.status === "success" && (
          <>
            <Typography variant="h6">You will recieve</Typography>
            <Typography>
              {queryStringData.amount} {queryStringData.currency}
            </Typography>
          </>
        )}

        <div className={styles.btns}>
          {loadingTransaction ? null : (
            <div className={styles.btn}>
              <Button
                text={
                  queryStringData?.status === "success"
                    ? "View transaction details"
                    : "Try again"
                }
                bgColor={"#1A2CCE"}
                loading={isLoadingBinancePayPayment}
                color={"#fff"}
                size="md"
                fullwidth
                type={"click"}
                borderRadius={"5px"}
                onClick={() => {
                  queryStringData?.status === "success"
                    ? handleGoToTransaction()
                    : handleTryAgain();
                }}
              />
            </div>
          )}

          <div className={styles.btn}>
            <Button
              Styles
              text={"View balances"}
              bgColor={loadingTransaction ? "#1A2CCE" : null}
              color={loadingTransaction ? "white" : "#676767"}
              size="md"
              type={"click"}
              borderRadius={"5px"}
              fullwidth
              onClick={() => {
                handleCloseModal();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BinancePayResponse;
