import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useState } from "react";
import styles from "./TransactionReceipt.module.css";
import logo from "../../../../assets/images/logo-dark.svg";
import CopyButton from "../../../UI-components/copy-button/CopyButton";
import { useLocation } from "react-router-dom";
import Moment from "react-moment";
import { DataCentral } from "../../../../context/AppContext";

function TransactionReceipt() {
  const { state: transaction } = useLocation();
  const { currencies } = useContext(DataCentral);

  const [copied, setCopied] = useState(false);
  const [copied2, setCopied2] = useState(false);
  return (
    <div className={styles.transaction_receipt}>
      <header className={styles.transaction_receipt_header}>
        <Typography>Transaction receipt</Typography>
      </header>
      <Container maxWidth="md">
        <div className={styles.transaction_receipt_body}>
          <div className={styles.summary}>
            <div className={styles.heading}>
              <Typography>Summary</Typography>
            </div>
            <div className={styles.body}>
              <div className={styles.item}>
                <Typography variant="h6">amount</Typography>
                <Typography>
                  {transaction?.currency}{" "}
                  {transaction?.amount?.toLocaleString(
                    "en-US",
                    currencies?.result?.find((currency) => {
                      if (currency.code === transaction?.currency) {
                        return currency.name;
                      } else {
                        return null;
                      }
                    }).type === "CRYPTO"
                      ? { minimumFractionDigits: 8 }
                      : { minimumFractionDigits: 2 }
                  )}
                </Typography>
              </div>
              <div className={`${styles.item}`}>
                <Typography variant="h6">date</Typography>
                <div className={styles.date}>
                  <Typography variant="h6">
                    <Moment
                      format="DD MMM YYYY"
                      date={transaction?.created_at}
                    />
                  </Typography>
                  <Typography>
                    {new Date(transaction?.created_at)?.toLocaleString(
                      "en-US",
                      { hour: "numeric", minute: "numeric", hour12: true }
                    )}
                  </Typography>
                </div>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">transaction reference</Typography>
                <div className={styles.reference}>
                  <Typography variant="h6">{transaction?.reference}</Typography>
                  <CopyButton
                    copy={transaction?.reference}
                    setCopied={setCopied}
                    copied={copied}
                  />
                </div>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">type</Typography>
                <Typography>{transaction?.type}</Typography>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">status</Typography>
                <div className={`${styles.status}`}>
                  <Typography
                    className={`${
                      transaction?.status === "pending" && styles.pending
                    } ${
                      transaction?.status === "success" && styles.approved
                    }  ${transaction?.status === "failed" && styles.failed} `}
                  >
                    {transaction?.status}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.summary_2}>
            <div className={styles.heading}>
              <Typography>Transaction Details</Typography>
            </div>
            <div className={`${styles.body} ${styles.border_radius_none}`}>
              <div className={styles.item}>
                <Typography variant="h6">Amount received</Typography>
                <Typography>500, 000 NGN</Typography>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">Fees (VAT inclusive)</Typography>
                <Typography>
                  {" "}
                  {transaction?.fee?.toLocaleString()} {transaction?.currency}
                </Typography>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">fee bearer</Typography>
                <Typography>
                  {" "}
                  {transaction?.fee_bearer
                    ? transaction?.fee_bearer === "merchant"
                      ? "SELF"
                      : transaction?.fee_bearer
                    : "NA"}
                </Typography>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">status</Typography>
                <div className={styles.status}>
                <Typography   className={`${
                    transaction?.status === "pending" && styles.pending} ${
                    transaction?.status === "success" && styles.approved}  ${
                    transaction?.status === "failed" && styles.failed
                  } `} >{transaction?.status}</Typography>
                </div>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">Merchant reference number</Typography>
                <div className={styles.reference}>
                  <Typography variant="h6">
                    {" "}
                    {transaction?.merchant_reference
                      ? transaction?.merchant_reference
                      : "NA"}
                  </Typography>
                  {transaction?.merchant_reference ? (
                    <CopyButton
                      copy={transaction?.merchant_reference}
                      setCopied={setCopied2}
                      copied={copied2}
                    />
                  ) : null}
                </div>
              </div>
              <div className={styles.item}>
                <Typography variant="h6">description</Typography>
                <Typography>{transaction?.narration || "NA"}</Typography>
              </div>
            </div>
          </div>
          <div className={styles.total}>
            <Typography variant="h6">Total amount</Typography>
            <Typography>
              {" "}
              {transaction?.amount_charged?.toLocaleString(
                "en-US",
                currencies?.result?.find((currency) => {
                  if (currency.code === transaction.currency) {
                    return currency.name;
                  } else {
                    return null;
                  }
                }).type === "CRYPTO"
                  ? { minimumFractionDigits: 8 }
                  : { minimumFractionDigits: 2 }
              )}{" "}
              {transaction?.currency}
            </Typography>
          </div>
          <div className={styles.logo}>
            <img src={logo} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TransactionReceipt;
