import { useEffect, useState } from "react";
import { extractFileType } from "../utils/helpers";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

const fileTypes = ["pdf", "png", "jpg", "jpeg"];

const fileValidation = (name) => {
  return Yup.mixed()
    .nullable("Personal document file is required")
    .test({
      name: "required",
      message: "Document is required",
      test: (value) => value?.length > 0,
    })
    .test({
      name: "fileSize",
      message: "file uploaded is larger then 500kb",
      test: (value) => {
        if (typeof value === "object") {
          return value[0]?.size < 500000;
        } else {
          return true;
        }
      },
    })
    .test({
      name: "fileType",
      message: "Only PDF, PNG, JPG & JPEG are allowed",
      test: (value) => {
        if (typeof value === "object") {
          return fileTypes.includes(extractFileType(value[0]?.name));
        } else {
          return true;
        }
       
      },
    });
};

const useDynamicSchema2 = (directorsCount, shareHoldersCount, fields) => {
  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schemaKeys = [];

    fields.map((field) => {
      if (field === "country") {
        const fieldToPush = {
          name: `${field}`,
          validation: Yup.string().required("Country is required"),
        };
        schemaKeys.push(fieldToPush);
      } else if (field === "id_directors_") {
        Array.from({ length: directorsCount }).map((e, index) => {
          const fieldToPush = {
            name: `${field}${index}`,
            validation: fileValidation(),
          };
          schemaKeys.push(fieldToPush);
        });
      } else if (field === "id_shareholders_") {
        Array.from({ length: shareHoldersCount }).map((e, index) => {
          const fieldToPush = {
            name: `${field}${index}`,
            validation: fileValidation(),
          };
          schemaKeys.push(fieldToPush);
        });
      } else {
        const fieldToPush = {
          name: field,
          validation: fileValidation(),
        };
        schemaKeys.push(fieldToPush);
      }
    });

    setSchema(createSchema(schemaKeys));
  }, [directorsCount, shareHoldersCount]);

  const createSchema = (keys) => {
    let schema = {};

    keys.forEach((key) => {
      schema[key.name] = key.validation;
    });

    return Yup.object().shape(schema);
  };

  return {
    schema,
  };
};

export default useDynamicSchema2;
