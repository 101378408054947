import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "../../../styles/RequestOverdraft.module.css";
import Button from "../../../../../../../UI-components/button";
import RequestOverdraftSuccessful from "../request-overdraft-successful/RequestOverdraftSuccessful";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../../../../UI-components/validation-message/ValidationMessage";
import LabeledInputsWithText from "../../../../../../../UI-components/new-inputs/labeledInputsWithTexts/LabeledInputsWithTexts";
import LabeledSelectTwo from "../../../../../../../UI-components/select/labeledSelectTwo/LabeledSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { REQUEST_OVERDRAFT } from "../../../../../../../../api/mutations/dashboard/overdraft";

const RequestOverdraft = ({
  currencyPairs,
  options,
  handleActionTwo,

  val,
  openSnackbar,
}) => {
  switch (val) {
    case "success":
      return <RequestOverdraftSuccessful />;
    case "":
      return (
        <Request
          currencyPairs={currencyPairs}
          options={options}
          handleActionTwo={handleActionTwo}
          openSnackbar={openSnackbar}
        />
      );
    default:
      return (
        <Request
          currencyPairs={currencyPairs}
          options={options}
          handleActionTwo={handleActionTwo}
          openSnackbar={openSnackbar}
        />
      );
  }
};

export default RequestOverdraft;

const Request = ({ currencyPairs, options, handleActionTwo, openSnackbar }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const fundType = watch("fund_type", "");
  const currencyPair = watch("currency_pair", "");
  const [baseAsset, setBaseAsset] = useState("");
  const [quoteAsset, setQuoteAsset] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(REQUEST_OVERDRAFT, {
    onSuccess: (response) => {
      openSnackbar("success", response.message);
      handleActionTwo();
      queryClient.invalidateQueries(["overdraft"]);
    },
    onError: (error) => {
      openSnackbar("error", error.response.data.message);
    },
  });
  useEffect(() => {
    handleSeperatePair(currencyPair);
  }, [currencyPair]);

  const handleSeperatePair = (pair) => {
    const pairs = pair.split("/");
    setBaseAsset(pairs[0]);
    setQuoteAsset(pairs[1]);
  };

  useEffect(() => {
    if (fundType === "Unlimited payout") {
      setValue("amount", 0);
    }
  }, [fundType]);

  const onSubmit = async (data) => {
    mutate({
      currency_pair: data.currency_pair,
      limited: data.fund_type,
      amount: parseFloat(data.amount),
    });
  };
  return (
    <form
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.request_overdraft}
    >
      <div className={styles.heading}>
        <Typography>Request Liquidity</Typography>
      </div>
      <div className={styles.body}>
        <div className={styles.field}>
          <LabeledSelectTwo
            data={currencyPairs?.map((pair) => {
              return {
                label: pair.pair,
                value: pair.pair,
              };
            })}
            placeholder="Currency pairs"
            required={true}
            name="currency_pair"
            register={register}
            label="Currency pairs"
            redOutline={errors.currency_pair}
          />

          <ValidationMessage
            message={errors.currency_pair ? "currency pair is required" : null}
          />
        </div>

        <div className={styles.field}>
          <LabeledInputsWithText
            watch={watch}
            setValue={setValue}
            label={"Credit cap"}
            placeholder={"0.00"}
            text={baseAsset}
            name="amount"
            type={"number"}
            redOutline={errors.amount ? true : false}
            register={register}
          />
          <ValidationMessage
            message={errors.amount ? "amount is required" : null}
          />
          <div className={styles.max_credit}>
            <Typography>
              This is the maximum credit amount you wish to request
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.btn}>
          <Button
            text={"Cancel"}
            bgColor={"#FFFFFF"}
            color={"#404040"}
            size="md"
            // fullwidth
            type="button"
            borderRadius={"5px"}
            onClick={() => handleActionTwo()}
          />
          <Button
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color={"#fff"}
            size="md"
            // fullwidth
            type="button"
            borderRadius={"5px"}
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </form>
  );
};
